(function() {
    angular.module('felfel').controller('OpsChecklists', OpsChecklists);

    OpsChecklists.$inject = ['SwalHelper', 'allServiceTiers', 'Restangular'];
    function OpsChecklists(SwalHelper, allServiceTiers, Restangular) {
        const vm = this;
        vm.locations = [];

        Restangular.all('api/v2/locations/list')
            .getList()
            .then(
                function(data) {
                    let locations = data.plain().filter(x => x.Status.IsActive);
                    determineLanguage(locations);
                    vm.allActiveLocations = locations;
                },
                function() {
                    SwalHelper.showServerError();
                }
            );

        vm.serviceTiers = allServiceTiers;
        vm.selectedLanguage = '';
        vm.selectedServiceTier = '';
        vm.clearAll = clearAll;
        vm.save = save;
        vm.selectAll = selectAll;
        vm.selectLanguage = selectLanguage;
        vm.selectedLocations = [];
        vm.selectByServiceTierId = selectByServiceTierId;
        vm.populateTasks = populateTasks;
        vm.getTasks = getTasks;

        function getTasks(locationId) {
            Restangular.all('api/v2/locations')
                .one(locationId)
                .one('checklist')
                .get()
                .then(
                    function(data) {
                        let tasks = data.plain();
                        vm.viewLocation.LogisticInfoText1 = tasks.LogisticInfoText1;
                        vm.viewLocation.LogisticInfoText2 = tasks.LogisticInfoText2;
                        vm.viewLocation.LogisticInfoText3 = tasks.LogisticInfoText3;
                    },
                    function() {
                        SwalHelper.showServerError();
                    }
                );
        }

        function clearAll() {
            vm.selectedLocations = [];
            vm.selectedLanguage = '';
            vm.selectedServiceTier = '';
        }

        function selectByServiceTierId(serviceTierId) {
            vm.selectedServiceTier = serviceTierId;
            vm.selectedLocations = filterSelection(vm.selectedLanguage, serviceTierId);
        }

        function selectLanguage(language) {
            vm.selectedLanguage = language;

            vm.selectedLocations = filterSelection(language, vm.selectedServiceTier);
            //vm.allActiveLocations.filter( x => x[language])
        }

        function filterSelection(language, serviceTierId) {
            let filtered = vm.allActiveLocations;

            if (language) {
                filtered = filtered.filter(x => x[language]);
            }

            if (serviceTierId) {
                filtered = filtered.filter(x => x.ServiceTierId === serviceTierId);
            }

            return filtered;
        }

        function populateTasks() {
            if (!vm.viewLocation) {
                vm.showReuseTextMessage = true;
                return;
            }

            vm.task1 = vm.viewLocation.LogisticInfoText1;
            vm.task2 = vm.viewLocation.LogisticInfoText2;
            vm.task3 = vm.viewLocation.LogisticInfoText3;
        }

        function isFrenchSpeaking(lat, lon) {
            const latCalc = ((47.510231 - 45.896973) / (7.160698 - 7.75366)) * (lon - 7.160698) + 47.510231;

            if (lat <= latCalc) {
                return true;
            }
            return false;
        }

        function isGermanSpeaking(lat, lon) {
            return !isFrenchSpeaking(lat, lon) && !isItalianSpeaking(lat, lon);
        }

        function isItalianSpeaking(lat, lon) {
            const latUpperLimit = 46.479613;

            const lonLeftLimit = 8.429509;
            const lonRightLimit = 9.227508;

            if (lat <= latUpperLimit && lon >= lonLeftLimit && lon <= lonRightLimit) {
                return true;
            }
            return false;
        }

        function selectAll() {
            vm.selectedLocations = [];
            vm.selectedLanguage = '';
            vm.selectedServiceTier = '';

            vm.selectedLocations = vm.allActiveLocations;
        }

        function determineLanguage(locations) {
            for (let i = 0; i < locations.length; i++) {
                let location = locations[i];
                location.italian = false;
                location.german = false;
                location.french = false;

                if (isItalianSpeaking(location.Latitude, location.Longitude)) {
                    location.italian = true;
                }

                if (isGermanSpeaking(location.Latitude, location.Longitude)) {
                    location.german = true;
                }

                if (isFrenchSpeaking(location.Latitude, location.Longitude)) {
                    location.french = true;
                }
            }
        }

        function save() {
            if (!vm.selectedLocations) return;

            let request = {};

            request.locationsToUpdate = vm.selectedLocations.map(location => location.Id);
            request.LogisticInfoText1 = vm.task1;
            request.LogisticInfoText2 = vm.task2;
            request.LogisticInfoText3 = vm.task3;

            Restangular.all('api/v2/locations/checklist')
                .patch(request)
                .then(
                    function() {
                        SwalHelper.showSaved();
                    },
                    function() {
                        SwalHelper.showServerError();
                    }
                );
        }
    }
})();
