(function() {
    const app = angular.module('felfel.menu.routes', ['ui.router', 'felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const menuList = {
            name: 'products.menus_list',
            url: '/menus/:page',
            params: {
                page: {
                    type: 'int',
                    value: 1, // <-- Default value
                    squash: false,
                },
            },
            templateUrl: './templates/products/menus/menu.html',
            controller: 'Menu',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const menuEdit = {
            name: 'products.menus_edit',
            url: '/menus/edit/:id?forecastweekid&startDate',
            params: { id: '' },
            templateUrl: './templates/products/menus/menu-edit.html',
            controller: 'MenuEdit',
            controllerAs: 'vm',
            resolve: {
                activeProducts,
                allLocations,
                foodCategories,
                foodTags,
                foodTypes,
                menu,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(menuList))
            .state(new appConfig.UiRouterConfig(menuEdit));
    }

    //
    // resolve functions
    //
    activeProducts.$inject = ['Products'];
    function activeProducts(Products) {
        return Products.one()
            .get({ page: 1, pageSize: '9999', IsActive: true })
            .then(
                function(data) {
                    const products = data.plain().Result;
                    for (let i = 0; i < products.length; i++) {
                        const j = _.findIndex(products[i].ProductLanguages, {
                            LanguageCode: 'en-US',
                        });
                        if (j > -1) {
                            products[i].DisplayName = products[i].ProductLanguages[j].ProductName;

                            if (products[i].IsFamily) {
                                products[i].DisplayName += ` (${products[i].ProductFamilyItems.length})`;
                            }
                        } else {
                            products[i].DisplayName = 'Invalid Product Name';
                        }
                    }
                    return products;
                },
                function(err) {
                    swal(errMsg);
                }
            );
    }

    allLocations.$inject = ['Common', 'Locations', 'SwalHelper'];
    function allLocations(Common, Locations, SwalHelper) {
        return Locations.getList({ selectList: true }).then(
            function(data) {
                return Common.sortByName(data, 'LocationName');
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allObjs.$inject = ['WebAPIMenus', 'SwalHelper', '$transition$'];
    function allObjs(WebAPIMenus, SwalHelper, $transition$) {
        const { page } = $transition$.params();

        return WebAPIMenus.one()
            .get({ page, pageSize: 30 })
            .then(
                function(data) {
                    swal.close();
                    return data.plain();
                },
                function(err) {
                    SwalHelper.showError(err);
                }
            );
    }

    foodCategories.$inject = ['FoodCats'];
    function foodCategories(FoodCats) {
        return FoodCats.getList().then(
            function(data) {
                return data.plain();
            },
            function(err) {
                swal({
                    title: '',
                    text: 'Server Error!',
                    type: 'error',
                });
            }
        );
    }

    foodTags.$inject = ['FoodTags'];
    function foodTags(FoodTags) {
        return FoodTags.getList().then(
            function(data) {
                return data.plain();
            },
            function(err) {
                swal({
                    title: '',
                    text: 'Server Error!',
                    type: 'error',
                });
            }
        );
    }

    foodTypes.$inject = ['FoodTypes'];
    function foodTypes(FoodTypes) {
        return FoodTypes.getList().then(
            function(data) {
                return data.plain();
            },
            function(err) {
                swal({
                    title: '',
                    text: 'Server Error!',
                    type: 'error',
                });
            }
        );
    }

    menu.$inject = ['WebAPIMenus', 'SwalHelper', '$transition$'];
    function menu(WebAPIMenus, SwalHelper, $transition$) {
        if ($transition$.params().id) {
            return WebAPIMenus.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        return data;
                    },
                    function(err) {
                        SwalHelper.showError(err);
                    }
                );
        }
    }
})();
