(function() {
    angular.module('felfel').factory('SupplierOrders', SupplierOrders);

    SupplierOrders.$inject = ['Restangular'];
    function SupplierOrders(Restangular) {
        const SupplierOrders = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRequestSuffix('/').setRestangularFields({
                id: 'supplierorderid',
            });

            RestangularConfigurer.setBaseUrl(REACT_APP_IX_URI);
        });

        return SupplierOrders.service('ix/supplierorders/');
    }
})();
