(function() {
    const app = angular.module('felfel.users.routes', [
        'felfel.config',
        'felfel.account.routes',
        'felfel.user.routes',
        'felfel.cashvoucher.routes',
        'felfel.promo.routes',
        'felfel.news.routes',
        'felfel.homedelivery.routes',
    ]);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const usersAbstract = {
            abstract: true,
            name: 'users',
            url: '/users',
            templateUrl: './templates/users/users-abstract.html',
        };

        const homeDeliveryAbstract = {
            abstract: true,
            name: 'homedelivery',
            url: '/home-delivery',
            templateUrl: './templates/users/users-abstract.html',
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(usersAbstract))
            .state(new appConfig.UiRouterConfig(homeDeliveryAbstract));
    }
})();
