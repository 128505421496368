(function() {
    angular.module('felfel').factory('ProductItems', ProductItems);

    ProductItems.$inject = ['Restangular'];
    function ProductItems(Restangular) {
        const ProductItems = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'ProductItemKey',
            });
        });

        // argument is the RESTapi URL
        return ProductItems.service('api/ProductItems');
    }
})();
