(function() {
    const app = angular.module('felfel.user.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const accounting = {
            name: 'users.accounting',
            url: '/users/accounting/',
            templateUrl: './templates/users/users/accounting.html',
        };

        const userList = {
            name: 'users.users_list',
            url:
                '/users/{page:int}?searchCardId&searchBalance&searchEmail&searchEmailConfirmed&searchFirstName&searchLastName&searchPageSize&location&orderby',
            params: { page: 1 },
            templateUrl: './templates/users/users/user.html',
            controller: 'User',
            controllerAs: 'vm',
            //reloadOnSearch: false,
            resolve: {
                allObjs,
                locationsList,
            },
        };

        const userEdit = {
            name: 'users.users_edit',
            url: '/users/edit/:id',
            params: { id: '' },
            templateUrl: './templates/users/users/user-edit.html',
            controller: 'UserEdit',
            controllerAs: 'vm',
            resolve: {
                userAccountBalance,
                userPaymentMethods,
                userContacts,
                userProfile,
                allLocations,
                allPaymentMethods,
            },
        };

        const userTransactionHistory = {
            name: 'users.users_transaction_history',
            url: '/users/transactionhistory/:id/{page:int}?year&month',
            params: {
                page: 1,
                year: undefined,
                month: undefined,
                userObject: null,
            },
            templateUrl: './templates/users/users/user-transaction-history.html',
            controller: 'UserTransactionHistory',
            controllerAs: 'vm',
            resolve: {
                allCashBookPositions,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(accounting))
            .state(new appConfig.UiRouterConfig(userList))
            .state(new appConfig.UiRouterConfig(userEdit))
            .state(new appConfig.UiRouterConfig(userTransactionHistory));
    }

    const errMsg = {
        title: '',
        text: 'Server Error!',
        type: 'error',
    };

    //
    // resolve functions
    //
    allCashBookPositions.$inject = ['CashBookPositions', '$state', '$transition$'];
    function allCashBookPositions(CashBookPositions, $state, $transition$) {
        const { page } = $transition$.params();

        return CashBookPositions.one($transition$.params().id)
            .get({
                page,
                pageSize: '20',
                year: $transition$.params().year,
                month: $transition$.params().month,
            })
            .then(
                function(data) {
                    for (let i = 0; i < data.Result.length; i++) {
                        data.Result[i].BookingDate = new Date(data.Result[i].BookingDate);
                    }
                    return data;
                },
                function(err) {
                    console.log('GET CashBookPositions Error!');
                }
            );
    }

    allLocations.$inject = ['Locations'];
    function allLocations(Locations) {
        return Locations.getList({ selectList: true }).then(
            function(data) {
                return data;
            },
            function(err) {
                swal(errMsg);
            }
        );
    }

    locationsList.$inject = ['Restangular', 'SwalHelper'];
    function locationsList(Restangular, SwalHelper) {
        return Restangular.all('api/v2/locations/list')
            .getList()
            .then(
                function(data) {
                    return data.plain();
                },
                function() {
                    SwalHelper.showServerError();
                }
            );
    }

    allObjs.$inject = ['UsersWebApi', '$transition$'];
    function allObjs(UsersWebApi, $transition$) {
        return UsersWebApi.one()
            .get({
                page: $transition$.params().page,
                pageSize: $transition$.params().searchPageSize ? $transition$.params().searchPageSize : '20',
                balance: $transition$.params().searchBalance,
                cardId: $transition$.params().searchCardId,
                email: $transition$.params().searchEmail,
                emailConfirmed: $transition$.params().searchEmailConfirmed,
                firstName: $transition$.params().searchFirstName,
                lastName: $transition$.params().searchLastName,
                locationId: $transition$.params().location,
                sortOrder: $transition$.params().orderby ? $transition$.params().orderby : 'email',
            })
            .then(
                function(data) {
                    swal.close();
                    return data.plain();
                },
                function(err) {
                    swal(errMsg);
                }
            );
    }

    allPaymentMethods.$inject = ['PaymentMethods'];
    function allPaymentMethods(PaymentMethods) {
        return PaymentMethods.getList().then(
            function(data) {
                return data.plain();
            },
            function(err) {
                swal(errMsg);
            }
        );
    }

    userAccountBalance.$inject = ['Users', '$transition$'];
    function userAccountBalance(Users, $transition$) {
        return Users.one($transition$.params().id)
            .one('account-balance')
            .get()
            .then(
                function(data) {
                    return data.plain();
                },
                function(err) {
                    swal(errMsg);
                }
            );
    }

    userContacts.$inject = ['Users', '$transition$'];
    function userContacts(Users, $transition$) {
        return Users.one($transition$.params().id)
            .one('contacts')
            .get()
            .then(
                function(data) {
                    return data.plain();
                },
                function(err) {
                    return undefined;
                }
            );
    }

    userPaymentMethods.$inject = ['Users', '$transition$'];
    function userPaymentMethods(Users, $transition$) {
        return Users.one($transition$.params().id)
            .one('payment-methods')
            .get()
            .then(
                function(data) {
                    return data;
                },
                function(err) {
                    swal(errMsg);
                }
            );
    }

    userInfo.$inject = ['Users', '$transition$'];
    function userInfo(Users, $transition$) {
        return Users.one($transition$.params().id)
            .one('user-info')
            .get()
            .then(
                function(data) {
                    swal.close();
                    return data.plain();
                },
                function(err) {
                    swal(errMsg);
                }
            );
    }

    userProfile.$inject = ['Users', '$transition$'];
    function userProfile(Users, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return Users.one($transition$.params().id)
                .one('profile')
                .get()
                .then(
                    function(data) {
                        // console.log("currentCat", data);
                        return data;
                    },
                    function(err) {
                        swal({
                            title: '',
                            text: 'User does not exist!',
                            type: 'error',
                        });
                    }
                );
        }
    }
})();
