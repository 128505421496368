(function() {
    angular.module('felfel').factory('UserOrders', UserOrders);

    UserOrders.$inject = ['Restangular'];
    function UserOrders(Restangular) {
        const UserOrders = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'OrderId',
            });
        });
        return UserOrders.service('api/UserOrders');
    }
})();
