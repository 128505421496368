(function() {
    angular.module('felfel').factory('FoodTypes', FoodTypes);

    FoodTypes.$inject = ['Restangular'];
    function FoodTypes(Restangular) {
        const FoodTypes = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'FoodTypeId',
            });
        });

        // argument is the RESTapi URL
        return FoodTypes.service('api/FoodTypes');
    }
})();
