(function() {
    angular.module('felfel').factory('InventoryLocations', InventoryLocations);

    InventoryLocations.$inject = ['Restangular'];
    function InventoryLocations(Restangular) {
        const InventoryLocations = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'InventoryLocationId',
            });
        });
        return InventoryLocations.service('api/InventoryLocations');
    }
})();
