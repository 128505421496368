(function() {
    angular
        .module('felfel')
        .controller('PricingGroup', PricingGroup)
        .controller('PricingGroupEdit', PricingGroupEdit);

    // first controller
    PricingGroup.$inject = ['PricingGroups', 'allObjs', 'SwalHelper', '$rootScope', '$state'];
    function PricingGroup(PricingGroups, allObjs, SwalHelper, $rootScope, $state) {
        $rootScope.title = 'Pricing Groups';

        const vm = this;
        vm.pricinggroups = allObjs;
        vm.deleteObj = deleteObj;

        // for sorting table, default options
        vm.sort_pred = 'GroupName';
        vm.sort_rev = false;
        vm.sort = sort;

        function deleteObj(group) {
            SwalHelper.confirmDelete(group.GroupName, function() {
                PricingGroups.one(group.PricingGroupId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(group.GroupName, function() {
                            $state.go('locations.pricinggroups_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    // second controller
    PricingGroupEdit.$inject = ['PricingGroups', 'currentObj', 'SwalHelper', '$state', '$rootScope'];
    function PricingGroupEdit(PricingGroups, currentObj, SwalHelper, $state, $rootScope) {
        const vm = this;

        vm.currentGroup = currentObj || {};
        vm.deleteObj = deleteObj;
        vm.isEdit = !!_.has(vm.currentGroup, 'PricingGroupId');
        vm.saveObj = saveObj;

        // swal alert
        const alert = {
            title: 'Your entry has been saved.',
            text: '',
            type: 'success',
        };

        function deleteObj() {
            SwalHelper.confirmDelete(vm.currentGroup.GroupName, function() {
                PricingGroups.one(vm.currentGroup.PricingGroupId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(vm.currentGroup.GroupName, function() {
                                $state.go('locations.pricinggroups_list', {}, { reload: true });
                                swal.close();
                            });
                        },
                        function() {
                            console.log('error');
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function saveObj(exit) {
            if (_.has(vm.currentGroup, 'PricingGroupId')) {
                vm.currentGroup.put().then(function() {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('locations.pricinggroups_list');
                        } else {
                            $state.go('locations.pricinggroups_edit', {
                                id: vm.currentGroup.PricingGroupId,
                            });
                        }
                    });
                });
            } else {
                PricingGroups.post(vm.currentGroup).then(function(data) {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('locations.pricinggroups_list');
                        } else {
                            $state.go('locations.pricinggroups_edit', {
                                id: data.PricingGroupId,
                            });
                        }
                    });
                });
            }
        }
    }
})();
