(function() {
    angular.module('felfel').controller('DeliveryDefaults', DeliveryDefaults);

    // second controller
    DeliveryDefaults.$inject = ['Restangular', 'SwalHelper', '$state', 'appConfig', 'KeycloakAuth'];
    function DeliveryDefaults(Restangular, SwalHelper, $state, appConfig, KeycloakAuth) {
        const vm = this;

        vm.deliveryMinimumOrderValues = [];
        vm.deliveryFees = [];
        vm.deliveryFeePayeeTypes = [];
        vm.deliveryTypes = [];

        vm.addDeliveryMinimumOrderValue = addDeliveryMinimumOrderValue;
        vm.addDeliveryFee = addDeliveryFee;

        // readonly variables
        vm.deliveryFeeReadOnly = !KeycloakAuth.hasRealmRole('DeliveryFeeCreate');
        vm.deliveryMinimumOrderValueReadOnly = !KeycloakAuth.hasRealmRole('DeliveryMinimumOrderValueCreate');

        loadDeliveryFees();
        loadDeliveryFeePayeeTypes();
        loadDeliveryMinimumOrderValues();
        loadDeliveryTypes();

        function loadDeliveryFeePayeeTypes() {
            return Restangular.one('api/v1/delivery-fee-payee-types')
                .get()
                .then(
                    function(data) {
                        vm.deliveryFeePayeeTypes = data.deliveryFeePayeeTypes;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function loadDeliveryTypes() {
            return Restangular.one('api/v1/delivery-types')
                .get()
                .then(
                    function(data) {
                        vm.deliveryTypes = data.deliveryTypes;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function loadDeliveryMinimumOrderValues() {
            return Restangular.one('/api/v1/delivery-minimum-order-values')
                .get()
                .then(
                    function(data) {
                        for (var i = 0; i < data.deliveryMinimumOrderValues.length; i++) {
                            data.deliveryMinimumOrderValues[i].startDate = new Date(
                                data.deliveryMinimumOrderValues[i].startDate
                            );
                        }

                        vm.deliveryMinimumOrderValues = data.deliveryMinimumOrderValues;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function addDeliveryMinimumOrderValue() {
            console.log(vm.deliveryMinimumOrderValue);
            if (
                vm.deliveryMinimumOrderValue === undefined ||
                vm.deliveryMinimumOrderValue.deliveryType === undefined ||
                vm.deliveryMinimumOrderValue.startDate === undefined ||
                vm.deliveryMinimumOrderValue.minimumOrderValue === undefined
            ) {
                return false;
            }

            // add new company delivery address to collection
            var deliveryMinimumOrderValue = angular.copy(vm.deliveryMinimumOrderValue);

            return Restangular.one('/api/v1/delivery-minimum-order-values')
                .customPOST(deliveryMinimumOrderValue)
                .then(
                    function(data) {
                        SwalHelper.showSaved('');

                        // add new item to list
                        vm.deliveryMinimumOrderValues.push(data);

                        // clear fields after adding
                        vm.deliveryMinimumOrderValue = {};
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function loadDeliveryFees() {
            return Restangular.one('/api/v1/delivery-fees')
                .get()
                .then(
                    function(data) {
                        for (var i = 0; i < data.deliveryFees.length; i++) {
                            data.deliveryFees[i].startDate = new Date(data.deliveryFees[i].startDate);
                        }

                        vm.deliveryFees = data.deliveryFees;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function addDeliveryFee() {
            if (
                vm.deliveryFee === undefined ||
                vm.deliveryFee.deliveryType === undefined ||
                vm.deliveryFee.deliveryFeePayeeType === undefined ||
                vm.deliveryFee.startDate === undefined ||
                vm.deliveryFee.fee === undefined
            ) {
                return false;
            }

            var deliveryFee = angular.copy(vm.deliveryFee);

            return Restangular.one('/api/v1/delivery-fees')
                .customPOST(deliveryFee)
                .then(
                    function(data) {
                        SwalHelper.showSaved('');

                        // add new item to list
                        vm.deliveryFees.push(data);

                        // clear fields after adding
                        vm.deliveryFee = {};
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }
    }
    // end second controller
})();
