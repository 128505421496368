(function() {
    angular.module('felfel').factory('PackagingTypes', PackagingTypes);

    PackagingTypes.$inject = ['Restangular'];
    function PackagingTypes(Restangular) {
        const PackagingTypes = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'PackagingTypeId',
            });
        });

        // argument is the RESTapi URL
        return PackagingTypes.service('api/PackagingTypes');
    }
})();
