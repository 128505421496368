(function() {
    angular.module('felfel').factory('ProductIngredients', ProductIngredients);

    ProductIngredients.$inject = ['Restangular'];
    function ProductIngredients(Restangular) {
        const ProductIngredients = Restangular.withConfig(function(RestangularConfigurer) {
            // RestangularConfigurer.setRestangularFields({
            // 	id: 'PricingGroupId'
            // });
        });
        return ProductIngredients.service('api/ProductIngredients');
    }
})();
