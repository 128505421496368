(function() {
    const app = angular.module('felfel.foodcategory.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const foodCategoryList = {
            name: 'products.foodcategories_list',
            url: '/foodcategories',
            templateUrl: './templates/products/foodcategories/foodcategory.html',
            controller: 'FoodCategory',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const foodCategoryEdit = {
            name: 'products.foodcategories_edit',
            url: '/foodcategories/edit/:id',
            params: { id: '' },
            templateUrl: './templates/products/foodcategories/foodcategory-edit.html',
            controller: 'FoodCategoryEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(foodCategoryList))
            .state(new appConfig.UiRouterConfig(foodCategoryEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['FoodCats'];
    function allObjs(FoodCats) {
        return FoodCats.getList().then(
            function(data) {
                // console.log("allObjs", data);
                return data;
            },
            function(err) {
                // console.log("foodcats get error");
                swal({
                    title: '',
                    text: 'Server Error!',
                    type: 'error',
                });
            }
        );
    }

    currentObj.$inject = ['FoodCats', '$transition$'];
    function currentObj(FoodCats, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return FoodCats.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        // console.log("foodcats get error");
                        swal({
                            title: '',
                            text: 'Food Category does not exist!',
                            type: 'error',
                        });
                    }
                );
        }
    }
})();
