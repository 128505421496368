(function() {
    angular
        .module('felfel')
        .controller('FoodCategory', FoodCategory)
        .controller('FoodCategoryEdit', FoodCategoryEdit);

    // first controller
    FoodCategory.$inject = ['FoodCats', 'allObjs', 'SwalHelper', '$state', '$rootScope'];
    function FoodCategory(FoodCats, allObjs, SwalHelper, $state, $rootScope) {
        $rootScope.title = 'Food Categories';

        const vm = this;
        vm.categories = allObjs;
        vm.categories_en = filterEN(vm.categories);
        vm.deleteObj = deleteObj;

        // for sorting table, default options
        vm.sort_pred = 'name';
        vm.sort_rev = false;
        vm.sort = sort;

        function deleteObj(category) {
            SwalHelper.confirmDelete(category.name, function() {
                FoodCats.one(category.id)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(category.name, function() {
                            $state.go('products.foodcategories_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        // filter GET for only en-US results
        function filterEN(array) {
            const results = [];

            for (let i = 0; i < array.length; i++) {
                const id = array[i].FoodCategoryId;
                const code = array[i].Code;

                for (let j = 0; j < array[i].FoodCategoryLanguages.length; j++) {
                    const lang = _.get(array[i].FoodCategoryLanguages[j], 'LanguageCode');

                    if (lang == 'en-US') {
                        var name = array[i].FoodCategoryLanguages[j].FoodCategoryName;
                        break;
                    } else {
                        var name = 'No En Input';
                    }
                }

                results.push({ id, code, name });
            }

            return results;
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    // second controller
    FoodCategoryEdit.$inject = ['FoodCats', 'currentObj', 'SwalHelper', '$state', '$rootScope'];
    function FoodCategoryEdit(FoodCats, currentObj, SwalHelper, $state, $rootScope) {
        const vm = this;

        vm.currentCat = currentObj || {};
        vm.currentCat.FoodCategoryLanguages = vm.currentCat.FoodCategoryLanguages || [];
        vm.deleteObj = deleteObj;
        vm.isEdit = !!_.has(vm.currentCat, 'FoodCategoryId');
        vm.saveObj = saveObj;

        // check if all languages exist
        // loop through languageOptions defined in rootscope
        vm.lang = $rootScope.languages; // an object with properties language code: language name
        vm.languages = $rootScope.languageOptions; // an array with language codes
        for (let j = 0; j < vm.languages.length; j++) {
            const code = vm.languages[j];
            // if the language code does not exist, append to array
            if (
                !_.some(vm.currentCat.FoodCategoryLanguages, {
                    LanguageCode: code,
                })
            ) {
                vm.currentCat.FoodCategoryLanguages.push({
                    FoodCategoryName: '',
                    LanguageCode: code,
                });
            }
        }

        function deleteObj() {
            SwalHelper.confirmDelete('this Category', function() {
                FoodCats.one(vm.currentCat.FoodCategoryId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted('Category', function() {
                            $state.go('products.foodcategories_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function saveObj(exit) {
            if (_.has(vm.currentCat, 'FoodCategoryId')) {
                vm.currentCat.put().then(function() {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('products.foodcategories_list');
                        } else {
                            $state.go('products.foodcategories_edit', {
                                id: vm.currentCat.FoodCategoryId,
                            });
                        }
                    });
                });
            } else {
                FoodCats.post(vm.currentCat).then(function(data) {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('products.foodcategories_list');
                        } else {
                            $state.go('products.foodcategories_edit', {
                                id: data.FoodCategoryId,
                            });
                        }
                    });
                });
            }
        }
    }
})();
