(function() {
    angular.module('felfel').factory('ForecastWeeks', ForecastWeeks);

    ForecastWeeks.$inject = ['Restangular'];
    function ForecastWeeks(Restangular) {
        const ForecastWeeks = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRequestSuffix('/').setRestangularFields({
                id: 'forecastweekid',
            });

            RestangularConfigurer.setBaseUrl(REACT_APP_IX_URI);
        });

        return ForecastWeeks.service('ix/forecastweeks');
    }
})();

(function() {
    angular.module('felfel').factory('WebAPIForecastWeeks', WebAPIForecastWeeks);

    WebAPIForecastWeeks.$inject = ['Restangular'];
    function WebAPIForecastWeeks(Restangular) {
        const WebAPIForecastWeeks = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRequestSuffix('/').setRestangularFields({
                id: 'forecastWeekId',
            });
        });

        return WebAPIForecastWeeks.service('api/v1/forecast-weeks/');
    }
})();
