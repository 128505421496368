(function() {
    angular.module('felfel').factory('DeliveryBatches', DeliveryBatches);

    DeliveryBatches.$inject = ['Restangular'];
    function DeliveryBatches(Restangular) {
        const DeliveryBatches = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'DeliveryBatchId',
            });
        });
        return DeliveryBatches.service('api/DeliveryBatches');
    }
})();
