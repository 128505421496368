(function() {
    angular.module('felfel').factory('ContactTypes', ContactTypes);

    ContactTypes.$inject = ['Restangular'];
    function ContactTypes(Restangular) {
        const ContactTypes = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'ContactTypeId',
            });
        });
        return ContactTypes.service('api/ContactTypes/');
    }
})();
