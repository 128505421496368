(function() {
    angular.module('felfel').factory('PromoRuleTags', PromoRuleTags);

    PromoRuleTags.$inject = ['Restangular'];
    function PromoRuleTags(Restangular) {
        const PromoRuleTags = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'PromoRuleTagId',
            });
        });

        // argument is the RESTapi URL
        return PromoRuleTags.service('api/v1/promo-rule-tags');
    }
})();
