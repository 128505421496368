(function() {
    angular.module('felfel').directive('contactAssociationList', contactAssociationList);

    contactAssociationList.$inject = ['appConfig'];
    function contactAssociationList(appConfig) {
        const directive = {
            replace: true,
            templateUrl: `./templates/directives/contact-association-list.html?ver=${appConfig.appVersion}`,
            scope: {
                contactObject: '=',
                contactModel: '=',
                showTitle: '=',
            },
            // link: function(scope) {
            // 	console.log(scope.showTitle);
            // }
        };

        return directive;
    }
})();
