(function() {
    angular.element(document).ready(function() {
        let config = 'keycloak.json';
        if (env === 'prod') {
            config = 'keycloak-prod.json';
        }

        const keycloakAuth = new Keycloak(config);
        keycloakAuth
            .init({ onLoad: 'check-sso' })
            .success(function() {
                // declare as a factory
                angular.module('felfel.keycloak', []).factory('KeycloakAuth', KeycloakAuth);
                function KeycloakAuth() {
                    return keycloakAuth;
                }

                angular.bootstrap(document, ['felfel']);
            })
            .error(function() {
                window.location.reload();
                console.log('Reload');
            });
    });
})();
