(function() {
    angular.module('felfel').factory('ProductPrice', ProductPrice);

    ProductPrice.$inject = ['Restangular'];
    function ProductPrice(Restangular) {
        const ProductPrice = Restangular.withConfig(function(RestangularConfigurer) {
            // RestangularConfigurer.setRestangularFields({
            // 	id: 'PricingGroupId'
            // });
        });
        return ProductPrice.service('api/ProductPrice');
    }
})();
