(function() {
    const app = angular.module('felfel.cashvoucher.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const voucherLookup = {
            name: 'users.cash_vouchers_lookup',
            url: '/cash-vouchers/lookup',
            templateUrl: './templates/users/cashvouchers/cashvouchers-lookup.html',
            controller: 'CashVouchersLookup',
            controllerAs: 'vm',
            resolve: {
                allLocations,
            },
        };

        $stateProvider.state(new appConfig.UiRouterConfig(voucherLookup));
    }

    //
    // resolve functions
    //
    allLocations.$inject = ['Locations', 'SwalHelper'];
    function allLocations(Locations, SwalHelper) {
        return Locations.getList({ selectList: true }).then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }
})();
