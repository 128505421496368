(function() {
    angular
        .module('felfel')
        .controller('Logout', Logout)
        .controller('IxLoginModal', IxLoginModal);

    Logout.$inject = ['$rootScope', '$uibModalStack', 'KeycloakAuth'];
    function Logout($rootScope, $uibModalStack, KeycloakAuth) {
        $rootScope.isLoginPage = true;

        $uibModalStack.dismissAll();
        swal.close();
        KeycloakAuth.logout({ redirectUri: 'http://felfel.ch' });
    }

    IxLoginModal.$inject = ['$uibModalInstance', '$state', 'IX_API'];
    function IxLoginModal($uibModalInstance, $state, IX_API) {
        const vm = this;

        vm.login = {};

        vm.cancel = cancel;
        vm.ok = ok;
        vm.requestLogin = requestLogin;

        function ok() {
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function requestLogin() {
            const data = {
                username: vm.login.email,
                password: vm.login.password,
            };

            IX_API.one('/token')
                .customPOST(data, '', undefined, {
                    'Content-Type': 'application/json',
                })
                .then(
                    function(succ) {
                        vm.login.error = undefined;

                        store.set('felfelix', succ.token);
                        ok();
                        $state.go($state.current.name, $state.params, {
                            reload: true,
                        });
                    },
                    function(err) {
                        vm.login.error = 'Error! Please check your email and password.';
                    }
                );
        }
    }
})();
