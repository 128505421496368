(function() {
    angular.module('felfel.core.common', []).factory('Common', Common);

    function _isObject(obj) {
        return typeof obj === 'object';
    }

    function _isNumber(num) {
        return typeof num === 'number';
    }

    function _isEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    // snakelize a string formed in underscore
    function _snakelize(key) {
        const separator = '_';
        const split = /(?=[A-Z])/;

        return key
            .split(split)
            .join(separator)
            .toLowerCase();
    }

    // camelize a string formed in underscore
    function _camelize(key) {
        if (_isNumber(key)) {
            return key;
        }
        key = key.replace(/[\-_\s]+(.)?/g, function(match, ch) {
            return ch ? ch.toUpperCase() : '';
        });
        // Ensure 1st char is always lowercase
        return key.substr(0, 1).toLowerCase() + key.substr(1);
    }

    // camelize/snakelize keys of an object
    // @param {number} depth to which level of keys should it process
    function _processKeys(obj, processer, depth) {
        if (depth === 0 || !_isObject(obj) || _isEmpty(obj)) {
            return obj;
        }

        if (Array.isArray(obj)) {
            var result = [];

            for (var i = 0; i < obj.length; i++) {
                result.push(_processKeys(obj[i], processer, depth - 1));
            }

            return result;
        }
        var result = {};
        const keys = Object.keys(obj);

        for (var i = 0; i < keys.length; i++) {
            result[processer(keys[i])] = _processKeys(obj[keys[i]], processer, depth - 1);
        }

        return result;
    }

    function Common() {
        return {
            convertToCamelCase,
            convertToSnakeCase,
            getAdjustmentCodes,
            getCoolcellAdjustmentTypes,
            getProductItemStatus,
            getLabelTypes,
            getLocationOptions,
            getProductPreparationTypes,
            getProductPreparationAdvice,
            sortByName,
            getYears,
        };

        /**
         * @param {Object|String} data string or keys of object are named in form of snake
         * @param {number} depth to which level of keys should it process
         * @return {Object|String} string or keys of object are named in form of camel case
         */
        function convertToCamelCase(data, depth) {
            if (_isObject(data)) {
                if (typeof depth === 'undefined') {
                    depth = 1;
                }
                return _processKeys(data, _camelize, depth);
            }
            return _camelize(data);
        }

        function convertToSnakeCase(data, depth) {
            if (_isObject(data)) {
                if (typeof depth === 'undefined') {
                    depth = 1;
                }
                return _processKeys(data, _snakelize, depth);
            }
            return _snakelize(data);
        }

        // "CashBookPositionType"
        function getAdjustmentCodes() {
            return [
                { Id: 300, Status: 'Bank Transfer', DefaultDescription: 'Bank Transfer - Enjoy FELFEL!' },
                { Id: 303, Status: 'Bargeld - Petty Cash Deposit' },
                { Id: 401, Status: 'Lunch Checks Manual' },
                {
                    Id: 500,
                    Status: 'FELFEL Marketing (gifts, tastings, ideas, suggestions, etc.)',
                },
                {
                    Id: 502,
                    Status: 'FELFEL Quality / Customer Service (quality issues, complaints, etc.)',
                },
                { Id: 504, Status: 'FELFEL Referral Bonus' },
                {
                    Id: 503,
                    Status: 'FELFEL Internal (employee lunch benefits (this is currently automated), etc.)',
                },
                { Id: 511, Status: 'FELFEL Credit Growth' },
                { Id: 512, Status: 'FELFEL Credit CP' },
                { Id: 513, Status: 'FELFEL Charge Reimbursement' },
                { Id: 599, Status: 'FELFEL Correction' },
                { Id: 600, Status: 'Corporate Transfer (GWF case, etc.)' },
            ];
        }

        function getCoolcellAdjustmentTypes() {
            return {
                normal: [
                    {
                        desc: 'Manually increase available quantity ',
                        instr: '(when sure that wrong quantity was delivered or inventoried)',
                        from: 10,
                        to: 20,
                    },
                    {
                        desc: 'Manually decrease available quantity ',
                        instr: '(when sure that wrong quantity was delivered or inventoried)',
                        from: 20,
                        to: 10,
                    },
                    {
                        desc: 'Set to Supplier Defective ',
                        instr: '(damaged goods received from supplier)',
                        from: 20,
                        to: 14,
                    },
                    {
                        desc: 'Set to Sorting Error ',
                        instr: '(after picking or inventory recount)',
                        from: 20,
                        to: 26,
                    },
                    {
                        desc: 'Set to Waste ',
                        instr: '(expired food that was not distributed)',
                        from: 20,
                        to: 50,
                    },
                ],
                defective: [
                    {
                        desc: 'Set from Supplier Defective to Available ',
                        instr: '(Inventory Manager only)',
                        from: 14,
                        to: 20,
                    },
                    {
                        desc: 'Set from Supplier Defective to Sorting Error ',
                        instr: '(Inventory Manager only)',
                        from: 14,
                        to: 26,
                    },
                ],

                sortingerror: [
                    {
                        desc: 'Set from Sorting Error to Available ',
                        instr: '(Inventory Manager only)',
                        from: 26,
                        to: 20,
                    },
                    {
                        desc: 'Set from Sorting Error to Supplier Defective ',
                        instr: '(Inventory Manager only)',
                        from: 26,
                        to: 14,
                    },
                ],
            };
        }

        function getLabelTypes() {
            return [
                { Id: 10, Description: 'Old FELFEL Label' },
                { Id: 11, Description: 'PDF 417 Code Only' },
                { Id: 30, Description: 'New FELFEL Large Label' },
                { Id: 31, Description: 'New FELFEL Small Label' },
                { Id: 35, Description: 'New FELFEL Large V2 Label' },
                { Id: 36, Description: 'New FELFEL Small V2 Label' },
            ];
        }

        function getLocationOptions() {
            return {
                OpsFridgeType: [
                    { id: 0, desc: 'N/A' },
                    { id: 10, desc: 'old - POS Left' },
                    { id: 11, desc: 'old - POS Right' },
                    { id: 12, desc: 'old - POS Left - 2 Combined' },
                    { id: 13, desc: 'old - POS Right - 2 Combined' },
                    { id: 20, desc: 'new - POS Left' },
                    { id: 21, desc: 'new - POS Right' },
                    { id: 22, desc: 'new - POS Left - 2 Combined' },
                    { id: 23, desc: 'new - POS Right - 2 Combined' },
                    { id: 30, desc: 'part of Butterfly setup' },
                ],
                OpsFridgeConnection: [
                    { id: 10, desc: '3G' },
                    { id: 20, desc: '3G ext. Antenna' },
                    { id: 30, desc: 'WIFI' },
                    { id: 40, desc: 'LAN' },
                ],
                CEBadgeType: [
                    { id: 0, desc: 'N/A' },
                    { id: 10, desc: 'FELFEL' },
                    { id: 11, desc: 'FELFEL Sticker Badge' },
                    { id: 20, desc: 'Company Badge' },
                    { id: 21, desc: 'Company Badge (KABA)' },
                    { id: 30, desc: 'Other' },
                ],
                ContractType: [
                    { id: 12, desc: 'One Month Notice' },
                    { id: 13, desc: 'Two Months Notice' },
                    { id: 10, desc: 'Three Months Notice' },
                    { id: 14, desc: 'Four Months Notice' },
                    { id: 15, desc: 'Five Months Notice' },
                    { id: 11, desc: 'Six Months Notice' },
                    { id: 20, desc: 'Trial Only' },
                    { id: 30, desc: 'Annual' },
                    { id: 40, desc: 'Month-to-Month' },
                    { id: 50, desc: 'Three Years Fixed' },
                    { id: 51, desc: 'Five Years Fixed' },
                ],
                CpRegion: [
                    { id: 0, desc: 'Not Set' },
                    { id: 1, desc: 'Central' },
                    { id: 2, desc: 'East' },
                    { id: 3, desc: 'North' },
                    { id: 4, desc: 'West' },
                ],
                IndustryType: sortByName(
                    [
                        { id: 0, desc: 'Other' },
                        { id: 10, desc: 'Consumer Products' },
                        { id: 11, desc: 'Financial Services' },
                        { id: 12, desc: 'Government' },
                        { id: 13, desc: 'Industrial' },
                        { id: 14, desc: 'Infrastructure' },
                        { id: 15, desc: 'Marketing / Advertising' },
                        { id: 16, desc: 'Pharma / MedTech' },
                        { id: 17, desc: 'Retail / Wholesale' },
                        { id: 18, desc: 'Services' },
                        { id: 19, desc: 'Technology' },
                    ],
                    'desc'
                ),
                Languages: [
                    { id: 'en-US', desc: 'English' },
                    { id: 'de-CH', desc: 'German' },
                    { id: 'fr-CH', desc: 'French' },
                    { id: 'it-CH', desc: 'Italian' },
                ],
                TrackingOptions: [
                    { id: 0, desc: 'Open' },
                    { id: 1, desc: 'In Progress' },
                    { id: 10, desc: 'No' },
                    { id: 11, desc: 'Yes' },
                    { id: 99, desc: 'Not Applicable' },
                ],
            };
        }

        function getProductPreparationTypes() {
            return [
                { Id: 10, Description: 'Cold' },
                { Id: 20, Description: 'Warm or Cold' },
                { Id: 30, Description: 'Warm' },
            ];
        }

        function getProductPreparationAdvice() {
            return [
                '15 secs',
                '20 secs',
                '30 secs',
                '45 secs',
                '1 min',
                '2 mins',
                '3 mins',
                '4 mins',
                '5 mins',
                '6 mins',
            ];
        }

        function getProductItemStatus() {
            return [
                { Id: 10, Status: 'Created' },
                { Id: 15, Status: 'Rejected' },
                { Id: 20, Status: 'Inventory' },
                { Id: 30, Status: 'On Sale' },
                { Id: 40, Status: 'Waste' },
                { Id: 50, Status: 'Donated' },
                { Id: 60, Status: 'Sold' },
                { Id: 70, Status: 'Missing' },
            ];
        }

        function getYears() {
            return _.range(2014, new Date().getFullYear() + 1, 1).map(function(i) {
                return i.toString();
            });
        }

        function sortByName(array, property) {
            // pass array and property
            const arr = array;
            return arr.sort(function(a, b) {
                // sort by name
                if (a[property] < b[property]) return -1;
                if (a[property] > b[property]) return 1;
                return 0;
            });
        }
    }
})();
