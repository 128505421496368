(function() {
    // filter to make up for .NET not returning date/times with timezone offset set
    angular.module('felfel').filter('utcToLocal', [
        '$filter',
        function($filter) {
            return function(utcDateString, format) {
                if (!utcDateString) {
                    return;
                }

                // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
                if (utcDateString.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) {
                    utcDateString += 'Z';
                }

                return $filter('date')(utcDateString, format);
            };
        },
    ]);
})();
