(function() {
    angular.module('felfel').controller('HomeDeliveryOrderProcessing', HomeDeliveryOrderProcessing);

    // first controller
    HomeDeliveryOrderProcessing.$inject = [
        'Common',
        'Restangular',
        'SwalHelper',
        '$state',
        '$transition$',
        'KeycloakAuth',
    ];
    function HomeDeliveryOrderProcessing(
        Common,
        Restangular,
        SwalHelper,
        $state,
        $transition$,
        KeycloakAuth
    ) {
        const vm = this;

        // constants
        dateFormat = 'YYYY/MM/DD';

        // function definitions
        vm.processOrdersStart = processOrdersStart;
        vm.processOrdersStop = processOrdersStop;

        // query params
        vm.queryAliasId = $transition$.params().aliasId;
        vm.queryDeliveryDate = $transition$.params().deliveryDate;
        vm.queryOverrideDeliveryDateCheck = $transition$.params().overrideDeliveryDateCheck;
        vm.queryPreview = $transition$.params().preview;

        // permissions
        vm.canProcessOrders = KeycloakAuth.hasRealmRole('HomeDeliveryOrderProcessing');

        // properties
        vm.aliasId = vm.queryAliasId ? vm.queryAliasId : null;
        vm.deliveryDate = vm.queryDeliveryDate
            ? new Date(vm.queryDeliveryDate)
            : vm.queryAliasId
            ? null
            : new Date(); // if delivery date is a param convert to date otherwise use a new date unless alias id is passed then don't use a date
        vm.noResults = false;
        vm.orderCreateDate;
        vm.orders = [];
        vm.overrideDeliveryDateCheck ? vm.queryOverrideDeliveryDateCheck : false;
        vm.preview = vm.queryPreview ? vm.queryPreview : false;
        vm.processorRunning = false;

        // functions
        async function getOrdersToProcess() {
            SwalHelper.showLoading('Fetching home delivery orders...');

            await Restangular.one('/api/v1/home-delivery/orders/search')
                .get({
                    aliasId: vm.aliasId ? vm.aliasId : null,
                    deliveryDate: vm.deliveryDate ? moment(vm.deliveryDate).format(dateFormat) : null,
                    page: 1,
                    sortOrder: 'aliasId',
                    homeDeliveryOrderStateAliasId: 'received',
                })
                .then(
                    function(data) {
                        vm.orders = data.pagedResults.results;
                        vm.noResults = vm.orders.length < 1;

                        // close loading popup
                        swal.close();
                    },
                    function(response) {
                        // close loading popup
                        swal.close();

                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showError('Unable to retrieve orders to process');
                        }
                    }
                );
        }

        async function processOrdersStart() {
            // get all orders to process
            await getOrdersToProcess();

            // if there are orders to process initialise order processor
            if (vm.orders.length > 0) {
                vm.processorRunning = true;
            }

            // process orders one at a time
            for (order of vm.orders) {
                // data for processing endpoint
                data = {
                    aliasIds: [order.aliasId],
                    numberOfOrdersToProcess: 1,
                    orderCreateDate: vm.orderCreateDate
                        ? moment(vm.orderCreateDate).format(dateFormat)
                        : null,
                    overrideProcessOrderDeliveryDateCheck: vm.overrideDeliveryDateCheck,
                    preview: vm.preview,
                };

                // if user stops processing - abort processing
                if (!vm.processorRunning) {
                    break;
                }

                // set process state to initial state
                order.processingState = {};
                order.processingState.state = 'Processing...';

                // process order
                await Restangular.one('/api/v1/home-delivery/orders/process')
                    .customPOST(data)
                    .then(
                        function(data) {
                            // set state of processed order from server to local order object
                            order.processingState = data[0];

                            // set order processing state
                            if (!order.processingState.error) {
                                order.processingState.state = 'Success';
                            } else {
                                order.processingState.state = 'Error';
                            }
                        },
                        function(response) {
                            // if there is an error stop processing other orders
                            vm.processorRunning = false;

                            console.log(response);
                            if (response.status == 400) {
                                SwalHelper.showError(response.data.error.message);
                            } else {
                                SwalHelper.showError('Generic error');
                            }
                        }
                    );
            }

            // orders have complete processing
            vm.processorRunning = false;
        }

        function processOrdersStop() {
            vm.processorRunning = false;
        }
    }
})();
