(function() {
    const configSingleton = (function() {
        const appVersion = '1.57.0';
        const baseUrl = REACT_APP_BASE_URL;
        const ixMediaUrl = '';
        const pusherAuth = 'api/Pusher/AuthAdmin';
        const pusherId = REACT_APP_PUSHER_ID;

        const config = {
            appVersion,
            baseUrl,
            ixMediaUrl,
            pusherAuth,
            pusherId,
            UiRouterConfig: function(originalConfig) {
                angular.extend(this, originalConfig);

                // if templateUrl exists, append with version number.
                if (this.templateUrl) {
                    this.templateUrl += '?ver=' + appVersion;
                }
            },
        };

        return config;
    })();

    angular.module('felfel.config', []).constant('appConfig', configSingleton);
})();
