(function() {
    angular
        .module('felfel')
        .controller('Coolcell', Coolcell)
        .controller('CoolcellModalInstanceCtrl', CoolcellModalInstanceCtrl);

    // first controller
    Coolcell.$inject = [
        'allInventoryLocations',
        'appConfig',
        'Common',
        'Locations',
        'Products',
        'ProductItems',
        'SwalHelper',
        '$filter',
        '$state',
        '$transition$',
        '$rootScope',
        '$uibModal',
    ];
    function Coolcell(
        allInventoryLocations,
        appConfig,
        Common,
        Locations,
        Products,
        ProductItems,
        SwalHelper,
        $filter,
        $state,
        $transition$,
        $rootScope,
        $uibModal
    ) {
        $rootScope.title = 'Cool Cell';

        const vm = this;

        vm.allInventoryLocations = allInventoryLocations;
        vm.getBatches = getBatches;
        vm.makeAdjustment = makeAdjustment;
        vm.makeRecall = makeRecall;
        vm.now = new Date().toISOString();
        vm.viewdate = new Date();

        vm.isLocation = false;
        vm.inventory_location = $transition$.params().inventory || '';
        vm.loadView = loadView;

        // for sorting table, default options
        vm.sort_pred = 'Name';
        vm.sort_rev = false;
        vm.sort = sort;
        vm.sortByShelf = sortByShelf;

        if (vm.inventory_location) {
            loadView();
        }

        function getBatches(index) {
            // if (!vm.status[index].IsFresh) return;

            if (vm.status[index].showBatch) {
                ProductItems.one('ProductItemStatusOverview')
                    .get({ ProductId: vm.status[index].ProductId })
                    .then(
                        function(data) {
                            vm.status[index].Batches = data.plain();
                        },
                        function(err) {
                            SwalHelper.showServerError();
                        }
                    );
            }
        }

        function makeAdjustment(parent, index, mode) {
            $uibModal
                .open({
                    templateUrl: `./templates/inventories/inventoryoverview/coolcell-adjustment-modal.html?ver=${
                        appConfig.appVersion
                    }`,
                    controller: 'CoolcellModalInstanceCtrl',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        batch() {
                            return vm.status[parent].Batches[index];
                        },

                        mode() {
                            return mode;
                        },

                        product() {
                            return {
                                ProductId: vm.status[parent].ProductId,
                                ProductName: vm.status[parent].ProductName,
                                IsFresh: vm.status[parent].IsFresh,
                            };
                        },
                    },
                })
                .result.then(
                    function() {
                        // force refresh of batch
                        getBatches(parent);
                    },
                    function() {
                        return null;
                    }
                );
        }

        function makeRecall(parent, index) {
            const txt = `This will recall all ${vm.status[parent].ProductName} from ${$filter('date')(
                vm.status[parent].Batches[index].ProductionDate,
                'EEE d MMM y'
            )}. This will make the items unsellable!`;

            const params = {
                supplierbatchid: vm.status[parent].Batches[index].ProductItemSupplierBatchId,
            };

            SwalHelper.confirm(txt, function() {
                SwalHelper.showSaving();

                ProductItems.one('ProductItemSupplierBatchRecall')
                    .customPUT(undefined, undefined, params)
                    .then(
                        function() {
                            SwalHelper.showSaved();
                            loadView();
                        },
                        function(err) {
                            if (err.data) {
                                SwalHelper.showError(err.data.Message);
                            } else {
                                SwalHelper.showServerError();
                            }
                        }
                    );
            });
        }

        function loadView() {
            const params = {
                InventoryLocationId: vm.inventory_location,
                ExpiringDate: vm.viewdate,
            };

            ProductItems.one('ProductItemStatusOverview')
                .get(params)
                .then(
                    function(data) {
                        vm.status = Common.sortByName(data.plain(), 'ProductName');
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }

        function sortByShelf(bool) {
            if (bool) {
                Common.sortByName(vm.status, 'ShelfPosition');
            } else {
                Common.sortByName(vm.status, 'ProductName');
            }
        }
    }

    CoolcellModalInstanceCtrl.$inject = [
        'batch',
        'mode',
        'product',
        'Common',
        'ProductItems',
        'SwalHelper',
        '$scope',
        '$uibModalInstance',
    ];
    function CoolcellModalInstanceCtrl(
        batch,
        mode,
        product,
        Common,
        ProductItems,
        SwalHelper,
        $scope,
        $uibModalInstance
    ) {
        const vm = this;

        vm.batch = batch;
        vm.cancel = cancel;
        vm.ok = ok;
        vm.product = product;

        vm.adjustmentTypes = Common.getCoolcellAdjustmentTypes()[mode];

        function ok() {
            const txt = `${vm.batch.AdjustmentType.desc} for ${vm.product.ProductName} by ${
                vm.batch.Adjustment
            } ?`;

            const data = {
                ProductItemSupplierBatchId: vm.batch.ProductItemSupplierBatchId,
                Quantity: vm.batch.Adjustment,
                ProductId: vm.product.ProductId,
                IsFresh: vm.product.IsFresh,
                ToStatus: vm.batch.AdjustmentType.to,
                FromStatus: vm.batch.AdjustmentType.from,
            };

            SwalHelper.confirm(txt, function() {
                SwalHelper.showSaving();

                ProductItems.one('InventoryAdjustment')
                    .customPUT(data)
                    .then(
                        function() {
                            SwalHelper.showSaved();
                            $uibModalInstance.close();
                        },
                        function(err) {
                            if (err.data) {
                                SwalHelper.showError(err.data.Message);
                            } else {
                                SwalHelper.showServerError();
                            }
                        }
                    );
            });
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
