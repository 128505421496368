(function() {
    angular.module('felfel').factory('Contacts', Contacts);

    Contacts.$inject = ['Restangular'];
    function Contacts(Restangular) {
        const Contacts = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'ContactId',
            });
        });
        return Contacts.service('api/Contacts/');
    }
})();
