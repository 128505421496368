(function() {
    const app = angular.module('felfel.logisticsbadge.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const logisticsBadgesList = {
            name: 'operations.logisticsbadges_list',
            url: '/logistics_badges/{page:int}?search',
            params: { page: 1, search: undefined },
            templateUrl: './templates/operations/logisticsbadges/logisticsbadge.html',
            controller: 'LogisticsBadge',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const logisticsBadgesEdit = {
            name: 'operations.logisticsbadges_edit',
            url: '/logistics_badges/edit/:id',
            params: { id: '' },
            templateUrl: './templates/operations/logisticsbadges/logisticsbadge-edit.html',
            controller: 'LogisticsBadgeEdit',
            controllerAs: 'vm',
            resolve: {
                allLogCompanies,
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(logisticsBadgesList))
            .state(new appConfig.UiRouterConfig(logisticsBadgesEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['LogisticsBadges', 'SwalHelper', '$transition$'];
    function allObjs(LogisticsBadges, SwalHelper, $transition$) {
        const { page } = $transition$.params();

        if (_.isUndefined($transition$.params().search)) {
            return LogisticsBadges.one()
                .get({ page, pageSize: '20' })
                .then(
                    function(data) {
                        swal.close();
                        return data.plain();
                    },
                    function(err) {
                        SwalHelper.showError(err);
                    }
                );
        }
        return LogisticsBadges.one()
            .get({
                page,
                pageSize: '20',
                search: $transition$.params().search,
            })
            .then(
                function(data) {
                    swal.close();
                    return data.plain();
                },
                function(err) {
                    SwalHelper.showError(err);
                }
            );
    }

    allLogCompanies.$inject = ['LogisticsCompanies', 'SwalHelper'];
    function allLogCompanies(LogisticsCompanies, SwalHelper) {
        return LogisticsCompanies.getList().then(
            function(data) {
                return data.plain();
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    currentObj.$inject = ['LogisticsBadges', 'SwalHelper', '$transition$'];
    function currentObj(LogisticsBadges, SwalHelper, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return LogisticsBadges.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        // console.log((Locations get error");
                        SwalHelper.showError('Log badge does not exist.');
                    }
                );
        }
    }
})();
