(function() {
    angular
        .module('felfel')
        .controller('Adjustment', Adjustment)
        .controller('CreateCodes', CreateCodes)
        .controller('RegisterByBatch', RegisterByBatch)
        .controller('RegisterSingle', RegisterSingle);

    Adjustment.$inject = ['Account', 'Common', 'SwalHelper', '$q', '$state', '$transition$', '$window'];
    function Adjustment(Account, Common, SwalHelper, $q, $state, $transition$, $window) {
        const vm = this;

        init();
        vm.adjustTypes = Common.getAdjustmentCodes();
        vm.backUserId = $transition$.params().userid || '';
        vm.init = init;
        vm.linkBack = linkBack;
        vm.makeAdjustments = makeAdjustments;
        vm.changeAdjustmentType = changeAdjustmentType;
        vm.currentAdjustmentType;

        function init() {
            vm.adjust = {
                UserName: $transition$.params().email || '',
                userNames: [],
                userNamesInput: $transition$.params().email || '',
                errors: [],
                successes: [],
                completed: false,
                multiple: false,
            };

            vm.currentAdjustmentType = undefined;
        }

        function changeAdjustmentType() {
            // set description field to adjustment type default text if it's set
            vm.adjust.Description = vm.currentAdjustmentType.DefaultDescription;

            // set cashbooktype to the id of the selected adjustment type so it can be sent to the server as an id and not an object
            vm.adjust.CashBookType = vm.currentAdjustmentType.Id;
        }

        function linkBack() {
            $window.location.href = `https://namak.felfel.ch/#/application-user/${vm.backUserId}/`;
        }

        function makeAdjustments() {
            SwalHelper.showLoading();

            vm.adjust.errors = [];
            vm.adjust.userNames = vm.adjust.userNamesInput.split(/\n/gi);

            const promises = [];

            for (let i = 0; i < vm.adjust.userNames.length; i++) {
                promises.push(
                    makeAdjustment({
                        UserName: vm.adjust.userNames[i],
                        CashBookType: vm.adjust.CashBookType,
                        Amount: vm.adjust.Amount,
                        Description: vm.adjust.Description,
                    })
                );
            }

            $q.all(promises).then(
                function(succ) {
                    if (vm.adjust.errors.length > 0) {
                        SwalHelper.showError(`Done but with errors! ${vm.adjust.errors.length} failed.`);
                    } else {
                        SwalHelper.showSaved();
                        init();
                    }
                    vm.adjust.completed = true;
                },
                function(err) {
                    SwalHelper.showServerError();
                }
            );
        }

        function makeAdjustment(adjust) {
            return Account.one()
                .all('BalanceChange')
                .post(adjust)
                .then(
                    function(succ) {
                        vm.adjust.successes.push(adjust.UserName);
                    },
                    function(err) {
                        console.log(err);
                        vm.adjust.errors.push(adjust.UserName);
                    }
                );
        }
    }

    // first controller
    CreateCodes.$inject = ['allLocations', 'Common', 'Restangular', 'SwalHelper', '$state', '$rootScope'];
    function CreateCodes(allLocations, Common, Restangular, SwalHelper, $state, $rootScope) {
        const vm = this;

        vm.allLocations = Common.sortByName(allLocations, 'LocationName');
        vm.csvExport = csvExport;
        vm.change = change;
        vm.clear = clear;
        vm.filename = 'export.csv';
        vm.generate = generate;
        vm.isGenerated = false;

        vm.codes = [{ code: 'No Location selected.' }];

        function change() {
            if (vm.location != undefined || vm.location != '') {
                vm.codes[0].code = vm.location.LocationName;
            } else {
                vm.codes[0].code = 'No Location selected.';
            }
        }

        function clear() {
            vm.isGenerated = false;
            vm.location = undefined;
            vm.number = undefined;
            vm.codes = [{ code: 'No Location selected.' }];
        }

        function csvExport() {
            return vm.codes;
        }

        function generate() {
            vm.isGenerated = true;
            Restangular.one('open-registration-keys')
                .customPOST({ amount: vm.number })
                .then(
                    function(succ) {
                        console.log(succ);
                        for (let i = 0; i < succ.length; i++) {
                            vm.codes.push({ code: succ[i] });
                        }
                    },
                    function(err) {
                        console.log(err);
                    }
                );
        }
    }
    // end first controller

    // second controller
    RegisterByBatch.$inject = ['Locations', 'Restangular', 'SwalHelper', 'Users', '$scope'];
    function RegisterByBatch(Locations, Restangular, SwalHelper, Users, $scope) {
        const vm = this;
        vm.clear = clear;
        vm.csvExport = csvExport;
        vm.isUploaded = false;
        vm.upload = upload;
        vm.validate = validate;

        $scope.$watch('vm.content', function() {
            if (vm.content != undefined) {
                vm.dataError = false;

                // validation
                Locations.one(vm.content[0].PrimaryLocationId)
                    .get({ isPublic: true })
                    .then(
                        function(succ) {
                            const name = succ.LocationName;

                            for (let i = 0; i < vm.content.length; i++) {
                                // add Location Name
                                vm.content[i].PrimaryLocationName = name;

                                if (vm.content[i].PrimaryLocationId != vm.content[0].PrimaryLocationId) {
                                    SwalHelper.showError(
                                        'Please check data! Primary Location Id is invalid!'
                                    );
                                    vm.dataError = true;
                                    break;
                                }
                                // QUICK FIX
                                if (
                                    !(
                                        vm.content[i].LanguageCode == 'en-US' ||
                                        vm.content[i].LanguageCode == 'de-CH' ||
                                        vm.content[i].LanguageCode == 'fr-CH' ||
                                        vm.content[i].LanguageCode == 'it-CH' ||
                                        vm.content[i].LanguageCode == 'rm-CH'
                                    )
                                ) {
                                    SwalHelper.showError(
                                        'Please check data and try again! Empty rows, invalid language codes, etc.'
                                    );
                                    vm.dataError = true;
                                    break;
                                } else {
                                    vm.content[i].Locale = vm.content[i].LanguageCode;
                                }
                            }

                            if (!vm.dataError) {
                                vm.data = JSON.parse(JSON.stringify(vm.content));
                            }
                        },
                        function(err) {
                            SwalHelper.showError('check Location Id!');
                        }
                    );
            }
        });

        function clear() {
            vm.content = undefined;
            vm.data = undefined;
            vm.response = undefined;
            vm.isUploaded = false;
        }

        function csvExport() {
            return vm.response;
        }

        function upload() {
            SwalHelper.showLoading();

            vm.isUploaded = true;

            // pass null for empty CardId
            vm.data.forEach(user => {
                if (!user.CardId) {
                    user.CardId = null;
                }
            });

            Users.one('create-by-batch')
                .customPOST(vm.data, '', {}, {})
                .then(
                    function(succ) {
                        console.log(succ);
                        for (let i = 0; i < succ.length; i++) {
                            if (succ[i].emailVerificationSecret) {
                                succ[i].emailVerificationSecret = encodeURIComponent(
                                    succ[i].emailVerificationSecret
                                );
                                succ[i].link = `https://my.felfel.ch/#/main/account/setup?email=${
                                    succ[i].email
                                }&userid=${succ[i].userId}&code=${succ[i].emailVerificationSecret}`;
                            }
                        }
                        vm.response = succ;
                        SwalHelper.showMessage('Activation codes have been generated, please export!');
                    },
                    function(err) {
                        console.log(err);
                        if (err.data) {
                            const validation = [];
                            for (let i = 0; i < err.data.data.length; i++) {
                                console.log(err.data.data[i]);
                                if (!_.isEmpty(err.data.data[i])) {
                                    validation.push(i + 1);
                                }
                            }

                            SwalHelper.showError(`Please check lines ${validation.toString()}`);
                        } else {
                            SwalHelper.showError('Server error!');
                        }
                    }
                );
        }

        function validate() {
            SwalHelper.showLoading();

            Restangular.one('api/Account/ValidateRegister')
                .customPOST(vm.data, '', {}, {})
                .then(
                    function(succ) {
                        SwalHelper.showSuccess('Emails are valid!');
                    },
                    function(err) {
                        SwalHelper.showError(err.data.Message);
                    }
                );
        }
    }
    // end second controller

    // third controller
    RegisterSingle.$inject = [
        'SwalHelper',
        'Common',
        'allLocations',
        'allPaymentMethods',
        'Restangular',
        '$state',
        '$scope',
        'Users',
    ];
    function RegisterSingle(
        SwalHelper,
        Common,
        allLocations,
        allPaymentMethods,
        Restangular,
        $state,
        $scope,
        Users
    ) {
        const vm = this;
        vm.allPaymentMethods = allPaymentMethods;

        vm.languages = [
            { Code: 'en-US', Language: 'English' },
            { Code: 'de-CH', Language: 'German' },
            { Code: 'fr-CH', Language: 'French' },
            { Code: 'it-CH', Language: 'Italian' },
            {
                Code: 'rm-CH',
                Language: 'Romansh (Available only on FELFEL - Defaults to English for other communications)',
            },
        ];

        vm.filterInternalLocations = filterInternalLocations;
        vm.filterMainPayment = filterMainPayment;
        vm.filterSupplementaryPayment = filterSupplementaryPayment;
        vm.register = register;

        allLocations.push({
            LocationId: null,
            LocationName:
                '------------------------------------------ Terminated -----------------------------------------',
            TerminationDate: '2001-01-01 00:00:00Z',
        });

        vm.allLocations = allLocations.sort(function(a, b) {
            // sort by LocationName

            if (!a.TerminationDate && b.TerminationDate) {
                return -1;
            }
            if (a.TerminationDate && !b.TerminationDate) {
                return 1;
            }
            if (!a.TerminationDate && !b.TerminationDate) {
                if (a.LocationName < b.LocationName) return -1;
                if (a.LocationName > b.LocationName) return 1;
            } else if (a.TerminationDate && b.TerminationDate) {
                if (a.LocationName < b.LocationName) return -1;
                if (a.LocationName > b.LocationName) return 1;
            }

            return 0;
        });

        vm.allLocations.unshift({
            LocationId: undefined,
            LocationName: 'All Locations',
        });

        function register() {
            if (!vm.data.cardId) {
                vm.data.cardId = null;
            }

            Users.post(vm.data).then(
                function(succ) {
                    console.log(succ);

                    const dataToKeep = {
                        primaryLocationId: vm.data.primaryLocationId,
                        locale: vm.data.locale,
                        PaymentMethodId: vm.data.PaymentMethodId,
                        SupplementaryPaymentMethodId: vm.data.SupplementaryPaymentMethodId,
                    };

                    let registeredEmail = vm.data.email;
                    vm.data = {};
                    vm.data = dataToKeep;

                    SwalHelper.showMessage('User: ' + registeredEmail + ' has been registered.');
                },
                function(err) {
                    console.log(err);
                    // TODO: Clean up all error messages

                    let err_msg = err.data.message;

                    for (let i = 0; i < err.data.data.length; i++) {
                        err_msg = `${err_msg}\n${err.data.data[i]} `;
                    }

                    SwalHelper.showError(err_msg);
                }
            );
        }

        function filterInternalLocations(a) {
            if (a.IsInternal) {
                return false;
            }

            return true;
        }

        function filterMainPayment(item) {
            if (!item.IsSupplementary) {
                return true;
            }
            return false;
        }

        function filterSupplementaryPayment(item) {
            if (item.IsSupplementary) {
                return true;
            }
            return false;
        }
    }
    // end third controller
})();
