(function() {
    const app = angular.module('felfel.promo.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const promoList = {
            name: 'users.promos_list',
            url: '/promos/{page:int}?search&IsActive',
            params: { page: 1, search: undefined, IsActive: undefined },
            templateUrl: './templates/users/promos/promo.html',
            controller: 'Promo',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const promoEdit = {
            name: 'users.promos_edit',
            url: '/promos/edit/:id',
            params: { id: '' },
            templateUrl: './templates/users/promos/promo-edit.html',
            controller: 'PromoEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
                promoRuleTags,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(promoList))
            .state(new appConfig.UiRouterConfig(promoEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['PromoRules', 'SwalHelper', '$transition$'];
    function allObjs(PromoRules, SwalHelper, $transition$) {
        const { page } = $transition$.params();

        if (_.isUndefined($transition$.params().search)) {
            return PromoRules.one()
                .get({
                    page,
                    pageSize: '20',
                    IsActive: $transition$.params().IsActive,
                })
                .then(
                    function(data) {
                        swal.close();
                        return data.plain();
                    },
                    function(err) {
                        SwalHelper.showError(err);
                    }
                );
        }
        return PromoRules.one()
            .get({
                page,
                pageSize: '20',
                search: $transition$.params().search,
                IsActive: $transition$.params().IsActive,
            })
            .then(
                function(data) {
                    swal.close();
                    return data.plain();
                },
                function(err) {
                    SwalHelper.showError(err);
                }
            );
    }

    currentObj.$inject = ['PromoRules', 'SwalHelper', '$transition$'];
    function currentObj(PromoRules, SwalHelper, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return PromoRules.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        // console.log((Locations get error");
                        SwalHelper.showError('Promo Rule does not exist.');
                    }
                );
        }
    }

    promoRuleTags.$inject = ['PromoRuleTags', 'SwalHelper'];
    function promoRuleTags(PromoRuleTags, SwalHelper) {
        return PromoRuleTags.getList().then(
            function(response) {
                return response.plain();
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }
})();
