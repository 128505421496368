(function() {
    angular
        .module('felfel')
        .controller('Location', Location)
        .controller('LocationEdit', LocationEdit)
        .controller('LocationGroupAdd', LocationGroupAdd);

    // first controller
    Location.$inject = [
        'allObjs',
        'Common',
        'Locations',
        'Restangular',
        'SwalHelper',
        '$window',
        '$state',
        '$transition$',
        '$rootScope',
        '$q',
    ];
    function Location(
        allObjs,
        Common,
        Locations,
        Restangular,
        SwalHelper,
        $window,
        $state,
        $transition$,
        $rootScope,
        $q
    ) {
        $rootScope.title = 'Locations';

        const vm = this;
        vm.pagination = {};
        let defaultSortOption = 'LocationName';

        //functions
        vm.deleteObj = deleteObj;
        vm.escape = escape;
        vm.statusSelected = statusSelected;
        vm.searchByName = searchByName;
        vm.changeSorting = changeSorting;
        vm.pagination.Change = pageChange;
        //functions end

        vm.locations = allObjs.Result;
        vm.locations_filtered = vm.locations;
        vm.now = new Date();
        vm.state = $transition$.params();

        let params = $transition$.params();
        vm.query = _.isUndefined($transition$.params().search) ? '' : $transition$.params().search;
        vm.pagination.CurrentPage = $transition$.params().page;
        vm.currentColumnToSortBy = params.sortOrder ? params.sortOrder : defaultSortOption;

        vm.pagination.PageSize = 20;
        vm.pagination.Count = allObjs.Count;

        vm.reverseSorting = false;
        vm.sortOption = vm.currentColumnToSortBy;

        function changeSorting(columnToSortBy) {
            if (vm.currentColumnToSortBy == columnToSortBy) {
                vm.reverseSorting = !vm.reverseSorting;
            } else {
                vm.reverseSorting = false;
            }
            vm.currentColumnToSortBy = columnToSortBy;

            let sortOption = vm.currentColumnToSortBy;

            if (vm.reverseSorting) {
                sortOption += 'Desc';
            }

            vm.sortOption = sortOption;

            $state.go('locations.locations_list', {
                page: 1,
                sortOrder: sortOption,
            });
        }

        Locations.one('options')
            .get()
            .then(function(response) {
                vm.locationStatuses = response.Statuses;
                vm.locationStatuses.unshift({ DisplayName: 'All', Value: 'all' });
                vm.locationsSelectedStatus = params.status ? params.status : 'Active';
            });

        function statusSelected(status) {
            vm.sortOption = defaultSortOption;
            vm.currentColumnToSortBy = defaultSortOption;
            vm.reverseSorting = false;

            $state.go('locations.locations_list', {
                page: 1,
                status,
            });
        }

        function escape() {
            canceler.resolve();
        }

        function pageChange() {
            $state.go('locations.locations_list', {
                page: vm.pagination.CurrentPage,
            });
        }

        function searchByName(nameString) {
            $state.go('locations.locations_list', {
                search: nameString,
            });
        }

        function deleteObj(location) {
            SwalHelper.confirmDelete(location.LocationName, function() {
                Locations.one(location.LocationId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(location.LocationName, function() {
                            $state.go('locations.locations_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }
    }

    // second controller
    LocationEdit.$inject = [
        'allAddressStates',
        'allCompanies',
        'allContractProducts',
        'allLocationGroups',
        'allLogCompanies',
        'allPricingGroups',
        'allServiceTiers',
        'currentObj',
        'Common',
        'Locations',
        'locationTags',
        'LocationsConfig',
        'logisticsSchedule',
        'LocationGroups',
        'PusherService',
        'Restangular',
        'SwalHelper',
        '$scope',
        '$state',
        '$transition$',
        '$uibModal',
        'appConfig',
        'KeycloakAuth',
    ];
    function LocationEdit(
        allAddressStates,
        allCompanies,
        allContractProducts,
        allLocationGroups,
        allLogCompanies,
        allPricingGroups,
        allServiceTiers,
        currentObj,
        Common,
        Locations,
        locationTags,
        LocationsConfig,
        logisticsSchedule,
        LocationGroups,
        PusherService,
        Restangular,
        SwalHelper,
        $scope,
        $state,
        $transition$,
        $uibModal,
        appConfig,
        KeycloakAuth
    ) {
        // set up pusher //
        if ($transition$.params().id) {
            const channel_name = `presence-loc-edit-${$transition$.params().id}`;
            PusherService.checkConcurrency(channel_name, $scope);
        }

        const vm = this;

        vm.allAddressStates = allAddressStates;
        vm.allContractProducts = allContractProducts;
        vm.allCompanies = Common.sortByName(allCompanies, 'Name');
        vm.allLocationGroups = allLocationGroups;
        vm.allLogCompanies = allLogCompanies;
        vm.allPricingGroups = allPricingGroups;
        vm.allServiceTiers = allServiceTiers;
        vm.logisticsSchedule = logisticsSchedule;
        vm.menuFlavors = [];

        Restangular.one('/api/v1/menus/menu-flavors')
            .get()
            .then(function(data) {
                vm.menuFlavors = data.plain();
            });

        vm.addHomeDeliveryEligiblityDate = addHomeDeliveryEligiblityDate;
        vm.removeHomeDeliveryEligiblityDate = removeHomeDeliveryEligiblityDate;
        vm.saveLocationHomeDeliveryEligiblityDates = saveLocationHomeDeliveryEligiblityDates;
        vm.tabSelected = tabSelected;
        vm.isLogisticsScheduleEditable = false; // TODO - point at permissions (only set to true if current user has permission to edit location schedule) - set to readonly until persmissions are added
        vm.locationTags = locationTags;
        vm.locationHomeDeliveryEligiblityDates = [];

        vm.currentLocation = currentObj || {};
        vm.currentLocation.CompanyId = _.isUndefined($transition$.params().CompanyId)
            ? vm.currentLocation.CompanyId
            : $transition$.params().CompanyId;
        vm.deleteObj = deleteObj;
        vm.isEdit = !!_.has(vm.currentLocation, 'LocationId');
        vm.launchdatecheck = new Date(new Date().getFullYear() + 2, 0, 1);
        vm.options = Common.getLocationOptions();
        vm.saveObj = saveObj;
        vm.today = new Date();
        vm.showControlButtons = true;

        // readonly variables
        vm.eligibilityReadOnly = !KeycloakAuth.hasRealmRole('HomeDeliveryEligibility');
        vm.canEditMenuFlavor = KeycloakAuth.hasRealmRole('MenuUpdate');

        console.log('canEditMenuFlavor', vm.canEditMenuFlavor);

        loadLocationHomeDeliveryEligiblityDates();

        vm.newLocationGroupName = undefined;

        vm.contactModel = {
            name: vm.currentLocation.LocationName,
            id: vm.currentLocation.LocationId,
            idName: 'LocationId',
            model: 'Location',
            list: 'ContactLocations',
            lodashSearch: { LocationId: vm.currentLocation.LocationId },
        };

        vm.tabs = [
            {
                aliasid: 'basicinfo',
                heading: 'Basic Info',
                active: true,
                template: `./templates/locations/locations/location-edit-basic-info.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                aliasid: 'contacts',
                heading: 'Contacts',
                active: false,
                template: `./templates/locations/locations/location-edit-contacts.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                aliasid: 'cp',
                heading: 'Corporate Partnerships',
                active: false,
                template: `./templates/locations/locations/location-edit-corporate-partnerships.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                aliasid: 'happy',
                heading: 'Happy',
                active: false,
                template: `./templates/locations/locations/location-edit-customer-excellence.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                aliasid: 'ops',
                heading: 'Operations & Logistics',
                active: false,
                template: `./templates/locations/locations/location-edit-operations-and-logistics.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                aliasid: 'installation',
                heading: 'Installation',
                active: false,
                template: `./templates/locations/locations/location-edit-installation.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                aliasid: 'homedelivery',
                heading: 'Home Delivery',
                active: false,
                template: `./templates/locations/locations/location-edit-home-delivery.html?ver=${
                    appConfig.appVersion
                }`,
            },
        ];

        // for issue with dates being converted to UTC
        if (vm.isEdit) {
            _.forEach(
                ['OpsInstallDate', 'TerminationDate', 'TrialPhaseStart', 'TrialPhaseEnd', 'ConversionDate'],
                function(field) {
                    if (vm.currentLocation[field] != null) {
                        vm.currentLocation[field] = new Date(vm.currentLocation[field]);
                    }
                }
            );

            if (new Date(vm.currentLocation.LaunchDateTime) > new Date(2013, 1, 1)) {
                vm.launchhour = moment(vm.currentLocation.LaunchDateTime).hours();

                if (vm.launchhour < 10) {
                    vm.launchhour = `0${String(vm.launchhour)}`;
                } else {
                    vm.launchhour = String(vm.launchhour);
                }

                vm.launchminute = moment(vm.currentLocation.LaunchDateTime).minutes();

                if (vm.launchminute < 10) {
                    vm.launchminute = `0${String(vm.launchminute)}`;
                } else {
                    vm.launchminute = String(vm.launchminute);
                }
            }
        }

        function changeDate(date) {
            // to make sure date is always sent as dd-mm-yyyy 00:00:00 UTC
            return `${moment(date)
                .format()
                .substring(0, 11)}00:00:00Z`;
        }

        function clearForm() {
            vm.currentLocation.PricingGroupId = undefined;
            vw.newLocationGroupName = undefined;
        }

        function deleteObj() {
            SwalHelper.confirmDelete(vm.currentLocation.LocationName, function() {
                Locations.one(vm.currentLocation.LocationId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(vm.currentLocation.LocationName, function() {
                                $state.go('locations.locations_list', {}, { reload: true });
                                swal.close();
                            });
                        },
                        function() {
                            // console.log("error");
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        vm.cancel = function() {
            vm.modalInstance.dismiss('cancel');
        };

        function addHomeDeliveryEligiblityDate() {
            vm.locationHomeDeliveryEligiblityDates.push({
                startDate: vm.homeDeliveryEligiblityStartDate,
                endDate: vm.homeDeliveryEligiblityEndDate,
            });

            vm.homeDeliveryEligiblityStartDate = '';
            vm.homeDeliveryEligiblityEndDate = '';
        }

        function loadLocationHomeDeliveryEligiblityDates() {
            return Restangular.one(
                '/api/v1/home-delivery/locations/' + vm.currentLocation.LocationId + '/eligibility'
            )
                .get()
                .then(
                    function(data) {
                        vm.locationHomeDeliveryEligiblityDates = data.eligibleDates;

                        for (var i = 0; i < data.eligibleDates.length; i++) {
                            data.eligibleDates[i].startDate = new Date(data.eligibleDates[i].startDate);

                            if (data.eligibleDates[i].endDate) {
                                data.eligibleDates[i].endDate = new Date(data.eligibleDates[i].endDate);
                            }
                        }
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function removeHomeDeliveryEligiblityDate(item) {
            var index = vm.locationHomeDeliveryEligiblityDates.indexOf(item);
            vm.locationHomeDeliveryEligiblityDates.splice(index, 1);
        }

        function saveLocationHomeDeliveryEligiblityDates() {
            // copy object so we can change the date format for sending to back-end (without timezone just date)
            var dataToSave = angular.copy(vm.locationHomeDeliveryEligiblityDates);
            for (var i = 0; i < dataToSave.length; i++) {
                dataToSave[i].startDate = moment(dataToSave[i].startDate).format('YYYY-MM-DD');

                if (dataToSave[i].endDate) {
                    dataToSave[i].endDate = moment(dataToSave[i].endDate).format('YYYY-MM-DD');
                }
            }

            return Restangular.one(
                '/api/v1/home-delivery/locations/' + vm.currentLocation.LocationId + '/eligibility'
            )
                .customPUT(dataToSave)
                .then(
                    function(data) {
                        SwalHelper.showSaved('');
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function tabSelected(selectedTab) {
            if (selectedTab.aliasid == 'homedelivery') {
                vm.showControlButtons = false;
            } else {
                vm.showControlButtons = true;
            }
        }

        vm.showLocationGroupAdd = function() {
            $uibModal
                .open({
                    templateUrl: `./templates/locations/locationgroups/partials/location-group-add.html?ver=${
                        appConfig.appVersion
                    }`,
                    backdrop: 'static',
                    controllerAs: 'vm',
                    controller: 'LocationGroupAdd',
                    size: 'lg',
                    resolve: {
                        newLocationGroupName() {
                            return vm.newLocationGroupName;
                        },
                    },
                })
                .result.then(
                    function(locationGroupName) {
                        LocationGroups.post({
                            LocationGroupName: locationGroupName,
                        }).then(
                            function(data) {
                                vm.allLocationGroups.push({
                                    LocationGroupId: data.LocationGroupId,
                                    LocationGroupName: data.LocationGroupName,
                                });

                                vm.currentLocation.LocationGroupId = data.LocationGroupId;

                                SwalHelper.showSuccess('New location group added.');
                            },
                            function(err) {
                                console.log(err);

                                if (err !== undefined) {
                                    if (err.status == 409) {
                                        SwalHelper.showError('Location group name already exists.');
                                        return;
                                    }
                                }

                                SwalHelper.showServerError('save');
                            }
                        );
                    },
                    function(cancel) {
                        console.log(cancel);

                        return null;
                    }
                );
        };

        function saveObj(exit) {
            // copy CELocationName if LocationName is empty
            if (!vm.currentLocation.CELocationName || vm.currentLocation.CELocationName == '') {
                vm.currentLocation.CELocationName = vm.currentLocation.LocationName;
            }

            // clone currentLocation to manipulate dates for parsing to server
            let locationToSave = LocationsConfig.copy(vm.currentLocation);
            locationToSave = validateDates(locationToSave);

            if (_.has(vm.currentLocation, 'LocationId')) {
                locationToSave.put().then(
                    function() {
                        SwalHelper.showSaved('', function() {
                            if (exit) {
                                history.go(-1);
                            } else {
                                $state.go(
                                    'locations.locations_edit',
                                    { id: locationToSave.LocationId },
                                    { reload: true }
                                );
                            }
                        });
                    },
                    function(errorResponse) {
                        console.log('location put error');
                        console.log(errorResponse);

                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError('save');
                        }
                    }
                );
            } else {
                Locations.post(locationToSave).then(
                    function(data) {
                        SwalHelper.showSaved('', function() {
                            if (exit) {
                                $state.go('locations.locations_list', {
                                    active: new Date(data.TrialPhaseStart) < vm.today,
                                });
                            } else {
                                $state.go(
                                    'locations.locations_edit',
                                    { id: data.LocationId },
                                    { reload: true }
                                );
                            }
                        });
                    },
                    function(errorResponse) {
                        console.log('location post error');
                        console.log(errorResponse);

                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError('save');
                        }
                    }
                );
            }
        }

        function validateDates(currentLocation) {
            _.forEach(
                ['OpsInstallDate', 'TerminationDate', 'TrialPhaseStart', 'TrialPhaseEnd', 'ConversionDate'],
                function(field) {
                    // default trial phase start/end to 1 Jan 2020 if null
                    if (
                        currentLocation[field] == null &&
                        (field == 'TrialPhaseStart' || field == 'TrialPhaseEnd')
                    ) {
                        currentLocation[field] = new Date(2020, 0, 1);
                    }

                    if (currentLocation[field] != null) {
                        currentLocation[field] = changeDate(currentLocation[field]);
                    } else {
                        currentLocation[field] = null;
                    }
                }
            );

            currentLocation.LaunchDateTime = moment(currentLocation.TrialPhaseStart);
            currentLocation.LaunchDateTime.hours(vm.launchhour);
            currentLocation.LaunchDateTime.minutes(vm.launchminute);

            return currentLocation;
        }
    }

    LocationGroupAdd.$inject = ['$scope', '$uibModalInstance'];
    function LocationGroupAdd($scope, $uibModalInstance) {
        const vm = this;
        vm.save = function() {
            // return new location group name to result
            $uibModalInstance.close(vm.newLocationGroupName);
        };

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
