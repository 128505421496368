(function() {
    angular
        .module('felfel')
        .controller('Company', Company)
        .controller('CompanyEdit', CompanyEdit);

    // first controller
    Company.$inject = ['Companies', 'allObjs', 'SwalHelper', '$state', '$rootScope'];
    function Company(Companies, allObjs, SwalHelper, $state, $rootScope) {
        $rootScope.title = 'Companies';

        const vm = this;

        vm.deleteObj = deleteObj;
        vm.sort = sort;
        vm.sort_pred = 'Name'; // for sorting table, default options
        vm.sort_rev = false; // for sorting table, default options
        vm.companies = allObjs;

        function deleteObj(company) {
            SwalHelper.confirmDelete(company.Name, function() {
                Companies.one(company.CompanyId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(company.Name, function() {
                                $state.go('locations.companies_list', {}, { reload: true });
                                swal.close();
                            });
                        },
                        function(err) {
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller

    // second controller
    CompanyEdit.$inject = [
        'Companies',
        'currentObj',
        'Restangular',
        'SwalHelper',
        '$state',
        'appConfig',
        'KeycloakAuth',
    ];
    function CompanyEdit(Companies, currentObj, Restangular, SwalHelper, $state, appConfig, KeycloakAuth) {
        const vm = this;

        vm.currentCompany = currentObj || {
            AliasId: '',
            RegistrableEmailDomains: [],
        };

        vm.deleteObj = deleteObj;

        vm.datepickToggle = datepickToggle;
        vm.datepickStatus = {
            openedStart: false,
            openedEnd: false,
        };

        vm.addCompanyDeliveryAddressDisabled = false;
        vm.saveCompanyDeliveryAddressesDisabled = false;

        vm.companyDeliveryAddresses = [];
        vm.countries = [];
        vm.deliveryEligiblityDates = [];
        vm.deliveryMinimumOrderValues = [];
        vm.deliveryFees = [];
        vm.deliveryFeePayeeTypes = [];
        vm.deliveryTypes = [];
        vm.zipCodes = [];

        vm.isEdit = false;
        if (_.has(vm.currentCompany, 'CompanyId')) {
            vm.isEdit = true;
            vm.currentCompany.ClientSince = new Date(vm.currentCompany.ClientSince);
            // vm.currentImage = vm.currentCompany.MediaImage.ThumbnailUrl;
        }

        vm.flowAdd = flowAdd;
        vm.flowError = flowError;
        vm.flowSuccess = flowSuccess;
        vm.removeImage = removeImage;
        vm.saveObj = saveObj;
        vm.addEmailDomain = addEmailDomain;
        vm.companyNameChanged = companyNameChanged;
        vm.validateAliasId = validateAliasId;
        vm.addDeliveryEligiblityDate = addDeliveryEligiblityDate;
        vm.removeDeliveryEligiblityDate = removeDeliveryEligiblityDate;
        vm.saveDeliveryEligiblityDates = saveDeliveryEligiblityDates;
        vm.addCompanyDeliveryAddress = addCompanyDeliveryAddress;
        vm.saveCompanyDeliveryAddresses = saveCompanyDeliveryAddresses;
        vm.addDeliveryMinimumOrderValue = addDeliveryMinimumOrderValue;
        vm.addDeliveryFee = addDeliveryFee;

        // readonly variables
        vm.companyDeliveryAddressReadOnly = !KeycloakAuth.hasRealmRole('CompanyDeliveryAddressCreate');
        vm.eligibilityReadOnly = !KeycloakAuth.hasRealmRole('HomeDeliveryEligibility');
        vm.deliveryFeeReadOnly = !KeycloakAuth.hasRealmRole('DeliveryFeeCreate');
        vm.deliveryMinimumOrderValueReadOnly = !KeycloakAuth.hasRealmRole('DeliveryMinimumOrderValueCreate');

        loadDeliveryEligiblityDates();
        loadCompanyDeliveryAddresses();
        loadDeliveryFees();
        loadDeliveryFeePayeeTypes();
        loadDeliveryMinimumOrderValues();
        loadDeliveryTypes();
        loadCountries();
        loadZipCodes();

        function companyNameChanged(obj, $event) {
            if (vm.isEdit || (vm.currentCompany.AliasId && vm.currentCompany.AliasId.length >= 10)) return;
            let lastAddedCharCode = event.charCode;

            if (!charCodeIsAlphaNumeric(lastAddedCharCode)) return;

            if (!vm.currentCompany.AliasId) {
                vm.currentCompany.AliasId = '';
            }

            var x = event.which || event.keyCode;

            let keyPressed = String.fromCharCode(x).toLowerCase();
            if (keyPressed == ' ') return;

            vm.currentCompany.AliasId = vm.currentCompany.AliasId + keyPressed;
        }

        function charCodeIsAlphaNumeric(charCode) {
            return (
                charCodeIsNumber(charCode) ||
                charCodeIsLetterLowercase(charCode) ||
                charCodeIsLetterUpperCase(charCode)
            );
        }

        function charCodeIsLetterLowercase(charCode) {
            return 97 <= charCode && charCode <= 122;
        }

        function charCodeIsNumber(charCode) {
            return 48 <= charCode && charCode <= 57;
        }

        function charCodeIsLetterUpperCase(charCode) {
            return 65 <= charCode && charCode <= 90;
        }

        function addDeliveryEligiblityDate() {
            vm.deliveryEligiblityDates.push({
                startDate: vm.deliveryEligiblityStartDate,
                endDate: vm.deliveryEligiblityEndDate,
            });

            vm.deliveryEligiblityStartDate = '';
            vm.deliveryEligiblityEndDate = '';
        }

        function loadDeliveryEligiblityDates() {
            return Restangular.one(
                '/api/v1/delivery/companies/' + vm.currentCompany.CompanyId + '/eligibility'
            )
                .get()
                .then(
                    function(data) {
                        vm.deliveryEligiblityDates = data.eligibleDates;

                        for (var i = 0; i < data.eligibleDates.length; i++) {
                            data.eligibleDates[i].startDate = new Date(data.eligibleDates[i].startDate);

                            if (data.eligibleDates[i].endDate) {
                                data.eligibleDates[i].endDate = new Date(data.eligibleDates[i].endDate);
                            }
                        }
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function loadDeliveryFeePayeeTypes() {
            return Restangular.one('api/v1/delivery-fee-payee-types')
                .get()
                .then(
                    function(data) {
                        vm.deliveryFeePayeeTypes = data.deliveryFeePayeeTypes;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function loadDeliveryTypes() {
            return Restangular.one('api/v1/delivery-types')
                .get()
                .then(
                    function(data) {
                        vm.deliveryTypes = data.deliveryTypes;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function loadCountries() {
            return Restangular.one('api/v1/countries')
                .get()
                .then(
                    function(data) {
                        vm.countries = data.countries;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function loadZipCodes() {
            return Restangular.one('/api/namak/zip-codes/search')
                .get()
                .then(
                    function(data) {
                        vm.zipCodes = data.result;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function removeDeliveryEligiblityDate(item) {
            var index = vm.deliveryEligiblityDates.indexOf(item);
            vm.deliveryEligiblityDates.splice(index, 1);
        }

        function saveDeliveryEligiblityDates() {
            // copy object so we can change the date format for sending to back-end (without timezone just date)
            var dataToSave = angular.copy(vm.deliveryEligiblityDates);

            for (var i = 0; i < dataToSave.length; i++) {
                dataToSave[i].startDate = moment(dataToSave[i].startDate).format('YYYY-MM-DD');

                if (dataToSave[i].endDate) {
                    dataToSave[i].endDate = moment(dataToSave[i].endDate).format('YYYY-MM-DD');
                }
            }

            return Restangular.one(
                '/api/v1/delivery/companies/' + vm.currentCompany.CompanyId + '/eligibility'
            )
                .customPUT(dataToSave)
                .then(
                    function(data) {
                        SwalHelper.showSaved('');
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function addCompanyDeliveryAddress() {
            vm.addCompanyDeliveryAddressDisabled = true;

            if (
                vm.companyDeliveryAddress === undefined ||
                vm.companyDeliveryAddress.deliveryAddress.companyName.length == 0 ||
                vm.companyDeliveryAddress.deliveryAddress.streetName.length == 0 ||
                vm.companyDeliveryAddress.deliveryAddress.streetNumber.length == 0 ||
                vm.companyDeliveryAddress.deliveryAddress.city.length == 0 ||
                vm.companyDeliveryAddress.deliveryAddress.zipCode === undefined ||
                vm.companyDeliveryAddress.deliveryAddress.country === undefined ||
                vm.companyDeliveryAddress.deliveryAddress.deliveryType === undefined
            ) {
                vm.addCompanyDeliveryAddressDisabled = false;
                return false;
            }

            // add new company delivery address to collection
            var companyDeliveryAddress = angular.copy(vm.companyDeliveryAddress);

            // add properties expected by back-end
            companyDeliveryAddress.companyId = vm.currentCompany.CompanyId;
            companyDeliveryAddress.country = vm.companyDeliveryAddress.deliveryAddress.country;
            companyDeliveryAddress.deliveryType = vm.companyDeliveryAddress.deliveryAddress.deliveryType;

            vm.companyDeliveryAddresses.push(companyDeliveryAddress);

            // clear fields after adding
            vm.companyDeliveryAddress = {};

            vm.addCompanyDeliveryAddressDisabled = false;
        }

        function loadCompanyDeliveryAddresses() {
            return Restangular.one(
                '/api/v1/company-delivery-addresses?companyId=' + vm.currentCompany.CompanyId
            )
                .get()
                .then(
                    function(data) {
                        vm.companyDeliveryAddresses = data.companyDeliveryAddresses;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function saveCompanyDeliveryAddresses() {
            vm.saveCompanyDeliveryAddressesDisabled = true;
            return Restangular.one('/api/v1/company-delivery-addresses')
                .customPATCH(vm.companyDeliveryAddresses, undefined, undefined, undefined)
                .then(
                    function(data) {
                        SwalHelper.showSaved('');
                        vm.saveCompanyDeliveryAddressesDisabled = false;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                        vm.saveCompanyDeliveryAddressesDisabled = false;
                    }
                );
        }

        function loadDeliveryMinimumOrderValues() {
            return Restangular.one(
                '/api/v1/delivery-minimum-order-values?companyId=' + vm.currentCompany.CompanyId
            )
                .get()
                .then(
                    function(data) {
                        for (var i = 0; i < data.deliveryMinimumOrderValues.length; i++) {
                            data.deliveryMinimumOrderValues[i].startDate = new Date(
                                data.deliveryMinimumOrderValues[i].startDate
                            );
                        }

                        vm.deliveryMinimumOrderValues = data.deliveryMinimumOrderValues;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function addDeliveryMinimumOrderValue() {
            console.log(vm.deliveryMinimumOrderValue);
            if (
                vm.deliveryMinimumOrderValue === undefined ||
                vm.deliveryMinimumOrderValue.deliveryType === undefined ||
                vm.deliveryMinimumOrderValue.startDate === undefined ||
                vm.deliveryMinimumOrderValue.minimumOrderValue === undefined
            ) {
                return false;
            }

            // add new company delivery address to collection
            var deliveryMinimumOrderValue = angular.copy(vm.deliveryMinimumOrderValue);

            // add properties expected by back-end
            deliveryMinimumOrderValue.companyId = vm.currentCompany.CompanyId;

            return Restangular.one('/api/v1/delivery-minimum-order-values')
                .customPOST(deliveryMinimumOrderValue)
                .then(
                    function(data) {
                        SwalHelper.showSaved('');

                        // add new item to list
                        vm.deliveryMinimumOrderValues.push(data);

                        // clear fields after adding
                        vm.deliveryMinimumOrderValue = {};
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function loadDeliveryFees() {
            return Restangular.one('/api/v1/delivery-fees?companyId=' + vm.currentCompany.CompanyId)
                .get()
                .then(
                    function(data) {
                        for (var i = 0; i < data.deliveryFees.length; i++) {
                            data.deliveryFees[i].startDate = new Date(data.deliveryFees[i].startDate);
                        }

                        vm.deliveryFees = data.deliveryFees;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function addDeliveryFee() {
            if (
                vm.deliveryFee === undefined ||
                vm.deliveryFee.deliveryType === undefined ||
                vm.deliveryFee.deliveryFeePayeeType === undefined ||
                vm.deliveryFee.startDate === undefined ||
                vm.deliveryFee.fee === undefined
            ) {
                return false;
            }

            var deliveryFee = angular.copy(vm.deliveryFee);

            // add properties expected by back-end
            deliveryFee.companyId = vm.currentCompany.CompanyId;

            return Restangular.one('/api/v1/delivery-fees')
                .customPOST(deliveryFee)
                .then(
                    function(data) {
                        SwalHelper.showSaved('');

                        // add new item to list
                        vm.deliveryFees.push(data);

                        // clear fields after adding
                        vm.deliveryFee = {};
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function validateAliasId() {
            if (vm.currentCompany.AliasId) {
                let lastChar = vm.currentCompany.AliasId.charCodeAt(vm.currentCompany.AliasId.length - 1);
                if (!(charCodeIsLetterLowercase(lastChar) || charCodeIsNumber(lastChar)))
                    vm.currentCompany.AliasId = vm.currentCompany.AliasId.substring(
                        0,
                        vm.currentCompany.AliasId.length - 1
                    );
            }
        }

        function addEmailDomain(domain) {
            if (!domain.match('(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]')) {
                return;
            }

            if (vm.currentCompany.RegistrableEmailDomains.filter(x => x.EmailDomain == domain).length > 0) {
                SwalHelper.showValidationError('Email domain already exists for this company');
                return;
            }

            vm.currentCompany.RegistrableEmailDomains.push({
                CompanyId: vm.currentCompany.CompanyId,
                EmailDomain: domain,
            });
        }

        function datepickToggle() {
            vm.datepickStatus.openedStart = true;
        }

        function deleteObj() {
            SwalHelper.confirmDelete(vm.currentCompany.Name, function() {
                Companies.one(vm.currentCompany.CompanyId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(vm.currentCompany.Name, function() {
                                $state.go('locations.companies_list', {}, { reload: true });
                                swal.close();
                            });
                        },
                        function(err) {
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function flowAdd($file, $message, $flow) {
            vm.file = $file;

            // checks for extension, returns false if doesnt match one in the object
            if ({ png: 1, gif: 1, jpg: 1, jpeg: 1 }[$file.getExtension()]) {
                console.log('image added');
                return true;
            }
            console.log('image rejected');
            SwalHelper.showError('Image is of invalid type!');
            return false;
        }

        function flowError($file, $message, $flow) {
            console.log('flow Error');
            SwalHelper.showServerError('save');
        }

        function flowSuccess($file, $message, $flow) {
            console.log('flow Success');
            uploadSuccess();
        }

        function removeImage() {
            vm.currentImage = null;
            delete vm.currentCompany.MediaImageId;
            delete vm.currentCompany.MediaImage;
            // console.log(vm.currentCompany);
        }

        function saveObj(exit, $flow) {
            vm.exit = exit;
            SwalHelper.showSaving();
            if (_.has(vm.currentCompany, 'CompanyId')) {
                vm.currentCompany.put().then(
                    function(data) {
                        uploadSuccess();
                    },
                    function(errorResponse) {
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError('save');
                        }
                    }
                );
            } else {
                Companies.post(vm.currentCompany).then(
                    function(data) {
                        vm.currentCompany = data;
                        uploadSuccess();
                    },
                    function(errorResponse) {
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError('save');
                        }
                    }
                );
            }
        }

        // function to upload image and return MediaImageID
        function uploadImage(obj, $flow) {
            $flow.opts.target = `${appConfig.baseUrl}api/FileManager?MediaImageForeignGuid=${
                obj.CompanyId
            }&MediaImageType=Product`;
            $flow.upload();
        }

        function uploadSuccess() {
            SwalHelper.showSaved('', function() {
                if (vm.exit) {
                    $state.go('locations.companies_list');
                } else {
                    $state.go('locations.companies_edit', {
                        id: vm.currentCompany.CompanyId,
                    });
                }
            });
        }
    }
    // end second controller
})();
