(function() {
    angular
        .module('felfel')
        .controller('FoodType', FoodType)
        .controller('FoodTypeEdit', FoodTypeEdit);

    // first controller
    FoodType.$inject = ['FoodTypes', 'allObjs', 'SwalHelper', '$state', '$rootScope'];
    function FoodType(FoodTypes, allObjs, SwalHelper, $state, $rootScope) {
        $rootScope.title = 'Food Types';

        const vm = this;

        vm.deleteObj = deleteObj;
        vm.sort = sort;
        vm.sort_pred = 'FoodTypeName'; // for sorting table, default options
        vm.sort_rev = false; // for sorting table, default options
        vm.types = allObjs;

        function deleteObj(type) {
            SwalHelper.confirmDelete(type.FoodTypeName, function() {
                FoodTypes.one(type.FoodTypeId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(type.FoodTypeName, function() {
                            $state.go('products.foodtypes_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller

    // second controller
    FoodTypeEdit.$inject = ['FoodTypes', 'Upload', 'currentObj', 'SwalHelper', '$state', 'appConfig'];
    function FoodTypeEdit(FoodTypes, Upload, currentObj, SwalHelper, $state, appConfig) {
        const vm = this;

        vm.currentType = currentObj || {};
        vm.deleteObj = deleteObj;

        vm.isEdit = false;
        if (_.has(vm.currentType, 'FoodTypeId')) {
            vm.isEdit = true;
        }

        vm.saveObj = saveObj;

        function deleteObj() {
            SwalHelper.confirmDelete(vm.currentType.FoodTypeName, function() {
                FoodTypes.one(vm.currentType.FoodTypeId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(vm.currentType.FoodTypeName, function() {
                            $state.go('products.foodtypes_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function saveObj(exit, $flow) {
            vm.exit = exit;

            SwalHelper.showSavingMessage();

            if (_.has(vm.currentType, 'FoodTypeId')) {
                vm.currentType.put().then(function(data) {
                    if (vm.image1) {
                        uploadImageSingle(vm.currentType, vm.image1).then(function(succ) {
                            uploadSuccess();
                        });
                    } else {
                        uploadSuccess();
                    }
                });
            } else {
                FoodTypes.post(vm.currentType).then(function(data) {
                    vm.currentType = data;
                    if (vm.image1) {
                        uploadImageSingle(data, vm.image1).then(function(succ) {
                            uploadSuccess();
                        });
                    } else {
                        uploadSuccess();
                    }
                });
            }
        }

        // uploads image
        // img is image to upload
        // obj is PromoRule
        function uploadImageSingle(obj, img) {
            console.log('save image');
            const url = `${appConfig.baseUrl}api/FileManager?MediaImageForeignGuid=${
                obj.FoodTypeId
            }&MediaImageType=FoodType`;

            img.upload = Upload.upload({
                url,
                data: { file: img },
            });

            // returns a promise
            return img.upload;
        }

        function uploadSuccess() {
            SwalHelper.showSaved('', function() {
                if (vm.exit) {
                    $state.go('products.foodtypes_list');
                } else {
                    $state.go('products.foodtypes_edit', {
                        id: vm.currentType.FoodTypeId,
                    });
                }
            });
        }
    }
    // end second controller
})();
