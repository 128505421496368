(function() {
    angular.module('felfel').controller('TemperatureRecall', TemperatureRecall);

    // first controller
    TemperatureRecall.$inject = [
        'allProductLocks',
        'allLocations',
        'Common',
        'Restangular',
        'ProductItems',
        'SwalHelper',
        '$state',
        '$rootScope',
    ];
    function TemperatureRecall(
        allProductLocks,
        allLocations,
        Common,
        Restangular,
        ProductItems,
        SwalHelper,
        $state,
        $rootScope
    ) {
        $rootScope.title = 'Temperature Recall';

        const vm = this;
        vm.allLocations = Common.sortByName(allLocations, 'LocationName');
        vm.allProductLocks = allProductLocks;

        vm.query = {
            deliveryDate: null,
            locationId: null,
        };

        vm.tempRecall = tempRecall;
        vm.unblockAll = unblockAll;

        async function tempRecall() {
            SwalHelper.confirm(
                'This will recall and block sale of all temperature sensitive products from this location',
                async function() {
                    SwalHelper.showLoading();

                    try {
                        const data = await ProductItems.one('TemperatureRecall').customPUT(
                            {},
                            undefined,
                            vm.query
                        );

                        SwalHelper.showMessage(`${data} items were recalled due to temperature issue.`, () =>
                            $state.go('inventories.temperature_recall', {}, { reload: true })
                        );
                    } catch (error) {
                        SwalHelper.showServerError();
                    }
                }
            );
        }

        async function unblockAll(locationId) {
            SwalHelper.confirm('This will ALLOW sale of ALL products at this location', async function() {
                SwalHelper.showLoading();

                try {
                    await Restangular.one('api/v2/locations')
                        .one(locationId)
                        .one('product-locks/release')
                        .customPUT();

                    SwalHelper.showSuccess('Done!', () =>
                        $state.go('inventories.temperature_recall', {}, { reload: true })
                    );
                } catch (error) {
                    SwalHelper.showServerError();
                }
            });
        }
    }
})();
