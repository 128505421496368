(function() {
    const app = angular.module('felfel.picklists.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const picklistEdit = {
            name: 'inventories.picklists_edit',
            url: '/picklists/edit',
            templateUrl: './templates/inventories/picklists/picklist-edit.html',
            controller: 'PickListEdit',
            controllerAs: 'vm',
            resolve: {
                allInventoryLocations,
                allLocations,
                allProducts,
            },
        };

        const picklistUpload = {
            name: 'inventories.picklists_upload',
            url: '/picklists/upload',
            templateUrl: './templates/inventories/picklists/picklist-upload.html',
            controller: 'PickListUpload',
            controllerAs: 'vm',
            resolve: {
                allBatches,
                allInventoryLocations,
                allLocations,
                allProducts,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(picklistEdit))
            .state(new appConfig.UiRouterConfig(picklistUpload));
    }

    allBatches.$inject = ['DeliveryBatches', 'SwalHelper'];
    function allBatches(DeliveryBatches, SwalHelper) {
        return DeliveryBatches.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allInventoryLocations.$inject = ['InventoryLocations', 'SwalHelper'];
    function allInventoryLocations(InventoryLocations, SwalHelper) {
        return InventoryLocations.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allLocations.$inject = ['Locations', 'SwalHelper'];
    function allLocations(Locations, SwalHelper) {
        return Locations.getList({ selectList: true }).then(
            function(data) {
                let i = data.length;
                while (i--) {
                    if (data[i].EmployeeAmount < 0 && new Date(data[i].TrialPhaseStart) < new Date()) {
                        data.splice(i, 1);
                    }
                }
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allProducts.$inject = ['Products', 'SwalHelper'];
    function allProducts(Products, SwalHelper) {
        return Products.one()
            .get()
            .then(
                function(data) {
                    for (let i = 0; i < data.Result.length; i++) {
                        const j = _.findIndex(data.Result[i].ProductLanguages, {
                            LanguageCode: 'en-US',
                        });
                        data.Result[i].DisplayName = data.Result[i].ProductLanguages[j].ProductName;
                    }
                    return data.Result;
                },
                function(err) {
                    SwalHelper.showServerError();
                }
            );
    }
})();
