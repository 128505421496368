(function() {
    angular
        .module('felfel')
        .controller('ForecastingEdit', ForecastingEdit)
        .controller('ForecastingList', ForecastingList);

    ForecastingEdit.$inject = [
        '$scope',
        '$rootScope',
        '$transition$',
        'forecasts',
        'forecastweeks',
        'forecastweeklocations',
        'allLocations',
        'allProducts',
        'SwalHelper',
        'Forecasts',
        'Metrics',
    ];
    function ForecastingEdit(
        $scope,
        $rootScope,
        $transition$,
        forecasts,
        forecastweeks,
        forecastweeklocations,
        allLocations,
        allProducts,
        SwalHelper,
        Forecasts,
        Metrics
    ) {
        $rootScope.title = 'ForecastingEdit';

        const vm = this;

        vm.current_location = $transition$.params().locationid;

        vm.forecasts = forecasts;
        vm.forecastweeks = forecastweeks;
        vm.forecastweeklocations = forecastweeklocations;
        vm.page_go = page_go;
        vm.page_next = page_next;
        vm.page_prev = page_prev;
        vm.save = save;

        vm.current_index = _.findIndex(vm.forecastweeklocations, {
            locationid: vm.current_location,
        });

        getSiteAverageSales(vm.current_location);
        getSitePredictedSales(vm.current_location, $transition$.params().forecastweekid);

        function save() {
            let final = [];

            for (let i = 0; i < vm.forecasts.length; i++) {
                final = final.concat(vm.forecasts[i].data);
            }

            Forecasts.one()
                .customPUT(final, undefined, undefined, undefined)
                .then(
                    function(succ) {
                        // update forecasts after successful PUT
                        getForecasts(vm.forecasts[0].locationid, $transition$.params().forecastweekid).then(
                            function(succ) {
                                vm.forecasts = succ;
                                getSiteAverageSales(vm.current_location);
                                getSitePredictedSales(
                                    vm.current_location,
                                    $transition$.params().forecastweekid
                                );
                            }
                        );
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function page_go() {
            vm.current_index = _.findIndex(vm.forecastweeklocations, {
                locationid: vm.current_location,
            });
            getForecasts(vm.current_location, $transition$.params().forecastweekid).then(function(succ) {
                vm.forecasts = succ;
                getSiteAverageSales(vm.current_location);
                getSitePredictedSales(vm.current_location, $transition$.params().forecastweekid);
            });
        }

        function page_next() {
            vm.current_location = vm.forecastweeklocations[vm.current_index + 1].locationid;
            vm.current_index = _.findIndex(vm.forecastweeklocations, {
                locationid: vm.current_location,
            });
            getForecasts(vm.current_location, $transition$.params().forecastweekid).then(function(succ) {
                vm.forecasts = succ;

                getSiteAverageSales(vm.current_location);
                getSitePredictedSales(vm.current_location, $transition$.params().forecastweekid);
            });
        }

        function page_prev() {
            vm.current_location = vm.forecastweeklocations[vm.current_index - 1].locationid;
            vm.current_index = _.findIndex(vm.forecastweeklocations, {
                locationid: vm.current_location,
            });
            getForecasts(vm.current_location, $transition$.params().forecastweekid).then(function(succ) {
                vm.forecasts = succ;

                getSiteAverageSales(vm.current_location);
                getSitePredictedSales(vm.current_location, $transition$.params().forecastweekid);
            });
        }

        function getForecasts(locationid, forecastweekid) {
            return Forecasts.one()
                .get({ locationid, forecastweekid })
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data.plain();
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function getSiteAverageSales(locationid) {
            return Metrics.one('averagesiteweeklysales')
                .get({ locationid })
                .then(
                    function(data) {
                        vm.avgWeeklySales = data.sales;
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function getSitePredictedSales(locationid, forecastweekid) {
            return Metrics.one('weeksitepredictedsales')
                .get({ locationid, forecastweekid })
                .then(
                    function(data) {
                        vm.thisWeekSales = data.sales;
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }
    }

    ForecastingList.$inject = [
        '$scope',
        '$state',
        '$rootScope',
        'forecastweeks',
        'SwalHelper',
        'ForecastWeeks',
        'WebAPIForecastWeeks',
        'SupplierOrders',
    ];
    function ForecastingList(
        $scope,
        $state,
        $rootScope,
        forecastweeks,
        SwalHelper,
        ForecastWeeks,
        WebAPIForecastWeeks,
        SupplierOrders
    ) {
        $rootScope.title = 'ForecastingList';

        const vm = this;

        vm.dayToggle = [];
        vm.forecastweeks = forecastweeks;
        vm.goToForecast = goToForecast;
        vm.loadWeekLocations = loadWeekLocations;
        vm.finaliseForecasts = finaliseForecasts;
        vm.makeOrders = makeOrders;
        vm.viewOrders = viewOrders;

        // console.log(vm.forecastweeks)

        function goToForecast(forecastweekid, locationid) {
            $state.go('forecasting.forecasting_edit', { forecastweekid, locationid }, { reload: true });
        }

        function loadWeekLocations(forecastweekid, index) {
            if (!vm.dayToggle[index]) {
                return;
            }

            vm.forecastweeks[index].locations = [];
        }

        function finaliseForecasts(forecastweekid, index, finalise) {
            WebAPIForecastWeeks.one(forecastweekid)
                .one('ordered')
                .customPOST(undefined, undefined, { ordered: finalise }, undefined)
                .then(
                    function(data) {
                        SwalHelper.showSaved();

                        var currentForecastWeek = vm.forecastweeks[index];
                        currentForecastWeek.ordered = data.ordered;
                        currentForecastWeek.updatedate = data.updateDate;

                        if (vm.dayToggle[index]) {
                            loadWeekLocations(forecastweekid, index);
                        }
                    },
                    function(errorResponse) {
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function makeOrders(date, index) {
            date = date.slice(0, 10);
            SupplierOrders.one('sum')
                .get({ weekstartdate: date })
                .then(
                    function(succ) {
                        SwalHelper.showSaved();
                        vm.forecastweeks[index].orders = succ.plain();
                    },
                    function(err) {
                        SwalHelper.showError(err.data.error);
                    }
                );
        }

        function viewOrders(week, index) {
            if (!vm.dayToggle[index]) {
                vm.dayToggle[index] = true;
            }
            if (!vm.forecastweeks[index].locations) {
                loadWeekLocations(week.forecastweekid, index);
            }

            const date = week.weekstartdate.slice(0, 10);
            SupplierOrders.one()
                .get({ weekstartdate: date })
                .then(
                    function(succ) {
                        vm.forecastweeks[index].orders = succ.plain();
                        // console.log(vm.orders);
                    },
                    function(err) {}
                );
        }
    }
})();
