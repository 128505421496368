(function() {
    angular.module('felfel').factory('LogisticsBadges', LogisticsBadges);

    LogisticsBadges.$inject = ['Restangular'];
    function LogisticsBadges(Restangular) {
        const LogisticsBadges = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'LogisticsBadgeId',
            });
        });

        // argument is the RESTapi URL
        return LogisticsBadges.service('api/LogisticsBadges');
    }
})();
