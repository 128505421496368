(function() {
    angular.module('felfel').controller('CashVouchersLookup', CashVouchersLookup);

    CashVouchersLookup.$inject = ['CashVouchers', 'SwalHelper', 'Restangular'];
    function CashVouchersLookup(CashVouchers, SwalHelper, Restangular) {
        const vm = this;

        vm.lookup = lookup;

        async function lookup(code) {
            // reset view
            vm.cashVoucher = null;

            try {
                const data = await CashVouchers.one(code).get();

                vm.cashVoucher = data.plain();

                if (vm.cashVoucher.ApplicationUserId) {
                    Restangular.one(`api/v1/users/${vm.cashVoucher.ApplicationUserId}/profile`)
                        .get()
                        .then(function(succ) {
                            vm.cashVoucher.userEmail = succ.email;
                        });
                }
            } catch (error) {
                console.log(error);
                if (error.status == 404) {
                    SwalHelper.showError('Cashvoucher does not exist. Check the code entered and try again.');
                } else {
                    SwalHelper.showServerError();
                }
            }
        }
    }
})();
