(function() {
    angular
        .module('felfel')
        .controller('LogisticsCompany', LogisticsCompany)
        .controller('LogisticsCompanyEdit', LogisticsCompanyEdit);

    // first controller
    LogisticsCompany.$inject = ['LogisticsCompanies', 'allObjs', 'SwalHelper', '$rootScope', '$state'];
    function LogisticsCompany(LogisticsCompanies, allObjs, SwalHelper, $rootScope, $state) {
        $rootScope.title = 'Pricing Groups';

        const vm = this;
        vm.logisticscompanies = allObjs;
        vm.deleteObj = deleteObj;

        // for sorting table, default options
        vm.sort_pred = 'Name';
        vm.sort_rev = false;
        vm.sort = sort;

        function deleteObj(company) {
            SwalHelper.confirmDelete(company.Name, function() {
                LogisticsCompanies.one(company.LogisticsCompanyId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(company.Name, function() {
                            $state.go('locations.logisticscompanies_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    // second controller
    LogisticsCompanyEdit.$inject = ['LogisticsCompanies', 'currentObj', 'SwalHelper', '$state', '$rootScope'];
    function LogisticsCompanyEdit(LogisticsCompanies, currentObj, SwalHelper, $state, $rootScope) {
        const vm = this;

        vm.currentCompany = currentObj || {};
        vm.deleteObj = deleteObj;
        vm.isEdit = !!_.has(vm.currentCompany, 'LogisticsCompanyId');
        vm.saveObj = saveObj;

        vm.contactModel = {
            name: vm.currentCompany.Name,
            id: vm.currentCompany.LogisticsCompanyId,
            idName: 'LogisticsCompanyId',
            model: 'LogisticsCompany',
            list: 'ContactLogisticsCompanies',
            lodashSearch: {
                LogisticsCompanyId: vm.currentCompany.LogisticsCompanyId,
            },
        };

        function deleteObj() {
            SwalHelper.confirmDelete(vm.currentCompany.Name, function() {
                LogisticsCompanies.one(vm.currentCompany.LogisticsCompanyId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(vm.currentCompany.Name, function() {
                                $state.go('locations.logisticscompanies_list', {}, { reload: true });
                                swal.close();
                            });
                        },
                        function() {
                            console.log('error');
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function saveObj(exit) {
            if (_.has(vm.currentCompany, 'LogisticsCompanyId')) {
                vm.currentCompany.put().then(function() {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('locations.logisticscompanies_list');
                        } else {
                            $state.go('locations.logisticscompanies_edit', {
                                id: vm.currentCompany.LogisticsCompanyId,
                            });
                        }
                    });
                });
            } else {
                LogisticsCompanies.post(vm.currentCompany).then(function(data) {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('locations.logisticscompanies_list');
                        } else {
                            $state.go('locations.logisticscompanies_edit', {
                                id: data.LogisticsCompanyId,
                            });
                        }
                    });
                });
            }
        }
    }
})();
