(function() {
    angular.module('felfel').factory('HomeDelivery', HomeDelivery);

    HomeDelivery.$inject = ['Restangular'];
    function HomeDelivery(Restangular) {
        const HomeDelivery = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({});
        });
        return HomeDelivery.service('api/v1/home-delivery');
    }
})();
