(function() {
    angular.module('felfel').controller('OfflineHistory', OfflineHistory);

    OfflineHistory.$inject = ['Common', 'Locations', 'Restangular', 'SwalHelper'];
    function OfflineHistory(Common, Locations, Restangular, SwalHelper) {
        const vm = this;
        vm.loadOfflineChart = loadOfflineChart;

        getLocationList();

        async function loadOfflineChart() {
            try {
                vm.loading = true;
                vm.chartObject = null;

                vm.chartObject = await getOfflineChart();
            } finally {
                vm.loading = false;
            }
        }

        async function getData() {
            const toDate = moment();
            const fromDate = moment().add(-1 * vm.prevDays, 'day');

            const data = await Restangular.all('api/LocationStatistics')
                .one(vm.query_location)
                .get({
                    page: 1,
                    pageSize: 90000,
                    fromdate: fromDate.format(),
                    todate: toDate.format(),
                });

            return data.plain().Result;
        }

        function transformData(logs) {
            const stats = {};
            const rows = [];

            let current = null;
            let previous = null;
            const THRESHOLD = 30 * 60 * 1000;

            // iterate from the back, because data is in descending chronological order
            for (let i = logs.length - 1; i >= 0; --i) {
                current = logs[i];

                if (previous) {
                    const diff = moment(current.CreateDate) - moment(previous.CreateDate);

                    if (diff > THRESHOLD) {
                        if (moment(current.CreateDate).format('YYYY-MM-DD') in stats) {
                            stats[moment(current.CreateDate).format('YYYY-MM-DD')] += 1;
                        } else {
                            stats[moment(current.CreateDate).format('YYYY-MM-DD')] = 1;
                        }
                    }
                }

                previous = current;
            }

            for (const d of Object.keys(stats)) {
                rows.push({ c: [{ v: new Date(d) }, { v: stats[d] }] });
            }

            return rows;
        }

        async function getOfflineChart() {
            try {
                const chartObject = {};
                chartObject.type = 'ColumnChart';

                chartObject.data = {
                    cols: [
                        { id: 'date', label: 'Date', type: 'date' },
                        { id: 'count', label: 'Offline Count', type: 'number' },
                    ],
                    rows: [],
                };

                const logs = await getData();

                if (!logs.length) return;

                chartObject.options = getChartOptions(logs);

                chartObject.data.rows = transformData(logs);

                return chartObject;
            } catch (error) {
                console.log(error);
                SwalHelper.showServerError();
            }
        }

        function getChartOptions(logs) {
            return {
                hAxis: {
                    viewWindow: {
                        min: new Date(logs[logs.length - 1].CreateDate),
                        max: new Date(logs[0].CreateDate),
                    },
                    gridlines: {
                        count: -1,
                        units: {
                            days: { format: ['MMM dd'] },
                        },
                    },
                    title: 'Date',
                    textStyle: { fontName: 'Ubuntu' },
                    titleTextStyle: {
                        fontName: 'Ubuntu',
                        italic: false,
                        bold: true,
                    },
                },

                vAxis: {
                    title: 'Frequency',
                    textStyle: { fontName: 'Ubuntu' },
                    titleTextStyle: {
                        fontName: 'Ubuntu',
                        italic: false,
                        bold: true,
                    },
                    gridlines: {
                        count: -1,
                    },
                    viewWindow: {
                        min: 0,
                    },
                },

                legend: {
                    position: 'none',
                },

                bar: {
                    groupWidth: 3,
                },

                tooltip: {
                    isHtml: true,
                },

                chartArea: {
                    width: '80%',
                    height: '80%',
                },
            };
        }

        async function getLocationList() {
            // location temperature
            // define date
            try {
                let data = await Locations.getList({ selectList: true });

                vm.allLocations = data.plain();

                Common.sortByName(vm.allLocations, 'LocationName');

                vm.query_location = vm.allLocations[0].LocationId.toLowerCase();
                loadOfflineChart();
            } catch (error) {
                console.log(error);
                SwalHelper.showServerError();
            }
        }
    }
})();
