(function() {
    angular.module('felfel').factory('LocationGroups', LocationGroups);

    LocationGroups.$inject = ['Restangular'];
    function LocationGroups(Restangular) {
        const LocationGroups = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'LocationGroupId',
            });
        });
        return LocationGroups.service('api/v3/LocationGroups');
    }
})();
