(function() {
    angular
        .module('felfel.restangular.helper', ['restangular'])
        .provider('RestangularHelper', RestangularHelper);

    RestangularHelper.$inject = ['RestangularProvider'];
    function RestangularHelper(RestangularProvider) {
        this.setIdByRoute = function(route, id) {
            RestangularProvider.extendModel(route, function setIdByRoute(model) {
                // ignore client-only models (e.g. created by Restangular.one)
                if (model.fromServer) {
                    model.id = model[id];
                }
                return model;
            });
        };

        this.$get = angular.noop;
    }
})();
