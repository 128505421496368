(function() {
    angular
        .module('felfel')
        .controller('Promo', Promo)
        .controller('PromoEdit', PromoEdit);

    // first controller
    Promo.$inject = ['Common', 'PromoRules', 'allObjs', 'SwalHelper', '$state', '$transition$', '$rootScope'];
    function Promo(Common, PromoRules, allObjs, SwalHelper, $state, $transition$, $rootScope) {
        const vm = this;

        vm.activeOnly = $transition$.params().IsActive;
        vm.deleteObj = deleteObj;
        vm.pageChange = pageChange;
        vm.pagination = {};
        vm.promos = allObjs.Result;
        vm.query = _.isUndefined($transition$.params().search) ? '' : $transition$.params().search;
        vm.query_location = $transition$.params().location;
        vm.reload = reload;
        vm.search = search;
        vm.sort = sort;
        vm.sort_pred = 'CreateDate'; // for sorting table, default options
        vm.sort_rev = true; // for sorting table, default options

        vm.pagination.Count = allObjs.Count;
        if (!_.isUndefined($transition$.params().search)) {
            vm.pagination.PageSize = 20;
        } else {
            vm.pagination.PageSize = 20;
        }
        vm.pagination.CurrentPage = $transition$.params().page;
        vm.pagination.Change = pageChange;

        // convert dates from UTC to browser setting
        changeDates();

        function changeDates() {
            for (let i = 0; i < vm.promos.length; i++) {
                vm.promos[i].CreateDate = new Date(vm.promos[i].CreateDate);
                vm.promos[i].DateStart = new Date(vm.promos[i].DateStart);
                vm.promos[i].DateEnd = new Date(vm.promos[i].DateEnd);
            }
        }

        function deleteObj(promo) {
            SwalHelper.confirmDelete(promo.Name, function() {
                PromoRules.one(promo.PromoRuleId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(promo.Name, function() {
                            $state.go('users.promos_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function pageChange() {
            SwalHelper.showLoading();
            $state.go(
                'users.promos_list',
                {
                    page: vm.pagination.CurrentPage,
                    search: vm.query,
                    location: vm.query_location,
                },
                { reload: true }
            );
        }

        function reload(para) {
            SwalHelper.showLoading();
            vm.activeOnly = para;
            $state.go(
                'users.promos_list',
                { page: 1, search: vm.query, IsActive: vm.activeOnly },
                { reload: true }
            );
        }

        function search() {
            SwalHelper.showLoading();
            $state.go(
                'users.promos_list',
                { page: 1, search: vm.query, IsActive: vm.activeOnly },
                { reload: true }
            );
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller

    // second controller
    PromoEdit.$inject = [
        'currentObj',
        'Common',
        'Locations',
        'Products',
        'PromoRules',
        'promoRuleTags',
        'PusherService',
        'FoodCats',
        'FoodTags',
        'FoodTypes',
        'SwalHelper',
        'Upload',
        '$rootScope',
        '$scope',
        '$state',
        '$transition$',
        'appConfig',
        'Restangular',
    ];
    function PromoEdit(
        currentObj,
        Common,
        Locations,
        Products,
        PromoRules,
        promoRuleTags,
        PusherService,
        FoodCats,
        FoodTags,
        FoodTypes,
        SwalHelper,
        Upload,
        $rootScope,
        $scope,
        $state,
        $transition$,
        appConfig,
        Restangular
    ) {
        // set up pusher //
        if ($transition$.params().id) {
            const channel_name = `presence-promo-edit-${$transition$.params().id}`;
            PusherService.checkConcurrency(channel_name, $scope);
        }

        const vm = this;

        vm.promoRuleTags = promoRuleTags;

        vm.currentPromo = currentObj || {};

        vm.datepickToggle = datepickToggle;
        vm.datepickStatus = {
            openedStart: false,
            openedEnd: false,
        };
        vm.hours = range(23);
        vm.filterActiveLocations = filterActiveLocations;
        vm.getLocations = getLocations;
        vm.getProducts = getProducts;
        vm.getProductGroupings = getProductGroupings;
        vm.isEdit = !!_.has(vm.currentPromo, 'PromoRuleId');
        vm.lang = $rootScope.languages;

        vm.expiryComparison = new Date(new Date().getTime() - 86399000); // alter datetime now the same way as EndDate is altered below

        vm.saveObj = saveObj;
        vm.toggleActive = toggleActive;
        vm.duplicatePromo = duplicatePromo;
        vm.toggleImageSelector = toggleImageSelector;

        if (!vm.isEdit) {
            setupNewPromo();
        } else {
            setupExisting();
        }

        function duplicatePromo() {
            Restangular.one('api/v1/promotions/duplicate/' + vm.currentPromo.PromoRuleId)
                .post()
                .then(
                    function() {
                        SwalHelper.showSaved();
                    },
                    function() {
                        SwalHelper.showServerError();
                    }
                );
        }

        function cleanProductMeta() {
            // case for all products
            if (vm.productOptions == 0) {
                vm.currentPromo.IsProductBased = false;
                vm.currentPromo.ProductMetaObj.FreshOnly = false;
                vm.currentPromo.ProductMetaObj.SnackDrinkOnly = false;
            }
            // case for product specific
            else if (vm.productOptions == 1) {
                vm.currentPromo.IsProductBased = true;
                vm.currentPromo.ProductMetaObj.FreshOnly = false;
                vm.currentPromo.ProductMetaObj.SnackDrinkOnly = false;
            }
            // case for product groupings
            else if (vm.productOptions == 2) {
                vm.currentPromo.IsProductBased = true;
                vm.currentPromo.ProductMetaObj.Products = [];
            }

            return JSON.stringify(vm.currentPromo.ProductMetaObj);
        }

        function convertHourFromUTC(h, date) {
            const d = new Date(date);

            d.setUTCHours(h);

            return d.getHours();
        }

        function convertHourToUTC(h, date) {
            const d = new Date(date);

            d.setHours(h);

            return d.getUTCHours();
        }

        function datepickToggle() {
            vm.datepickStatus.openedStart = true;
            vm.datepickStatus.openedEnd = true;
        }

        function filterActiveLocations(e) {
            if ((!e.TerminationDate || new Date(e.TerminationDate) > new Date()) && !e.IsInternal) {
                if (_.findIndex(vm.logschedules, { LocationId: e.LocationId }) > -1) {
                    return false;
                }
                return true;
            }
            return false;
        }

        function getLocations() {
            if (!vm.locations) {
                Locations.getList({ selectList: true }).then(
                    function(data) {
                        vm.locations = Common.sortByName(data.plain(), 'LocationName');
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
            }
        }

        function getProducts() {
            if (!vm.products) {
                return Products.one()
                    .get({ page: 1, pageSize: '5000', IsActive: true })
                    .then(
                        function(data) {
                            vm.products = data.plain().Result;
                            for (let i = 0; i < vm.products.length; i++) {
                                Common.sortByName(vm.products[i].ProductLanguages, 'LanguageCode');
                            }
                        },
                        function(err) {
                            SwalHelper.showServerError();
                        }
                    );
            }
        }

        function getProductGroupings() {
            if (!vm.categories) {
                FoodCats.getList().then(
                    function(data) {
                        vm.categories = data.plain();
                        for (let i = 0; i < vm.categories.length; i++) {
                            Common.sortByName(vm.categories[i].FoodCategoryLanguages, 'LanguageCode');
                        }
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
            }
            if (!vm.foodtags) {
                FoodTags.getList().then(
                    function(data) {
                        vm.foodtags = data.plain();
                        for (let i = 0; i < vm.foodtags.length; i++) {
                            Common.sortByName(vm.foodtags[i].FoodTagLanguages, 'LanguageCode');
                        }
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
            }
            if (!vm.foodtypes) {
                FoodTypes.getList().then(
                    function(data) {
                        vm.foodtypes = data.plain();

                        Common.sortByName(vm.foodtypes, 'FoodTypeName');
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
            }
        }

        function range(x) {
            const rng = [];

            for (let i = 0; i < x + 1; i++) {
                rng.push(i);
            }

            return rng;
        }

        // function saves everything in one go. saves object first, return ProductId in order to save pricing, ingredients then image.
        function saveObj(exit) {
            //remove all null PromoRuleLocations
            vm.currentPromo.PromoRuleLocations = vm.currentPromo.PromoRuleLocations.filter(function(el) {
                return el != null;
            });

            // convert product meta to string
            vm.currentPromo.ProductMeta = cleanProductMeta();

            validation();

            if (vm.currentPromo.PromoType == 20 && vm.currentPromo.Amount > 1) {
                SwalHelper.showError('Amount has to be in decimals (0 to 1.0)');
                return;
            }

            if (isEditingPromo()) {
                saveEdit(exit);
            } else {
                saveNewPromo(exit);
            }
        }

        function saveNewPromo(exit) {
            console.log(vm.currentPromo);

            if (reuseImage()) {
                vm.currentPromo.MediaImageId = vm.currentPromo.MediaImage.MediaImageId;
                vm.currentPromo.MediaImage = null;
            }

            PromoRules.post(vm.currentPromo).then(function(data) {
                if (vm.image1) {
                    uploadImageSingle(data, vm.image1).then(function() {
                        saveSuccess(data.PromoRuleId, exit);
                    });
                } else {
                    saveSuccess(data.PromoRuleId, exit);
                }
            });
        }

        function reuseImage() {
            return vm.currentPromo.MediaImage && vm.useExistingImage;
        }

        function saveEdit(exit) {
            vm.currentPromo.put().then(function() {
                if (vm.image1) {
                    uploadImageSingle(vm.currentPromo, vm.image1).then(function() {
                        saveSuccess(vm.currentPromo.PromoRuleId, exit);
                    });
                } else {
                    saveSuccess(vm.currentPromo.PromoRuleId, exit);
                }
            });
        }

        function isEditingPromo() {
            return _.has(vm.currentPromo, 'PromoRuleId');
        }

        function saveSuccess(id, exit) {
            SwalHelper.showSaved('', function() {
                if (exit) {
                    $state.go('users.promos_list');
                } else {
                    $state.go('users.promos_edit', { id }, { reload: true });
                }
            });
        }

        function setupNewPromo() {
            vm.currentPromo.PromoRuleLanguages = [];

            const langs = ['de-CH', 'en-US', 'fr-CH'];

            angular.forEach(langs, function(value, key) {
                vm.currentPromo.PromoRuleLanguages.push({
                    LanguageCode: value,
                    SubTitle: '',
                    Description: '',
                });
            });

            vm.currentPromo.PromoType = 10;

            vm.currentPromo.IsBasketBased = false;
            vm.currentPromo.IsActive = true;
            vm.currentPromo.IsLocationBased = true;
            vm.currentPromo.IsProductBased = true;
            vm.currentPromo.IsUserSegmentBased = false;
            vm.currentPromo.IsPaidByClient = false;

            vm.currentPromo.TimeStart = 0;
            vm.currentPromo.TimeEnd = 23;

            vm.currentPromo.Mon = true;
            vm.currentPromo.Tue = true;
            vm.currentPromo.Wed = true;
            vm.currentPromo.Thu = true;
            vm.currentPromo.Fri = true;
            vm.currentPromo.Sat = true;
            vm.currentPromo.Sun = true;

            vm.currentPromo.ProductMetaObj = {
                FoodCategories: [],
                FoodTags: [],
                FoodTypes: [],
                Products: [],
                FreshOnly: false,
                SnackDrinkOnly: false,
            };

            vm.productOptions = 1;
        }

        function setupExisting() {
            vm.currentPromo.CreateDate = new Date(vm.currentPromo.CreateDate);
            vm.currentPromo.UpdateDate = new Date(vm.currentPromo.UpdateDate);

            vm.currentPromo.DateStart = new Date(vm.currentPromo.DateStart);

            Common.sortByName(vm.currentPromo.PromoRuleLanguages, 'LanguageCode');

            // on the backend it adds 1 day minus 1 second, so we substract it back here.
            let d = new Date(vm.currentPromo.DateEnd);
            if (vm.currentPromo.IsActive) {
                d = d.getTime() - 86399000;
            }
            vm.currentPromo.DateEnd = new Date(d);

            if (vm.currentPromo.IsLocationBased) {
                getLocations();
            }

            if (vm.currentPromo.IsProductBased) {
                vm.currentPromo.ProductMetaObj = JSON.parse(vm.currentPromo.ProductMeta);

                if (vm.currentPromo.ProductMetaObj.Products.length != 0) {
                    vm.productOptions = 1;
                    getProducts();
                } else {
                    vm.productOptions = 2;
                    getProductGroupings();
                }
            } else {
                vm.currentPromo.ProductMetaObj = {};
                vm.productOptions = 0;
            }
        }

        function toggleActive(bool) {
            // activate
            if (bool) {
                vm.currentPromo.IsActive = true;
                saveObj(false);
            }
            // deactivate
            else {
                vm.currentPromo.IsActive = false;
                // so that it expires immediately
                vm.currentPromo.DateEnd = new Date();

                saveObj(false);
            }
        }

        function toggleImageSelector() {
            vm.useExistingImage = !vm.useExistingImage;

            if (vm.useExistingImage) {
                vm.image1 = null;
                getProducts();
            } else {
                vm.currentPromo.MediaImage = null;
            }
        }

        // uploads image
        // img is image to upload
        // obj is PromoRule
        function uploadImageSingle(obj, img) {
            console.log('save image');
            const url = `${appConfig.baseUrl}api/FileManager?MediaImageForeignGuid=${
                obj.PromoRuleId
            }&MediaImageType=Promo`;

            img.upload = Upload.upload({
                url,
                data: { file: img },
            });

            // returns a promise
            return img.upload;
        }

        function validation() {
            // basket based promo has to apply to all products
            if (vm.currentPromo.IsBasketBased) {
                vm.currentPromo.IsProductBased = false;
            }

            // ensures that start date and end date has minutes, seconds = 0
            if (vm.currentPromo.IsActive) {
                vm.currentPromo.DateStart.setHours(0, 0, 0, 0);
                vm.currentPromo.DateEnd.setHours(0, 0, 0, 0);
            } else {
                let d = new Date(vm.currentPromo.DateEnd);
                d = d.getTime() - 86399000;
                vm.currentPromo.DateEnd = new Date(d);
            }

            // make sure MediaImageId has a value if MediaImage is selected
            // otherwise backend throws integrity error
            if (vm.currentPromo.MediaImage) {
                vm.currentPromo.MediaImageId = vm.currentPromo.MediaImage.MediaImageId;
            }
        }
    }
    // end second controller
})();
