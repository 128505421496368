(function() {
    const app = angular.module('felfel.supplier.routes', ['ui.router', 'felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const supplierList = {
            name: 'products.suppliers_list',
            url: '/suppliers',
            templateUrl: './templates/products/suppliers/supplier.html',
            controller: 'Supplier',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const supplierEdit = {
            name: 'products.suppliers_edit',
            url: '/suppliers/edit/:id',
            params: { id: '' },
            templateUrl: './templates/products/suppliers/supplier-edit.html',
            controller: 'SupplierEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(supplierList))
            .state(new appConfig.UiRouterConfig(supplierEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['Suppliers'];
    function allObjs(Suppliers) {
        return Suppliers.getList().then(
            function(data) {
                // console.log("allCats", data);
                return data;
            },
            function(err) {
                // console.log((Suppliers get error");
                swal({
                    title: '',
                    text: 'Server Error!',
                    type: 'error',
                });
            }
        );
    }

    currentObj.$inject = ['Suppliers', '$transition$'];
    function currentObj(Suppliers, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return Suppliers.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        // console.log((Suppliers get error");
                        swal({
                            title: '',
                            text: 'Supplier does not exist!',
                            type: 'error',
                        });
                    }
                );
        }
    }
})();
