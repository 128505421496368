import Pusher from 'pusher-js';

(function() {
    angular.module('felfel.core.pusher', ['felfel.config']).factory('PusherService', PusherService);

    PusherService.$inject = [
        'SwalHelper',
        'KeycloakAuth',
        'appConfig',
        '$pusher',
        '$rootScope',
        '$timeout',
        '$window',
    ];
    function PusherService(SwalHelper, KeycloakAuth, appConfig, $pusher, $rootScope, $timeout, $window) {
        return {
            initiate,
            checkConcurrency,
        };

        function initiate() {
            const c = new Pusher(appConfig.pusherId, {
                authEndpoint: appConfig.baseUrl + appConfig.pusherAuth,
                encrypted: true,
                cluster: 'eu',
                auth: {
                    headers: {
                        Authorization: `Bearer ${KeycloakAuth.token}`,
                    },
                },
            });

            return $pusher(c);
        }

        function getConcurrencyChannel(channel_name) {
            const pusher = initiate();

            pusher.connection.bind('connected', function() {
                console.log('pusher connected');
            });

            pusher.connection.bind('disconnected', function() {
                console.log('pusher disconnected');
            });

            const channel = pusher.subscribe(channel_name);

            channel.bind('pusher:subscription_succeeded', function() {
                $rootScope.$broadcast(channel_name, pusher, channel);
            });
        }

        // method to check if there is already someone editing the page
        // if so, vm.canEdit is set to false and a warning pops showing who is currently editing
        function checkConcurrency(channel_name, scope) {
            scope.$on(channel_name, function(elem, pusher, channel) {
                scope.vm.pusher = pusher;
                scope.vm.pusher_channel = channel;

                if (scope.vm.pusher_channel.members.count > 1) {
                    scope.vm.canEdit = false;

                    scope.vm.pusher_channel.members.each(function(member) {
                        if (member.info.email != scope.vm.pusher_channel.members.me.info.email) {
                            scope.vm.editor = member;
                        }
                    });

                    SwalHelper.showMessage(`${scope.vm.editor.info.email} is editing at the moment!`);
                    scope.vm.pusher.unsubscribe(channel_name);
                } else {
                    scope.vm.canEdit = true;
                }
            });

            // hack to prevent $apply/$digest clash
            scope.$on('$destroy', function() {
                $timeout(
                    function() {
                        scope.vm.pusher.disconnect();
                    },
                    0,
                    false
                );
            });

            getConcurrencyChannel(channel_name);
        }
    }
})();
