(function() {
    angular
        .module('felfel')
        .controller('Ingredient', Ingredient)
        .controller('IngredientEdit', IngredientEdit);

    // first controller
    Ingredient.$inject = ['Ingredients100g', 'allObjs', 'SwalHelper', '$state', '$transition$', '$rootScope'];
    function Ingredient(Ingredients100g, allObjs, SwalHelper, $state, $transition$, $rootScope) {
        $rootScope.title = 'Ingredients';

        const vm = this;
        vm.deleteObj = deleteObj;

        vm.pagination = {};
        vm.search = search;
        // for sorting table, default options
        vm.sort_pred = 'OriginalName';
        vm.sort_rev = false;
        vm.sort = sort;
        vm.query = _.isUndefined($transition$.params().search) ? '' : $transition$.params().search;
        vm.ingredients = allObjs.Result;
        if (!_.isUndefined($transition$.params().search)) {
            vm.pagination.PageSize = 20;
            vm.pagination.Count = 20;
        } else {
            vm.pagination.PageSize = 20;
            vm.pagination.Count = allObjs.Count;
        }

        vm.pagination.CurrentPage = $transition$.params().page;
        vm.pagination.Change = pageChange;

        function deleteObj(ingredient) {
            SwalHelper.confirmDelete(ingredient.OriginalName, function() {
                Ingredients100g.remove(ingredient.IngredientId).then(function() {
                    SwalHelper.showDeleted(ingredient.OriginalName, function() {
                        $state.go('products.ingredients_list', {}, { reload: true });
                        swal.close();
                    });
                });
            });
        }

        // filter GET for only en-US results, loop through IngredientLanguagesArray
        filterEN(vm.ingredients);

        function filterEN(array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i].IngredientLanguages.length > 0) {
                    const en = _.findIndex(array[i].IngredientLanguages, {
                        LanguageCode: 'en-US',
                    });
                    const de = _.findIndex(array[i].IngredientLanguages, {
                        LanguageCode: 'de-CH',
                    });
                    const fr = _.findIndex(array[i].IngredientLanguages, {
                        LanguageCode: 'fr-CH',
                    });

                    array[i].EnglishName = array[i].IngredientLanguages[en].Name;
                    array[i].GermanName = array[i].IngredientLanguages[de].Name;
                    array[i].FrenchName = array[i].IngredientLanguages[fr].Name;
                }
            }
        }

        function pageChange() {
            SwalHelper.showLoading();
            $state.go(
                'products.ingredients_list',
                { page: vm.pagination.CurrentPage, search: vm.query },
                { reload: true }
            );
        }

        function search() {
            SwalHelper.showLoading();
            $state.go('products.ingredients_list', { page: 1, search: vm.query }, { reload: true });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    // second controller
    IngredientEdit.$inject = [
        'Ingredients100g',
        'currentObj',
        'products',
        'SwalHelper',
        '$state',
        '$rootScope',
    ];
    function IngredientEdit(Ingredients100g, currentObj, products, SwalHelper, $state, $rootScope) {
        const vm = this;

        vm.currentIngredient = currentObj || {};

        vm.currentIngredient.IngredientLanguages = vm.currentIngredient.IngredientLanguages || [];

        vm.deleteObj = deleteObj;
        vm.relatedProducts = products;
        vm.isEdit = !!_.has(vm.currentIngredient, 'IngredientId');
        vm.saveObj = saveObj;

        // check if all languages exist
        // loop through languageOptions defined in rootscope
        vm.lang = $rootScope.languages; // an object with properties language code: language name
        vm.languages = $rootScope.languageOptions; // an array with language codes
        for (let j = 0; j < vm.languages.length; j++) {
            const code = vm.languages[j];
            // if the language code does not exist, append to array
            if (
                !_.some(vm.currentIngredient.IngredientLanguages, {
                    LanguageCode: code,
                })
            ) {
                vm.currentIngredient.IngredientLanguages.push({
                    Name: '',
                    LanguageCode: code,
                });
            }
        }

        function deleteObj() {
            console.log('delete');
            SwalHelper.confirmDelete(vm.currentIngredient.OriginalName, function() {
                Ingredients100g.remove(ingredient.IngredientId).then(function() {
                    SwalHelper.showDeleted(vm.currentIngredient.OriginalName, function() {
                        $state.go('products.ingredients_list', {}, { reload: true });
                        swal.close();
                    });
                });
            });
        }

        function saveObj(exit) {
            const currentIngredient = _.cloneDeep(vm.currentIngredient);

            if (_.has(currentIngredient, 'IngredientId')) {
                Ingredients100g.update(currentIngredient).then(
                    function() {
                        SwalHelper.showSaved('', function() {
                            if (exit) {
                                // $state.go('products.ingredients_list');
                                window.history.back();
                            } else {
                                $state.go('products.ingredients_edit', {
                                    id: currentIngredient.IngredientId,
                                });
                            }
                        });
                    },
                    function(err) {
                        SwalHelper.showServerError('update');
                        console.log('put error');
                    }
                );
            } else {
                Ingredients100g.create(currentIngredient).then(
                    function(data) {
                        SwalHelper.showSaved('', function() {
                            if (exit) {
                                // $state.go('products.ingredients_list');
                                window.history.back();
                            } else {
                                $state.go('products.ingredients_edit', {
                                    id: data.IngredientId,
                                });
                            }
                        });
                    },
                    function(err) {
                        SwalHelper.showServerError('save');
                        console.log('post error');
                    }
                );
            }
        }
    }
})();
