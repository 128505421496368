var locationModelConstructor = Restangular => {
    const Locations = Restangular.withConfig(function(RestangularConfigurer) {
        RestangularConfigurer.setRestangularFields({
            id: 'LocationId',
        });
    });
    return Locations;
};

(function() {
    angular.module('felfel').factory('Locations', Locations);

    Locations.$inject = ['Restangular'];
    function Locations(Restangular) {
        return locationModelConstructor(Restangular).service('api/Locations');
    }
})();

//Second Module created to alter the Restangular config on the fly
//(for cloning Restangular instances into other objects for example)
(function() {
    angular.module('felfel').factory('LocationsConfig', Locations);

    Locations.$inject = ['Restangular'];
    function Locations(Restangular) {
        return locationModelConstructor(Restangular);
    }
})();
