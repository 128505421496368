(function() {
    angular.module('felfel.leaflet.helper', []).factory('leafletHelper', leafletHelper);

    function leafletHelper() {
        return {
            getMarkers,
            getClusters,
        };

        function getMarkers() {
            return {
                markerOnline: {
                    type: 'div',
                    iconSize: [30, 30],
                    iconAnchor: [10, 10],
                    popupAnchor: [10, 0],
                    shadowSize: [0, 0],
                    className: 'custom-icon custom-icon-online',
                },
                markerOnlineDelivered: {
                    type: 'div',
                    iconSize: [30, 30],
                    iconAnchor: [10, 10],
                    popupAnchor: [10, 0],
                    shadowSize: [0, 0],
                    className: 'custom-icon custom-icon-online',
                    html: '<div class="center-all"><i class="fa fa-truck" style="color: #1b651e"></i></div>',
                },
                markerOnlineFailSafe: {
                    type: 'div',
                    iconSize: [30, 30],
                    iconAnchor: [10, 10],
                    popupAnchor: [10, 0],
                    shadowSize: [0, 0],
                    className: 'custom-icon custom-icon-online',
                    html: '<div class="center-all"><i class="fa fa-truck" style="color: #d24f4f"></i></div>',
                },
                markerOnlineAdmin: {
                    type: 'div',
                    iconSize: [30, 30],
                    iconAnchor: [10, 10],
                    popupAnchor: [10, 0],
                    shadowSize: [0, 0],
                    className: 'custom-icon custom-icon-online',
                    html: '<div class="center-all"><i class="fa fa-child" style="color: #157719"></i></div>',
                },
                markerOffline: {
                    type: 'div',
                    iconSize: [30, 30],
                    iconAnchor: [10, 10],
                    popupAnchor: [5, 10],
                    shadowSize: [0, 0],
                    className: 'custom-icon custom-icon-offline',
                },
                markerAction: {
                    type: 'div',
                    iconSize: [30, 30],
                    iconAnchor: [10, 10],
                    popupAnchor: [5, 10],
                    shadowSize: [0, 0],
                    className: 'custom-icon custom-icon-action',
                },
                markerTemp: {
                    type: 'div',
                    iconSize: [30, 30],
                    iconAnchor: [10, 10],
                    popupAnchor: [5, 10],
                    shadowSize: [0, 0],
                    className: 'custom-icon custom-icon-temp',
                },
            };
        }

        function getClusters() {
            return {
                markerClusterOnline: new L.MarkerClusterGroup({
                    iconCreateFunction(cluster) {
                        return L.divIcon({
                            iconSize: [30, 30],
                            iconAnchor: [10, 10],
                            shadowSize: [0, 0],
                            className: 'custom-icon custom-icon-online',
                            html: `<div class="center-all custom-cluster"><div class="center-all NoveFontBold">${cluster.getChildCount()}</div></div>`,
                        });
                    },
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: true,
                    disableClusteringAtZoom: 10,
                    maxClusterRadius: 20,
                }),
                markerClusterAction: new L.MarkerClusterGroup({
                    iconCreateFunction(cluster) {
                        return L.divIcon({
                            iconSize: [30, 30],
                            iconAnchor: [10, 10],
                            shadowSize: [0, 0],
                            className: 'custom-icon custom-icon-action',
                            html: `<div class="center-all NoveFontBold">${cluster.getChildCount()}</div>`,
                        });
                    },
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: false,
                    disableClusteringAtZoom: 10,
                    maxClusterRadius: 50,
                }),
                markerClusterTemp: new L.MarkerClusterGroup({
                    iconCreateFunction(cluster) {
                        return L.divIcon({
                            iconSize: [30, 30],
                            iconAnchor: [10, 10],
                            shadowSize: [0, 0],
                            className: 'custom-icon custom-icon-temp',
                            html: `<div class="center-all NoveFontBold">${cluster.getChildCount()}</div>`,
                        });
                    },
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: false,
                    disableClusteringAtZoom: 10,
                    maxClusterRadius: 100,
                }),
                markerClusterOffline: new L.MarkerClusterGroup({
                    iconCreateFunction(cluster) {
                        return L.divIcon({
                            iconSize: [30, 30],
                            iconAnchor: [10, 10],
                            shadowSize: [0, 0],
                            className: 'custom-icon custom-icon-offline',
                            html: `<div class="center-all NoveFontBold">${cluster.getChildCount()}</div>`,
                        });
                    },
                    spiderfyOnMaxZoom: false,
                    showCoverageOnHover: false,
                    zoomToBoundsOnClick: false,
                    disableClusteringAtZoom: 10,
                    maxClusterRadius: 100,
                }),
            };
        }
    }
})();
