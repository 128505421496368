(function() {
    angular.module('felfel').controller('OpsDriversTraining', OpsDriversTraining);

    OpsDriversTraining.$inject = ['SwalHelper', '$http'];
    function OpsDriversTraining(SwalHelper, $http) {
        const vm = this;
        vm.resetDevWasteMode = resetDevWasteMode;
        vm.testItems = [
            [100002770248, 'Homemade Spätzle with Beef Goulash'],
            [100002771078, 'Swiss Wurst-Käsesalat'],
            [100002773794, 'Coconut Cream with Mango'],
            [100002778397, 'Moroccan Couscous Salad & Chicken'],
            [100002778495, 'Moroccan Couscous Salad & Chicken'],
            [100002778503, 'Moroccan Couscous Salad & Chicken'],
            [100002779762, 'Körnli Bagel with Turkey'],
            [100002780425, 'Körnli Bread: Hüttenkäse & Kresse'],
            [100002780438, 'Körnli Bread: Hüttenkäse & Kresse'],
            [100002786256, 'Edamame Pot'],
            [100002787441, 'Scialatelli con Agnello'],
            [100002787511, 'Scialatelli con Agnello'],
            [100002817258, 'Shake Don'],
            [100002819305, 'Saladier Nüsslisalat mit Ei'],
        ];

        async function resetDevWasteMode() {
            SwalHelper.showSaving();

            const url =
                'https://felfel-data-generator-dev.azurewebsites.net/api/v1/waste-mode-tests/locations/e48dd706-ba4e-47ab-8fee-899ad03ca113/reset-product-items';

            try {
                await $http({ method: 'PUT', url });
                SwalHelper.showSuccess('Done!');
            } catch (error) {
                SwalHelper.showServerError();
            }
        }
    }
})();
