(function() {
    angular.module('felfel').directive('geoComplete', function() {
        return {
            scope: { '=': '@' },
            templateUrl: './templates/directives/geo-complete.html',
            link(scope, element, attrs) {
                scope.currentLocation = attrs.id;
                scope.currentPos = Number(attrs.id.replace('location-', ''));
                scope.currentFormId = `form-location-${scope.currentLocation}`;
                scope.location = scope.$eval(attrs.location);
                scope.displayLocation = '';

                if (scope.location) {
                    if (scope.location.name && scope.location.formattedAddress) {
                        scope.displayLocation = `${scope.location.name}, ${scope.location.formattedAddress}`;
                    }
                }

                $(element)
                    .find('.geo-complete')
                    .geocomplete({
                        types: ['geocode', 'establishment'],
                        // details: scope.currentFormId
                    })
                    .bind('geocode:result', function(event, result) {
                        mapGeoInformations(scope.currentPos, result);
                    });

                function mapGeoInformations(index, result) {
                    const currentItem = scope.$parent.$parent.vm.supplier.supplierLocations[index];

                    console.log(result.geometry.location);

                    currentItem.name = result.name;
                    currentItem.formattedAddress = result.formatted_address;
                    currentItem.langitude = result.geometry.location.G;
                    currentItem.longitude = result.geometry.location.K;
                }
            },
            controller($scope, $state, SupplierLocations) {
                const alert = {
                    title: 'Vorgang erfolgreich',
                    text: 'Klicken Sie auf OK um das Ergebniss zu sehen',
                    type: 'success',
                };

                $scope.removeAddress = function(locationPos) {
                    const currentItem = $scope.$parent.$parent.vm.supplier.supplierLocations[locationPos];
                    const { supplier } = $scope.$parent.$parent.vm;

                    if (_.has(currentItem, 'supplierLocationId')) {
                        SupplierLocations.one(currentItem.supplierLocationId)
                            .remove()
                            .then(function(data) {
                                swal(alert, function() {
                                    $(`#location-${locationPos}`).remove();
                                    $state.go('supplier.detail', {
                                        id: supplier.supplierId,
                                        lang: $scope.currentLanguage,
                                    });
                                });
                            });
                    }
                };

                $scope.saveAddress = function(locationPos) {
                    const currentItem = $scope.$parent.$parent.vm.supplier.supplierLocations[locationPos];
                    const { supplier } = $scope.$parent.$parent.vm;

                    if (currentItem.formattedAddress) {
                        if (_.has(currentItem, 'supplierLocationId')) {
                            SupplierLocations.one(currentItem.supplierLocationId)
                                .get()
                                .then(function(supplierLocation) {
                                    supplierLocation.name = currentItem.name;
                                    supplierLocation.formattedAddress = currentItem.formattedAddress;
                                    supplierLocation.langitude = currentItem.langitude;
                                    supplierLocation.longitude = currentItem.longitude;

                                    supplierLocation.put().then(function() {
                                        swal(alert, function() {
                                            $state.go('supplier.detail', {
                                                id: supplier.supplierId,
                                                lang: $scope.currentLanguage,
                                            });
                                        });
                                    });
                                });
                        } else {
                            SupplierLocations.post(currentItem).then(function(data) {
                                swal(alert, function() {
                                    $state.go('supplier.detail', {
                                        id: supplier.supplierId,
                                    });
                                });
                            });
                        }
                    }
                };
            },
        };
    });
})();
