(function() {
    angular.module('felfel').controller('LogSchedule', LogSchedule);

    // first controller
    LogSchedule.$inject = [
        'LogisticsSchedules',
        'allObjs',
        'allLocations',
        'Common',
        'SwalHelper',
        '$state',
        '$rootScope',
        'KeycloakAuth',
    ];
    function LogSchedule(
        LogisticsSchedules,
        allObjs,
        allLocations,
        Common,
        SwalHelper,
        $state,
        $rootScope,
        KeycloakAuth
    ) {
        $rootScope.title = 'LogisticsSchedules';

        const vm = this;

        vm.userCanEdit = KeycloakAuth.hasRealmRole('DeliveryScheduleModify');

        allLocations = Common.sortByName(allLocations, 'Name');
        vm.logschedules = allObjs;

        //filter locations that are active or in implementations
        vm.locationsWithDeliveries = allLocations.filter(
            x => x.Status.IsActive || x.Status.IsInImplementation
        );

        let locationsWithDeliveriesMap = {};

        vm.locationsWithDeliveries.forEach(location => {
            locationsWithDeliveriesMap[location.Id] = location;
        });

        //get only schedules for active or in implementation locations.
        vm.logschedules = allObjs.filter(x => x.LocationId in locationsWithDeliveriesMap);

        vm.deleteObj = deleteObj;
        vm.newSchedule = newSchedule;
        vm.saveObj = saveObj;

        vm.sort = sort;
        vm.sort_pred = 'LocationName'; // for sorting table, default options
        vm.sort_rev = false; // for sorting table, default options

        vm.logschedules.forEach(schedule => {
            schedule.LocationName = locationsWithDeliveriesMap[schedule.LocationId].Name;
        });

        function deleteObj(schedule) {
            SwalHelper.confirmDelete(schedule.LocationName, function() {
                schedule.remove().then(
                    function() {
                        SwalHelper.showDeleted(`Schedule for ${schedule.LocationName}`, function() {
                            $state.go('operations.logschedules_list', {}, { reload: true });
                            swal.close();
                        });
                    },
                    function(err) {
                        SwalHelper.showServerError('delete');
                    }
                );
            });
        }

        function newSchedule() {
            const data = {
                LocationId: vm.new_location,
                WasteMonday: true,
                WasteTuesday: true,
                WasteWednesday: true,
                WasteThursday: true,
                WasteFriday: true,
                WasteSaturday: true,
                WasteSunday: true,
                DonationMonday: true,
                DonationTuesday: true,
                DonationWednesday: true,
                DonationThursday: true,
                DonationFriday: true,
                DonationSaturday: true,
                DonationSunday: true,
                DeliveryMonday: true,
                DeliveryTuesday: true,
                DeliveryWednesday: true,
                DeliveryThursday: true,
                DeliveryFriday: true,
                DeliverySaturday: false,
                DeliverySunday: false,
            };

            LogisticsSchedules.post(data).then(
                function(succ) {
                    $state.go('operations.logschedules_list', {}, { reload: true });
                },
                function(err) {
                    SwalHelper.showServerError();
                }
            );
        }

        function saveObj(schedule) {
            schedule.put().then(
                function() {
                    SwalHelper.showSuccess(`Schedule for ${schedule.LocationName} updated.`);
                },
                function(err) {
                    SwalHelper.showServerError();
                }
            );
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller
})();
