(function() {
    angular.module('felfel').factory('PaymentMethods', PaymentMethods);

    PaymentMethods.$inject = ['Restangular'];
    function PaymentMethods(Restangular) {
        const PaymentMethods = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'PaymentMethodId',
            });
        });

        // argument is the RESTapi URL
        return PaymentMethods.service('api/PaymentMethods');
    }
})();

(function() {
    angular.module('felfel').factory('PaymentMethodService', PaymentMethodService);

    PaymentMethodService.$inject = ['Restangular'];
    function PaymentMethodService(Restangular) {
        const PaymentMethodService = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'ApplicationUserId',
            });

            RestangularConfigurer.setBaseUrl(REACT_APP_PAYMENT_URI);
        });

        // argument is the RESTapi URL
        return PaymentMethodService.service('v1/payment-methods');
    }
})();
