(function() {
    const app = angular.module('felfel.deliverybatches.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const deliverybatchList = {
            name: 'inventories.deliverybatches_list',
            url: '/delivery-batches-legacy',
            templateUrl: './templates/inventories/deliverybatches/deliverybatch.html',
            controller: 'DeliveryBatch',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const deliverybatchEdit = {
            name: 'inventories.deliverybatches_edit',
            url: '/delivery-batches-legacy/edit/:id',
            params: { id: '' },
            templateUrl: './templates/inventories/deliverybatches/deliverybatch-edit.html',
            controller: 'DeliveryBatchEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
            },
        };

        const deliverybatchPrint = {
            name: 'inventories.deliverybatches_print',
            url:
                '/deliverybatches/print?deliveryDate&inventoryLocationId&logisticsCompanyId&logisticsCompanyName',
            templateUrl: './templates/inventories/deliverybatches/deliverybatch-print.html',
            controller: 'DeliveryBatchPrint',
            controllerAs: 'vm',
            resolve: {
                batches: batchDetail,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(deliverybatchList))
            .state(new appConfig.UiRouterConfig(deliverybatchPrint))
            .state(new appConfig.UiRouterConfig(deliverybatchEdit));
    }

    //
    // resolve functions
    //
    allLocations.$inject = ['Common', 'Locations', 'SwalHelper'];
    function allLocations(Common, Locations, SwalHelper) {
        return Locations.getList().then(
            function(data) {
                let i = data.length;
                while (i--) {
                    if (data[i].EmployeeAmount <= 0 && new Date(data[i].TrialPhaseStart) < new Date()) {
                        data.splice(i, 1);
                    }
                }
                return Common.sortByName(data, 'LocationName');
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allObjs.$inject = ['DeliveryBatches', 'SwalHelper'];
    function allObjs(DeliveryBatches, SwalHelper) {
        return DeliveryBatches.one('Detail')
            .get()
            .then(
                function(data) {
                    for (let i = 0; i < data.length; i++) {
                        // convert date to local time
                        data[i].DeliveryDate = new Date(data[i].DeliveryDate);
                        // sort batches by Location Name
                        data[i].DeliveryBatches = _.sortBy(data[i].DeliveryBatches, function(o) {
                            return o.Location.LocationName;
                        });
                    }
                    return data;
                },
                function(err) {
                    SwalHelper.showServerError();
                }
            );
    }

    batchDetail.$inject = ['DeliveryBatches', 'SwalHelper', '$transition$'];
    function batchDetail(DeliveryBatches, SwalHelper, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().inventoryLocationId && $transition$.params().deliveryDate) {
            return DeliveryBatches.one()
                .get({
                    InventoryLocationId: $transition$.params().inventoryLocationId,
                    DeliveryDateBetweenStart: $transition$.params().deliveryDate,
                    DeliveryDateBetweenEnd: $transition$.params().deliveryDate,
                })
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return _.filter(data, {
                            Location: {
                                LogisticsCompanyId: $transition$.params().logisticsCompanyId,
                            },
                        });
                    },
                    function(err) {
                        SwalHelper.showError('Delivery batch does not exist!');
                    }
                );
        }
    }

    currentObj.$inject = ['DeliveryBatches', 'SwalHelper', '$transition$'];
    function currentObj(DeliveryBatches, SwalHelper, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return DeliveryBatches.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        SwalHelper.showError('Delivery batch does not exist!');
                    }
                );
        }
    }
})();
