(function() {
    angular
        .module('felfel')
        .controller('PickListEdit', PickListEdit)
        .controller('PickListUpload', PickListUpload);

    // first controller
    PickListEdit.$inject = [
        'allInventoryLocations',
        'allLocations',
        'allProducts',
        'SwalHelper',
        'Common',
        'Restangular',
        'DeliveryBatches',
        'PickListProducts',
        'Products',
        '$scope',
        '$state',
        '$rootScope',
    ];
    function PickListEdit(
        allInventoryLocations,
        allLocations,
        allProducts,
        SwalHelper,
        Common,
        Restangular,
        DeliveryBatches,
        PickListProducts,
        Products,
        $scope,
        $state,
        $rootScope
    ) {
        $rootScope.title = 'Pick Lists';
        const vm = this;

        vm.addPickListItem = addPickListItem;
        vm.allBatches = [];
        vm.allInventoryLocations = allInventoryLocations;
        vm.allLocations = allLocations.sort(function(a, b) {
            // sort by name
            if (a.LocationName < b.LocationName) return -1;
            if (a.LocationName > b.LocationName) return 1;
            return 0;
        });
        vm.locations = [];
        vm.allProducts = allProducts;
        vm.getProducts = getProducts;
        vm.deleteObj = deleteObj;
        vm.getPickList = getPickList;
        vm.getTotalCost = getTotalCost;
        vm.removePickListItem = removePickListItem;
        vm.saveObj = saveObj;
        vm.searchProducts = [];
        // for sorting table, default options
        vm.sort_pred = 'Name';
        vm.sort_rev = false;
        vm.sort = sort;

        loadLocations();

        $scope.$watch('vm.selected_product', function() {
            if (
                vm.selected_product &&
                _.some(vm.picklist.PickListProducts, {
                    ProductId: vm.selected_product.ProductId,
                })
            ) {
                SwalHelper.showError('Product has already been added!');
                vm.selected_product = undefined;
                vm.selected_product_amount = undefined;
            }
        });

        $scope.$watch('vm.newBatch.LocationId', function() {
            const startdate = new Date().toJSON().slice(0, 10); // Today
            const enddate = new Date('2100-01-01').toJSON();

            if (!_.isUndefined(vm.newBatch)) {
                const i = _.findIndex(vm.eventLocations, {
                    LocationId: vm.newBatch.LocationId,
                });

                if (i < 0) {
                    vm.showDestination = false;
                } else {
                    vm.showDestination = true;
                }

                const params = {
                    InventoryLocationId: vm.newBatch.InventoryLocationId,
                    LocationId: vm.newBatch.LocationId,
                    DeliveryDateBetweenStart: startdate,
                    DeliveryDateBetweenEnd: enddate,
                };

                DeliveryBatches.getList(params).then(
                    function(data) {
                        vm.allBatches = data;
                        for (let i = 0; i < vm.allBatches.length; i++) {
                            const date = new Date(vm.allBatches[i].DeliveryDate).toDateString();
                            vm.allBatches[i].DisplayName = `${vm.allBatches[i].Location.LocationName} - ${
                                vm.allBatches[i].Location.Address
                            } - ${date}`;
                        }
                        if (vm.allBatches.length == 0 && vm.isEdit) {
                            SwalHelper.showMessage('No existing delivery batches in this location!');
                        }
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
            }
        });

        $scope.$watch('vm.newBatch.DeliveryDate', function() {
            // check  if delivery date already exists and sends a warning
        });

        function addPickListItem() {
            vm.picklist.PickListProducts.push({
                ProductId: vm.selected_product.ProductId,
                Amount: vm.selected_product_amount,
                DisplayName: vm.selected_product.DisplayName,
                Cost: vm.selected_product.CostPrice,
            });

            vm.picklist.PickListProducts.sort(function(a, b) {
                const nameA = a.DisplayName.toLowerCase();
                const nameB = b.DisplayName.toLowerCase();

                if (nameA < nameB) {
                    // sort string ascending
                    return -1;
                }

                if (nameA > nameB) {
                    return 1;
                }

                return 0; // default return value (no sorting)
            });

            vm.selected_product = undefined;
            vm.selected_product_amount = undefined;
        }

        function changeDate(date) {
            // to make sure date is always sent as dd-mm-yyyy 00:00:00 UTC
            return `${moment(date)
                .format()
                .substring(0, 11)}00:00:00Z`;
        }

        function deleteObj(batch) {
            SwalHelper.confirmDelete(batch.Name, function() {
                PickListProducts.one(batch.DeliveryBatchId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(batch.Name);
                            $state.go('inventories.deliverybatches_list', {}, { reload: true });
                        },
                        function(err) {
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        // get products in the search field
        function getProducts(query) {
            Products.one()
                .get({ search: query })
                .then(
                    function(data) {
                        vm.searchProducts = data.Result;
                        let i = vm.searchProducts.length;
                        while (i--) {
                            const j = _.findIndex(vm.searchProducts[i].ProductLanguages, {
                                LanguageCode: 'en-US',
                            });
                            vm.searchProducts[i].DisplayName =
                                vm.searchProducts[i].ProductLanguages[j].ProductName;

                            if (!vm.searchProducts[i].IsActive) {
                                vm.searchProducts.splice(i, 1);
                            }
                        }
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function getPickList() {
            if (vm.isEdit) {
                PickListProducts.one(vm.existingBatch.DeliveryBatchId)
                    .get()
                    .then(function(data) {
                        vm.picklist = data;

                        // sort DisplayName
                        for (let i = 0; i < vm.picklist.PickListProducts.length; i++) {
                            const j = _.findIndex(vm.allProducts, {
                                ProductId: vm.picklist.PickListProducts[i].ProductId,
                            });
                            vm.picklist.PickListProducts[i].DisplayName = vm.allProducts[j].DisplayName;
                            vm.picklist.PickListProducts[i].Amount =
                                vm.picklist.PickListProducts[i].RequestedAmount;
                        }

                        vm.picklist.PickListProducts.sort(function(a, b) {
                            const nameA = a.DisplayName.toLowerCase();
                            const nameB = b.DisplayName.toLowerCase();
                            if (nameA < nameB) {
                                // sort string ascending
                                return -1;
                            }

                            if (nameA > nameB) {
                                return 1;
                            }

                            return 0; // default return value (no sorting)
                        });
                    });
            } else {
                vm.newBatch.DeliveryDate = changeDate(vm.deliveryDate);

                if (vm.locationInput) {
                    vm.newBatch.Destination = null;
                } else {
                    vm.newBatch.RelatedLocationId = null;
                }

                DeliveryBatches.post(vm.newBatch).then(
                    function(data) {
                        PickListProducts.one(data.DeliveryBatchId)
                            .get()
                            .then(
                                function(data2) {
                                    vm.picklist = data2;
                                    console.log(data2);
                                },
                                function(err) {
                                    SwalHelper.showServerError();
                                }
                            );
                    },
                    function(err) {
                        SwalHelper.showError(err.data.Message);
                    }
                );
            }
        }

        function getTotalCost() {
            let sum = 0;

            if (!vm.picklist) return sum;

            for (let i = 0; i < vm.picklist.PickListProducts.length; i++) {
                sum += vm.picklist.PickListProducts[i].Amount * vm.picklist.PickListProducts[i].Cost;
            }

            return sum;
        }

        async function loadLocations() {
            await Restangular.one('/api/v2/locations')
                .get({
                    locationTagAliasIds: 'event-location',
                    locationResponseType: 'LocationDto',
                    pageSize: '9999', // poor back-end coding enforces pageSize :(
                })
                .then(
                    function(response) {
                        vm.locations = response.Result;
                    },
                    function(errorResponse) {
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function removePickListItem(id) {
            _.remove(vm.picklist.PickListProducts, function(each) {
                return each.ProductId == id;
            });

            vm.picklist.PickListProducts.sort(function(a, b) {
                const nameA = a.DisplayName.toLowerCase();
                const nameB = b.DisplayName.toLowerCase();
                if (nameA < nameB) {
                    // sort string ascending
                    return -1;
                }

                if (nameA > nameB) {
                    return 1;
                }

                return 0; // default return value (no sorting)
            });
        }

        function saveObj(exit) {
            vm.picklist.put().then(function() {
                SwalHelper.showSaved('');
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    // second controller
    PickListUpload.$inject = [
        'allBatches',
        'allLocations',
        'allInventoryLocations',
        'allProducts',
        'SwalHelper',
        'DeliveryBatches',
        'PickListProducts',
        '$state',
        '$scope',
    ];
    function PickListUpload(
        allBatches,
        allLocations,
        allInventoryLocations,
        allProducts,
        SwalHelper,
        DeliveryBatches,
        PickListProducts,
        $state,
        $scope
    ) {
        const vm = this;
        vm.allBatches = allBatches;
        vm.allProducts = allProducts;
        vm.allLocations = allLocations;
        vm.allInventoryLocations = allInventoryLocations;
        vm.savePicklist = savePicklist;

        // console.log(allBatches);
        $scope.$watch('vm.content', function() {
            vm.picklists = [];
            vm.count = -1;
            if (!_.isUndefined(vm.content)) {
                // transfer csv data to vm.picklists
                for (let i = 0; i < vm.content.length; i++) {
                    if (
                        i == 0 ||
                        (i > 0 &&
                            (vm.content[i].InventoryLocationId != vm.content[i - 1].InventoryLocationId ||
                                vm.content[i].LocationId != vm.content[i - 1].LocationId))
                    ) {
                        vm.count++;

                        // if new location, then push new picklist
                        vm.picklists.push({
                            InventoryLocationId: vm.content[i].InventoryLocationId.toLowerCase(),
                            LocationId: vm.content[i].LocationId.toLowerCase(),
                            DeliveryDate: vm.content[i].DeliveryDate,
                            PickListProducts: [],
                            isExisting: isExistingBatch(vm.content[i]), // checks if it is existing batch
                            Overwrite: false,
                        });

                        vm.picklists[vm.count].PickListProducts.push({
                            ProductId: vm.content[i].ProductId.toLowerCase(),
                            Amount: vm.content[i].Amount,
                        });
                    } else {
                        // if same location, just add to PickListProducts
                        vm.picklists[vm.count].PickListProducts.push({
                            ProductId: vm.content[i].ProductId.toLowerCase(),
                            Amount: vm.content[i].Amount,
                        });
                    }

                    // look for DisplayName of Location, InventoryLocation and each Product
                    getNames(vm.picklists[vm.count]);
                }
            }
        });

        function getNames(picklist) {
            var k = _.findIndex(vm.allLocations, {
                LocationId: picklist.LocationId,
            });
            if (k >= 0) {
                picklist.LocationName = vm.allLocations[k].LocationName;
            }

            var k = _.findIndex(vm.allInventoryLocations, {
                InventoryLocationId: picklist.InventoryLocationId,
            });
            if (k >= 0) {
                picklist.InventoryLocationName = vm.allInventoryLocations[k].Name;
            }

            for (let j = 0; j < picklist.PickListProducts.length; j++) {
                var k = _.findIndex(vm.allProducts, {
                    ProductId: picklist.PickListProducts[j].ProductId,
                });
                if (k >= 0) {
                    picklist.PickListProducts[j].DisplayName = vm.allProducts[k].DisplayName;
                }
            }
        }

        function isExistingBatch(picklist) {
            for (let i = 0; i < vm.allBatches.length; i++) {
                if (
                    vm.allBatches[i].InventoryLocationId == picklist.InventoryLocationId.toLowerCase() &&
                    vm.allBatches[i].LocationId == picklist.LocationId.toLowerCase() &&
                    vm.allBatches[i].DeliveryDate.slice(0, 10) == picklist.DeliveryDate.slice(0, 10)
                ) {
                    console.log('true');
                    return {
                        isExisting: true,
                        DeliveryBatchId: vm.allBatches[i].DeliveryBatchId,
                    };
                }
            }

            console.log('false');

            return {
                isExisting: false,
                DeliveryBatchId: '',
            };
        }

        function savePicklist() {
            SwalHelper.showSaving();
            saveOnePicklist(vm.picklists, 0);
        }

        function saveOnePicklist(picklists, index) {
            if (picklists[index].isExisting.isExisting && picklists[index].Overwrite) {
                PickListProducts.one(picklists[index].isExisting.DeliveryBatchId)
                    .get()
                    .then(function(picklist_products) {
                        // add the array of products to retrieved picklist_products / overwrite
                        picklist_products.PickListProducts = picklists[index].PickListProducts;

                        // PUT new picklist_products
                        return picklist_products.put();
                    })
                    .then(function() {
                        SwalHelper.showMessage(
                            `${picklists[index].LocationName} - ${picklists[index].DeliveryDate} saved.`
                        );
                        if (index < picklists.length - 1) {
                            index++;
                            // loop is here, promise loop
                            saveOnePicklist(picklists, index);
                        } else {
                            console.log(`${index + 1} number of loops`);
                            SwalHelper.showSaved();
                        }
                    })
                    .catch(function(err) {
                        SwalHelper.showServerError();
                    });
            } else {
                // create new batch first
                const newBatch = {
                    InventoryLocationId: picklists[index].InventoryLocationId,
                    LocationId: picklists[index].LocationId,
                    DeliveryDate: picklists[index].DeliveryDate,
                };

                DeliveryBatches.post(newBatch)
                    .then(function(batch) {
                        // GET PickListProducts of the created batch
                        return PickListProducts.one(batch.DeliveryBatchId).get();
                    })
                    .then(function(picklist_products) {
                        // add the array of products to retrieved picklist_products
                        picklist_products.PickListProducts = picklists[index].PickListProducts;

                        // PUT new picklist_products
                        return picklist_products.put();
                    })
                    .then(function() {
                        SwalHelper.showMessage(
                            `${picklists[index].LocationName} - ${picklists[index].DeliveryDate} saved.`
                        );
                        if (index < picklists.length - 1) {
                            index++;
                            saveOnePicklist(picklists, index);
                        } else {
                            console.log(`${index + 1} number of loops`);
                            SwalHelper.showSaved();
                        }
                    })
                    .catch(function(err) {
                        SwalHelper.showServerError();
                    });
            }
        }
    }
})();
