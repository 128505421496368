(function() {
    angular
        .module('felfel')
        .controller('Supplier', Supplier)
        .controller('SupplierEdit', SupplierEdit);

    // first controller
    Supplier.$inject = ['Suppliers', 'allObjs', 'SwalHelper', '$rootScope', '$state'];
    function Supplier(Suppliers, allObjs, SwalHelper, $rootScope, $state) {
        $rootScope.title = 'Suppliers';

        const vm = this;
        vm.suppliers = allObjs;
        vm.deleteObj = deleteObj;

        // for sorting table, default options
        vm.sort_pred = 'SupplierName';
        vm.sort_rev = false;
        vm.sort = sort;

        function deleteObj(supplier) {
            SwalHelper.confirmDelete(supplier.SupplierName, function() {
                Suppliers.one(supplier.SupplierId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(supplier.SupplierName);
                        $state.go('products.suppliers_list', {}, { reload: true });
                    });
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    // second controller
    SupplierEdit.$inject = ['Suppliers', 'currentObj', 'SwalHelper', '$state', '$rootScope'];
    function SupplierEdit(Suppliers, currentObj, SwalHelper, $state, $rootScope) {
        const vm = this;

        vm.currentSupplier = currentObj || {};
        vm.deleteObj = deleteObj;
        vm.currentSupplier.SupplierLanguages = vm.currentSupplier.SupplierLanguages || [];
        vm.isEdit = !!_.has(vm.currentSupplier, 'SupplierId');
        vm.saveObj = saveObj;

        vm.lang = $rootScope.languages; // an object with language code: language name
        vm.languages = $rootScope.languageOptions; // an array with language codes
        // check if all languages exist
        // loop through languageOptions defined in rootscope
        for (let j = 0; j < vm.languages.length; j++) {
            const code = vm.languages[j];
            // if the language code does not exist, append to array
            if (
                !_.some(vm.currentSupplier.SupplierLanguages, {
                    LanguageCode: code,
                })
            ) {
                vm.currentSupplier.SupplierLanguages.push({
                    SupplierName: '',
                    OtherInformation: '',
                    LanguageCode: code,
                });
            }
        }

        vm.contactModel = {
            name: vm.currentSupplier.SupplierName,
            id: vm.currentSupplier.SupplierId,
            idName: 'SupplierId',
            model: 'Supplier',
            list: 'ContactSuppliers',
            lodashSearch: { SupplierId: vm.currentSupplier.SupplierId },
        };

        function deleteObj() {
            // console.log("delete");
            SwalHelper.confirmDelete(vm.currentSupplier.SupplierName, function() {
                Suppliers.one(vm.currentSupplier.SupplierId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(vm.currentSupplier.SupplierName);
                            $state.go('products.suppliers_list', {}, { reload: true });
                        },
                        function() {
                            console.log('error');
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function saveObj(exit) {
            if (_.has(vm.currentSupplier, 'SupplierId')) {
                vm.currentSupplier.put().then(function() {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('products.suppliers_list');
                        } else {
                            $state.go('products.suppliers_edit', {
                                id: vm.currentSupplier.SupplierId,
                            });
                        }
                    });
                });
            } else {
                Suppliers.post(vm.currentSupplier).then(function(data) {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('products.suppliers_list');
                        } else {
                            $state.go('products.suppliers_edit', {
                                id: data.SupplierId,
                            });
                        }
                    });
                });
            }
        }
    }
})();
