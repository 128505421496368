(function() {
    const app = angular.module('felfel.products.routes', [
        'felfel.config',
        'felfel.foodcategory.routes',
        'felfel.foodtag.routes',
        'felfel.foodtype.routes',
        'felfel.ingredient.routes',
        'felfel.supplier.routes',
        'felfel.packagingtype.routes',
        'felfel.product.routes',
        'felfel.menu.routes',
    ]);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const productsAbstract = {
            abstract: true,
            name: 'products',
            url: '/products',
            templateUrl: './templates/products/products-abstract.html',
        };

        $stateProvider.state(new appConfig.UiRouterConfig(productsAbstract));
    }
})();
