(function() {
    angular
        .module('felfel')
        .controller('ContactEdit', ContactEdit)
        .factory('ContactsFactory', ContactsFactory);

    ContactsFactory.$inject = ['$uibModal', '$state', 'appConfig'];
    function ContactsFactory($uibModal, $state, appConfig) {
        return {
            addNew,
            addExisting,
            edit,
        };

        // contactModel is an object of type:
        // contactModel = {
        //          name: vm.currentLocation.LocationName,
        //          id: vm.currentLocation.LocationId,
        //          model: 'Location'
        //      };

        function addNew(contactModel) {
            openModal(contactModel, null, 'new');
        }

        function addExisting(contactModel) {
            openModal(contactModel, null, 'existing');
        }

        function edit(contactModel, contactId) {
            openModal(contactModel, contactId, 'edit');
        }

        function openModal(contactModel, contactId, mode) {
            $uibModal
                .open({
                    templateUrl: `./templates/contacts/contact-edit-modal.html?ver=${appConfig.appVersion}`,
                    backdrop: 'static',
                    // keyboard: false,
                    controller: 'ContactEdit',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        contact: [
                            'Contacts',
                            'SwalHelper',
                            function(Contacts, SwalHelper) {
                                if (contactId == null) {
                                    return null;
                                }
                                return Contacts.one(contactId)
                                    .get()
                                    .then(
                                        function(succ) {
                                            return succ;
                                        },
                                        function(err) {
                                            SwalHelper.showServerError();
                                            return null;
                                        }
                                    );
                            },
                        ],
                        contactTypes: [
                            'ContactTypes',
                            function(ContactTypes) {
                                return ContactTypes.getList().then(
                                    function(succ) {
                                        return succ.plain();
                                    },
                                    function(err) {
                                        return null;
                                    }
                                );
                            },
                        ],
                        locations: [
                            'Locations',
                            function(Locations) {
                                return Locations.getList({
                                    selectList: true,
                                }).then(
                                    function(succ) {
                                        return succ.plain();
                                    },
                                    function(err) {
                                        return null;
                                    }
                                );
                            },
                        ],
                        contactModel() {
                            return contactModel;
                        },
                        mode() {
                            return mode;
                        },
                    },
                })
                .result.then(
                    function(ok) {
                        if (contactModel.model == 'Location') {
                            $state.go('locations.locations_edit', { id: contactModel.id }, { reload: true });
                        } else if (contactModel.model == 'LogisticsCompany') {
                            $state.go(
                                'locations.logisticscompanies_edit',
                                { id: contactModel.id },
                                { reload: true }
                            );
                        } else if (contactModel.model == 'Supplier') {
                            $state.go('products.suppliers_edit', { id: contactModel.id }, { reload: true });
                        }
                    },
                    function(cancel) {
                        return null;
                    }
                );
        }
    }

    ContactEdit.$inject = [
        'Common',
        'Contacts',
        'SwalHelper',
        'contact',
        'contactTypes',
        'contactModel',
        'mode',
        '$uibModalInstance',
        'Restangular',
    ];
    function ContactEdit(
        Common,
        Contacts,
        SwalHelper,
        contact,
        contactTypes,
        contactModel,
        mode,
        $uibModalInstance,
        Restangular
    ) {
        const vm = this;
        vm.contact = contact || {};
        vm.contactModel = contactModel;
        vm.save = save;
        vm.cancel = cancel;
        vm.contactTypes = contactTypes;
        vm.filterContactTypes = filterContactTypes;
        vm.getContacts = getContacts;
        vm.getUsers = getUsers;
        vm.mode = mode;
        vm.removeFromLocation = removeFromLocation;
        vm.toggleAttachUser = toggleAttachUser;

        if (vm.contact.ApplicationUserId) {
            vm.isUser = true;
        }

        if (vm.mode == 'edit') {
            const index = _.findIndex(vm.contact[vm.contactModel.list], vm.contactModel.lodashSearch);
            vm.contact.AdditionalInfo = vm.contact[vm.contactModel.list][index].AdditionalInfo;
            vm.contact.SelectedTypes = vm.contact[vm.contactModel.list][index].ContactTypes;

            if (vm.contact.ApplicationUserId) {
                vm.selectedUser = vm.contact.ApplicationUser;
            }
        }

        function filterContactTypes(item) {
            if (vm.contactModel.model == 'Location') {
                if (
                    item.ContactTypeId < 20 ||
                    (item.ContactTypeId >= 30 && item.ContactTypeId < 40) ||
                    item.ContactTypeId >= 900
                ) {
                    return true;
                }
                return false;
            }
            if (vm.contactModel.model == 'LogisticsCompany') {
                if (item.ContactTypeId == 4 || (item.ContactTypeId >= 30 && item.ContactTypeId < 40)) {
                    return true;
                }
                return false;
            }
            if (vm.contactModel.model == 'Supplier') {
                if (item.ContactTypeId == 4 || (item.ContactTypeId >= 20 && item.ContactTypeId < 30)) {
                    return true;
                }
                return false;
            }
        }

        function removeFromLocation() {
            SwalHelper.confirm(`This will remove this contact from ${vm.contactModel.name}.`, function() {
                const index = _.findIndex(vm.contact[vm.contactModel.list], vm.contactModel.lodashSearch);
                if (index > -1) {
                    vm.contact[vm.contactModel.list].splice(index, 1);
                }

                return saveToDb(false);
            });
        }

        function save(newPost) {
            if (!preSaveValidation()) {
                return;
            }

            // create ContactEntity List if it does not exist yet
            if (!vm.contact[vm.contactModel.list]) {
                vm.contact[vm.contactModel.list] = [];
            } else {
                // find the ContactEntity List with the same EntityId and delete it
                const index = _.findIndex(vm.contact[vm.contactModel.list], vm.contactModel.lodashSearch);
                if (index > -1) {
                    // checks if adding to existing
                    if (vm.mode == 'existing') {
                        SwalHelper.showError(
                            `This contact has already been added for this ${vm.contactModel.model}.`
                        );
                        return;
                    }
                    vm.contact[vm.contactModel.list].splice(index, 1);
                }
            }

            const contacttypes = [];
            for (let i = 0; i < vm.contact.SelectedTypes.length; i++) {
                contacttypes.push({
                    ContactTypeId: vm.contact.SelectedTypes[i].ContactTypeId,
                });
            }

            const pushData = {
                ContactTypes: contacttypes,
                AdditionalInfo: vm.contact.AdditionalInfo,
            };
            pushData[vm.contactModel.idName] = vm.contactModel.id;

            vm.contact[vm.contactModel.list].push(pushData);

            if (vm.isUser && vm.selectedUser) {
                vm.contact.ApplicationUserId = vm.selectedUser.Id;
            }

            return saveToDb(newPost);
        }

        function saveToDb(newPost) {
            if (newPost) {
                return Contacts.post(vm.contact).then(
                    function(succ) {
                        SwalHelper.showSaving();
                        ok();
                    },
                    function(err) {
                        SwalHelper.showError(err.data.Message);
                    }
                );
            }
            return Contacts.one(vm.contact.ContactId)
                .customPUT(vm.contact)
                .then(
                    function(succ) {
                        SwalHelper.showSaving();
                        ok();
                    },
                    function(err) {
                        SwalHelper.showError(err.data.Message);
                    }
                );
        }

        function getUsers(search) {
            if (search == '') return;

            Restangular.one('api/v2/contacts/users-search')
                .get({
                    page: 1,
                    pagesize: 20,
                    query: search,
                })
                .then(
                    function(succ) {
                        vm.searchUsers = succ;
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function getContacts(search) {
            if (search == '') return;

            Contacts.one()
                .get({ search })
                .then(
                    function(succ) {
                        vm.searchContacts = succ.Result;
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function preSaveValidation() {
            if (vm.isUser) {
                if (!vm.selectedUser) {
                    SwalHelper.showError('User cannot be empty!');
                    return false;
                }
                // legacy compatibility
                vm.contact.ApplicationUserId = vm.selectedUser.userId || vm.selectedUser.applicationUserId;
            }

            if (vm.mode == 'existing') {
                if (!vm.contact.ContactId) {
                    SwalHelper.showError('You must select a contact!');
                    return false;
                }
            }

            if (!vm.contact.SelectedTypes || vm.contact.SelectedTypes.length == 0) {
                SwalHelper.showError('You must select a contact type!');
                return false;
            }

            return true;
        }

        function toggleAttachUser() {
            vm.isUser = !vm.isUser;

            if (!vm.isUser) {
                vm.selectedUser = null;
                vm.contact.ApplicationUserId = null;
            } else {
                vm.contact.FirstName = null;
                vm.contact.LastName = null;
                vm.contact.Email = null;
            }
        }

        function ok() {
            swal.close();
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
