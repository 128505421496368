(function() {
    angular.module('felfel').factory('WebAPIMenus', WebAPIMenus);

    WebAPIMenus.$inject = ['Restangular'];
    function WebAPIMenus(Restangular) {
        const WebAPIMenus = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRequestSuffix('/').setRestangularFields({
                id: 'menuId',
            });
        });

        return WebAPIMenus.service('api/v1/menus/');
    }
})();
