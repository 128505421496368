(function() {
    'use strict';

    angular.module('felfel').factory('ServiceTiers', ServiceTiers);

    ServiceTiers.$inject = ['Restangular'];
    function ServiceTiers(Restangular) {
        var ServiceTiers = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'ServiceTierId',
            });
        });
        return ServiceTiers.service('api/v3/service-tiers');
    }
})();
