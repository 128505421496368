(function() {
    const app = angular.module('felfel.foodtag.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const foodTagList = {
            name: 'products.foodtags_list',
            url: '/foodtags',
            templateUrl: './templates/products/foodtags/foodtag.html',
            controller: 'FoodTag',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const foodTagEdit = {
            name: 'products.foodtags_edit',
            url: '/foodtags/edit/:id',
            params: { id: '' },
            templateUrl: './templates/products/foodtags/foodtag-edit.html',
            controller: 'FoodTagEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
                productsWithFoodTag,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(foodTagList))
            .state(new appConfig.UiRouterConfig(foodTagEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['FoodTags'];
    function allObjs(FoodTags) {
        return FoodTags.getList().then(
            function(data) {
                // console.log("allObjs", data);
                return data;
            },
            function(err) {
                // console.log("foodTags get error");
                swal({
                    title: '',
                    text: 'Server Error!',
                    type: 'error',
                });
            }
        );
    }

    currentObj.$inject = ['FoodTags', '$transition$'];
    function currentObj(FoodTags, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return FoodTags.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        // console.log("foodTags get error");
                        swal({
                            title: '',
                            text: 'Food Tag does not exist!',
                            type: 'error',
                        });
                    }
                );
        }
    }

    productsWithFoodTag.$inject = ['FoodTags', '$transition$'];
    function productsWithFoodTag(FoodTags, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return FoodTags.one($transition$.params().id)
                .one('Products')
                .get()
                .then(
                    function(data) {
                        return data;
                    },
                    function(err) {
                        swal({
                            title: '',
                            text: 'Error fetching products with this tag!',
                            type: 'error',
                        });
                    }
                );
        }
    }
})();
