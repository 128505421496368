(function() {
    const app = angular.module('felfel.product.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const productList = {
            name: 'products.products_list',
            url: '/products/{page:int}?search&IsActive&supplier',
            params: { page: 1 },
            templateUrl: './templates/products/products/product.html',
            controller: 'Product',
            controllerAs: 'vm',
            resolve: {
                allObjs,
                allSuppliers,
            },
        };

        const productEdit = {
            name: 'products.products_edit',
            url: '/products/edit/:id',
            params: { id: '' },
            templateUrl: './templates/products/products/product-edit.html',
            controller: 'ProductEdit',
            controllerAs: 'vm',
            resolve: {
                allCats,
                allPackagingTypes,
                allPricingGroups,
                allProductChildren,
                allSuppliers,
                allTags,
                allTypes,
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(productList))
            .state(new appConfig.UiRouterConfig(productEdit));
    }

    const errMsg = {
        title: '',
        text: 'Server Error!',
        type: 'error',
    };

    //
    // resolve functions
    //
    allCats.$inject = ['FoodCats'];
    function allCats(FoodCats) {
        return FoodCats.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                swal(errMsg);
            }
        );
    }

    allObjs.$inject = ['Products', '$transition$'];
    function allObjs(Products, $transition$) {
        const { page } = $transition$.params();

        if (_.isUndefined($transition$.params().search)) {
            return Products.one()
                .get({
                    page,
                    pageSize: '20',
                    IsActive: $transition$.params().IsActive,
                    SupplierId: $transition$.params().supplier,
                })
                .then(
                    function(data) {
                        swal.close();
                        return data;
                    },
                    function(err) {
                        swal(errMsg);
                    }
                );
        }
        return Products.one()
            .get({
                page,
                pageSize: '20',
                search: $transition$.params().search,
                IsActive: $transition$.params().IsActive,
                SupplierId: $transition$.params().supplier,
            })
            .then(
                function(data) {
                    swal.close();
                    return data;
                },
                function(err) {
                    swal(errMsg);
                }
            );
    }

    allPackagingTypes.$inject = ['PackagingTypes'];
    function allPackagingTypes(PackagingTypes) {
        return PackagingTypes.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                swal(errMsg);
            }
        );
    }

    allPricingGroups.$inject = ['PricingGroups'];
    function allPricingGroups(PricingGroups) {
        return PricingGroups.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                swal(errMsg);
            }
        );
    }

    allProductChildren.$inject = ['Common', 'Products', '$transition$'];
    function allProductChildren(Common, Products, $transition$) {
        return Products.one()
            .get({ IsActive: true, ShelfLifeGte: 10 })
            .then(
                function(data) {
                    data = data.plain().Result;
                    for (let i = 0; i < data.length; i++) {
                        Common.sortByName(data[i].ProductLanguages, 'LanguageCode');
                    }
                    return data;
                },
                function(err) {
                    swal(errMsg);
                }
            );
    }

    allSuppliers.$inject = ['Suppliers'];
    function allSuppliers(Suppliers) {
        return Suppliers.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                swal(errMsg);
            }
        );
    }

    allTags.$inject = ['FoodTags'];
    function allTags(FoodTags) {
        return FoodTags.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                swal(errMsg);
            }
        );
    }

    allTypes.$inject = ['FoodTypes'];
    function allTypes(FoodTypes) {
        return FoodTypes.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                swal(errMsg);
            }
        );
    }

    currentObj.$inject = ['Products', '$transition$'];
    function currentObj(Products, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return Products.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentCat", data);
                        return data;
                    },
                    function(err) {
                        swal({
                            title: '',
                            text: 'Product does not exist!',
                            type: 'error',
                        });
                    }
                );
        }
    }
})();
