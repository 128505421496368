(function() {
    angular.module('felfel.swal.helper', []).factory('SwalHelper', SwalHelper);

    SwalHelper.$inject = ['$state'];
    function SwalHelper($state) {
        return {
            confirm,
            confirmDelete,
            confirmSend,
            showDeleted,
            showError,
            showLoading,
            showMessage,
            showSaved,
            showSaving,
            showSavingMessage,
            showServerError,
            showSuccess,
            showUploadingImage,
            showUnauthorized,
            showValidationError,
        };

        function confirm(msg, callback) {
            const options = {
                title: 'Are you sure?',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                closeOnConfirm: false,
            };

            swal(options, callback);
        }

        function confirmDelete(name, callback) {
            const options = {
                title: 'Are you sure?',
                text: `Do you really want to delete ${name}?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, delete!',
                closeOnConfirm: false,
            };

            swal(options, callback);
        }

        function confirmSend(name, callback) {
            const options = {
                title: 'Are you sure?',
                text: `Do you want to send ${name} ?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, send!',
                closeOnConfirm: false,
            };

            swal(options, callback);
        }

        function showDeleted(name, callback) {
            const options = {
                title: `${name} has been successfully deleted.`,
                type: 'success',
                timer: 1500,
            };

            swal(options, callback);
        }

        function showError(message, callback) {
            const options = {
                title: message,
                type: 'error',
            };

            swal(options, callback);
        }

        function showLoading(title, callback) {
            if (title === undefined) {
                title = 'Loading...';
            }

            const options = {
                title,
                text: '',
                type: 'info',
                showConfirmButton: false,
            };

            swal(options, callback);
        }

        function showMessage(message, callback) {
            const options = {
                title: message,
                type: 'info',
            };

            swal(options, callback);
        }

        function showSaved(name, callback) {
            const options = {
                title: 'Your entry has been saved.',
                text: '',
                type: 'success',
            };

            swal(options, callback);
        }

        function showSavingMessage(msg, callback) {
            if (msg == undefined) {
                msg = 'Saving ...';
            }

            const options = {
                title: msg,
                text: '',
                type: 'info',
                showConfirmButton: false,
            };

            swal(options, callback);
        }

        function showSaving(callback) {
            const options = {
                title: 'Saving ...',
                text: '',
                type: 'info',
                showConfirmButton: false,
            };

            swal(options, callback);
        }

        function showServerError(name, callback) {
            const options = {
                title: 'Server error!',
                text: `Unable to ${name} entry.`,
                type: 'error',
            };

            if (name == undefined) {
                options.text = '';
            }

            swal(options, callback);
        }

        function showSuccess(msg, callback) {
            const options = {
                title: msg,
                text: '',
                type: 'success',
            };

            swal(options, callback);
        }

        function showUploadingImage(callback) {
            const options = {
                title: 'Uploading image, please wait...',
                text: '',
                type: 'info',
                showConfirmButton: false,
            };

            swal(options, callback);
        }

        function showUnauthorized(callback) {
            const options = {
                title: 'You are not authorized for access!',
                text: '',
                type: 'error',
                showConfirmButton: true,
                allowEscapeKey: false,
            };

            swal(options, callback);
        }

        function showValidationError(errorText, callback) {
            const options = {
                title: 'Validation error!',
                text: `${errorText}`,
                type: 'warning',
            };

            if (name == undefined) {
                options.text = '';
            }

            swal(options, callback);
        }
    }
})();
