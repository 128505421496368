(function() {
    angular.module('felfel').controller('OpsMapNew', OpsMapNew);

    OpsMapNew.$inject = [
        '$filter',
        '$scope',
        '$state',
        '$rootScope',
        '$q',
        'allLocations',
        'lastLocationStats',
        'lastLogEntries',
        'leafletData',
        'leafletHelper',
        'Common',
        'Restangular',
        'PusherService',
        'appConfig',
    ];
    function OpsMapNew(
        $filter,
        $scope,
        $state,
        $rootScope,
        $q,
        allLocations,
        lastLocationStats,
        lastLogEntries,
        leafletData,
        leafletHelper,
        Common,
        Restangular,
        PusherService,
        appConfig
    ) {
        $rootScope.title = 'OpsMapNew';

        // one effect of this is to make it so there is no header on the page
        if ($state.current.url == '/map-full') {
            $rootScope.isLoginPage = true;
        }

        $scope.$on('$destroy', function() {
            if (p) {
                p.disconnect();
            }
        });

        const vm = this;

        vm.mapOptions = {};
        vm.mapOptions.defaults = {
            tileLayer:
                'https://api.mapbox.com/styles/v1/fahyik-felfel/cioffgfme003yczmav8ef0a31/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmFoeWlrLWZlbGZlbCIsImEiOiJjaW5weDlteGwwMGVmdzJrbHVlYWg3cDJ6In0.Q0nux18kYq198OElgo66nQ',
            tileLayerOptions: {
                attribution:
                    '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> | © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                tileSize: 512,
                zoomOffset: -1,
            },
            scrollWheelZoom: true,
            touchZoom: false,
            doubleClickZoom: false,
            zoomControl: false,
        };
        vm.mapOptions.center = {
            init: {
                lat: 46.82,
                lng: 8.15,
                zoom: 9,
            },
            initCoords: [46.82, 8.15],
            initZoom: 9,
        };
        vm.mapOptions.markers = {};

        vm.markers = leafletHelper.getMarkers(); // get markers defined in helper
        vm.clusters = leafletHelper.getClusters(); // get clusters defined in helper

        vm.offlineLimit = 1000 * 60 * 30; // 30 minutes in milliseconds
        vm.actionLimit = 1000 * 60 * 15; // 15 minutes in milliseconds, limit
        vm.tempLimit = 1000 * 60 * 10; // 10 minutes in milliseconds, check temperature only after 10 mins after last action

        vm.zoomToLocation = zoomToLocation; // function to zoom in on click

        // get data and generate markers
        refreshLocationData(allLocations, lastLocationStats, lastLogEntries).then(
            function(succ) {
                generateAllMarkers();
            },
            function(err) {
                console.log('error loading markers');
            }
        );

        // initialise leaflet / mapbox
        leafletData.getMap().then(function(map) {
            vm.map = map;
            vm.map.attributionControl.setPrefix(
                'felfel ops powered by © <a href="https://www.leafletjs.com/">Leaflet</a>'
            );

            // change zoom button position
            L.control
                .zoom({
                    position: 'topright',
                })
                .addTo(vm.map);

            // add home button for re-centering
            L.easyButton('fa-home', function() {
                vm.map.closePopup();
                vm.map.setView(vm.mapOptions.center.initCoords, vm.mapOptions.center.initZoom, {
                    animate: true,
                });
            }).addTo(vm.map);

            // add full screen button
            // L.control.fullscreen({position: "topright"}).addTo(vm.map);

            // add all marker layers
            vm.map.addLayer(vm.clusters.markerClusterOnline);
            vm.map.addLayer(vm.clusters.markerClusterAction);
            vm.map.addLayer(vm.clusters.markerClusterTemp);
            vm.map.addLayer(vm.clusters.markerClusterOffline);

            // console.log(vm.mapOptions.markers['d5d1a298-6838-11e5-9225-00ac14ef2300'].openPopup());
        });

        function addMarker(location, type) {
            const date1 =
                location.LastAction > location.LastUpdate ? location.LastAction : location.LastUpdate;
            const date2 =
                location.LastInventory > location.LastFailSafe
                    ? location.LastInventory
                    : location.LastFailSafe;
            const popup =
                `<div class="marker-popup text-center"><h3 class="NoveFont">${location.LocationName}</h3>` +
                `<p><i class="fa fa-android"></i> : &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp` +
                `v${location.Version}&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</p>` +
                `<p><i class="fa fa-stethoscope"></i> : &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp${location.Temperature /
                    100}&degC&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</p>` +
                `<div><i class="fa fa-refresh"></i> : ${$filter('date')(date1, 'dd MMM yyyy HH:mm')}</div>` +
                `<div><i class="fa fa-sign-in"></i> : ${$filter('date')(
                    location.LastAction,
                    'dd MMM yyyy HH:mm'
                )}</div>` +
                `<div><i class="fa fa-child"></i> : ${$filter('date')(
                    location.LastAdmin,
                    'dd MMM yyyy HH:mm'
                )}</div>` +
                `<div><i class="fa fa-truck"></i> : ${$filter('date')(
                    date2,
                    'dd MMM yyyy HH:mm'
                )}</div></div>`;

            if (!location.Latitude || !location.Longitude) {
                return;
            }

            switch (type) {
                case 'undelivered':
                    var marker = L.marker([location.Latitude, location.Longitude], {
                        icon: new L.divIcon(vm.markers.markerOnline),
                    });
                    break;
                case 'delivered':
                    var marker = L.marker([location.Latitude, location.Longitude], {
                        icon: new L.divIcon(vm.markers.markerOnlineDelivered),
                    });
                    break;
                case 'failsafe':
                    var marker = L.marker([location.Latitude, location.Longitude], {
                        icon: new L.divIcon(vm.markers.markerOnlineFailSafe),
                    });
                    break;
                case 'admin':
                    var marker = L.marker([location.Latitude, location.Longitude], {
                        icon: new L.divIcon(vm.markers.markerOnlineAdmin),
                    });
                    break;
                case 'action':
                    var marker = L.marker([location.Latitude, location.Longitude], {
                        icon: new L.divIcon(vm.markers.markerAction),
                    });
                    break;
                case 'temp':
                    var marker = L.marker([location.Latitude, location.Longitude], {
                        icon: new L.divIcon(vm.markers.markerTemp),
                    });
                    break;
                case 'offline':
                    var marker = L.marker([location.Latitude, location.Longitude], {
                        icon: new L.divIcon(vm.markers.markerOffline),
                    });
                    break;
            }

            marker.bindPopup(popup);

            switch (type) {
                case 'undelivered':
                    vm.clusters.markerClusterOnline.addLayer(marker);
                    break;
                case 'delivered':
                    vm.clusters.markerClusterOnline.addLayer(marker);
                    break;
                case 'failsafe':
                    vm.clusters.markerClusterOnline.addLayer(marker);
                    break;
                case 'admin':
                    vm.clusters.markerClusterOnline.addLayer(marker);
                    break;
                case 'action':
                    vm.clusters.markerClusterAction.addLayer(marker);
                    break;
                case 'temp':
                    vm.clusters.markerClusterTemp.addLayer(marker);
                    break;
                case 'offline':
                    vm.clusters.markerClusterOffline.addLayer(marker);
                    break;
            }

            vm.mapOptions.markers[location.LocationId] = marker;
        }

        function generateAllMarkers() {
            for (let i = 0; i < vm.locations.length; i++) {
                generateMarker(vm.locations[i]);
            }
        }

        function generateMarker(location) {
            vm.now = new Date();

            if (
                vm.now - location.LastUpdate > vm.offlineLimit &&
                vm.now - location.LastAction > vm.offlineLimit
            ) {
                // if offline
                location.Label = 'offline';
                addMarker(location, 'offline');
            } else if (
                vm.now - location.LastAction < vm.actionLimit &&
                location.LastActionType &&
                location.LastActionType != 'Exit'
            ) {
                // if action was in the last 15 mins and type not exit
                location.Label = 'action';
                addMarker(location, 'action');
            } else if (vm.now - location.LastAction > vm.tempLimit && location.Temperature > 1100) {
                // if high temp
                location.Label = 'temp';

                if (!location.FirstTempWarning) {
                    // only set the datetime for the first warning
                    location.FirstTempWarning = location.LastUpdate;
                }

                addMarker(location, 'temp');
            } else {
                // if online
                location.Label = 'online';

                // remove FirstTempWarning
                if (location.FirstTempWarning) {
                    delete location.FirstTempWarning;
                }

                if (location.LastFailSafe && location.LastFailSafe.getDate() == vm.now.getDate()) {
                    addMarker(location, 'failsafe');
                } else if (!location.LastInventory) {
                    addMarker(location, 'undelivered');
                } else if (location.LastInventory.getDate() != vm.now.getDate()) {
                    addMarker(location, 'undelivered');
                } else if (location.LastAdmin && location.LastAdmin.getDate() == vm.now.getDate()) {
                    addMarker(location, 'admin');
                } else {
                    addMarker(location, 'delivered');
                }
            }
        }

        function mapRefresh() {
            Restangular.one('api/LastLocationStatistic')
                .get()
                .then(
                    function(data) {
                        lastLocationStats = data.plain();
                        Restangular.one('api/InventoryManagement/LastLogEntries')
                            .get()
                            .then(
                                function(data) {
                                    lastLogEntries = data.plain();
                                    refreshLocationData(allLocations, lastLocationStats, lastLogEntries);
                                    redrawAllMarkers();
                                },
                                function(err) {
                                    SwalHelper.showServerError();
                                }
                            );
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function redrawMarker(location) {
            // find marker
            const m = vm.mapOptions.markers[location.LocationId];

            if (!m) return;

            // remove marker
            switch (location.Label) {
                case 'online':
                    vm.clusters.markerClusterOnline.removeLayer(m);
                    break;
                case 'offline':
                    vm.clusters.markerClusterOffline.removeLayer(m);
                    break;
                case 'action':
                    vm.clusters.markerClusterAction.removeLayer(m);
                    break;
                case 'temp':
                    vm.clusters.markerClusterTemp.removeLayer(m);
                    break;
                default:
                    console.log('error! location with no Label');
                    break;
            }

            generateMarker(location);
        }

        function redrawAllMarkers() {
            vm.clusters.markerClusterOnline.clearLayers();
            vm.clusters.markerClusterAction.clearLayers();
            vm.clusters.markerClusterTemp.clearLayers();
            vm.clusters.markerClusterOffline.clearLayers();
            generateAllMarkers();
        }

        function refreshLocationData(locations, tempStats, logs) {
            const deferred = $q.defer();

            // first loop thru tempStats
            for (var i = 0; i < tempStats.length; i++) {
                tempStats[i].LastUpdate = new Date(tempStats[i].CreateDate);

                const j = _.findIndex(locations, {
                    LocationId: tempStats[i].LocationId,
                });
                if (j >= 0) {
                    angular.extend(tempStats[i], locations[j]);
                }

                var k = _.findIndex(logs, {
                    LocationId: tempStats[i].LocationId,
                });
                if (k >= 0) {
                    tempStats[i].LastAction = new Date(logs[k].CreateDate);
                    tempStats[i].LastActionType = logs[k].Action;
                    tempStats[i].LastInventory = new Date(logs[k].LastInventory);
                    tempStats[i].LastAdmin = new Date(logs[k].LastAdmin);
                    tempStats[i].LastFailSafe = new Date(logs[k].LastFailSafe);
                } else {
                    tempStats[i].LastAction = null;
                    tempStats[i].LastActionType = null;
                    tempStats[i].LastInventory = null;
                    tempStats[i].LastAdmin = null;
                    tempStats[i].LastFailSafe = null;
                }
            }

            // lastly, loop thru locations again to make sure no active locations are missed out
            const missedLocations = [];
            for (var i = 0; i < locations.length; i++) {
                if (locations[i].OpsIsInstalled) {
                    var k = _.findIndex(tempStats, {
                        LocationId: locations[i].LocationId,
                    });
                    if (k < 0) {
                        missedLocations.push(locations[i]);
                    }
                }
            }

            // if there are locations that are missed out, make separate calls to api
            // resolve promise accordingly
            if (missedLocations.length > 0) {
                let finished = 0;
                for (var i = 0; i < missedLocations.length; i++) {
                    (function(i) {
                        Restangular.one('api/LastLocationStatistic')
                            .one(missedLocations[i].LocationId)
                            .get()
                            .then(
                                function(succ) {
                                    finished += 1;
                                    const tempStat = succ.plain();
                                    tempStat.LastUpdate = new Date(tempStat.CreateDate);
                                    tempStat.LastAction = null;

                                    const k = _.findIndex(logs, {
                                        LocationId: tempStat.LocationId,
                                    });
                                    if (k >= 0) {
                                        tempStat.LastAction = new Date(logs[k].CreateDate);
                                        tempStat.LastActionType = logs[k].Action;
                                        tempStat.LastInventory = new Date(logs[k].LastInventory);
                                        tempStat.LastAdmin = new Date(logs[k].LastAdmin);
                                        tempStat.LastFailSafe = new Date(logs[k].LastFailSafe);
                                    } else {
                                        tempStat.LastAction = null;
                                        tempStat.LastActionType = null;
                                        tempStat.LastInventory = null;
                                        tempStat.LastAdmin = null;
                                        tempStat.LastFailSafe = null;
                                    }

                                    angular.extend(tempStat, missedLocations[i]);
                                    tempStats.push(tempStat);

                                    if (finished == missedLocations.length) {
                                        vm.locations = tempStats;
                                        deferred.resolve('true');
                                    }
                                },
                                function(err) {
                                    finished += 1;
                                    console.log(err);
                                    if (err.status != 404) {
                                        deferred.reject(err);
                                    } else if (finished == missedLocations.length) {
                                        vm.locations = tempStats;
                                        deferred.resolve('true');
                                    }
                                }
                            );
                    })(i);
                }
            } else {
                vm.locations = tempStats;
                deferred.resolve('done');
            }

            return deferred.promise;
        }

        function zoomToLocation(location) {
            vm.map.closePopup();
            vm.map.on('moveend', onClick);

            vm.map.setView([location.Latitude, location.Longitude], 13, {
                pan: { animate: true, duration: 3 },
                zoom: { animate: true },
            });

            function onClick(e) {
                vm.mapOptions.markers[location.LocationId].openPopup();
                vm.map.off('moveend', onClick);
            }
        }

        // for sorting table, default options
        vm.sort_pred = 'LastUpdate';
        vm.sort_rev = false;
        vm.sort = sort;
        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }

        // --------------- initialise web socket - pusher --------------------- //
        var p = PusherService.initiate(appConfig.pusherId, appConfig.pusherAuth);

        const channel = p.subscribe('private-Admin_pmKdw8IFyc');

        channel.bind('pusher:subscription_succeeded', function() {
            channel.bind('pos_waste_mode_action', function(action) {
                // find index of vm.locations
                const i = _.findIndex(vm.locations, {
                    LocationId: action.LocationId,
                });
                // update LastAction, LastActionType
                if (i >= 0) {
                    vm.locations[i].LastAction = new Date(action.CreateDate);
                    vm.locations[i].LastActionType = action.Action;

                    if (action.Action == 'InventoryModeCompleted') {
                        vm.locations[i].LastInventory = new Date(action.CreateDate);
                    } else if (action.Action == 'EnteredAdminWasteMode') {
                        vm.locations[i].LastAdmin = new Date(action.CreateDate);
                    } else if (action.Action == 'InventoryFailSafeActivated') {
                        vm.locations[i].LastFailSafe = new Date(action.CreateDate);
                    }
                    // redraw markers
                    redrawMarker(vm.locations[i]);
                }
            });

            channel.bind('pos_temperature_report', function(item) {
                // find index of vm.locations
                const i = _.findIndex(vm.locations, {
                    LocationId: item.LocationId,
                });
                // update LastUpdate, Temperature, Version
                if (i >= 0) {
                    vm.locations[i].LastUpdate = new Date(item.CreateDate);
                    vm.locations[i].Temperature = item.Temperature;
                    vm.locations[i].Version = item.Version;

                    // redraw marker
                    redrawMarker(vm.locations[i]);
                }
            });
        });

        // ---------------------------- END pusher ----------------------------- //
    }
})();
