(function() {
    angular
        .module('felfel')
        .controller('User', User)
        .controller('UserEdit', UserEdit)
        .controller('UserTransactionHistory', UserTransactionHistory)
        .controller('UserTransactionHistoryModalInstanceCtrl', UserTransactionHistoryModalInstanceCtrl);

    // first controller
    User.$inject = [
        'Common',
        'Users',
        'Restangular',
        'allObjs',
        'locationsList',
        'SwalHelper',
        '$state',
        '$transition$',
        '$rootScope',
    ];
    function User(
        Common,
        Users,
        Restangular,
        allObjs,
        locationsList,
        SwalHelper,
        $state,
        $transition$,
        $rootScope
    ) {
        const vm = this;
        let externalLocations = locationsList.filter(x => !x.Status.IsInternal);
        vm.externalLocations = Common.sortByName(externalLocations, 'Name');
        vm.activeOnly = $transition$.params().IsActive;
        vm.canSendActivationEmailToAll = canSendActivationEmailToAll;
        vm.filterInternalLocations = filterInternalLocations;
        vm.isAllSelected = false;
        vm.linkAdjust = linkAdjust;
        vm.linkHistory = linkHistory;
        vm.linkResend = linkResend;
        vm.linkResendSelected = linkResendSelected;
        vm.search = search;
        vm.pageChange = pageChange;
        vm.pagination = {};
        vm.users = allObjs.result;

        vm.queryBalance = $transition$.params().searchBalance;
        vm.queryCardId = $transition$.params().searchCardId;
        vm.queryEmail = $transition$.params().searchEmail;
        vm.queryEmailConfirmed = $transition$.params().searchEmailConfirmed;
        vm.queryFirstName = $transition$.params().searchFirstName;
        vm.queryLastName = $transition$.params().searchLastName;
        vm.queryPageSize = $transition$.params().searchPageSize;
        vm.query_location = $transition$.params().location;
        vm.query_orderby = $transition$.params().orderby;
        vm.pageSize = vm.queryPageSize ? vm.queryPageSize : 20;
        vm.pagination.Count = allObjs.count;
        if (!_.isUndefined($transition$.params().search)) {
            vm.pagination.PageSize = vm.pageSize;
        } else {
            vm.pagination.PageSize = vm.pageSize;
        }
        vm.pagination.CurrentPage = $transition$.params().page;
        vm.pagination.Change = pageChange;
        vm.toggleSelectAll = toggleSelectAll;

        vm.externalLocations.unshift({
            Id: null,
            Name: '-------------- Terminated -------------',
            TerminationDate: '2001-01-01 00:00:00Z',
            Status: {
                IsTerminated: true,
            },
        });

        vm.externalLocations = externalLocations.sort(function(a, b) {
            // sort by LocationName

            if (!a.Status.IsTerminated && b.Status.IsTerminated) {
                return -1;
            }
            if (a.Status.IsTerminated && !b.Status.IsTerminated) {
                return 1;
            }
            if (!a.Status.IsTerminated && !b.Status.IsTerminated) {
                if (a.Name < b.Name) return -1;
                if (a.Name > b.Name) return 1;
            } else if (a.Status.IsTerminated && b.Status.IsTerminated) {
                if (a.Name < b.Name) return -1;
                if (a.Name > b.Name) return 1;
            }

            return 0;
        });

        vm.externalLocations.unshift({
            Id: undefined,
            Name: 'All Locations',
        });

        // append location names
        for (let i = 0; i < vm.users.length; i++) {
            const j = _.findIndex(vm.externalLocations, {
                Id: vm.users[i].primaryLocationId,
            });

            // if user has no primary location id prevent index error
            if (j > -1) {
                vm.users[i].locationName = vm.externalLocations[j].Name;
            }
        }

        function canSendActivationEmailToAll() {
            return vm.users.filter(x => x.Selected && !x.emailConfirmed).length > 0;
        }

        function filterInternalLocations(a) {
            if (a.IsInternal) {
                return false;
            }

            return true;
        }

        function linkAdjust(email, applicationUserId) {
            $state.go('users.adjustments', { email, userid: applicationUserId }, { reload: true });
        }

        function linkHistory(user) {
            $state.go(
                'users.users_transaction_history',
                { id: user.applicationUserId, userObject: user },
                { reload: true }
            );
        }

        function linkResend(email, applicationUserId) {
            SwalHelper.confirmSend(`an activation mail to: ${email}`, function() {
                swal.close();
                Users.one(applicationUserId)
                    .one('resend-activation-email')
                    .post()
                    .then(
                        function(succ) {
                            SwalHelper.showMessage('The activation mail has been resent successfully.');
                        },
                        function(err) {
                            SwalHelper.showServerError('resend');
                            console.log(err);
                        }
                    );
            });
        }

        function linkResendSelected() {
            var usersToSendTo = vm.users.filter(x => x.Selected && !x.emailConfirmed);

            if (usersToSendTo.length > 0) {
                SwalHelper.confirmSend(`an activation mail to ${usersToSendTo.length} user(s)`, function() {
                    swal.close();

                    usersToSendTo.forEach(function(user) {
                        Users.one(user.applicationUserId)
                            .one('resend-activation-email')
                            .post()
                            .then(
                                function(succ) {
                                    console.log(succ);
                                },
                                function(err) {
                                    SwalHelper.showServerError('resend');
                                    console.log(err);
                                }
                            );
                    });
                });
            } else {
                SwalHelper.showError('You must select at least 1 user who has not activated their account.');
            }
        }

        function pageChange() {
            SwalHelper.showLoading();
            selectAll(false);
            $state.go(
                'users.users_list',
                {
                    page: vm.pagination.CurrentPage,
                    searchPageSize: vm.pageSize,
                    searchBalance: vm.queryBalance,
                    searchCardId: vm.queryCardId,
                    searchEmail: vm.queryEmail,
                    searchEmailConfirmed: vm.queryEmailConfirmed,
                    searchFirstName: vm.queryFirstName,
                    searchLastName: vm.queryLastName,
                    location: vm.query_location,
                    orderby: vm.query_orderby,
                },
                { reload: false }
            );
        }

        function search(orderby) {
            SwalHelper.showLoading();
            if (orderby) {
                vm.query_orderby = orderby;
            }

            $state.go(
                'users.users_list',
                {
                    page: 1,
                    searchPageSize: vm.pageSize,
                    searchBalance: vm.queryBalance,
                    searchCardId: vm.queryCardId,
                    searchEmail: vm.queryEmail,
                    searchEmailConfirmed: vm.queryEmailConfirmed,
                    searchFirstName: vm.queryFirstName,
                    searchLastName: vm.queryLastName,
                    location: vm.query_location,
                    orderby: vm.query_orderby,
                },
                { reload: true }
            );
        }

        function toggleSelectAll() {
            vm.isAllSelected = !vm.isAllSelected;
            selectAll(vm.isAllSelected);
        }

        function selectAll(selected) {
            vm.users.forEach(function(user) {
                user.Selected = selected;
            });
        }
    }
    // end first controller

    // second controller
    UserEdit.$inject = [
        'allLocations',
        'allPaymentMethods',
        'PaymentMethodService',
        'userAccountBalance',
        'userContacts',
        'userPaymentMethods',
        'userProfile',
        'Common',
        'PusherService',
        'Restangular',
        'SwalHelper',
        'Users',
        '$rootScope',
        '$scope',
        '$state',
        '$transition$',
    ];
    function UserEdit(
        allLocations,
        allPaymentMethods,
        PaymentMethodService,
        userAccountBalance,
        userContacts,
        userPaymentMethods,
        userProfile,
        Common,
        PusherService,
        Restangular,
        SwalHelper,
        Users,
        $rootScope,
        $scope,
        $state,
        $transition$
    ) {
        // set up pusher //
        if ($transition$.params().id) {
            const channel_name = `presence-user-edit-${$transition$.params().id}`;
            PusherService.checkConcurrency(channel_name, $scope);
        }

        const vm = this;

        vm.allLanguages = [
            { id: 'en-US', desc: 'English' },
            { id: 'de-CH', desc: 'German' },
            { id: 'fr-CH', desc: 'French' },
            { id: 'it-CH', desc: 'Italian' },
            {
                id: 'rm-CH',
                desc: 'Romansh (Available only on FELFEL - Defaults to English for other communications)',
            },
        ];

        vm.allLocations = Common.sortByName(allLocations, 'LocationName');
        vm.allPaymentMethods = allPaymentMethods;
        vm.chargeToZero = chargeToZero;
        vm.currentUserContacts = userContacts;
        vm.currentUserProfile = userProfile || {};
        vm.currentUserPaymentMethods = userPaymentMethods || {};
        vm.currentUserPaymentMethods.accountBalance = +Number.parseFloat(
            userAccountBalance.accountBalance
        ).toFixed(2);
        vm.isEdit = true;
        vm.userAccountIsEnabled = null;

        Restangular.one(`api/v1/users/${vm.currentUserProfile.userId}/profile`)
            .get()
            .then(function(succ) {
                vm.userAccountIsEnabled = succ.keycloakEnabled;
            });

        vm.filterMainPayment = filterMainPayment;
        vm.filterSupplementaryPayment = filterSupplementaryPayment;

        vm.linkAdjust = linkAdjust;
        vm.linkHistory = linkHistory;
        vm.linkResend = linkResend;
        vm.linkReset = linkReset;

        vm.paymentMethodsDatatrans = [];

        vm.deleteAnonymize = deleteAnonymize;
        vm.getPaymentMethodImage = getPaymentMethodImage;
        vm.savePaymentMethods = savePaymentMethods;
        vm.saveProfile = saveProfile;
        vm.enableAccount = enableAccount;
        vm.disableAccount = disableAccount;

        loadPaymentMethods();

        // quick hack to
        if (vm.currentUserPaymentMethods) {
            vm.currentUserPaymentMethods.paymentMethodId =
                vm.currentUserPaymentMethods.paymentMethod.paymentMethodId;

            if (vm.currentUserPaymentMethods.supplementaryPaymentMethod) {
                vm.currentUserPaymentMethods.supplementaryPaymentMethodId =
                    vm.currentUserPaymentMethods.supplementaryPaymentMethod.paymentMethodId;
            }
        }

        function chargeToZero() {
            if (-1 * vm.currentUserPaymentMethods.accountBalance < 0.5) {
                SwalHelper.showError('Charge amount must be greater than 0.50 CHF.');
            } else {
                SwalHelper.confirm(
                    "This will charge the outstanding balance to customer's credit card.",
                    function() {
                        // swal.close();
                        // console.log(vm.currentUserProfile.userId);
                        SwalHelper.showLoading();
                        Restangular.all('api/ZeroBalance')
                            .customPOST(
                                {},
                                '',
                                {
                                    userId: vm.currentUserProfile.userId,
                                    sendErrorMail: false,
                                },
                                {}
                            )
                            .then(
                                function(succ) {
                                    // charge tried, pulls latest cc info by pulling profile again
                                    Users.one(vm.currentUserProfile.userId)
                                        .one('account-balance')
                                        .get()
                                        .then(
                                            function(data) {
                                                swal.close();
                                                vm.currentUserPaymentMethods.accountBalance =
                                                    data.accountBalance;
                                            },
                                            function(err) {
                                                $state.go(
                                                    'users.users_edit',
                                                    {
                                                        id: vm.currentUserProfile.userId,
                                                    },
                                                    { reload: false }
                                                );
                                            }
                                        );
                                },
                                function(err) {
                                    SwalHelper.showError('An error occurred.', function() {
                                        console.log(err);
                                    });
                                }
                            );
                    }
                );
            }
        }

        function filterMainPayment(item) {
            if (!item.IsSupplementary) {
                return true;
            }
            return false;
        }

        function filterSupplementaryPayment(item) {
            if (item.IsSupplementary) {
                return true;
            }
            return false;
        }

        function getPaymentMethodImage(alias) {
            if (alias == 'twint') {
                return 'https://my.felfel.ch/static/datatrans-logos/twint.svg';
            }

            if (alias == 'amex') {
                return 'https://my.felfel.ch/static/datatrans-logos/amex.svg';
            }

            if (alias == 'mastercard') {
                return 'https://my.felfel.ch/static/datatrans-logos/mastercard.svg';
            }

            if (alias == 'visa') {
                return 'https://my.felfel.ch/static/datatrans-logos/visa.svg';
            }

            if (alias == 'postfinancecard') {
                return 'https://my.felfel.ch/static/datatrans-logos/postfinancecard.svg';
            }

            if (alias == 'reka') {
                return 'https://my.felfel.ch/static/datatrans-logos/reka.svg';
            }
        }

        function linkAdjust(email, id) {
            $state.go('users.adjustments', { email, userid: id }, { reload: false });
        }

        function linkHistory(user) {
            $state.go(
                'users.users_transaction_history',
                { id: user.userId, userObject: user },
                { reload: false }
            );
        }

        function linkResend() {
            SwalHelper.confirmSend('an activation mail', function() {
                swal.close();

                Users.one(vm.currentUserProfile.userId)
                    .one('resend-activation-email')
                    .post()
                    .then(
                        function(succ) {
                            SwalHelper.showMessage('The activation mail has been resent successfully.');
                        },
                        function(err) {
                            SwalHelper.showServerError('resend');
                            console.log(err);
                        }
                    );
            });
        }

        function linkReset() {
            SwalHelper.confirmSend('a reset password mail', function() {
                swal.close();
                Restangular.one('api/keycloak/reset-password/' + vm.currentUserProfile.userId)
                    .post()
                    .then(
                        function(succ) {
                            SwalHelper.showMessage('The reset password mail has been sent successfully.');
                        },
                        function(err) {
                            SwalHelper.showServerError('send password');
                            console.log(err);
                        }
                    );
            });
        }

        function loadPaymentMethods() {
            return PaymentMethodService.one(vm.currentUserProfile.userId)
                .get()
                .then(
                    function(data) {
                        vm.paymentMethodsDatatrans = data;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function deleteAnonymize() {
            if (vm.currentUserPaymentMethods.accountBalance != 0) {
                SwalHelper.showError('User has a non-zero account balance!');
                return;
            }

            SwalHelper.confirmDelete(vm.currentUserProfile.email, function() {
                swal.close();

                Users.one(vm.currentUserProfile.userId)
                    .one('delete-anonymize')
                    .put()
                    .then(
                        function(succ) {
                            SwalHelper.showSuccess('User has been deleted and anonymized.', function() {
                                $state.go(
                                    'users.users_edit',
                                    { id: vm.currentUserProfile.userId },
                                    { reload: false }
                                );
                            });
                        },
                        function(err) {
                            SwalHelper.showServerError('Unable to delete and anonymize user');
                            console.log(err);
                        }
                    );
            });
        }

        function saveProfile(exit) {
            // pass null instead of empty string for cardIdHash
            if (vm.currentUserProfile.cardIdHash == '') {
                vm.currentUserProfile.cardIdHash = null;
                vm.currentUserProfile.cardId = null; // namak-users doesn't seem to know what it's doing so I am passing both cardIdHash and cardId as null it seems to use these terms interchangeably
            }
            vm.currentUserProfile.put().then(
                function(succ) {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            window.history.back();
                        } else {
                            vm.currentUserProfile = succ;
                            $state.go(
                                'users.users_edit',
                                { id: vm.currentUserProfile.userId },
                                { reload: false }
                            );
                        }
                    });
                },
                function(err) {
                    SwalHelper.showServerError('update');
                    console.log('put error');
                }
            );
        }

        function disableAccount() {
            Restangular.one(`api/v1/users/${vm.currentUserProfile.userId}/disable`)
                .customPOST(null, null, {})
                .then(function() {
                    SwalHelper.showSaved('', function() {
                        $state.go('users.users_edit', { id: vm.currentUserProfile.userId }, { reload: true });
                    });
                });
        }

        function enableAccount() {
            Restangular.one(`api/v1/users/${vm.currentUserProfile.userId}/enable`)
                .customPOST(null, null, {})
                .then(function() {
                    SwalHelper.showSaved('', function() {
                        $state.go('users.users_edit', { id: vm.currentUserProfile.userId }, { reload: true });
                    });
                });
        }

        function savePaymentMethods(exit) {
            vm.currentUserPaymentMethods.put().then(
                function() {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            window.history.back();
                        } else {
                            $state.go(
                                'users.users_edit',
                                { id: vm.currentUserProfile.userId },
                                { reload: false }
                            );
                        }
                    });
                },
                function(err) {
                    SwalHelper.showServerError('update');
                    console.log('put error');
                }
            );
        }
    }
    // end second controller

    UserTransactionHistory.$inject = [
        'allCashBookPositions',
        'Common',
        '$locale',
        '$state',
        '$transition$',
        '$uibModal',
        'appConfig',
    ];
    function UserTransactionHistory(
        allCashBookPositions,
        Common,
        $locale,
        $state,
        $transition$,
        $uibModal,
        appConfig
    ) {
        const vm = this;

        vm.types = {
            10: 'Order',
            20: 'Payment',
            30: 'Refund',
            40: 'Gift',
            41: 'Lunch Check',
            42: 'Bank Deposit',
            43: 'Freebie',
            46: 'Cash Vouchers',
            90: 'Correction',

            100: 'Order',
            101: 'Order - Manually Charged',
            102: 'FELFEL Internal Order',
            103: 'FELFEL Home Delivery',
            104: 'FELFEL Delivery Fee',
            106: 'FELFEL Delivery',
            107: 'FELFEL Delivery',
            108: 'Sales Transaction Gavetti',
            199: 'Order Correction',

            200: 'Payment',
            201: 'Payment',
            202: 'Payment Weekly Billing',
            203: 'Payment',
            210: 'Payment Error',
            299: 'Payment Refund',

            300: 'Bank Transfer',
            301: 'PostFinance',
            303: 'Petty Cash',
            304: 'TWINT',
            305: 'Reka (Datatrans/Top-up)',
            306: 'TWINT (Datatrans/Top-up)',
            307: 'PostFinance (Datatrans/Top-up)',
            308: 'Reka (Datatrans/Authorised)',
            309: 'TWINT (Datatrans/Authorised)',
            310: 'PostFinance (Datatrans/Authorised)',
            311: 'Credit Card (Datatrans/Concardis)',
            312: 'Credit Card (Datatrans/Swisscard)',

            400: 'Lunch Check',
            401: 'Lunch Check Manual',
            402: 'Lunch Check (Datatrans)',

            500: 'FELFEL',
            501: 'FELFEL Cash Voucher',
            502: 'FELFEL Quality',
            503: 'FELFEL Internal',
            504: 'FELFEL Referral Bonus',
            511: 'FELFEL Credit Growth',
            512: 'FELFEL Credit CP',
            513: 'FELFEL Charge Reimbursement',
            599: 'FELFEL Correction',

            600: 'Corporate',
            601: 'Corporate Cash Vouchers',

            602: 'Gift Given',
            603: 'Gift Received',
        };

        vm.transactionHistory = allCashBookPositions.Result;
        vm.months = getMonths();
        vm.years = Common.getYears();

        vm.query_year = $transition$.params().year || undefined;
        vm.query_month = $transition$.params().month || undefined;

        vm.modalOpen = modalOpen;

        vm.pagination = {
            Change: pageChange,
            Count: allCashBookPositions.Count,
            CurrentPage: $transition$.params().page,
            PageSize: 20,
        };

        function pageChange() {
            $state.go(
                'users.users_transaction_history',
                {
                    page: vm.pagination.CurrentPage,
                    year: vm.query_year,
                    month: vm.query_month,
                },
                { reload: true }
            );
        }

        function getMonths() {
            // set months according to $locale
            const array = [];
            for (let i = 0; i < $locale.DATETIME_FORMATS.MONTH.length; i++) {
                array.push({
                    number: String(i + 1),
                    name: $locale.DATETIME_FORMATS.MONTH[i],
                });
            }

            return array;
        }

        function modalOpen(size, orderId, ordertype) {
            $uibModal
                .open({
                    templateUrl: `./templates/users/users/user-transaction-history-modal.html?ver=${
                        appConfig.appVersion
                    }`,
                    controller: 'UserTransactionHistoryModalInstanceCtrl',
                    controllerAs: 'vm',
                    size,
                    resolve: {
                        order: [
                            'UserOrders',
                            function(UserOrders) {
                                return UserOrders.one(orderId)
                                    .get()
                                    .then(function(succ) {
                                        // convert date to local date
                                        succ.CreateDate = new Date(succ.CreateDate);

                                        // if order or part of order is refunded;
                                        succ.IsRefunded = false;

                                        // convert itemkey to last 5 digits only
                                        for (let i = 0; i < succ.OrderItems.length; i++) {
                                            succ.OrderItems[i].ProductItemKey =
                                                succ.OrderItems[i].ProductItemKey;

                                            if (succ.OrderItems[i].IsRefunded) {
                                                succ.IsRefunded = true;
                                            }
                                        }

                                        return succ;
                                    });
                            },
                        ],
                        language() {
                            return 'en-US';
                        },
                        ordertype() {
                            return ordertype;
                        },
                    },
                })
                .result.then(
                    function() {
                        console.log('closed');
                        // force refresh
                        $state.go(
                            'users.users_transaction_history',
                            {
                                page: vm.pagination.CurrentPage,
                                year: vm.query_year,
                                month: vm.query_month,
                            },
                            { reload: true }
                        );
                    },
                    function() {
                        return null;
                    }
                );
        }
    }

    UserTransactionHistoryModalInstanceCtrl.$inject = [
        'language',
        'order',
        'ordertype',
        'Locations',
        'ProductItems',
        'Products',
        'Restangular',
        'SwalHelper',
        '$scope',
        '$uibModalInstance',
    ];
    function UserTransactionHistoryModalInstanceCtrl(
        language,
        order,
        ordertype,
        Locations,
        ProductItems,
        Products,
        Restangular,
        SwalHelper,
        $scope,
        $uibModalInstance
    ) {
        const vm = this;

        vm.confirmRefund = confirmRefund;
        vm.resendReceipt = resendReceipt;
        vm.showItemDetails = showItemDetails;
        vm.hideItemDetails = hideItemDetails;

        vm.ok = ok;
        vm.language = language;
        vm.order = order;
        vm.cancel = cancel;

        // only show refund options for orders dated from 1 April
        vm.order.CreateDate = new Date(vm.order.CreateDate);
        vm.april = new Date('2017-03-31 23:59:59');

        vm.order.OrderType = ordertype;

        getProductInfo(vm.order.OrderItems, 0);

        vm.texts = {
            'en-US': {
                Product: 'Product Number',
                ProductName: 'Product Name',
                Discount: 'Discount',
                Price: 'Price',
                OrderTotal: 'Order Total: ',
                OrderReference: 'Order Reference: ',
                Date: 'Date: ',
                Close: 'Close',
            },
            'de-CH': {
                Product: 'Produktnummer',
                ProductName: 'Produktname',
                Discount: 'Rabatt',
                Price: 'Preis',
                OrderTotal: 'Gesamtbetrag: ',
                OrderReference: 'Referenznummer: ',
                Date: 'Datum: ',
                Close: 'Schliessen',
            },
            'fr-CH': {
                Product: 'nº de Produit',
                ProductName: 'Nom de Produit',
                Discount: 'Rabais',
                Price: 'Prix',
                OrderTotal: 'Total: ',
                OrderReference: 'Numéro de Reference: ',
                Date: 'Date: ',
                Close: 'Fermer',
            },
        };

        function getProductInfo(OrderItems, index) {
            Products.one(OrderItems[index].ProductId)
                .get()
                .then(function(succ) {
                    succ = setProductInfo(succ, 'en-US');
                    OrderItems[index].Product = succ;
                    if (index < OrderItems.length - 1) {
                        index++;
                        // loop is here, promise loop
                        getProductInfo(OrderItems, index);
                    }
                });
        }

        function confirmRefund(item, isFullRefund) {
            SwalHelper.confirm('This action is not reversible!', function() {
                makeRefund(item, isFullRefund);
            });
        }

        function makeRefund(item, isFullRefund) {
            SwalHelper.showSavingMessage('Processing...');

            if (isFullRefund) {
                var id = item.OrderId;
            } else {
                var id = item.OrderItemId;
            }

            Restangular.one('api/Orders/Refund')
                .customPOST(null, null, { id, isFullRefund })
                .then(
                    function(succ) {
                        vm.order.IsRefunded = true;

                        if (isFullRefund) {
                            for (let i = 0; i < vm.order.OrderItems.length; i++) {
                                vm.order.OrderItems[i].IsRefunded = true;
                            }
                        } else {
                            item.IsRefunded = true;
                        }

                        SwalHelper.showSuccess('Refunded!');
                    },
                    function(err) {
                        if (err.status == 403) {
                            SwalHelper.showError('You are not authorized to perform this action.');
                        } else {
                            SwalHelper.showError(err.data.Message);
                        }
                    }
                );
        }

        function resendReceipt(orderId) {
            SwalHelper.confirm('This will resend the receipt to user', function() {
                SwalHelper.showLoading('Sending...');

                Restangular.one('api/Orders')
                    .one(orderId)
                    .one('Receipt')
                    .get({ email: '' })
                    .then(
                        function(succ) {
                            SwalHelper.showSuccess('Sent!');
                        },
                        function(err) {
                            if (err.status == 403) {
                                SwalHelper.showError('You are not authorized to perform this action.');
                            } else {
                                SwalHelper.showError(err.data.Message);
                            }
                        }
                    );
            });
        }

        function ok() {
            $uibModalInstance.close();
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function setProductInfo(product, language) {
            product.DisplayName = _.find(product.ProductLanguages, {
                LanguageCode: language,
            }).ProductName;
            return product;
        }

        async function showItemDetails(itemKey) {
            vm.showItemDetail = true;
            vm.itemDetails = await getItemDetails(itemKey);
        }

        async function hideItemDetails() {
            vm.showItemDetail = false;
            vm.itemDetails = null;
        }

        async function getItemDetails(itemKey) {
            const item = await ProductItems.one(itemKey).get();
            item.Product = setProductInfo(item.Product, 'en-US');
            item.Location = await Locations.one(item.LocationId).get({
                isPublic: true,
            });

            return item;
        }
    }
})();
