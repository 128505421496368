(function() {
    const app = angular.module('felfel.location.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const locationList = {
            name: 'locations.locations_list',
            url: '/locations?{page:int}?{pageSize:int}?{status:string}?{search:string}?{sortOrder:string}',
            templateUrl: './templates/locations/locations/location.html',
            controller: 'Location',
            controllerAs: 'vm',
            params: { page: 1, pageSize: 20 },
            reloadOnsearch: false,
            resolve: {
                allObjs,
            },
        };

        const locationEdit = {
            name: 'locations.locations_edit',
            url: '/locations/edit/:id?CompanyId',
            params: { id: '' },
            templateUrl: './templates/locations/locations/location-edit.html',
            controller: 'LocationEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
                allAddressStates,
                allCompanies,
                allContractProducts,
                allLocationGroups,
                allLogCompanies,
                allPricingGroups,
                logisticsSchedule,
                allServiceTiers: allServiceTiers,
                locationTags,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(locationList))
            .state(new appConfig.UiRouterConfig(locationEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['Common', 'SwalHelper', '$transition$', 'Restangular'];
    function allObjs(Common, SwalHelper, $transition$, Restangular) {
        const options = Common.getLocationOptions();

        let params = $transition$.params();

        const queryParams = {
            status: params.status ? params.status : 'Active',
            page: params.page ? params.page : 1,
            pageSize: params.pageSize ? params.pageSize : 20,
            sortOrder: params.sortOrder ? params.sortOrder : 'LocationName',
        };

        if (typeof $transition$.params().search === 'string' && $transition$.params().search.length > 0) {
            queryParams.search = $transition$.params().search;
        }

        return Restangular.one('api/v2/')
            .one('Locations')
            .get(queryParams)
            .then(
                function(data) {
                    // console.log("allCats", data);
                    swal.close();

                    for (var i = 0; i < data.Result.length; i++) {
                        data.Result[i].TrialPhaseStart = new Date(data.Result[i].TrialPhaseStart);
                        data.Result[i].TrialPhaseEnd = new Date(data.Result[i].TrialPhaseEnd);
                        data.Result[i].TerminationDate = new Date(data.Result[i].TerminationDate);

                        _.forEach(['OpsFridgeType', 'OpsFridgeConnection', 'CEBadgeType'], function(field) {
                            const j = _.findIndex(options[field], {
                                id: data.Result[i][field],
                            });
                            if (j > -1) {
                                data.Result[i][field] = options[field][j].desc;
                            }
                        });

                        _.forEach(['OpsIsCutlery', 'OpsRecycling', 'CEClientEmailList'], function(field) {
                            const l = _.findIndex(options.TrackingOptions, {
                                id: data.Result[i][field],
                            });
                            if (l > -1) {
                                data.Result[i][field] = options.TrackingOptions[l].desc;
                            }
                        });
                    }

                    return data;
                },
                function(err) {
                    // console.log((Locations get error");
                    swal.close();
                    SwalHelper.showServerError();
                }
            );
    }

    allAddressStates.$inject = ['AddressStates', 'SwalHelper'];
    function allAddressStates(AddressStates, SwalHelper) {
        return AddressStates.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allCompanies.$inject = ['Companies', 'SwalHelper'];
    function allCompanies(Companies, SwalHelper) {
        return Companies.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allContractProducts.$inject = ['ContractProducts', 'SwalHelper'];
    function allContractProducts(ContractProducts, SwalHelper) {
        return ContractProducts.getList().then(
            function(data) {
                return data.plain();
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allLogCompanies.$inject = ['LogisticsCompanies', 'SwalHelper'];
    function allLogCompanies(LogisticsCompanies, SwalHelper) {
        return LogisticsCompanies.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allLocationGroups.$inject = ['LocationGroups', 'SwalHelper'];
    function allLocationGroups(LocationGroups, SwalHelper) {
        return LocationGroups.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allPricingGroups.$inject = ['PricingGroups', 'SwalHelper'];
    function allPricingGroups(PricingGroups, SwalHelper) {
        return PricingGroups.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allServiceTiers.$inject = ['ServiceTiers', 'SwalHelper'];
    function allServiceTiers(ServiceTiers, SwalHelper) {
        return ServiceTiers.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    currentObj.$inject = ['Locations', '$transition$'];
    function currentObj(Locations, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return Locations.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        // console.log((Locations get error");
                        swal({
                            title: 'Location does not exist!',
                            type: 'error',
                        });
                    }
                );
        }
    }

    logisticsSchedule.$inject = ['LogisticsSchedules', '$transition$'];
    function logisticsSchedule(LogisticsSchedules, $transition$) {
        if ($transition$.params().id) {
            return LogisticsSchedules.one()
                .get({
                    locationId: $transition$.params().id,
                })
                .then(
                    function(data) {
                        return data.plain();
                    },
                    function(err) {
                        console.log(err);
                        if (err.status == 404) {
                            // delivery schedule not set
                        }
                    }
                );
        }
    }

    locationTags.$inject = ['LocationTags', 'SwalHelper'];
    function locationTags(LocationTags, SwalHelper) {
        return LocationTags.getList().then(
            function(response) {
                return response.plain();
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }
})();
