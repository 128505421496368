(function() {
    angular.module('felfel').factory('LocationTags', LocationTags);

    LocationTags.$inject = ['Restangular'];
    function LocationTags(Restangular) {
        const LocationTags = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'LocationTagId',
            });
        });

        // argument is the RESTapi URL
        return LocationTags.service('api/v2/locations/tags');
    }
})();
