(function() {
    const app = angular.module('felfel.locations.routes', [
        'felfel.config',
        'felfel.pricinggroup.routes',
        'felfel.location.routes',
        'felfel.company.routes',
        'felfel.logisticscompany.routes',
        'felfel.contract.routes',
    ]);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const locationsAbstract = {
            abstract: true,
            name: 'locations',
            url: '/locations',
            templateUrl: './templates/locations/locations-abstract.html',
        };

        $stateProvider.state(new appConfig.UiRouterConfig(locationsAbstract));
    }
})();
