(function() {
    angular.module('felfel').directive('contactList', contactList);

    contactList.$inject = ['ContactsFactory', 'appConfig'];
    function contactList(ContactsFactory, appConfig) {
        const directive = {
            replace: true,
            templateUrl: `./templates/directives/contact-list.html?ver=${appConfig.appVersion}`,
            scope: {
                contactsArray: '=',
                contactModel: '=',
            },
            link(scope) {
                scope.ContactsFactory = ContactsFactory;

                // sort contacts according to contact types, then by name
                if (scope.contactsArray) {
                    scope.contactsArray = scope.contactsArray.sort(function(a, b) {
                        // sort by contact types
                        if (a.ContactTypes[0].ContactTypeId < b.ContactTypes[0].ContactTypeId) return -1;
                        if (a.ContactTypes[0].ContactTypeId > b.ContactTypes[0].ContactTypeId) return 1;

                        if (a.Contact.ApplicationUser && b.Contact.ApplicationUser) {
                            if (a.Contact.ApplicationUser.Email < b.Contact.ApplicationUser.Email) return -1;
                            if (a.Contact.ApplicationUser.Email > b.Contact.ApplicationUser.Email) return 1;
                        } else if (!a.Contact.ApplicationUser && !b.Contact.ApplicationUser) {
                            if (a.Contact.Email < b.Contact.Email) return -1;
                            if (a.Contact.Email > b.Contact.Email) return 1;
                        } else if (a.Contact.ApplicationUser && !b.Contact.ApplicationUser) {
                            if (a.Contact.ApplicationUser.Email < b.Contact.Email) return -1;
                            if (a.Contact.ApplicationUser.Email > b.Contact.Email) return 1;
                        } else if (!a.Contact.ApplicationUser && b.Contact.ApplicationUser) {
                            if (a.Contact.Email < b.Contact.ApplicationUser.Email) return -1;
                            if (a.Contact.Email > b.Contact.ApplicationUser.Email) return 1;
                        }

                        return 0;
                    });
                }
            },
        };

        return directive;
    }
})();
