(function() {
    angular.module('felfel').factory('PromoRules', PromoRules);

    PromoRules.$inject = ['Restangular'];
    function PromoRules(Restangular) {
        const PromoRules = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'PromoRuleId',
            });
        });
        return PromoRules.service('api/PromoRules');
    }
})();
