(function() {
    angular.module('felfel').controller('FindBatch', FindBatch);

    FindBatch.$inject = ['Common', 'Locations', 'Products', 'Restangular', 'SwalHelper'];
    function FindBatch(Common, Locations, Products, Restangular, SwalHelper) {
        const vm = this;
        vm.findBatch = findBatch;

        async function findBatch(batchId) {
            vm.item = null;

            try {
                // fetch batch
                const batch = await Restangular.one('api/v2/pos/supplier-batches/find')
                    .one(batchId)
                    .get({
                        locationId: 'e48dd706-ba4e-47ab-8fee-899ad03ca113',
                    });
                vm.item = batch.plain();

                // fetch product details
                getProductName(vm.item.ProductId);

                // fetch recalled locations details if any
                getLocationNames(vm.item.RecalledLocations);
            } catch (error) {
                console.log(error);
                if (error.status == 404) {
                    SwalHelper.showError('Batch Id does not exist');
                } else {
                    SwalHelper.showServerError();
                }
            }
        }

        async function getProductName(productId) {
            const product = (await Products.one(productId).get()).plain();
            vm.item.ProductName = _.find(product.ProductLanguages, {
                LanguageCode: 'en-US',
            }).ProductName;
        }

        async function getLocationNames(recalledLocations) {
            const promises = recalledLocations.map(async locationId => {
                return Locations.one(locationId).get({ isPublic: true });
            });
            const resolvedPromises = await Promise.all(promises);

            // turn data into a single string
            vm.item.RecalledLocations = resolvedPromises
                .map(l => l.LocationName)
                .reduce((a, c) => a.concat(`${c}, `), '');
        }
    }
})();
