(function() {
    angular.module('felfel').factory('FoodTags', FoodTags);

    FoodTags.$inject = ['Restangular'];
    function FoodTags(Restangular) {
        const FoodTags = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'FoodTagId',
            });
        });

        // argument is the RESTapi URL
        return FoodTags.service('api/FoodTags');
    }
})();
