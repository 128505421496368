(function() {
    const app = angular.module('felfel.inventoryoverview.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const inventoryoverview = {
            name: 'inventories.inventory_overview',
            url: '/inventory_overview',
            templateUrl: './templates/inventories/inventoryoverview/inventoryoverview.html',
            controller: 'InventoryOverview',
            controllerAs: 'vm',
            resolve: {
                allInventoryLocations,
                allLocations,
            },
        };

        const temperatureRecall = {
            name: 'inventories.temperature_recall',
            url: '/temperature-recall-legacy',
            templateUrl: './templates/inventories/inventoryoverview/temperature-recall.html',
            controller: 'TemperatureRecall',
            controllerAs: 'vm',
            resolve: {
                allProductLocks,
                allLocations,
            },
        };

        const coolcell = {
            name: 'inventories.coolcell',
            url: '/coolcell/?inventory',
            templateUrl: './templates/inventories/inventoryoverview/coolcell.html',
            controller: 'Coolcell',
            controllerAs: 'vm',
            resolve: {
                allInventoryLocations,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(inventoryoverview))
            .state(new appConfig.UiRouterConfig(temperatureRecall))
            .state(new appConfig.UiRouterConfig(coolcell));
    }

    //
    // resolve functions
    //
    allInventoryLocations.$inject = ['InventoryLocations', 'SwalHelper'];
    function allInventoryLocations(InventoryLocations, SwalHelper) {
        return InventoryLocations.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allLocations.$inject = ['Locations', 'SwalHelper'];
    function allLocations(Locations, SwalHelper) {
        return Locations.getList({ selectList: true }).then(
            function(data) {
                let i = data.length;
                while (i--) {
                    if (data[i].EmployeeAmount <= 0) {
                        data.splice(i, 1);
                    }
                }
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allProductLocks.$inject = ['Restangular', 'SwalHelper'];
    function allProductLocks(Restangular, SwalHelper) {
        return Restangular.one('api/v2')
            .one('product-locks')
            .getList()
            .then(
                function(data) {
                    return data.plain();
                },
                function(err) {
                    SwalHelper.showServerError();
                }
            );
    }
})();
