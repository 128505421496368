(function() {
    angular.module('felfel').factory('Forecasts', Forecasts);

    Forecasts.$inject = ['Restangular'];
    function Forecasts(Restangular) {
        const Forecasts = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRequestSuffix('/').setRestangularFields({
                id: 'forecastid',
            });
        });

        return Forecasts.service('ix/forecasts');
    }
})();
