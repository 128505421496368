(function() {
    angular.module('felfel').controller('InventoryOverview', InventoryOverview);

    // first controller
    InventoryOverview.$inject = [
        'allInventoryLocations',
        'allLocations',
        'Common',
        'Locations',
        'ProductItems',
        'SwalHelper',
        '$state',
        '$rootScope',
    ];
    function InventoryOverview(
        allInventoryLocations,
        allLocations,
        Common,
        Locations,
        ProductItems,
        SwalHelper,
        $state,
        $rootScope
    ) {
        $rootScope.title = 'Inventory Overview';

        const vm = this;
        vm.allLocations = Common.sortByName(allLocations, 'LocationName');
        vm.allInventoryLocations = allInventoryLocations;
        vm.viewdate = new Date();

        vm.deleteObj = deleteObj;
        vm.isLocation = false;
        vm.loadView = loadView;

        // for sorting table, default options
        vm.sort_pred = 'Name';
        vm.sort_rev = false;
        vm.sort = sort;

        function deleteObj(location) {
            SwalHelper.confirmDelete(location.Name, function() {
                InventoryLocations.one(location.InventoryLocationId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(location.Name);
                            $state.go('inventories.inventorylocations_list', {}, { reload: true });
                        },
                        function(err) {
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function loadView(isLocation) {
            // console.log(isLocation);
            if (isLocation) {
                var params = {
                    LocationId: vm.location,
                    ExpiringDate: vm.viewdate,
                };

                ProductItems.one('ProductItemInLocation')
                    .get(params)
                    .then(
                        function(data) {
                            vm.status = Common.sortByName(data, 'ProductName');
                            // console.log(vm.status);
                        },
                        function(err) {
                            SwalHelper.showServerError();
                        }
                    );
            } else {
                var params = {
                    InventoryLocationId: vm.inventory_location,
                    ExpiringDate: vm.viewdate,
                };

                ProductItems.one('ProductItemStatusOverview')
                    .get(params)
                    .then(
                        function(data) {
                            vm.status = Common.sortByName(data, 'ProductName');
                            // console.log(vm.status);
                        },
                        function(err) {
                            SwalHelper.showServerError();
                        }
                    );
            }
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
})();
