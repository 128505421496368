(function() {
    angular.module('felfel').factory('PricingGroups', PricingGroups);

    PricingGroups.$inject = ['Restangular'];
    function PricingGroups(Restangular) {
        const PricingGroups = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'PricingGroupId',
            });
        });
        return PricingGroups.service('api/PricingGroups');
    }
})();
