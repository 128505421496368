(function() {
    angular.module('felfel').factory('AddressStates', AddressStates);

    AddressStates.$inject = ['Restangular'];
    function AddressStates(Restangular) {
        const AddressStates = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'stateId',
            });
        });
        return AddressStates.service('api/states');
    }
})();
