(function() {
    angular.module('felfel').factory('UsersWebApi', UsersWebApi);

    UsersWebApi.$inject = ['Restangular'];
    function UsersWebApi(Restangular) {
        const UsersWebApi = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({});
        });
        return UsersWebApi.service('api/v1/users');
    }
})();
