(function() {
    angular.module('felfel').factory('LogisticsSchedules', LogisticsSchedules);

    LogisticsSchedules.$inject = ['Restangular'];
    function LogisticsSchedules(Restangular) {
        const LogisticsSchedules = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'LogisticsScheduleId',
            });
        });
        return LogisticsSchedules.service('api/LogisticsSchedules');
    }
})();
