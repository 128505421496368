(function() {
    const app = angular.module('felfel.packagingtype.routes', ['ui.router', 'felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const packagingTypeList = {
            name: 'products.packagingtypes_list',
            url: '/packagingtypes',
            templateUrl: './templates/products/packagingtypes/packagingtype.html',
            controller: 'PackagingType',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const packagingTypeEdit = {
            name: 'products.packagingtypes_edit',
            url: '/packagingtypes/edit/:id',
            params: { id: '' },
            templateUrl: './templates/products/packagingtypes/packagingtype-edit.html',
            controller: 'PackagingTypeEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(packagingTypeList))
            .state(new appConfig.UiRouterConfig(packagingTypeEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['PackagingTypes'];
    function allObjs(PackagingTypes) {
        return PackagingTypes.getList().then(
            function(data) {
                // console.log("allObjs", data);
                return data;
            },
            function(err) {
                // console.log("foodtypes get error");
                swal({
                    title: '',
                    text: 'Server Error!',
                    type: 'error',
                });
            }
        );
    }

    currentObj.$inject = ['PackagingTypes', '$transition$'];
    function currentObj(PackagingTypes, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return PackagingTypes.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentCat", data);
                        return data;
                    },
                    function(err) {
                        // console.log("packagingtypes get error");
                        swal({
                            title: '',
                            text: 'Food Type does not exist!',
                            type: 'error',
                        });
                    }
                );
        }
    }
})();
