(function() {
    const app = angular.module('felfel.logisticscompany.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const logisticsCompanyList = {
            name: 'locations.logisticscompanies_list',
            url: '/logisticscompanies',
            templateUrl: './templates/locations/logisticscompanies/logisticscompany.html',
            controller: 'LogisticsCompany',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const logisticsCompanyEdit = {
            name: 'locations.logisticscompanies_edit',
            url: '/logisticscompanies/edit/:id',
            params: { id: '' },
            templateUrl: './templates/locations/logisticscompanies/logisticscompany-edit.html',
            controller: 'LogisticsCompanyEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(logisticsCompanyList))
            .state(new appConfig.UiRouterConfig(logisticsCompanyEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['LogisticsCompanies', 'SwalHelper'];
    function allObjs(LogisticsCompanies, SwalHelper) {
        return LogisticsCompanies.getList().then(
            function(data) {
                // console.log("allCats", data);
                return data;
            },
            function(err) {
                // console.log((LogisticsCompanies get error");
                SwalHelper.showServerError();
            }
        );
    }

    currentObj.$inject = ['LogisticsCompanies', 'SwalHelper', '$transition$'];
    function currentObj(LogisticsCompanies, SwalHelper, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return LogisticsCompanies.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        // console.log((LogisticsCompanies get error");
                        SwalHelper.showError('Logistic Company does not exist!');
                    }
                );
        }
    }
})();
