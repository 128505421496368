(function() {
    const app = angular.module('felfel.pricinggroup.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const pricingGroupList = {
            name: 'locations.pricinggroups_list',
            url: '/pricinggroups',
            templateUrl: './templates/locations/pricinggroups/pricinggroup.html',
            controller: 'PricingGroup',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const pricingGroupEdit = {
            name: 'locations.pricinggroups_edit',
            url: '/pricinggroups/edit/:id',
            params: { id: '' },
            templateUrl: './templates/locations/pricinggroups/pricinggroup-edit.html',
            controller: 'PricingGroupEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(pricingGroupList))
            .state(new appConfig.UiRouterConfig(pricingGroupEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['PricingGroups'];
    function allObjs(PricingGroups) {
        return PricingGroups.getList().then(
            function(data) {
                // console.log("allCats", data);
                return data;
            },
            function(err) {
                // console.log((PricingGroups get error");
                swal({
                    title: '',
                    text: 'Server Error!',
                    type: 'error',
                });
            }
        );
    }

    currentObj.$inject = ['PricingGroups', '$transition$'];
    function currentObj(PricingGroups, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return PricingGroups.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentObj", data);
                        return data;
                    },
                    function(err) {
                        // console.log((PricingGroups get error");
                        swal({
                            title: '',
                            text: 'Pricing Group does not exist!',
                            type: 'error',
                        });
                    }
                );
        }
    }
})();
