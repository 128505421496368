(function() {
    angular
        .module('felfel')
        .controller('Product', Product)
        .controller('ProductEdit', ProductEdit);

    // first controller
    Product.$inject = [
        'Common',
        'Products',
        'ProductPrice',
        'allObjs',
        'allSuppliers',
        'SwalHelper',
        '$state',
        '$transition$',
        '$rootScope',
    ];
    function Product(
        Common,
        Products,
        ProductPrice,
        allObjs,
        allSuppliers,
        SwalHelper,
        $state,
        $transition$,
        $rootScope
    ) {
        $rootScope.title = 'Products';

        const vm = this;
        vm.allSuppliers = Common.sortByName(allSuppliers, 'SupplierName');
        vm.activeOnly = $transition$.params().IsActive;
        vm.deleteObj = deleteObj;
        vm.search = search;
        vm.sort = sort;
        vm.sort_pred = 'DisplayName'; // for sorting table, default options
        vm.sort_rev = false; // for sorting table, default options
        vm.pageChange = pageChange;
        vm.pagination = {};
        vm.products = allObjs.Result;
        vm.query = _.isUndefined($transition$.params().search) ? '' : $transition$.params().search;
        vm.query_supplier = $transition$.params().supplier;
        vm.reload = reload;

        vm.pagination.Count = allObjs.Count;

        if (!_.isUndefined($transition$.params().search)) {
            vm.pagination.PageSize = 20;
        } else {
            vm.pagination.PageSize = 20;
        }
        vm.pagination.CurrentPage = $transition$.params().page;
        vm.pagination.Change = pageChange;

        vm.allSuppliers.unshift({
            SupplierId: undefined,
            SupplierName: 'All Suppliers',
        });

        // find the english name and set to DisplayName
        for (let i = 0; i < vm.products.length; i++) {
            const j = _.findIndex(vm.products[i].ProductLanguages, {
                LanguageCode: 'en-US',
            });
            vm.products[i].DisplayName = vm.products[i].ProductLanguages[j].ProductName;

            (function(i) {
                ProductPrice.one()
                    .get({ productid: vm.products[i].ProductId })
                    .then(function(succ) {
                        const j = _.findIndex(succ.ProductPricingGroups, {
                            PricingGroupId: 'e86d5737-3e6c-4460-baa1-3df33925b8e4',
                        });
                        if (j > -1) {
                            vm.products[i].SellingPrice = succ.ProductPricingGroups[j].Price;
                        }
                    });
            })(i);
        }

        function deleteObj(product) {
            SwalHelper.confirmDelete(product.DisplayName, function() {
                Products.one(product.ProductId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(product.DisplayName, function() {
                            $state.go('products.products_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function pageChange() {
            SwalHelper.showLoading();
            $state.go(
                'products.products_list',
                {
                    page: vm.pagination.CurrentPage,
                    search: vm.query,
                    IsActive: vm.activeOnly,
                    supplier: vm.query_supplier,
                },
                { reload: true }
            );
        }

        function reload(para) {
            SwalHelper.showLoading();
            vm.activeOnly = para;
            $state.go(
                'products.products_list',
                {
                    page: 1,
                    search: vm.query,
                    IsActive: vm.activeOnly,
                    supplier: vm.query_supplier,
                },
                { reload: true }
            );
        }

        function search() {
            SwalHelper.showLoading();
            $state.go(
                'products.products_list',
                {
                    page: 1,
                    search: vm.query,
                    IsActive: vm.activeOnly,
                    supplier: vm.query_supplier,
                },
                { reload: true }
            );
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller

    // second controller
    ProductEdit.$inject = [
        'Common',
        'Upload',
        'Products',
        'Ingredients',
        'ProductPrice',
        'ProductIngredients',
        'Restangular',
        'PusherService',
        'SwalHelper',
        'allCats',
        'allPackagingTypes',
        'allPricingGroups',
        'allProductChildren',
        'allSuppliers',
        'allTags',
        'allTypes',
        'currentObj',
        '$http',
        '$rootScope',
        '$scope',
        '$state',
        '$transition$',
        'appConfig',
    ];
    function ProductEdit(
        Common,
        Upload,
        Products,
        Ingredients,
        ProductPrice,
        ProductIngredients,
        Restangular,
        PusherService,
        SwalHelper,
        allCats,
        allPackagingTypes,
        allPricingGroups,
        allProductChildren,
        allSuppliers,
        allTags,
        allTypes,
        currentObj,
        $http,
        $rootScope,
        $scope,
        $state,
        $transition$,
        appConfig
    ) {
        // set up pusher //
        if ($transition$.params().id) {
            const channel_name = `presence-prod-edit-${$transition$.params().id}`;
            PusherService.checkConcurrency(channel_name, $scope);
        }

        const vm = this;

        $scope.$watch('vm.selected_ingredient', function() {
            // console.log("ingredient change");
            if (
                vm.selected_ingredient &&
                _.some(vm.ingredients, {
                    IngredientId: vm.selected_ingredient.IngredientId,
                })
            ) {
                SwalHelper.showError('Ingredient has already been added!');
                vm.selected_ingredient = undefined;
            }
        });

        vm.addIngredient = addIngredient;
        vm.allCats = allCats;
        vm.allIngredients = [];
        vm.allPackagingTypes = allPackagingTypes;
        vm.allPricingGroups = allPricingGroups;
        vm.allSuppliers = Common.sortByName(allSuppliers, 'SupplierName');
        vm.allTags = allTags;
        vm.allTypes = allTypes;
        vm.clearForm = clearForm;
        vm.currentProduct = currentObj || {};
        vm.currentProduct.ProductLanguages = vm.currentProduct.ProductLanguages || [];
        vm.deleteObj = deleteObj;
        vm.ingredients = [];
        vm.isEdit = false;
        vm.packagingcost = 0;
        vm.previewLabel = previewLabel;
        vm.productchildren = allProductChildren;
        vm.ProductPricingGroups = allPricingGroups;
        vm.refreshIngredients = refreshIngredients;
        vm.removeImage = removeImage;
        vm.removeIngredient = removeIngredient;
        vm.saveObj = saveObj;
        vm.selectPackaging = selectPackaging;
        vm.selected_ingredient_titleitem = false; // default for ingredient IsItemInTitle
        vm.updateIngredients = updateIngredients;

        vm.tabs = [
            {
                heading: 'General',
                active: true,
                template: `./templates/products/products/product-edit-general.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                heading: 'Nutritional Info',
                active: false,
                template: `./templates/products/products/product-edit-nutritionalinfo.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                heading: 'Costing',
                active: false,
                template: `./templates/products/products/product-edit-costing.html?ver=${
                    appConfig.appVersion
                }`,
            },
            {
                heading: 'Pricing',
                active: false,
                template: `./templates/products/products/product-edit-pricing.html?ver=${
                    appConfig.appVersion
                }`,
            },
        ];

        vm.label_info = {
            LabelTypes: Common.getLabelTypes(),
            ProductPreparationTypes: Common.getProductPreparationTypes(),
            ProductPreparationAdvice: Common.getProductPreparationAdvice(),
        };

        // preset all current product to active and fresh
        if (_.isUndefined(vm.currentProduct.IsActive)) {
            vm.currentProduct.IsActive = true;
        }

        if (_.isUndefined(vm.currentProduct.IsFresh)) {
            vm.currentProduct.IsFresh = true;
        }

        // determine if product exists, i.e. to add new or edit existing
        if (_.has(vm.currentProduct, 'ProductId')) {
            vm.isEdit = true;
            vm.currentImage = vm.currentProduct.MediaImage.LGUrl;
            vm.currentImage2 = vm.currentProduct.MediaImage2.LGUrl;

            // get the product price if product already exists
            ProductPrice.one()
                .get({ productid: vm.currentProduct.ProductId })
                .then(function(data) {
                    vm.ProductPrice = data;
                    // update ProductPrice with currentProductPrice array
                    for (let i = 0; i < vm.ProductPrice.ProductPricingGroups.length; i++) {
                        const j = _.findIndex(vm.ProductPricingGroups, {
                            PricingGroupId: vm.ProductPrice.ProductPricingGroups[i].PricingGroupId,
                        });
                        vm.ProductPricingGroups[j].Price = vm.ProductPrice.ProductPricingGroups[i].Price;
                    }
                });

            // get the product ingredients if product already exists
            ProductIngredients.one()
                .get({ productid: vm.currentProduct.ProductId })
                .then(function(data) {
                    vm.ProductIngredients = data;
                    vm.ingredients = vm.ProductIngredients.ProductIngredients;
                    vm.ingredients.sort(function(a, b) {
                        return b.Amount - a.Amount;
                    }); // descending sort on amount
                    vm.updateIngredients(true);
                });
        }

        // check if all languages exist for ProductLanguages
        // loop through languageOptions defined in rootscope
        vm.lang = $rootScope.languages; // an object with language code: language name //used in template
        vm.languages = $rootScope.languageOptions; // an array with language codes

        for (var j = 0; j < vm.languages.length; j++) {
            const code = vm.languages[j];
            // if the language code does not exist, append to array
            if (
                !_.some(vm.currentProduct.ProductLanguages, {
                    LanguageCode: code,
                })
            ) {
                vm.currentProduct.ProductLanguages.push({
                    ProductName: '',
                    Tagline: '',
                    IngredientList: '',
                    LanguageCode: code,
                });
            }
        }
        // sort product languages
        Common.sortByName(vm.currentProduct.ProductLanguages, 'LanguageCode');

        // loop through allCats to find the en-US name and set to DisplayName
        for (var i = 0; i < vm.allCats.length; i++) {
            var j = _.findIndex(vm.allCats[i].FoodCategoryLanguages, {
                LanguageCode: 'en-US',
            });
            vm.allCats[i].DisplayName = vm.allCats[i].FoodCategoryLanguages[j].FoodCategoryName;
        }

        // loop through allTags to find the en-US name and set to DisplayName
        for (var i = 0; i < vm.allTags.length; i++) {
            var j = _.findIndex(vm.allTags[i].FoodTagLanguages, {
                LanguageCode: 'en-US',
            });
            vm.allTags[i].DisplayName = vm.allTags[i].FoodTagLanguages[j].FoodTagName;
        }

        function addIngredient() {
            console.log('add ingredient');

            vm.ingredients.push({
                ProductId: vm.currentProduct.ProductId,
                Ingredient: vm.selected_ingredient,
                IngredientId: vm.selected_ingredient.IngredientId,
                Amount: vm.selected_ingredient_amount,
                IsItemInTitle: vm.selected_ingredient_titleitem,
            });
            vm.ingredients.sort(function(a, b) {
                return b.Amount - a.Amount;
            }); // descending sort on amount
            vm.updateIngredients();

            vm.selected_ingredient = undefined;
            vm.selected_ingredient_amount = undefined;
            vm.selected_ingredient_titleitem = false;
            console.log(vm.currentProduct);
        }

        function clearForm() {
            console.log('clear form');
            vm.removeImage();
            vm.currentProduct.FoodCategories = undefined;
            vm.currentProduct.FoodTypeId = undefined;
            vm.currentProduct.SupplierId = undefined;
            vm.selected_ingredient = undefined;
        }

        // extract only the information required for POST or PUT for ProductIngredients
        function cleanIngredients() {
            vm.ProductIngredients.ProductIngredients = [];
            for (let i = 0; i < vm.ingredients.length; i++) {
                vm.ProductIngredients.ProductIngredients.push({
                    IngredientId: vm.ingredients[i].IngredientId,
                    Amount: vm.ingredients[i].Amount,
                    IsItemInTitle: vm.ingredients[i].IsItemInTitle,
                });
            }
        }

        function deleteObj() {
            console.log('delete');
            SwalHelper.confirmDelete('this Product', function() {
                Products.one(vm.currentProduct.ProductId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted('Product', function() {
                            $state.go('products.products_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function previewLabel() {
            Restangular.one('api/LabelPrint')
                .one('ZPL')
                .one('ProductLabel')
                .one('Preview')
                .customPOST(
                    {
                        ProductId: vm.currentProduct.ProductId,
                        PrintAmount: 1,
                        BarcodeOnly: false,
                    },
                    null,
                    null,
                    {
                        'Content-Type': 'application/json',
                    }
                )
                .then(
                    function(succ) {
                        previewLabelAPI(encodeURIComponent(succ.PrintCommand));
                    },
                    function(err) {
                        console.log('GET label preview error');
                        console.log(err);
                    }
                );
        }

        function previewLabelAPI(zpl) {
            // $http({
            //   method: 'GET',
            //   url: 'http://api.labelary.com/v1/printers/8dpmm/labels/3.6x5/0/' + zpl
            // }).then(function(succ) {
            //     vm.label = succ;
            //   }, function(err) {
            //     console.log("GET label API error");
            // 	console.log(err);
            //   });

            vm.label = `http://api.labelary.com/v1/printers/8dpmm/labels/3.6x4/0/${zpl}`;
        }

        // GET ingredients by search query
        function refreshIngredients(query) {
            if (!_.isEmpty(query)) {
                Ingredients.getList({ search: query, amount: 50 }).then(function(data) {
                    vm.allIngredients = data;
                });
            }
        }

        function removeImage() {
            console.log('remove image');
            vm.currentImage = null;
            vm.currentImage2 = null;
            vm.image1 = null;
            vm.image2 = null;
            delete vm.currentProduct.MediaImageId;
            delete vm.currentProduct.MediaImage2Id;
            delete vm.currentProduct.MediaImage;
            delete vm.currentProduct.MediaImage2;
            // console.log(vm.currentProduct);
        }

        function removeIngredient(id) {
            _.remove(vm.ingredients, function(each) {
                return each.IngredientId == id;
            });
            vm.ingredients.sort(function(a, b) {
                return b.Amount - a.Amount;
            }); // descending sort on amount
            vm.updateIngredients();
        }

        // function saves everything in one go. saves object first, return ProductId in order to save pricing, ingredients then image.
        function saveObj(exit, $flow) {
            vm.exit = exit;

            if (vm.currentProduct.FoodCategories.length > 1) {
                SwalHelper.showError('Please select only one category.');
                return;
            }

            if (vm.currentProduct.ShelfLife > vm.currentProduct.ProductLife) {
                SwalHelper.showError('Product Life has to be greater or equal to Shelf Life.');
                return;
            }

            delete vm.currentProduct.PackagingType;
            delete vm.currentProduct.FoodType;
            delete vm.currentProduct.Supplier;
            SwalHelper.showSaving();

            if (_.has(vm.currentProduct, 'ProductId')) {
                vm.currentProduct.put().then(
                    function() {
                        // after saving product, PUT ProductPricingGroups
                        vm.ProductPrice.ProductPricingGroups = vm.ProductPricingGroups;
                        vm.ProductPrice.put({
                            productid: vm.currentProduct.ProductId,
                        }).then(
                            function() {
                                // after saving product, and PUT ProductPricingGroups, PUT ProductIngredients
                                cleanIngredients();
                                vm.ProductIngredients.put({
                                    productid: vm.currentProduct.ProductId,
                                }).then(
                                    function() {
                                        uploadImage(vm.currentProduct);
                                    },
                                    function(err) {
                                        SwalHelper.showServerError('update');
                                        console.log('product ingredients put error');
                                        console.log(err);
                                    }
                                );
                            },
                            function(errorResponse) {
                                SwalHelper.showServerError('update');
                                console.log('product price put error');
                                console.log(errorResponse);
                            }
                        );
                    },
                    function(errorResponse) {
                        console.log('product put error');
                        console.log(errorResponse);
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError('save');
                        }
                    }
                );
            } else {
                Products.post(vm.currentProduct).then(
                    function(data) {
                        vm.currentProduct = data;

                        // once product created we GET ProductPrice
                        ProductPrice.one()
                            .get({ productid: vm.currentProduct.ProductId })
                            .then(function(data) {
                                vm.ProductPrice = data;
                                vm.ProductPrice.ProductPricingGroups = vm.ProductPricingGroups;
                                vm.ProductPrice.put({
                                    productid: vm.currentProduct.ProductId,
                                }).then(
                                    function() {
                                        ProductIngredients.one()
                                            .get({
                                                productid: vm.currentProduct.ProductId,
                                            })
                                            .then(function(data) {
                                                vm.ProductIngredients = data;
                                                cleanIngredients();
                                                vm.ProductIngredients.put({
                                                    productid: vm.currentProduct.ProductId,
                                                }).then(
                                                    function() {
                                                        uploadImage(vm.currentProduct);
                                                    },
                                                    function(err) {
                                                        SwalHelper.showServerError('save');
                                                        console.log('product ingredients put error');
                                                        console.log(err);
                                                    }
                                                );
                                            });
                                    },
                                    function(err) {
                                        SwalHelper.showServerError('save');
                                        console.log('product price put error');
                                        console.log(err);
                                    }
                                );
                            });
                    },
                    function(errorResponse) {
                        console.log('product post error');
                        console.log(errorResponse);
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError('save');
                        }
                    }
                );
            }
        }

        function saveSuccess() {
            SwalHelper.showSaved('', function() {
                if (vm.exit) {
                    // $state.go('products.products_list');
                    window.history.back();
                } else {
                    $state.go('products.products_edit', {
                        id: vm.currentProduct.ProductId,
                    });
                }
            });
        }

        // used to display packaging cost in Costing View.
        function selectPackaging() {
            if (!_.isUndefined(vm.currentProduct.PackagingTypeId)) {
                const j = _.findIndex(vm.allPackagingTypes, {
                    PackagingTypeId: vm.currentProduct.PackagingTypeId,
                });
                vm.packagingcost = vm.allPackagingTypes[j].PackagingCost;
            }
        }

        function uploadImage(obj) {
            let count = 0;

            if (vm.image1) {
                count += 1;
                SwalHelper.showUploadingImage();
                uploadImageSingle(obj, vm.image1, 1).then(
                    function(succ) {
                        count -= 1;
                        if (count == 0) {
                            saveSuccess();
                        }
                    },
                    function(err) {}
                );
            }

            if (vm.image2) {
                count += 1;
                SwalHelper.showUploadingImage();
                uploadImageSingle(obj, vm.image2, 2).then(
                    function(succ) {
                        count -= 1;
                        if (count == 0) {
                            saveSuccess();
                        }
                    },
                    function(err) {}
                );
            }

            if (count == 0) {
                saveSuccess();
            }
        }

        function uploadImageSingle(obj, img, type) {
            // type denotes "main" or "secondary" image
            let url;
            if (type == 1) {
                url = `${appConfig.baseUrl}api/FileManager?MediaImageForeignGuid=${
                    obj.ProductId
                }&MediaImageType=Product`;
            } else if (type == 2) {
                url = `${appConfig.baseUrl}api/FileManager?MediaImageForeignGuid=${
                    obj.ProductId
                }&MediaImageType=Product2`;
            }

            img.upload = Upload.upload({
                url,
                data: { file: img },
            });

            // returns a promise
            return img.upload;
        }

        // function that runs everytime an ingredient is added.
        function updateIngredients(init) {
            vm.product_contents = {
                weight: 0,
                energy: 0,
                carbs: 0,
                proteins: 0,
                fats: 0,
            };

            // calculate product contents
            for (var i = 0; i < vm.ingredients.length; i++) {
                vm.product_contents.weight += vm.ingredients[i].Amount;
                vm.product_contents.energy += vm.ingredients[i].Amount * vm.ingredients[i].Ingredient.KCal;
                vm.product_contents.carbs += vm.ingredients[i].Amount * vm.ingredients[i].Ingredient.Carbs;
                vm.product_contents.proteins +=
                    vm.ingredients[i].Amount * vm.ingredients[i].Ingredient.Protein;
                vm.product_contents.fats += vm.ingredients[i].Amount * vm.ingredients[i].Ingredient.Fat;
            }

            // push final contents to currentProduct
            // vm.currentProduct.Weight = vm.product_contents.weight;
            // vm.currentProduct.Carbs = vm.product_contents.carbs;
            // vm.currentProduct.Energy = vm.product_contents.energy;
            // vm.currentProduct.Protein = vm.product_contents.proteins;
            // vm.currentProduct.Fats = vm.product_contents.fats;

            // dont run the below for the first time
            if (!init) {
                // clear all ingredient displays
                for (var i = 0; i < vm.currentProduct.ProductLanguages.length; i++) {
                    vm.currentProduct.ProductLanguages[i].IngredientListSuggestion = '';
                }

                for (var i = 0; i < vm.ingredients.length; i++) {
                    // loop through all languages and change IngredientListSuggestion
                    for (let j = 0; j < vm.languages.length; j++) {
                        const code = vm.languages[j];

                        const k = _.findIndex(vm.currentProduct.ProductLanguages, { LanguageCode: code });
                        const l = _.findIndex(vm.ingredients[i].Ingredient.IngredientLanguages, {
                            LanguageCode: code,
                        });

                        if (_.isEmpty(vm.currentProduct.ProductLanguages[k].IngredientListSuggestion)) {
                            if (vm.ingredients[i].Ingredient.Allergen) {
                                // UPPER CASE for Allergens
                                vm.currentProduct.ProductLanguages[
                                    k
                                ].IngredientListSuggestion += vm.ingredients[
                                    i
                                ].Ingredient.IngredientLanguages[l].Name.toUpperCase();
                            } else {
                                vm.currentProduct.ProductLanguages[k].IngredientListSuggestion +=
                                    vm.ingredients[i].Ingredient.IngredientLanguages[l].Name;
                            }
                        } else if (vm.ingredients[i].Ingredient.Allergen) {
                            // UPPER CASE for Allergens
                            vm.currentProduct.ProductLanguages[
                                k
                            ].IngredientListSuggestion += `, ${vm.ingredients[
                                i
                            ].Ingredient.IngredientLanguages[l].Name.toUpperCase()}`;
                        } else {
                            vm.currentProduct.ProductLanguages[k].IngredientListSuggestion += `, ${
                                vm.ingredients[i].Ingredient.IngredientLanguages[l].Name
                            }`;
                        }

                        // add percentage if ingredient is in title
                        if (vm.ingredients[i].IsItemInTitle) {
                            vm.currentProduct.ProductLanguages[k].IngredientListSuggestion += ` ${String(
                                Math.round((vm.ingredients[i].Amount / vm.product_contents.weight) * 100)
                            )}%`;
                        }
                    }
                }
            }
        }
    }
    // end second controller
})();
