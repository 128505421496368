(function() {
    angular.module('felfel').factory('News', News);

    News.$inject = ['Restangular'];
    function News(Restangular) {
        const News = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'NewsId',
            });
        });
        return News.service('api/News');
    }
})();
