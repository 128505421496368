(function() {
    angular.module('felfel').factory('FoodCats', FoodCats);

    FoodCats.$inject = ['Restangular'];
    function FoodCats(Restangular) {
        const FoodCats = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'FoodCategoryId',
            });
        });

        // argument is the RESTapi URL
        return FoodCats.service('api/FoodCategories');
    }
})();
