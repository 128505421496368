(function() {
    angular.module('felfel').controller('InternalOrdersEditNew', InternalOrdersEditNew);

    InternalOrdersEditNew.$inject = [
        'allLocations',
        'allProducts',
        'Common',
        'Restangular',
        'DeliveryBatches',
        'WebAPIMenus',
        'PickListProducts',
        'ForecastWeeks',
        'SwalHelper',
        '$scope',
        '$transition$',
    ];
    function InternalOrdersEditNew(
        allLocations,
        allProducts,
        Common,
        Restangular,
        DeliveryBatches,
        WebAPIMenus,
        PickListProducts,
        ForecastWeeks,
        SwalHelper,
        $scope,
        $transition$
    ) {
        const vm = this;
        vm.allLocations = allLocations;
        vm.allProducts = allProducts;

        vm.getPickList = getPickList;
        vm.getTotalCost = getTotalCost;

        vm.saveObj = saveObj;
        vm.autofill = autofill;

        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);

        vm.locations = [];

        vm.datepickerOptions = {
            minDate: tomorrow,
        };

        loadLocations();

        $scope.$watch('vm.newBatch.LocationId', function() {
            const startdate = new Date();
            startdate.setDate(today.getDate() - 14);

            const enddate = new Date('2100-01-01').toJSON();

            vm.picklist = null;

            if (!_.isUndefined(vm.newBatch) && vm.isEdit && vm.newBatch.LocationId != null) {
                const params = {
                    InventoryLocationId: 'DD3108D4-2AFA-4E13-A270-A6DB1735C911', // hardcoded
                    LocationId: vm.newBatch.LocationId,
                    DeliveryDateBetweenStart: startdate,
                    DeliveryDateBetweenEnd: enddate,
                };

                DeliveryBatches.one()
                    .get(params)
                    .then(
                        function(data) {
                            vm.allBatches = data;

                            for (let i = 0; i < vm.allBatches.length; i++) {
                                const date = new Date(vm.allBatches[i].DeliveryDate).toDateString();

                                // if true, then use location name
                                if (vm.allBatches[i].RelatedLocation) {
                                    const j = _.findIndex(vm.allLocations, {
                                        LocationId: vm.allBatches[i].Destination,
                                    });
                                    vm.allBatches[i].DisplayName = `${
                                        vm.allBatches[i].Location.LocationName
                                    } - ${vm.allBatches[i].RelatedLocation.LocationName} - ${date}`;
                                } else {
                                    vm.allBatches[i].DisplayName = `${
                                        vm.allBatches[i].Location.LocationName
                                    } - ${vm.allBatches[i].Destination} - ${date}`;
                                }
                            }
                            if (vm.allBatches.length == 0 && vm.isEdit) {
                                SwalHelper.showMessage('No existing orders found!');
                            }
                        },
                        function(err) {
                            SwalHelper.showServerError();
                        }
                    );
            }
        });

        $scope.$watch('vm.existingBatch', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (vm.existingBatch && vm.existingBatch.RelatedLocationId) {
                    vm.locationInput = true;
                } else {
                    vm.locationInput = false;
                }

                // clear pick list on change
                vm.picklist = null;
            }
        });

        function changeDate(date) {
            // to make sure date is always sent as dd-mm-yyyy 00:00:00 UTC
            return `${moment(date)
                .format()
                .substring(0, 11)}00:00:00Z`;
        }

        function getPickList() {
            if (vm.isEdit) {
                // prevents double-saving. either Destination or RelatedLocationId has to be null
                if (vm.locationInput) {
                    vm.existingBatch.Destination = null;
                } else {
                    vm.existingBatch.RelatedLocationId = null;
                }
                // updates current delivery batch, in case instructions/location change
                DeliveryBatches.one(vm.existingBatch.DeliveryBatchId).customPUT(vm.existingBatch);

                // get current picklist
                PickListProducts.one(vm.existingBatch.DeliveryBatchId)
                    .get()
                    .then(function(data) {
                        vm.picklist = data;

                        // sort DisplayName
                        for (let i = 0; i < vm.picklist.PickListProducts.length; i++) {
                            const j = _.findIndex(vm.allProducts, {
                                ProductId: vm.picklist.PickListProducts[i].ProductId,
                            });
                            vm.picklist.PickListProducts[i].DisplayName = vm.allProducts[j].DisplayName;
                            vm.picklist.PickListProducts[i].Amount =
                                vm.picklist.PickListProducts[i].RequestedAmount;
                            vm.picklist.PickListProducts[i].Image = vm.allProducts[j].MediaImage.ThumbUrl;
                            vm.picklist.PickListProducts[i].Cost = vm.allProducts[j].CostPrice;
                        }
                    });
            } else {
                vm.newBatch.DeliveryDate = changeDate(vm.deliveryDate);
                vm.newBatch.InventoryLocationId = 'DD3108D4-2AFA-4E13-A270-A6DB1735C911'; // hardcoded

                // prevents double-saving. either Destination or RelatedLocationId has to be null
                if (vm.locationInput) {
                    vm.newBatch.Destination = null;
                } else {
                    vm.newBatch.RelatedLocationId = null;
                }

                getForecastWeek(vm.newBatch.DeliveryDate)
                    .then(getMenu, function(err) {
                        $q.reject(err);
                    })
                    .then(saveAndLoadEmptyPickList, function(err) {});
            }
        }

        function getTotalCost() {
            let sum = 0;

            if (!vm.picklist) return sum;

            for (let i = 0; i < vm.picklist.PickListProducts.length; i++) {
                sum += vm.picklist.PickListProducts[i].Amount * vm.picklist.PickListProducts[i].Cost;
            }

            return sum;
        }

        async function loadLocations() {
            await Restangular.one('/api/v2/locations')
                .get({
                    locationTagAliasIds: 'event-location',
                    locationResponseType: 'LocationDto',
                    pageSize: '9999', // poor back-end coding enforces pageSize :(
                })
                .then(
                    function(response) {
                        vm.locations = response.Results;
                    },
                    function(errorResponse) {
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function autofill(qty) {
            for (let i = 0; i < vm.picklist.PickListProducts.length; i++) {
                vm.picklist.PickListProducts[i].Amount = qty;
            }
        }

        function populatePickList() {
            if (vm.menuproducts) {
                for (let i = 0; i < vm.menuproducts.length; i++) {
                    const { productid } = vm.menuproducts[i];
                    const l = _.findIndex(vm.allProducts, {
                        ProductId: productid,
                    });

                    if (l > -1) {
                        vm.picklist.PickListProducts.push({
                            ProductId: productid,
                            Amount: 0,
                            DisplayName: vm.allProducts[l].DisplayName,
                            Image: vm.allProducts[l].MediaImage.ThumbUrl,
                            Cost: vm.allProducts[l].CostPrice,
                        });
                    }
                }
            }
        }

        function saveAndLoadEmptyPickList(menuproducts) {
            vm.menuproducts = menuproducts;
            DeliveryBatches.post(vm.newBatch).then(
                function(data) {
                    PickListProducts.one(data.DeliveryBatchId)
                        .get()
                        .then(
                            function(data2) {
                                vm.picklist = data2;

                                // populate picklist
                                populatePickList();
                                // saves zero picklist
                                saveObj();
                            },
                            function(err) {
                                SwalHelper.showServerError();
                            }
                        );
                },
                function(err) {
                    console.log(err);
                    SwalHelper.showError(err.data.Message);
                }
            );
        }

        function getMenu(forecastweek) {
            return WebAPIMenus.one()
                .get({
                    forecastweekid: forecastweek.forecastweekid,
                    locationid: '6e543f90-d228-428e-ab05-ebe86f7b1e46',
                })
                .then(
                    function(succ) {
                        try {
                            const { menuProducts } = succ.plain().pagedResults.result[0];
                            const res = menuProducts.map(item => {
                                const dto = {};
                                dto.productid = item.productId;
                                dto.shelfposition = item.shelfPosition;

                                return dto;
                            });

                            return res;
                        } catch (ex) {
                            SwalHelper.showError('Could not find a menu.');
                            console.log(ex);
                            return $q.reject(err);
                        }
                    },
                    function(err) {
                        SwalHelper.showError('Could not find a menu.');
                        console.log(err);
                        return $q.reject(err);
                    }
                );
        }

        function getForecastWeek(date) {
            const monday = getMonday(date);
            return ForecastWeeks.one()
                .get({ weekstartdate: monday })
                .then(
                    function(data) {
                        return data.plain();
                    },
                    function(err) {
                        SwalHelper.showError('Menu is not yet ready for this week.');
                        return $q.reject(err);
                    }
                );
        }

        // updates picklist
        function saveObj(exit) {
            if (vm.existingBatch && new Date(vm.existingBatch.DeliveryDate) < new Date()) {
                SwalHelper.showError('Unable to update order in the past.');
                return;
            }

            if (exit) {
                SwalHelper.showSaving();
            }

            vm.picklist.put().then(function() {
                if (exit) {
                    SwalHelper.showSaved('');
                }
            });
        }

        function getMonday(d) {
            d = new Date(d);
            const day = d.getDay();
            const diff = d.getDate() - day + 1; // adjust when day is sunday
            return moment(new Date(d.setDate(diff)))
                .format()
                .slice(0, 10);
        }
    }
})();
