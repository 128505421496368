(function() {
    angular.module('felfel').factory('Account', Account);

    Account.$inject = ['Restangular'];
    function Account(Restangular) {
        const Users = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({});
        });
        return Users.service('api/Account');
    }
})();
