(function() {
    angular.module('felfel').factory('SupplierOrderItems', SupplierOrderItems);

    SupplierOrderItems.$inject = ['Restangular'];
    function SupplierOrderItems(Restangular) {
        const SupplierOrderItems = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRequestSuffix('/').setRestangularFields({
                id: 'supplierorderitemid',
            });

            RestangularConfigurer.setBaseUrl(REACT_APP_IX_URI);
        });

        return SupplierOrderItems.service('ix/supplierorderitems/');
    }
})();
