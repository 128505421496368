(function() {
    const app = angular.module('felfel.logschedules.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const logScheduleList = {
            name: 'operations.logschedules_list',
            url: '/logisticsschedules',
            templateUrl: './templates/operations/logisticsschedules/logschedule.html',
            controller: 'LogSchedule',
            controllerAs: 'vm',
            resolve: {
                allObjs,
                allLocations,
            },
        };

        const logScheduleEdit = {
            name: 'operations.logschedules_edit',
            url: '/logisticsschedules/edit/:id',
            params: { id: '' },
            templateUrl: './templates/operations/logisticsschedules/logschedule-edit.html',
            controller: 'LogScheduleEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(logScheduleList))
            .state(new appConfig.UiRouterConfig(logScheduleEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['LogisticsSchedules', 'SwalHelper'];
    function allObjs(LogisticsSchedules, SwalHelper) {
        return LogisticsSchedules.getList().then(
            function(data) {
                // console.log("allObjs", data);
                return data;
            },
            function(err) {
                // console.log("LogisticsSchedules get error");
                SwalHelper.showServerError();
            }
        );
    }

    allLocations.$inject = ['Restangular', 'SwalHelper'];
    function allLocations(Restangular, SwalHelper) {
        return Restangular.all('api/v2/locations/list')
            .getList()
            .then(
                function(data) {
                    // console.log("allObjs", data);
                    return data.plain();
                },
                function(err) {
                    // console.log("LogisticsSchedules get error");
                    SwalHelper.showServerError();
                }
            );
    }

    currentObj.$inject = ['LogisticsSchedules', '$transition$', 'SwalHelper'];
    function currentObj(LogisticsSchedules, $transition$, SwalHelper) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return LogisticsSchedules.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        // console.log("currentCat", data);
                        return data;
                    },
                    function(err) {
                        // console.log("foodtypes get error");
                        SwalHelper.showError('LogSchedule does not exist!');
                    }
                );
        }
    }
})();
