(function() {
    const app = angular.module('felfel.items.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const findItem = {
            name: 'inventories.find_batch',
            url: '/find-batch',
            templateUrl: './templates/inventories/items/find-batch.html',
            controller: 'FindBatch',
            controllerAs: 'vm',
            resolve: {},
        };

        $stateProvider.state(new appConfig.UiRouterConfig(findItem));
    }
})();
