(function() {
    angular
        .module('felfel')
        .controller('LogisticsBadge', LogisticsBadge)
        .controller('LogisticsBadgeEdit', LogisticsBadgeEdit);

    // first controller
    LogisticsBadge.$inject = [
        'Common',
        'LogisticsBadges',
        'allObjs',
        'SwalHelper',
        '$state',
        '$transition$',
        '$rootScope',
    ];
    function LogisticsBadge(Common, LogisticsBadges, allObjs, SwalHelper, $state, $transition$, $rootScope) {
        const vm = this;

        vm.activeOnly = $transition$.params().IsActive;
        vm.csvExport = csvExport;
        vm.deleteObj = deleteObj;
        vm.pageChange = pageChange;
        vm.pagination = {};
        vm.badges = allObjs.Result;
        vm.query = _.isUndefined($transition$.params().search) ? '' : $transition$.params().search;
        vm.reload = reload;
        vm.search = search;
        vm.sort = sort;
        vm.sort_pred = 'CreateDate'; // for sorting table, default options
        vm.sort_rev = true; // for sorting table, default options

        vm.pagination.Count = allObjs.Count;
        if (!_.isUndefined($transition$.params().search)) {
            vm.pagination.PageSize = 20;
        } else {
            vm.pagination.PageSize = 20;
        }
        vm.pagination.CurrentPage = $transition$.params().page;
        vm.pagination.Change = pageChange;

        // convert dates from UTC to browser setting
        changeDates();

        function changeDates() {
            for (let i = 0; i < vm.badges.length; i++) {
                vm.badges[i].CreateDate = new Date(vm.badges[i].CreateDate);
            }
        }

        function csvExport() {
            const res = [];
            for (let i = 0; i < vm.badges.length; i++) {
                let name = '';
                for (let j = 0; j < vm.badges[i].Contact.ContactLogisticsCompanies.length; j++) {
                    name += `${vm.badges[i].Contact.ContactLogisticsCompanies[j].LogisticsCompany.Name} `;
                }

                if (vm.badges[i].Type == 'Driver') {
                    res.push({
                        code: `D1${vm.badges[i].LogisticsBadgeId}`,
                        name: vm.badges[i].HolderName,
                        type: vm.badges[i].Type,
                        coy: name,
                    });
                } else {
                    res.push({
                        code: `D2${vm.badges[i].LogisticsBadgeId}`,
                        name: vm.badges[i].HolderName,
                        type: vm.badges[i].Type,
                        coy: name,
                    });
                }
            }

            return res;
        }

        function deleteObj(badge) {
            SwalHelper.confirmDelete(badge.HolderName, function() {
                LogisticsBadges.one(badge.LogisticsBadgeId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(badge.HolderName, function() {
                            $state.go('operations.logisticsbadges_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function pageChange() {
            SwalHelper.showLoading();
            $state.go(
                'operations.logisticsbadges_list',
                { page: vm.pagination.CurrentPage, search: vm.query },
                { reload: true }
            );
        }

        function reload(para) {
            SwalHelper.showLoading();
            vm.activeOnly = para;
            $state.go('operations.logisticsbadges_list', { page: 1, search: vm.query }, { reload: true });
        }

        function search() {
            SwalHelper.showLoading();
            $state.go('operations.logisticsbadges_list', { page: 1, search: vm.query }, { reload: true });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller

    // second controller
    LogisticsBadgeEdit.$inject = [
        'allLogCompanies',
        'currentObj',
        'Contacts',
        'LogisticsBadges',
        'SwalHelper',
        'Upload',
        '$rootScope',
        '$scope',
        '$state',
    ];
    function LogisticsBadgeEdit(
        allLogCompanies,
        currentObj,
        Contacts,
        LogisticsBadges,
        SwalHelper,
        Upload,
        $rootScope,
        $scope,
        $state
    ) {
        const vm = this;

        vm.allLogCompanies = allLogCompanies;
        vm.currentBadge = currentObj || {};
        vm.getContacts = getContacts;

        vm.isEdit = !!_.has(vm.currentBadge, 'LogisticsBadgeId');

        vm.now = new Date();
        vm.saveObj = saveObj;

        function getContacts(search) {
            if (search == '') return;

            Contacts.one()
                .get({ search })
                .then(
                    function(succ) {
                        vm.searchContacts = succ.Result;
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        // function saves everything in one go. saves object first, return ProductId in order to save pricing, ingredients then image.
        function saveObj(exit) {
            if (!vm.currentBadge.Contact) {
                SwalHelper.showError('Contact cannot be empty!');
                return;
            }

            vm.currentBadge.ContactId = vm.currentBadge.Contact.ContactId;

            if (_.has(vm.currentBadge, 'LogisticsBadgeId')) {
                vm.currentBadge.put().then(function() {
                    saveSuccess(vm.currentBadge.LogisticsBadgeId, exit);
                });
            } else {
                LogisticsBadges.post(vm.currentBadge).then(function(data) {
                    saveSuccess(data.LogisticsBadgeId, exit);
                });
            }
        }

        function saveSuccess(id, exit) {
            SwalHelper.showSaved('', function() {
                if (exit) {
                    $state.go('operations.logisticsbadges_list');
                } else {
                    $state.go('operations.logisticsbadges_edit', { id }, { reload: true });
                }
            });
        }
    }
    // end second controller
})();
