(function() {
    angular
        .module('felfel')
        .controller('ContractProduct', ContractProduct)
        .controller('ContractProductEdit', ContractProductEdit);

    // first controller
    ContractProduct.$inject = ['ContractProducts', 'allObjs', 'SwalHelper', '$state', '$rootScope'];
    function ContractProduct(ContractProducts, allObjs, SwalHelper, $state, $rootScope) {
        $rootScope.title = 'ContractProducts';

        const vm = this;

        vm.deleteObj = deleteObj;
        vm.sort = sort;
        vm.sort_pred = 'Name'; // for sorting table, default options
        vm.sort_rev = false; // for sorting table, default options
        vm.contractproducts = allObjs;

        function deleteObj(prod) {
            SwalHelper.confirmDelete(prod.Name, function() {
                ContractProducts.one(prod.ContractProductId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(prod.Name, function() {
                                $state.go('locations.contractproducts_list', {}, { reload: true });
                                swal.close();
                            });
                        },
                        function(err) {
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller

    // second controller
    ContractProductEdit.$inject = ['ContractProducts', 'currentObj', 'SwalHelper', '$state', 'appConfig'];
    function ContractProductEdit(ContractProducts, currentObj, SwalHelper, $state, appConfig) {
        const vm = this;

        vm.currentContractProduct = currentObj || {};
        vm.deleteObj = deleteObj;
        vm.isEdit = !!_.has(vm.currentContractProduct, 'ContractProductId');
        vm.saveObj = saveObj;

        function deleteObj() {
            SwalHelper.confirmDelete(vm.currentContractProduct.Name, function() {
                ContractProducts.one(vm.currentContractProduct.ContractProductId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(vm.currentContractProduct.Name, function() {
                                $state.go('locations.contractproducts_list', {}, { reload: true });
                                swal.close();
                            });
                        },
                        function(err) {
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function saveObj(exit) {
            vm.exit = exit;
            SwalHelper.showSaving();
            if (_.has(vm.currentContractProduct, 'ContractProductId')) {
                vm.currentContractProduct.put().then(function(data) {
                    SwalHelper.showSaved('', function() {
                        if (vm.exit) {
                            $state.go('locations.contractproducts_list');
                        } else {
                            $state.go('locations.contractproducts_edit', {
                                id: vm.currentContractProduct.ContractProductId,
                            });
                        }
                    });
                });
            } else {
                ContractProducts.post(vm.currentContractProduct).then(function(data) {
                    vm.currentContractProduct = data;
                    SwalHelper.showSaved('', function() {
                        if (vm.exit) {
                            $state.go('locations.contractproducts_list');
                        } else {
                            $state.go('locations.contractproducts_edit', {
                                id: vm.currentContractProduct.ContractProductId,
                            });
                        }
                    });
                });
            }
        }
    }
    // end second controller
})();
