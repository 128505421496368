(function() {
    angular
        .module('felfel')
        .controller('InventoryLocation', InventoryLocation)
        .controller('InventoryLocationEdit', InventoryLocationEdit);

    // first controller
    InventoryLocation.$inject = ['InventoryLocations', 'allObjs', 'SwalHelper', '$state', '$rootScope'];
    function InventoryLocation(InventoryLocations, allObjs, SwalHelper, $state, $rootScope) {
        $rootScope.title = 'Inventory Locations';

        const vm = this;
        vm.locations = allObjs;
        vm.deleteObj = deleteObj;

        // for sorting table, default options
        vm.sort_pred = 'Name';
        vm.sort_rev = false;
        vm.sort = sort;

        function deleteObj(location) {
            SwalHelper.confirmDelete(location.Name, function() {
                InventoryLocations.one(location.InventoryLocationId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(location.Name);
                            $state.go('inventories.inventorylocations_list', {}, { reload: true });
                        },
                        function(err) {
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    // second controller
    InventoryLocationEdit.$inject = ['InventoryLocations', 'currentObj', 'SwalHelper', '$state'];
    function InventoryLocationEdit(InventoryLocations, currentObj, SwalHelper, $state) {
        const vm = this;

        vm.currentLocation = currentObj || {};
        vm.deleteObj = deleteObj;
        vm.isEdit = !!_.has(vm.currentLocation, 'InventoryLocationId');
        vm.saveObj = saveObj;

        function deleteObj() {
            // console.log("delete");
            SwalHelper.confirmDelete(vm.currentLocation.Name, function() {
                InventoryLocations.one(vm.currentLocation.InventoryLocationId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(vm.currentLocation.Name);
                            $state.go('inventories.inventorylocations_list', {}, { reload: true });
                        },
                        function() {
                            console.log('error');
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function saveObj(exit) {
            if (_.has(vm.currentLocation, 'InventoryLocationId')) {
                vm.currentLocation.put().then(function() {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('inventories.inventorylocations_list');
                        } else {
                            $state.go('inventories.inventorylocations_edit', {
                                id: vm.currentLocation.InventoryLocationId,
                            });
                        }
                    });
                });
            } else {
                InventoryLocations.post(vm.currentLocation).then(function(data) {
                    SwalHelper.showSaved('', function() {
                        if (exit) {
                            $state.go('inventories.inventorylocations_list');
                        } else {
                            $state.go('inventories.inventorylocations_edit', {
                                id: data.InventoryLocationId,
                            });
                        }
                    });
                });
            }
        }
    }
})();
