(function() {
    angular
        .module('felfel')
        .controller('HomeDeliveryOrders', HomeDeliveryOrders)
        .controller('HomeDeliveryOrderEdit', HomeDeliveryOrderEdit);

    // first controller
    HomeDeliveryOrders.$inject = [
        'Common',
        'orders',
        'locations',
        'Restangular',
        'SwalHelper',
        '$state',
        '$transition$',
        'KeycloakAuth',
    ];
    function HomeDeliveryOrders(
        Common,
        orders,
        locations,
        Restangular,
        SwalHelper,
        $state,
        $transition$,
        KeycloakAuth
    ) {
        const vm = this;

        let externalLocations = locations.filter(x => !x.Status.IsInternal);
        vm.externalLocations = Common.sortByName(externalLocations, 'Name');

        // constants
        dateFormat = 'YYYY/MM/DD';

        // function definitions
        vm.pageChange = pageChange;
        vm.processOrder = processOrder;
        vm.processOrders = processOrders;
        vm.search = search;

        // query params
        vm.queryAliasId = $transition$.params().searchAliasId;
        vm.queryCreatedAt = $transition$.params().searchCreatedAt;
        vm.queryDeliveryDate = $transition$.params().searchDeliveryDate;
        vm.queryDeliveryType = $transition$.params().searchDeliveryType;
        vm.queryEmail = $transition$.params().searchEmail;
        vm.queryHomeDeliveryOrderId = $transition$.params().searchHomeDeliveryOrderId;
        vm.queryLocation = $transition$.params().searchLocation;
        vm.queryPageSize = $transition$.params().searchPageSize ? $transition$.params().searchPageSize : '20';
        vm.queryOrderBy = $transition$.params().searchOrderBy
            ? $transition$.params().searchOrderBy
            : 'createdAtDesc';
        vm.queryStatus = $transition$.params().searchStatus;

        // permissions
        vm.canProcessOrders = KeycloakAuth.hasRealmRole('HomeDeliveryOrderProcessing');

        // properties
        vm.deliveryTypes = [];
        vm.orders = orders.pagedResults.results;
        vm.homeDeliveryOrderStates = orders.homeDeliveryOrderStates;

        // initialise paging
        vm.pagination = {};
        vm.pagination.Change = pageChange;
        vm.pagination.Count = orders.pagedResults.count;
        vm.pagination.CurrentPage = $transition$.params().searchPage;
        vm.pagination.PageSize = vm.queryPageSize;

        loadDeliveryTypes();

        // functions
        function getSearchParams(page) {
            return {
                searchAliasId: vm.queryAliasId,
                searchCreatedAt: vm.queryCreatedAt ? moment(vm.queryCreatedAt).format(dateFormat) : undefined,
                searchDeliveryDate: vm.queryDeliveryDate
                    ? moment(vm.queryDeliveryDate).format(dateFormat)
                    : undefined,
                searchDeliveryType: vm.queryDeliveryType,
                searchEmail: vm.queryEmail,
                searchHomeDeliveryOrderId: vm.queryHomeDeliveryOrderId,
                searchLocation: vm.queryLocation,
                searchPage: page,
                searchPageSize: vm.queryPageSize,
                searchOrderBy: vm.queryOrderBy,
                searchStatus: vm.queryStatus,
            };
        }

        function loadDeliveryTypes() {
            return Restangular.one('api/v1/delivery-types')
                .get()
                .then(
                    function(data) {
                        vm.deliveryTypes = data.deliveryTypes;
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        function pageChange() {
            SwalHelper.showLoading();
            $state.go('homedelivery.orders_list', getSearchParams(vm.pagination.CurrentPage), {
                reload: true,
            });
        }

        function processOrder(aliasId) {
            $state.go(
                'homedelivery.order_processing',
                {
                    aliasId: aliasId ? aliasId : null,
                },
                { reload: false }
            );
        }

        function processOrders() {
            $state.go(
                'homedelivery.order_processing',
                {
                    deliveryDate: vm.queryDeliveryDate
                        ? moment(vm.queryDeliveryDate).format(dateFormat)
                        : null,
                },
                { reload: false }
            );
        }

        function search(orderby) {
            SwalHelper.showLoading();
            if (orderby) {
                vm.queryOrderBy = orderby;
            }

            $state.go('homedelivery.orders_list', getSearchParams(1), { reload: true });
        }
    }

    // first controller
    HomeDeliveryOrderEdit.$inject = [
        'Common',
        'deliveryTours',
        'Restangular',
        'SwalHelper',
        '$state',
        '$transition$',
        'KeycloakAuth',
    ];
    function HomeDeliveryOrderEdit(
        Common,
        deliveryTours,
        Restangular,
        SwalHelper,
        $state,
        $transition$,
        KeycloakAuth
    ) {
        const vm = this;

        // function definitions
        vm.amountActualChange = amountActualChange;
        vm.getLocalisedProductName = getLocalisedProductName;
        vm.orderCancel = orderCancel;
        vm.orderItemAdd = orderItemAdd;
        vm.orderUpdate = orderUpdate;
        vm.orderItemAmountActualChange = orderItemAmountActualChange;
        vm.orderItemProductChange = orderItemProductChange;

        // permissions
        vm.canCancel = KeycloakAuth.hasRealmRole('HomeDeliveryOrderCancel');
        vm.canEdit = KeycloakAuth.hasRealmRole('HomeDeliveryOrderEdit');
        vm.canViewSensitive = KeycloakAuth.hasRealmRole('HomeDeliveryOrderViewSensitive');

        // properties
        vm.dateFormatDisplay = 'dd/MM/yyyy';
        vm.dateFormatSending = 'YYYY/MM/DD';
        vm.deliveryTours = deliveryTours;
        vm.locale = 'en-US';
        vm.order = {};
        vm.orderItemDefault = {
            amountOrdered: 0,
            amountActual: 0,
            finalItemPrice: 0.0,
            totalPriceActual: 0.0,
        };
        vm.orderItem = angular.copy(vm.orderItemDefault);
        vm.productDefault = {
            price: 0,
        };
        vm.product = angular.copy(vm.productDefault);
        vm.products = {};
        vm.readonly = true;
        vm.sendEmailConfirmation = true;

        // init methods
        getOrder($transition$.params().id);

        // update order total when amount actual changes
        function amountActualChange(homeDeliveryOrderItem) {
            homeDeliveryOrderItem.totalPriceActual =
                homeDeliveryOrderItem.finalItemPrice * homeDeliveryOrderItem.amountActual;
            updateOrderTotal();
        }

        // display product names in en-US
        function getLocalisedProductName(product) {
            return product.productLanguages.filter(x => x.languageCode == vm.locale)[0].productName;
        }

        async function getOrder(homeDeliveryOrderId) {
            if (!$transition$.params().id) {
                SwalHelper.showValidationError('No id set, go back and select another order');
            }

            await Restangular.one('/api/v1/home-delivery/orders/' + homeDeliveryOrderId)
                .get()
                .then(
                    function(data) {
                        orderLoad(data);

                        // if readonly is disabled load products
                        if (!updateReadonlyState()) {
                            getProducts();
                        }
                    },
                    function(errorResponse) {
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        async function getProducts() {
            await Restangular.one('/api/v1/home-delivery/offer')
                .get({
                    applicationUserId: KeycloakAuth.tokenParsed.sub,
                    deliveryDate: moment(vm.order.deliveryDate).format(vm.dateFormatSending),
                    locale: vm.locale,
                })
                .then(
                    function(data) {
                        vm.products = data.homeDeliveryOfferProducts;
                    },
                    function(errorResponse) {
                        if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                            SwalHelper.showValidationError(errorResponse.data.Message);
                        } else {
                            SwalHelper.showServerError();
                        }
                    }
                );
        }

        async function orderCancel() {
            SwalHelper.confirm(
                'Are you sure you want to cancel this home delivery order for the customer?',
                function() {
                    Restangular.one(
                        '/api/v1/home-delivery/orders/' + vm.order.homeDeliveryOrderId + '/cancel'
                    )
                        .customPOST()
                        .then(
                            function(data) {
                                SwalHelper.showSaved(
                                    'Home Delivery Order (' + vm.order.aliasId + ') Cancelled!'
                                );
                                vm.order = data;
                                updateReadonlyState();
                            },
                            function(response) {
                                console.log(response);
                                if (response.status == 400) {
                                    SwalHelper.showError(response.data.error.message);
                                } else {
                                    SwalHelper.showError('Generic error');
                                }
                            }
                        );
                }
            );
        }

        function orderItemAdd() {
            if (!vm.product.productId) {
                SwalHelper.showValidationError('No product has been selected.');
                return;
            }

            if (
                vm.order.homeDeliveryOrderItems.filter(x => x.product.productId == vm.product.productId)
                    .length > 0
            ) {
                SwalHelper.showValidationError(
                    'Product already in order item list (please adjust the amount actual as needed).'
                );
                return;
            }

            if (vm.orderItem.amountActual < 1) {
                SwalHelper.showValidationError('Amount has not been set.');
                return;
            }

            // push new home delivery order item
            vm.order.homeDeliveryOrderItems.push({
                amountActual: vm.orderItem.amountActual,
                amountOrdered: vm.orderItem.amountOrdered,
                finalItemPrice: vm.orderItem.finalItemPrice,
                product: {
                    productId: vm.product.productId,
                    productLanguages: [
                        {
                            languageCode: vm.locale,
                            productName: vm.product.productName,
                        },
                    ],
                    finalItemPrice: vm.product.price,
                },
                totalPriceActual: vm.orderItem.totalPriceActual,
            });

            updateOrderTotal();

            // reset objects used to add a new item to their defaults
            vm.orderItem = angular.copy(vm.orderItemDefault);
            vm.product = angular.copy(vm.productDefault);
        }

        // when the item to add is changed update the order total
        function orderItemAmountActualChange() {
            vm.orderItem.totalPriceActual = vm.product.price * vm.orderItem.amountActual;
            updateOrderTotal();
        }

        // when the item to add product is changed update the item price and total
        function orderItemProductChange() {
            vm.orderItem.finalItemPrice = vm.product.price;
            vm.orderItem.totalPriceActual = vm.product.price * vm.orderItem.amountActual;
        }

        // function to perform actions after order has been fetched or saved and fetched
        function orderLoad(data) {
            vm.order = data;

            // set delivery date to javascript date so date picker can use it (have to be careful here and set it back again when sending to server)
            vm.order.deliveryDate = new Date(vm.order.deliveryDate);
        }

        async function orderUpdate() {
            SwalHelper.showSaving('Updating home delivery order...');

            let data = {
                homeDeliveryOrderId: vm.order.homeDeliveryOrderId,
                applicationUserId: vm.order.applicationUser.applicationUserId,
                deliveryDate: moment(vm.order.deliveryDate).format(vm.dateFormatSending),
                deliveryInstructions: vm.order.deliveryInstructions,
                deliveryAddress: {
                    careOf: vm.order.deliveryAddress.careOf,
                    city: vm.order.deliveryAddress.city,
                    deliveryAddressId: vm.order.deliveryAddress.deliveryAddressId,
                    firstName: vm.order.deliveryAddress.firstName,
                    lastName: vm.order.deliveryAddress.lastName,
                    phoneNumber: vm.order.deliveryAddress.phoneNumber,
                    streetName: vm.order.deliveryAddress.streetName,
                    streetNumber: vm.order.deliveryAddress.streetNumber,
                    zipCode: { zipCodeId: vm.order.deliveryAddress.zipCode.zipCodeId },
                },
                deliveryTour: {
                    deliveryTourId: vm.order.homeDeliveryTour.homeDeliveryTourId,
                },
                homeDeliveryOrderItems: [],
                sendEmailConfirmation: vm.sendEmailConfirmation,
            };

            // add the order items in a way the backend needs
            vm.order.homeDeliveryOrderItems.forEach(orderItem => {
                data.homeDeliveryOrderItems.push({
                    amount: orderItem.amountActual,
                    productId: orderItem.product.productId,
                });
            });

            await Restangular.one('api/v2/delivery-order/update')
                .customPOST(data)
                .then(
                    function(response) {
                        SwalHelper.showSaved('Home delivery order updated');
                        orderLoad(response);
                    },
                    function(response) {
                        console.log(response);
                        if (response.status == 400) {
                            SwalHelper.showError(response.data.validationErrors.homeDeliveryOrderId);
                        } else {
                            SwalHelper.showError('Generic error');
                        }
                    }
                );
        }

        // sum function - could be moved to common or math helper
        function sum(items, prop) {
            return items.reduce(function(a, b) {
                return a + b[prop];
            }, 0);
        }

        // sum all order items for order total
        function updateOrderTotal() {
            vm.order.totalPriceActual = sum(vm.order.homeDeliveryOrderItems, 'totalPriceActual');
        }

        // update readonly state of page
        function updateReadonlyState() {
            if (!vm.canEdit) {
                return (vm.readonly = true);
            }

            if (vm.order.homeDeliveryOrderState.aliasId != 'received') {
                return (vm.readonly = true);
            }

            return (vm.readonly = false);
        }
    }
})();
