(function() {
    'use strict';

    var app = angular.module('felfel', [
        'ngAnimate',
        'ngCookies',
        'ngResource',
        'ngRoute',
        'ngSanitize',
        'ngTouch',

        'http-auth-interceptor',
        'angular-loading-bar',
        'chart.js',
        'googlechart',
        'flow',
        'mgcrea.ngStrap',
        'ngCsvImport',
        'ngCsv',
        'ngFileUpload',

        'restangular',
        'ui.select',
        'ui.router',
        'ui.bootstrap',
        'tmh.dynamicLocale',
        'uiGmapgoogle-maps',
        'leaflet-directive',
        'pusher-angular',

        'felfel.restangular.helper',
        'felfel.config',
        'felfel.core.auth',
        'felfel.core.common',
        'felfel.core.pusher',
        'felfel.swal.helper',
        'felfel.leaflet.helper',
        'felfel.products.routes',
        'felfel.locations.routes',
        'felfel.inventories.routes',
        'felfel.users.routes',
        'felfel.operations.routes',
        'felfel.forecasting.routes',
        'felfel.keycloak',
    ]);

    app.factory('KeycloakAuthInterceptor', KeycloakAuthInterceptor);
    KeycloakAuthInterceptor.$inject = ['$q', 'KeycloakAuth', 'appConfig'];
    function KeycloakAuthInterceptor($q, KeycloakAuth, appConfig) {
        return {
            request: function(config) {
                var deferred = $q.defer();

                if (KeycloakAuth.token) {
                    // only for Azure APIs
                    KeycloakAuth.updateToken(5)
                        .success(function() {
                            config.headers = config.headers || {};
                            config.headers.Authorization = 'Bearer ' + KeycloakAuth.token;

                            deferred.resolve(config);
                        })
                        .error(function() {
                            console.log("'Failed to refresh token'");
                            deferred.resolve(config);
                            KeycloakAuth.login();
                        });
                } else {
                    deferred.resolve(config);
                }

                return deferred.promise;
            },
        };
    }

    app.run(MainRun);

    MainRun.$inject = [
        '$rootScope',
        '$state',
        '$location',
        '$http',
        '$timeout',
        '$trace',
        '$transitions',
        '$window',
        'PusherService',
        'SwalHelper',
        'tmhDynamicLocale',
        'appConfig',
        'KeycloakAuth',
    ];
    function MainRun(
        $rootScope,
        $state,
        $location,
        $http,
        $timeout,
        $trace,
        $transitions,
        $window,
        PusherService,
        SwalHelper,
        tmhDynamicLocale,
        appConfig,
        KeycloakAuth
    ) {
        console.log('felfel Admin v', appConfig.appVersion);

        $rootScope.env = env;
        $rootScope.environmentDescription = REACT_APP_ENVIRONMENT_DESCRIPTION;
        $rootScope.namakLocationsUrl =
            REACT_APP_ADMIN_2_URL + 'locations?page=0&pageSize=25&sortOrder=LocationName&status=Active';
        $rootScope.namakPosNewsUrl = REACT_APP_ADMIN_2_URL + 'pos-news';
        $rootScope.namakAllUsersUrl = REACT_APP_ADMIN_2_URL + 'users/?page=0&pageSize=100';
        $rootScope.namakUserCreateNewOrder = REACT_APP_ADMIN_2_URL + 'create-new-order';
        $rootScope.namakAddUserUrl = REACT_APP_ADMIN_2_URL + 'users/?createNew=1&page=0&pageSize=100';
        $rootScope.namakAddBulkUsersUrl = REACT_APP_ADMIN_2_URL + 'users-bulk-upload/';
        $rootScope.namakPromoRules = REACT_APP_ADMIN_2_URL + 'promo-rules?page=0&pageSize=25';

        $rootScope.namakDeliverySchedulesUrl = REACT_APP_ADMIN_2_URL + 'delivery-schedules';
        $rootScope.namakPackagingTypesUrl = REACT_APP_ADMIN_2_URL + 'packaging-types';
        $rootScope.namakZipCodesUrl = REACT_APP_ADMIN_2_URL + 'zip-codes';
        $rootScope.namakProductsUrl = REACT_APP_ADMIN_2_URL + 'products/?isActive=true&page=0&pageSize=25';
        $rootScope.$state = $state;
        $rootScope.version = appConfig.appVersion;
        $rootScope.currentYear = new Date().getFullYear();

        $rootScope.currentLanguage = 'en-US';
        $rootScope.isTranslatable = false;
        $rootScope.isLoginPage = true;

        $rootScope.languageOptions = ['en-US', 'de-CH', 'fr-CH', 'it-CH'];

        $rootScope.languages = [];
        $rootScope.languages['en-US'] = 'English';
        $rootScope.languages['de-CH'] = 'Deutsch';
        $rootScope.languages['fr-CH'] = 'Français';
        $rootScope.languages['it-CH'] = 'Italiano';

        $rootScope.user = {};

        tmhDynamicLocale.set('en-us');

        // enumerate routes that don't need authentication
        // these are now routes that don't need footer and header bars
        var routesThatDontRequireAuth = ['/login', '/map-full', '/deliverybatches/print'];

        // check if current location matches route
        var routeClean = function(route) {
            return _.find(routesThatDontRequireAuth, function(noAuthRoute) {
                return _.startsWith(route, noAuthRoute);
            });
        };

        var checkAdmin = function() {
            if (KeycloakAuth.hasRealmRole('Admin')) {
                $rootScope.isFullAdmin = true;
                $rootScope.isLoggedIn = true;
            } else if (KeycloakAuth.hasRealmRole('RestrictedAdmin')) {
                $rootScope.isFullAdmin = false;
                $rootScope.isLoggedIn = true;
            } else {
                $rootScope.isLoggedIn = false;
                SwalHelper.showUnauthorized(function() {
                    KeycloakAuth.logout({ redirectUri: 'http://felfel.ch' });
                });
            }
        };

        // transition tracing
        // $trace.enable('TRANSITION');
        $transitions.onBefore({}, function(trans) {
            angular.element('body').scrollTop(0);

            if (!routeClean(trans.to().url)) {
                $rootScope.isLoginPage = false;

                if (!KeycloakAuth.authenticated) {
                    KeycloakAuth.login();
                } else {
                    checkAdmin();
                    $rootScope.user.Email = KeycloakAuth.tokenParsed.email;
                }

                //if using old version, force them to logout again
                // if (store.get('appVersion') != appVersion) {
                // 	// sets the new version
                // 	store.set('appVersion', appVersion);

                // 	// force logout
                // 	AuthenticationService.logout();
                // 	$state.go('login', {}, {reload: true});
                // }
            } else {
                // if authentication is not needed
                $rootScope.isLoggedIn = true;
            }
        });

        $rootScope.$on('event:auth-loginRequired', function() {
            console.log('login required');
        });

        $rootScope.$on('event:auth-forbidden', function() {
            SwalHelper.showError('You are not authorized to perform this action');
        });

        // $state.transitionTo('operations.ops_map');
    }

    app.config(Configuration);

    Configuration.$inject = [
        '$urlServiceProvider',
        '$locationProvider',
        '$windowProvider',
        '$stateProvider',
        '$logProvider',
        '$httpProvider',
        'RestangularProvider',
        'flowFactoryProvider',
        'tmhDynamicLocaleProvider',
        'uiGmapGoogleMapApiProvider',
        'appConfig',
    ];
    function Configuration(
        $urlServiceProvider,
        $locationProvider,
        $windowProvider,
        $stateProvider,
        $logProvider,
        $httpProvider,
        RestangularProvider,
        flowFactoryProvider,
        tmhDynamicLocaleProvider,
        uiGmapGoogleMapApiProvider,
        appConfig
    ) {
        //disable log debug
        $logProvider.debugEnabled(false);

        // if you don't wish to set base URL then use this
        // $locationProvider.html5Mode({
        // 	enabled: true,
        // });

        // $httpProvider.interceptors.push(function() {
        // 	return {
        // 		request: function(req) {
        // 			req.headers.Authorization = 'Bearer ' + store.get('ta');
        // 			return req;
        // 		}
        // 	};
        // });

        $httpProvider.interceptors.push('KeycloakAuthInterceptor');

        RestangularProvider.setBaseUrl(appConfig.baseUrl);

        // add a response interceptor
        RestangularProvider.setErrorInterceptor(function(response, deferred, responseHandler) {
            if (response.status === 403) {
                return false; // error handled
            }

            return true; // error not handled
        });

        tmhDynamicLocaleProvider.localeLocationPattern(
            'angular/i18n/angular-locale_{{locale}}.js?ver=' + appConfig.appVersion
        );

        flowFactoryProvider.factory = function(opts) {
            var Flow = require('@flowjs/ng-flow/dist/ng-flow-standalone.js');
            // orignal flow.js was probably not loading i dont know why.
            return new Flow(opts);
        };

        flowFactoryProvider.defaults = {
            permanentErrors: [404, 500, 501],
            maxChunkRetries: 1,
            chunkRetryInterval: 2500,
            chunkSize: 9999 * 9999,
            singleFile: true,
        };

        uiGmapGoogleMapApiProvider.configure({
            key: 'AIzaSyA6NZjT0K2OMN510aDiPvPi9LVIKiZVYwM',
            //libraries: 'weather,geometry,visualization'
        });

        var logout = {
            url: '/logout',
            name: 'logout',
            templateUrl: 'templates/login/logout.html',
            params: { queryParams: null },
            controller: 'Logout',
            controllerAs: 'vm',
        };

        var home = {
            url: '/',
            name: 'home',
            templateUrl: 'templates/dashboard/home.html',
            // controller: 'Dashboard',
            // controllerAs: 'vm'
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(logout))
            // .state(dashboard)
            // .state(dashboard2)
            .state(new appConfig.UiRouterConfig(home));

        $urlServiceProvider.rules.otherwise('/');
    }
})();
