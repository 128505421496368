(function() {
    angular.module('felfel').factory('Metrics', Metrics);

    Metrics.$inject = ['Restangular'];
    function Metrics(Restangular) {
        const Metric = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRequestSuffix('/');
            RestangularConfigurer.setBaseUrl(REACT_APP_IX_URI);
        });

        return Metric.service('ix/metrics/');
    }
})();
