(function() {
    angular
        .module('felfel')
        .controller('Menu', Menu)
        .controller('MenuEdit', MenuEdit);

    // first controller
    Menu.$inject = [
        'WebAPIMenus',
        'Suppliers',
        'allObjs',
        'SwalHelper',
        'Restangular',
        '$rootScope',
        '$state',
        '$transition$',
    ];
    function Menu(
        WebAPIMenus,
        Suppliers,
        allObjs,
        SwalHelper,
        Restangular,
        $rootScope,
        $state,
        $transition$
    ) {
        $rootScope.title = 'Suppliers';

        const vm = this;
        vm.menus = allObjs.pagedResults.result;
        vm.deleteObj = deleteObj;

        vm.pagination = {};
        vm.pagination.Change = pageChange;
        vm.pagination.Count = allObjs.pagedResults.count;
        vm.pagination.CurrentPage = $transition$.params().page;
        vm.pagination.PageSize = 20;

        vm.forecastWeek = undefined;
        vm.weeksToAllocateDefaults = getForecastWeeks();
        vm.allocateDefaults = allocateDefaults;

        // for sorting table, default options
        vm.sort_pred = 'menuName';
        vm.sort_rev = false;
        vm.sort = sort;

        vm.list = {};

        // group the menus by forecastweeks
        for (let i = 0; i < vm.menus.length; i++) {
            if (!(vm.menus[i].dateStart in vm.list)) {
                vm.list[vm.menus[i].dateStart] = [];
            }

            vm.list[vm.menus[i].dateStart].push(vm.menus[i]);
        }

        function allocateDefaults() {
            const callAllocateDefaults = Restangular.one(
                `/api/v1/menus/allocate-defaults?forecastWeekStart=${vm.forecastWeek}`
            ).post;

            SwalHelper.confirm(`this will override manual edits.`, function() {
                SwalHelper.showLoading();
                callAllocateDefaults().then(
                    function() {
                        SwalHelper.showSuccess('Done!');
                    },
                    function(response) {
                        if (response.status == 400) {
                            SwalHelper.showError(response.Message);
                        } else {
                            SwalHelper.showError('Unable to allocate menus');
                        }
                    }
                );
            });
        }

        function getForecastWeeks() {
            let today = new Date();

            return [
                getMonday(today.setDate(today.getDate() + 7)).toISOString(),
                getMonday(today.setDate(today.getDate() + 7)).toISOString(),
            ];
        }

        function deleteObj(menu) {
            SwalHelper.confirmDelete(menu.menuName, function() {
                WebAPIMenus.one(menu.menuId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(menu.menuName);
                            $state.go('products.menus_list', {}, { reload: true });
                        },
                        function(response) {
                            console.log(response);
                            if (response.status == 400) {
                                SwalHelper.showError(response.data.error.message);
                            } else {
                                SwalHelper.showError('Unable to delete menu');
                            }
                        }
                    );
            });
        }

        function pageChange() {
            SwalHelper.showLoading();
            $state.go('products.menus_list', { page: vm.pagination.CurrentPage }, { reload: true });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    // second controller
    MenuEdit.$inject = [
        'activeProducts',
        'allLocations',
        'foodCategories',
        'foodTags',
        'foodTypes',
        'menu',
        'PusherService',
        'SwalHelper',
        'Common',
        'Restangular',
        'WebAPIMenus',
        '$scope',
        '$state',
        '$transition$',
        '$rootScope',
    ];
    function MenuEdit(
        activeProducts,
        allLocations,
        foodCategories,
        foodTags,
        foodTypes,
        menu,
        PusherService,
        SwalHelper,
        Common,
        Restangular,
        WebAPIMenus,
        $scope,
        $state,
        $transition$,
        $rootScope
    ) {
        // set up pusher //
        if ($transition$.params().id) {
            const channel_name = `presence-menu-edit-${$transition$.params().id}`;
            PusherService.checkConcurrency(channel_name, $scope);
        }

        const vm = this;

        vm.allProducts = activeProducts;
        vm.addToPlano = addToPlano;
        vm.deleteObj = deleteObj;
        vm.locations = allLocations;
        vm.menu = menu || {};
        vm.menuFlavors = [];

        //for slim fridges
        vm.slimShelfWidth = 8;
        vm.isSlim = false;

        vm.setCurrentMenuFlavor = () => {
            vm.menuFlavors.current = vm.menuFlavors.filter(
                flavor => flavor.menuFlavorId === vm.menu.menuFlavorId
            )[0];
            if (!vm.menuFlavors.current) {
                vm.isSlim = false;
            } else {
                vm.isSlim = vm.menuFlavors.current.aliasId.toLowerCase().startsWith('slim');
            }
        };

        Restangular.one('/api/v1/menus/menu-flavors')
            .get()
            .then(function(data) {
                vm.menuFlavors = data.plain();
                vm.setCurrentMenuFlavor();
            });

        vm.removeFromPlano = removeFromPlano;
        vm.removeFromNotInList = removeFromNotInList;
        vm.saveObj = saveObj;

        // for locations
        vm.calculateSummary = calculateSummary;
        vm.clearAll = clearAll;
        vm.filterActive = filterActive;
        vm.filterChildren = filterChildren;
        vm.selectAll = selectAll;
        vm.selectDE = selectDE;
        vm.selectFELFEL = selectFELFEL;
        vm.selectReset = selectReset;
        vm.selectedLocations = [];

        // options for menu type
        vm.menuTypes = [
            { MenuType: 'AU', MenuName: 'Automatic' },
            { MenuType: 'MN', MenuName: 'Manual (Caterings, Launches, Tastings, MOJs, etc.)' },
            { MenuType: 'HD', MenuName: 'Webshop' },
            { MenuType: 'AC', MenuName: 'Accessories' },
            { MenuType: 'GV', MenuName: 'Gavetti' },
        ];

        // options for datepicker
        vm.dateOptions = {
            'starting-day': 1,
        };
        vm.datepickToggle = datepickToggle;
        vm.datepickStatus = {
            openedStart: false,
            openedEnd: false,
        };

        vm.isEdit = !!_.has(vm.menu, 'menuId');

        // initialise objects for shelves and plano
        vm.plano = []; // total of 7 shelves with 10 columns each
        for (var i = 0; i < 8; i++) {
            const row = [];
            // no undefines for last shelf
            if (i < 7) {
                for (let j = 0; j < 10; j++) {
                    row.push(undefined);
                }
            }
            vm.plano.push(row);
        }
        calculateSummary(vm.toggle);

        if (!_.isEmpty(vm.menu)) {
            vm.menu.dateStart = new Date(vm.menu.dateStart);
            vm.menu.dateStart = new Date(
                vm.menu.dateStart.setHours(0, 0, 0, 0) - vm.menu.dateStart.getTimezoneOffset() * 60000
            );
            vm.originaldate = new Date(vm.menu.dateStart);

            // initialise menu planogram if menu products exist
            for (var i = 0; i < vm.menu.menuProducts.length; i++) {
                const index = _.findIndex(vm.allProducts, {
                    ProductId: vm.menu.menuProducts[i].productId,
                });
                if (index > -1) {
                    if (vm.menu.menuProducts[i].shelfPosition == null) {
                        addToPlano(vm.allProducts[index]);
                    } else {
                        vm.allProducts[index].ShelfPosition = vm.menu.menuProducts[i].shelfPosition;
                        addToPlano(vm.allProducts[index]);
                    }
                }
            }

            // initialise menu locations if menulocations exist
            selectReset();
        } else {
            // set menu type to au by default if none is set
            vm.menu.menuType = 'AU';
        }

        function isOutOfBound(positionStart, positionEnd) {
            if (!vm.isSlim) return false;

            if (positionStart > vm.slimShelfWidth - 1 || positionEnd > vm.slimShelfWidth - 1) {
                return true;
            }

            return false;
        }

        function addToPlano(product) {
            const shelfIndex = parseInt(product.ShelfPosition.slice(1, 2)) - 1;

            const positionStart = convertCharToShelfIndex(product.ShelfPosition.slice(3, 4));
            const positionEnd = convertCharToShelfIndex(product.ShelfPosition.slice(4, 5));
            const shelfWidth = positionEnd - positionStart + 1;

            //slim fridge product out out of bound
            if (isOutOfBound(positionStart, positionEnd)) {
                SwalHelper.showError('Slim fridge! Product shelf position is out of bound.');
                return;
            }

            if (shelfWidth > 4) {
                SwalHelper.showError('Product width is too long! Check Shelf Position.');
                return;
            }

            //  check if shelf position is already taken
            for (var i = positionStart; i < positionStart + shelfWidth; i++) {
                if (vm.plano[shelfIndex][i] != undefined) {
                    var cell = vm.plano[shelfIndex][i];

                    if (!_.has(cell, 'products')) {
                        SwalHelper.showError(
                            'Shelf position already partially taken. Only exact overlap is ok.'
                        );
                        addToNotInList(product);
                        return;
                    }

                    if (cell.ShelfWidth != shelfWidth) {
                        SwalHelper.showError(
                            'Shelf position already partially taken. Only exact overlap is ok.'
                        );
                        addToNotInList(product);
                        return;
                    }

                    if (
                        _.findIndex(cell.products, {
                            ProductId: product.ProductId,
                        }) > -1
                    ) {
                        SwalHelper.showError('Duplicate product!');
                        return;
                    }

                    cell.products.push(product);
                    cell.DisplayName = 'Multiple Products Here';
                    cell.MediaImage = undefined;
                    var exists = true;
                    break;
                }
                var exists = false;
            }

            if (!exists) {
                // insert product only when there are no existing product
                var cell = {};
                cell.ShelfWidth = shelfWidth;
                cell.Display = true;
                cell.DisplayDesc = getDescription(product);
                cell.products = [product];
                cell.DisplayName = product.DisplayName;
                cell.MediaImage = product.MediaImage;

                for (var i = positionStart; i < positionStart + shelfWidth; i++) {
                    if (i == positionStart) {
                        vm.plano[shelfIndex][i] = cell;
                    } else {
                        // every shelf index is taken, related is index of head of product
                        vm.plano[shelfIndex][i] = {
                            Related: positionStart,
                            Display: false,
                        };
                    }
                }

                vm.selected = undefined;
            }

            // recalculates summary
            calculateSummary(vm.toggle);
        }

        function addToNotInList(product) {
            const cell = {};
            cell.DisplayDesc = getDescription(product);
            cell.products = [product];
            cell.DisplayName = product.DisplayName;
            cell.MediaImage = product.MediaImage;
            cell.ShelfPosition = product.ShelfPosition;
            vm.plano[7].push(cell);
            calculateSummary(vm.toggle);
        }

        function getDescription(product) {
            const DisplayDesc = [];

            var ft = _.findIndex(foodTypes, { FoodTypeId: product.FoodTypeId });
            DisplayDesc.push(foodTypes[ft].FoodTypeName);

            for (var k = 0; k < product.FoodCategories.length; k++) {
                const fc = _.findIndex(foodCategories, {
                    FoodCategoryId: product.FoodCategories[k],
                });
                const fcl = _.findIndex(foodCategories[fc].FoodCategoryLanguages, { LanguageCode: 'en-US' });
                DisplayDesc.push(foodCategories[fc].FoodCategoryLanguages[fcl].FoodCategoryName);
            }

            for (var k = 0; k < product.FoodTags.length; k++) {
                var ft = _.findIndex(foodTags, {
                    FoodTagId: product.FoodTags[k],
                });
                const ftl = _.findIndex(foodTags[ft].FoodTagLanguages, {
                    LanguageCode: 'en-US',
                });
                DisplayDesc.push(foodTags[ft].FoodTagLanguages[ftl].FoodTagName);
            }

            return DisplayDesc;
        }

        function calculateSummary(toggle) {
            if (!toggle) {
                toggle = 'all';
            }

            vm.summary = {
                total: 0,
                snacks: 0,
                cogs: 0,
                FoodTypes: {},
                FoodCategories: {},
                FoodTags: {},
            };

            for (let i = 0; i < vm.plano.length; i++) {
                for (let j = 0; j < vm.plano[i].length; j++) {
                    if (_.has(vm.plano[i][j], 'products')) {
                        for (let p = 0; p < vm.plano[i][j].products.length; p++) {
                            const product = vm.plano[i][j].products[p];

                            if (toggle == 'fresh') {
                                if (product.ShelfLife > 14) continue;
                            }
                            if (toggle == 'nonfresh') {
                                if (product.ShelfLife <= 14) continue;
                            } else if (toggle == 'daniela') {
                                // only warm meals, mixed bowls and salads and exclude dressing
                                if (
                                    !(
                                        (_.includes(
                                            product.FoodCategories,
                                            'd5764b4b-6838-11e5-9225-00ac14ef2300'
                                        ) ||
                                            _.includes(
                                                product.FoodCategories,
                                                'd57649b3-6838-11e5-9225-00ac14ef2300'
                                            ) ||
                                            _.includes(
                                                product.FoodCategories,
                                                'd576474b-6838-11e5-9225-00ac14ef2300'
                                            )) &&
                                        !_.includes(product.DisplayName, 'Dressing')
                                    )
                                )
                                    continue;
                            }

                            if (product.ShelfLife <= 14) {
                                vm.summary.total += 1;
                            } else {
                                vm.summary.snacks += 1;
                            }

                            vm.summary.cogs += product.CostPrice;

                            // count FoodType
                            if (_.isEmpty(vm.summary.FoodTypes[product.FoodTypeId])) {
                                vm.summary.FoodTypes[product.FoodTypeId] = {
                                    Name: '',
                                    Count: 0,
                                };
                                var ft = _.findIndex(foodTypes, {
                                    FoodTypeId: product.FoodTypeId,
                                });
                                vm.summary.FoodTypes[product.FoodTypeId].Name = foodTypes[ft].FoodTypeName;
                            }

                            vm.summary.FoodTypes[product.FoodTypeId].Count += 1;

                            // count FoodCategories
                            for (var k = 0; k < product.FoodCategories.length; k++) {
                                if (_.isEmpty(vm.summary.FoodCategories[product.FoodCategories[k]])) {
                                    vm.summary.FoodCategories[product.FoodCategories[k]] = {
                                        Name: '',
                                        Count: 0,
                                    };
                                    const fc = _.findIndex(foodCategories, {
                                        FoodCategoryId: product.FoodCategories[k],
                                    });
                                    const fcl = _.findIndex(foodCategories[fc].FoodCategoryLanguages, {
                                        LanguageCode: 'en-US',
                                    });
                                    vm.summary.FoodCategories[product.FoodCategories[k]].Name =
                                        foodCategories[fc].FoodCategoryLanguages[fcl].FoodCategoryName;
                                }

                                vm.summary.FoodCategories[product.FoodCategories[k]].Count += 1;
                            }

                            // count FoodTags
                            for (var k = 0; k < product.FoodTags.length; k++) {
                                if (_.isEmpty(vm.summary.FoodTags[product.FoodTags[k]])) {
                                    vm.summary.FoodTags[product.FoodTags[k]] = {
                                        Name: '',
                                        Count: 0,
                                    };
                                    var ft = _.findIndex(foodTags, {
                                        FoodTagId: product.FoodTags[k],
                                    });
                                    const ftl = _.findIndex(foodTags[ft].FoodTagLanguages, {
                                        LanguageCode: 'en-US',
                                    });
                                    vm.summary.FoodTags[product.FoodTags[k]].Name =
                                        foodTags[ft].FoodTagLanguages[ftl].FoodTagName;
                                }

                                vm.summary.FoodTags[product.FoodTags[k]].Count += 1;
                            }
                        }
                    }
                }
            }
        }

        function clearAll() {
            vm.selectedLocations = [];
        }

        function convertCharToShelfIndex(char) {
            return char.charCodeAt(0) - 65;
        }

        function datepickToggle() {
            vm.datepickStatus.openedStart = true;
            vm.datepickStatus.openedEnd = true;
        }

        function deleteObj() {
            SwalHelper.confirmDelete(vm.currentSupplier.SupplierName, function() {
                Suppliers.one(vm.currentSupplier.SupplierId)
                    .remove()
                    .then(
                        function() {
                            SwalHelper.showDeleted(vm.currentSupplier.SupplierName);
                            $state.go('products.suppliers_list', {}, { reload: true });
                        },
                        function() {
                            console.log('error');
                            SwalHelper.showServerError('delete');
                        }
                    );
            });
        }

        function filterActive(item) {
            // not internal and not terminated
            if (item.TerminationDate == undefined || new Date(item.TerminationDate) > vm.menu.dateStart) {
                return true;
            }
            return false;
        }

        function filterChildren(item) {
            return !item.IsFamily;
        }

        function removeFromNotInList(index) {
            vm.plano[7].splice(index, 1);

            // recalculates summary
            calculateSummary(vm.toggle);
        }

        function removeFromPlano(shelfIndex, positionIndex, width) {
            // if products array length > 1, we pop the item one by one
            // when products array == 1, we change back to the normal display type, with proper displayname and mediaimage
            for (let i = positionIndex; i < positionIndex + width; i++) {
                if (vm.plano[shelfIndex][i].products && vm.plano[shelfIndex][i].products.length > 1) {
                    vm.plano[shelfIndex][i].products.pop();
                    if (vm.plano[shelfIndex][i].products.length == 1) {
                        vm.plano[shelfIndex][i].DisplayName = vm.plano[shelfIndex][i].products[0].DisplayName;
                        vm.plano[shelfIndex][i].MediaImage = vm.plano[shelfIndex][i].products[0].MediaImage;
                    }
                    break;
                }

                vm.plano[shelfIndex][i] = undefined;
            }

            // recalculates summary
            calculateSummary(vm.toggle);
        }

        function getOutOfBoundProducts() {
            let outOfBoundProducts = [];

            for (var i = 0; i < vm.plano.length; i++) {
                for (let j = 0; j < vm.plano[i].length; j++) {
                    if (_.has(vm.plano[i][j], 'products')) {
                        for (let p = 0; p < vm.plano[i][j].products.length; p++) {
                            const product = vm.plano[i][j].products[p];
                            const positionStart = convertCharToShelfIndex(product.ShelfPosition.slice(3, 4));
                            const positionEnd = convertCharToShelfIndex(product.ShelfPosition.slice(4, 5));

                            if (isOutOfBound(positionStart, positionEnd)) outOfBoundProducts.push(product);
                        }
                    }
                }
            }
            return outOfBoundProducts;
        }

        function saveObj(exit, saveas, skipValidation) {
            const outOfBoundProducts = getOutOfBoundProducts();
            console.log(outOfBoundProducts);
            if (vm.isSlim && !skipValidation && outOfBoundProducts.length > 0) {
                let message = 'The following products have out of bound shelf positions:  ';
                for (const outOfBoundProduct of outOfBoundProducts) {
                    message +=
                        ' ' + outOfBoundProduct.DisplayName + ' (' + outOfBoundProduct.ShelfPosition + '), ';
                }
                message = message.slice(0, -2);

                //Products with shelf position out of bound.
                SwalHelper.confirm(message, () => saveObj(exit, saveas, true));
                return;
            }

            if (vm.menu.dateStart.getDay() != 1) {
                SwalHelper.showError('Start date has to be a Monday!');
                return;
            }
            SwalHelper.showSaving();
            // set to UTC midnight always
            vm.menu.dateStart = new Date(
                vm.menu.dateStart.setHours(0, 0, 0, 0) - vm.menu.dateStart.getTimezoneOffset() * 60000
            );

            vm.menu.menuProducts = [];
            vm.menu.menuLocations = [];

            for (var i = 0; i < vm.plano.length; i++) {
                for (let j = 0; j < vm.plano[i].length; j++) {
                    if (_.has(vm.plano[i][j], 'products')) {
                        for (let p = 0; p < vm.plano[i][j].products.length; p++) {
                            const product = vm.plano[i][j].products[p];
                            vm.menu.menuProducts.push({
                                productId: product.ProductId,
                                shelfPosition: product.ShelfPosition,
                            });
                        }
                    }
                }
            }

            for (var i = 0; i < vm.selectedLocations.length; i++) {
                vm.menu.menuLocations.push(vm.selectedLocations[i].LocationId);
            }

            const countSaved = 0;
            if (_.has(vm.menu, 'menuId') && !saveas) {
                if (vm.originaldate && vm.menu.dateStart.getTime() != vm.originaldate.getTime()) {
                    SwalHelper.showError('You have changed the start date, please use Save As New instead!');
                    return;
                }

                vm.menu.post().then(
                    function() {
                        saved(exit);
                    },
                    function(response) {
                        console.log(response);

                        if (response.status == 400) {
                            SwalHelper.showError(response.data.error.message);
                        } else {
                            SwalHelper.showError(response.Message);
                        }
                    }
                );
            } else {
                if (saveas) {
                    delete vm.menu.menuId;
                    vm.menu.menuLocations = [];
                }

                WebAPIMenus.post(vm.menu).then(
                    function(data) {
                        vm.menu = data;
                        saved(exit);
                    },
                    function(err) {
                        console.log(err);
                        SwalHelper.showServerError();
                    }
                );
            }
        }

        function saved(exit) {
            SwalHelper.showSaved('', function() {
                if (exit) {
                    $state.go('products.menus_list');
                } else {
                    $state.go('products.menus_edit', { id: vm.menu.menuId }, { reload: true });
                }
            });
        }

        function selectAll() {
            vm.selectedLocations = [];
            const menuDateEnd = new Date(vm.menu.dateStart);
            menuDateEnd.setDate(menuDateEnd.getDate() + 7);

            for (let i = 0; i < vm.locations.length; i++) {
                if (
                    !vm.locations[i].IsInternal &&
                    new Date(vm.locations[i].TrialPhaseStart) < menuDateEnd &&
                    (vm.locations[i].TerminationDate == undefined ||
                        new Date(vm.locations[i].TerminationDate) > vm.menu.dateStart)
                ) {
                    vm.selectedLocations.push(vm.locations[i]);
                }
            }
        }

        function selectFELFEL() {
            vm.selectedLocations = [];
            for (let i = 0; i < vm.locations.length; i++) {
                if (vm.locations[i].IsInternal && vm.locations[i].TerminationDate == undefined) {
                    vm.selectedLocations.push(vm.locations[i]);
                }
            }
        }

        function selectReset() {
            vm.selectedLocations = [];
            // initialise menu locations if menulocations exist
            for (let i = 0; i < vm.menu.menuLocations.length; i++) {
                const index = _.findIndex(vm.locations, {
                    LocationId: vm.menu.menuLocations[i],
                });
                vm.selectedLocations.push(vm.locations[index]);
            }

            Common.sortByName(vm.selectedLocations, 'LocationName');
        }

        function selectDE() {
            vm.selectedLocations = [];
            for (let i = 0; i < vm.locations.length; i++) {
                if (
                    filterActive(vm.locations[i]) &&
                    vm.locations[i].Latitude &&
                    vm.locations[i].Latitude >= 46.823
                ) {
                    vm.selectedLocations.push(vm.locations[i]);
                }
            }
        }
    }
})();
