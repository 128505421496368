(function() {
    angular
        .module('felfel')
        .controller('News', News)
        .controller('NewsEdit', NewsEdit);

    // first controller
    News.$inject = ['Common', 'News', 'allObjs', 'SwalHelper', '$state', '$transition$', '$rootScope'];
    function News(Common, News, allObjs, SwalHelper, $state, $transition$, $rootScope) {
        const vm = this;

        vm.activeOnly = $transition$.params().IsActive;
        vm.deleteObj = deleteObj;
        vm.pageChange = pageChange;
        vm.pagination = {};
        vm.news = allObjs.Result;
        vm.query = _.isUndefined($transition$.params().search) ? '' : $transition$.params().search;
        vm.query_location = $transition$.params().location;
        vm.reload = reload;
        vm.search = search;
        vm.sort = sort;
        vm.sort_pred = 'CreateDate'; // for sorting table, default options
        vm.sort_rev = true; // for sorting table, default options

        vm.pagination.Count = allObjs.Count;
        if (!_.isUndefined($transition$.params().search)) {
            vm.pagination.PageSize = 20;
        } else {
            vm.pagination.PageSize = 20;
        }
        vm.pagination.CurrentPage = $transition$.params().page;
        vm.pagination.Change = pageChange;

        // convert dates from UTC to browser setting
        changeDates();

        function changeDates() {
            for (let i = 0; i < vm.news.length; i++) {
                vm.news[i].CreateDate = new Date(vm.news[i].CreateDate);
                vm.news[i].DateStart = new Date(vm.news[i].DateStart);
                vm.news[i].DateEnd = new Date(vm.news[i].DateEnd);
            }
        }

        function deleteObj(news) {
            SwalHelper.confirmDelete(news.Name, function() {
                News.one(news.NewsId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(news.Name, function() {
                            $state.go('users.news_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function pageChange() {
            SwalHelper.showLoading();
            $state.go('users.news_list', {
                page: vm.pagination.CurrentPage,
                search: vm.query,
                location: vm.query_location,
            });
        }

        function reload(para) {
            SwalHelper.showLoading();
            vm.activeOnly = para;
            $state.go(
                'users.news_list',
                { page: 1, search: vm.query === '' ? undefined : vm.query, IsActive: vm.activeOnly },
                { reload: true }
            );
        }

        function search() {
            SwalHelper.showLoading();
            $state.go(
                'users.news_list',
                { page: 1, search: vm.query, IsActive: vm.activeOnly },
                { reload: true }
            );
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller

    // second controller
    NewsEdit.$inject = [
        'currentObj',
        'Common',
        'Locations',
        'Products',
        'PusherService',
        'News',
        'FoodCats',
        'FoodTags',
        'FoodTypes',
        'SwalHelper',
        'Upload',
        '$rootScope',
        '$scope',
        '$state',
        '$transition$',
        'appConfig',
        'Restangular',
    ];
    function NewsEdit(
        currentObj,
        Common,
        Locations,
        Products,
        PusherService,
        News,
        FoodCats,
        FoodTags,
        FoodTypes,
        SwalHelper,
        Upload,
        $rootScope,
        $scope,
        $state,
        $transition$,
        appConfig,
        Restangular
    ) {
        // set up pusher //
        if ($transition$.params().id) {
            const channel_name = `presence-news-edit-${$transition$.params().id}`;
            PusherService.checkConcurrency(channel_name, $scope);
        }

        const vm = this;

        vm.currentNews = currentObj || {};
        vm.datepickToggle = datepickToggle;
        vm.datepickStatus = {
            openedStart: false,
            openedEnd: false,
        };
        vm.hours = range(23);
        vm.filterActiveLocations = filterActiveLocations;
        vm.getLocations = getLocations;
        vm.getProducts = getProducts;
        vm.isEdit = !!_.has(vm.currentNews, 'NewsId');
        vm.lang = $rootScope.languages;
        vm.toggleImageSelector = toggleImageSelector;
        vm.duplicateNews = duplicateNews;

        vm.expiryComparison = new Date(new Date().getTime() - 86399000); // alter datetime now the same way as EndDate is altered below

        vm.saveObj = saveObj;
        vm.toggleActive = toggleActive;

        if (vm.currentNews.IsLocationBased !== false) {
            vm.getLocations();
        }

        if (!vm.isEdit) {
            setupNews();
        } else {
            setupExisting();
        }

        function convertHourFromUTC(h, date) {
            const d = new Date(date);

            d.setUTCHours(h);

            return d.getHours();
        }

        function convertHourToUTC(h, date) {
            const d = new Date(date);

            d.setHours(h);

            return d.getUTCHours();
        }

        function duplicateNews() {
            Restangular.one('api/News/Duplicate/' + vm.currentNews.NewsId)
                .post()
                .then(
                    function() {
                        SwalHelper.showSaved();
                    },
                    function() {
                        SwalHelper.showServerError();
                    }
                );
        }

        function datepickToggle() {
            vm.datepickStatus.openedStart = true;
            vm.datepickStatus.openedEnd = true;
        }

        function filterActiveLocations(e) {
            if ((!e.TerminationDate || new Date(e.TerminationDate) > new Date()) && !e.IsInternal) {
                if (_.findIndex(vm.logschedules, { LocationId: e.LocationId }) > -1) {
                    return false;
                }
                return true;
            }
            return false;
        }

        function getLocations() {
            if (!vm.locations) {
                Locations.getList({ selectList: true }).then(
                    function(data) {
                        vm.locations = Common.sortByName(data.plain(), 'LocationName');
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
            }
        }

        function getProducts() {
            if (!vm.products) {
                return Products.one()
                    .get({ page: 1, pageSize: '500', IsActive: true })
                    .then(
                        function(data) {
                            vm.products = data.plain().Result;
                            for (let i = 0; i < vm.products.length; i++) {
                                Common.sortByName(vm.products[i].ProductLanguages, 'LanguageCode');
                            }
                        },
                        function(err) {
                            SwalHelper.showServerError();
                        }
                    );
            }
        }

        function range(x) {
            const rng = [];

            for (let i = 0; i < x + 1; i++) {
                rng.push(i);
            }

            return rng;
        }

        // function saves everything in one go. saves object first, return ProductId in order to save pricing, ingredients then image.
        function saveObj(exit) {
            validation();

            if (_.has(vm.currentNews, 'NewsId')) {
                vm.currentNews.put().then(function() {
                    if (vm.image1) {
                        uploadImageSingle(vm.currentNews, vm.image1).then(function(succ) {
                            saveSuccess(vm.currentNews.NewsId, exit);
                        });
                    } else {
                        saveSuccess(vm.currentNews.NewsId, exit);
                    }
                });
            } else {
                News.post(vm.currentNews).then(function(data) {
                    if (vm.image1) {
                        uploadImageSingle(data, vm.image1).then(function(succ) {
                            saveSuccess(data.NewsId, exit);
                        });
                    } else {
                        saveSuccess(data.NewsId, exit);
                    }
                });
            }
        }

        function saveSuccess(id, exit) {
            SwalHelper.showSaved('', function() {
                if (exit) {
                    $state.go('users.news_list');
                } else {
                    $state.go('users.news_edit', { id }, { reload: true });
                }
            });
        }

        function setupNews() {
            vm.currentNews.NewsLanguages = [];

            const langs = ['de-CH', 'en-US', 'fr-CH'];

            angular.forEach(langs, function(value, key) {
                vm.currentNews.NewsLanguages.push({
                    LanguageCode: value,
                    SubTitle: '',
                    Description: '',
                });
            });

            vm.currentNews.IsActive = true;
            vm.currentNews.IsLocationBased = true;

            vm.currentNews.TimeStart = 0;
            vm.currentNews.TimeEnd = 23;

            vm.currentNews.Mon = true;
            vm.currentNews.Tue = true;
            vm.currentNews.Wed = true;
            vm.currentNews.Thu = true;
            vm.currentNews.Fri = true;
            vm.currentNews.Sat = true;
            vm.currentNews.Sun = true;
        }

        function setupExisting() {
            vm.currentImage = vm.currentNews.MediaImage.LGUrl;

            vm.currentNews.CreateDate = new Date(vm.currentNews.CreateDate);
            vm.currentNews.UpdateDate = new Date(vm.currentNews.UpdateDate);

            vm.currentNews.DateStart = new Date(vm.currentNews.DateStart);

            Common.sortByName(vm.currentNews.NewsLanguages, 'LanguageCode');

            // on the backend it adds 1 day minus 1 second, so we substract it back here.
            let d = new Date(vm.currentNews.DateEnd);
            if (vm.currentNews.IsActive) {
                d = d.getTime() - 86399000;
            }
            vm.currentNews.DateEnd = new Date(d);

            if (vm.currentNews.IsLocationBased) {
                getLocations();
            }
        }

        function toggleActive(bool) {
            // activate
            if (bool) {
                vm.currentNews.IsActive = true;
                saveObj(false);
            }
            // deactivate
            else {
                vm.currentNews.IsActive = false;
                // so that it expires immediately
                vm.currentNews.DateEnd = new Date();

                saveObj(false);
            }
        }

        function toggleImageSelector() {
            vm.useExistingImage = !vm.useExistingImage;

            if (vm.useExistingImage) {
                vm.image1 = null;
                getProducts();
            } else {
                vm.currentNews.MediaImage = null;
            }
        }

        // uploads image
        // img is image to upload
        // obj is News
        function uploadImageSingle(obj, img) {
            console.log('save image');
            const url = `${appConfig.baseUrl}api/FileManager?MediaImageForeignGuid=${
                obj.NewsId
            }&MediaImageType=News`;

            img.upload = Upload.upload({
                url,
                data: { file: img },
            });

            // returns a promise
            return img.upload;
        }

        function validation() {
            // ensures that start date and end date has minutes, seconds = 0
            if (vm.currentNews.IsActive) {
                vm.currentNews.DateStart.setHours(0, 0, 0, 0);
                vm.currentNews.DateEnd.setHours(0, 0, 0, 0);
            } else {
                let d = new Date(vm.currentNews.DateEnd);
                d = d.getTime() - 86399000;
                vm.currentNews.DateEnd = new Date(d);
            }

            // copies over the image id in case of UseExisting
            if (vm.useExistingImage && vm.currentNews.MediaImage) {
                vm.currentNews.MediaImageId = vm.currentNews.MediaImage.MediaImageId;
                vm.currentNews.MediaImage = null;
            }

            // make sure MediaImageId has a value if MediaImage is selected
            // otherwise backend throws integrity error
            if (vm.currentNews.MediaImage) {
                vm.currentNews.MediaImageId = vm.currentNews.MediaImage.MediaImageId;
            }
        }
    }
    // end second controller
})();
