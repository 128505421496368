(function() {
    angular.module('felfel').factory('DeliveryTours', DeliveryTours);

    DeliveryTours.$inject = ['Restangular'];
    function DeliveryTours(Restangular) {
        const DeliveryTours = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'DeliveryTourId',
            });
        });
        return DeliveryTours.service('api/delivery-tours');
    }
})();
