(function() {
    angular.module('felfel').directive('tableHeaderFilter', tableHeaderFilter);

    function tableHeaderFilter() {
        const directive = {
            replace: true,
            templateUrl: './templates/directives/table-header-filter.html',
            scope: {
                dispName: '@',
                fieldName: '@',
            },
            // controller: ExampleController,
            // controllerAs: 'vm',
            // bindToController: true,
            transclude: true,
        };

        return directive;
    }

    // function ExampleController() {
    //     var vm = this;
    //     vm.min = 3;
    //     console.log('CTRL: vm.min = %s', vm.min);
    //     console.log(vm.dispName);
    //     console.log($parent.vm.sort_pred);
    // }
})();
