(function() {
    angular.module('felfel').factory('Products', Products);

    Products.$inject = ['Restangular'];
    function Products(Restangular) {
        const Products = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'ProductId',
            });
        });

        // argument is the RESTapi URL
        return Products.service('api/Products');
    }
})();
