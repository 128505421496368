(function() {
    const app = angular.module('felfel.ingredient.routes', ['ui.router', 'felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const ingredientList = {
            name: 'products.ingredients_list',
            url: '/ingredients/{page:int}?search',
            params: { page: 1, search: undefined },
            templateUrl: './templates/products/ingredients/ingredient.html',
            controller: 'Ingredient',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const ingredientEdit = {
            name: 'products.ingredients_edit',
            url: '/ingredients/edit/:id',
            params: { id: '' },
            templateUrl: './templates/products/ingredients/ingredient-edit.html',
            controller: 'IngredientEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
                products,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(ingredientList))
            .state(new appConfig.UiRouterConfig(ingredientEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['Ingredients100g', '$transition$'];
    function allObjs(Ingredients100g, $transition$) {
        const { page } = $transition$.params();

        if (_.isUndefined($transition$.params().search)) {
            return Ingredients100g.get({ page: page, pageSize: '20' }).then(
                function(data) {
                    swal.close();
                    return data;
                },
                function(errMsg) {
                    swal(errMsg);
                }
            );
        }
        return Ingredients100g.get({
            search: $transition$.params().search,
            amount: 20,
            page: page,
        }).then(
            function(data) {
                swal.close();
                return data;
            },
            function(errMsg) {
                swal(errMsg);
            }
        );
    }

    currentObj.$inject = ['Ingredients100g', '$transition$'];
    function currentObj(Ingredients100g, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return Ingredients100g.get({}, $transition$.params().id).then(
                function(data) {
                    return data;
                },
                function(err) {
                    swal({
                        title: '',
                        text: 'Ingredient does not exist!',
                        type: 'error',
                    });
                }
            );
        }
    }

    products.$inject = ['Ingredients', '$transition$'];
    function products(Ingredients, $transition$) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return Ingredients.one($transition$.params().id)
                .one('Products')
                .get()
                .then(
                    function(data) {
                        return data;
                    },
                    function(err) {
                        swal({
                            title: '',
                            text: 'Error fetching related products!',
                            type: 'error',
                        });
                    }
                );
        }
    }
})();
