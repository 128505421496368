(function() {
    const app = angular.module('felfel.supplierorders.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const supplierOrdersList = {
            name: 'inventories.supplierorders_list',
            url: '/supplierorders/?page',
            templateUrl: './templates/inventories/supplierorders/supplierorders.html',
            controller: 'SupplierOrdersList',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
            // template: '<supplier-orders />',
        };

        const snackOrders = {
            name: 'inventories.supplierorders_new',
            url: '/supplierorders/snackorders/?orderId&supplierId',
            templateUrl: './templates/inventories/supplierorders/snackorders.html',
            controller: 'SnackOrders',
            controllerAs: 'vm',
            resolve: {
                allSuppliers,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(supplierOrdersList))
            .state(new appConfig.UiRouterConfig(snackOrders));
    }

    //
    // resolve functions
    //
    allSuppliers.$inject = ['Suppliers', 'SwalHelper'];
    function allSuppliers(Suppliers, SwalHelper) {
        return Suppliers.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showError(err);
            }
        );
    }

    allObjs.$inject = ['SupplierOrders', 'SwalHelper', '$transition$'];
    function allObjs(SupplierOrders, SwalHelper, $transition$) {
        if (_.isEmpty($transition$.params().page)) {
            var page = '1';
        } else {
            var { page } = $transition$.params();
        }

        return SupplierOrders.one()
            .get({ page, page_size: 100 })
            .then(
                function(data) {
                    data = data.plain();
                    return data;
                },
                function(err) {
                    SwalHelper.showError(err);
                }
            );
    }
})();
