(function() {
    angular.module('felfel').factory('CashVouchers', CashVouchers);

    CashVouchers.$inject = ['Restangular'];
    function CashVouchers(Restangular) {
        const CashVouchers = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'CashVoucherId',
            });
        });

        var testTagging = false;

        // argument is the RESTapi URL
        return CashVouchers.service('api/CashVouchers');
    }
})();
