(function() {
    const app = angular.module('felfel.forecasting.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const forecastingAbstract = {
            abstract: true,
            name: 'forecasting',
            url: '/forecasting',
            template: '<ui-view/>',
            // templateUrl: 'templates/users/users-abstract.html'
        };

        const forecastingEdit = {
            name: 'forecasting.forecasting_edit',
            url: '/forecasting/edit/?forecastweekid&locationid',
            templateUrl: './templates/forecasting/forecasting-edit.html',
            controller: 'ForecastingEdit',
            controllerAs: 'vm',
            resolve: {
                forecasts,
                forecastweeks,
                forecastweeklocations,
                allLocations,
                allProducts,
            },
        };

        const forecastingList = {
            name: 'forecasting.forecasting_list',
            url: '/forecasting/',
            templateUrl: './templates/forecasting/forecasting.html',
            controller: 'ForecastingList',
            controllerAs: 'vm',
            resolve: {
                forecastweeks,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(forecastingAbstract))
            .state(new appConfig.UiRouterConfig(forecastingEdit))
            .state(new appConfig.UiRouterConfig(forecastingList));
    }

    //
    // resolve functions
    //
    allLocations.$inject = ['Locations', 'SwalHelper'];
    function allLocations(Locations, SwalHelper) {
        return Locations.getList({ selectList: true }).then(
            function(succ) {
                return succ.plain();
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allProducts.$inject = ['Products', 'SwalHelper'];
    function allProducts(Products, SwalHelper) {
        return Products.one()
            .get({ page: 1, pageSize: '1000', IsActive: true })
            .then(
                function(succ) {
                    return succ.plain().Results;
                },
                function(err) {
                    SwalHelper.showServerError();
                }
            );
    }

    forecasts.$inject = ['Forecasts', 'SwalHelper', '$transition$'];
    function forecasts(Forecasts, SwalHelper, $transition$) {
        return Forecasts.one()
            .get({
                locationid: $transition$.params().locationid,
                forecastweekid: $transition$.params().forecastweekid,
            })
            .then(
                function(data) {
                    // console.log("currentObj", data);
                    return data.plain();
                },
                function(err) {
                    SwalHelper.showError(err);
                }
            );
    }

    forecastweeks.$inject = ['WebAPIForecastWeeks', 'SwalHelper'];
    function forecastweeks(ForecastWeeks, SwalHelper) {
        return ForecastWeeks.one()
            .get()
            .then(
                function(data) {
                    const res = data.plain().results.map(item => {
                        const dto = {};
                        dto.forecastweekid = item.forecastWeekId;
                        dto.createdate = item.createDate + 'Z';
                        dto.ordered = item.ordered;
                        dto.updatedate = item.updateDate + 'Z';
                        dto.weekstartdate = item.weekStartDate + 'Z';

                        return dto;
                    });
                    // console.log(res)
                    // console.log(data.plain().results)
                    return res;
                },
                function(err) {
                    SwalHelper.showError(err);
                }
            );
    }

    forecastweeklocations.$inject = ['ForecastWeeks', 'SwalHelper', '$transition$'];
    function forecastweeklocations(ForecastWeeks, SwalHelper, $transition$) {
        return [];
    }
})();
