(function() {
    angular
        .module('felfel')
        .controller('PackagingType', PackagingType)
        .controller('PackagingTypeEdit', PackagingTypeEdit);

    // first controller
    PackagingType.$inject = ['PackagingTypes', 'allObjs', 'SwalHelper', '$rootScope', '$state'];
    function PackagingType(PackagingTypes, allObjs, SwalHelper, $rootScope, $state) {
        $rootScope.title = 'Packaging Types';

        const vm = this;

        vm.deleteObj = deleteObj;
        vm.sort = sort;
        vm.sort_pred = 'PackagingName'; // for sorting table, default options
        vm.sort_rev = false; // for sorting table, default options
        vm.types = allObjs;

        function deleteObj(type) {
            SwalHelper.confirmDelete(type.PackagingName, function() {
                PackagingTypes.one(type.PackagingTypeId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(type.PackagingName, function() {
                            $state.go('products.packagingtypes_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }
    // end first controller

    // second controller
    PackagingTypeEdit.$inject = ['PackagingTypes', 'currentObj', 'SwalHelper', '$state'];
    function PackagingTypeEdit(PackagingTypes, currentObj, SwalHelper, $state) {
        const vm = this;

        vm.currentType = currentObj || {};
        vm.clearForm = clearForm;
        vm.deleteObj = deleteObj;
        vm.isEdit = !!_.has(vm.currentType, 'PackagingTypeId');
        vm.saveObj = saveObj;

        function clearForm(form) {
            console.log(form);
            form.name.$setValidity('required', false);
            form.cost.$setValidity('required', false);
        }

        function deleteObj() {
            console.log('delete');
            SwalHelper.confirmDelete(vm.currentType.PackagingName, function() {
                PackagingTypes.one(vm.currentType.PackagingTypeId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(vm.currentType.PackagingName, function() {
                            $state.go('products.packagingtypes_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function saveObj(exit) {
            vm.exit = exit;
            if (_.has(vm.currentType, 'PackagingTypeId')) {
                vm.currentType.put().then(function() {
                    SwalHelper.showSaved('', function() {
                        if (vm.exit) {
                            $state.go('products.packagingtypes_list');
                        } else {
                            $state.go('products.packagingtypes_edit', {
                                id: vm.currentType.PackagingTypeId,
                            });
                        }
                    });
                });
            } else {
                PackagingTypes.post(vm.currentType).then(function(data) {
                    SwalHelper.showSaved('', function() {
                        if (vm.exit) {
                            $state.go('products.packagingtypes_list');
                        } else {
                            $state.go('products.packagingtypes_edit', {
                                id: data.PackagingTypeId,
                            });
                        }
                    });
                });
            }
        }
    }
    // end second controller
})();
