(function() {
    const app = angular.module('felfel.inventories.routes', [
        'felfel.config',
        'felfel.deliverybatches.routes',
        'felfel.picklists.routes',
        'felfel.inventorylocations.routes',
        'felfel.inventoryoverview.routes',
        'felfel.items.routes',
        'felfel.internalorders.routes',
        'felfel.supplierorders.routes',
    ]);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const inventoriesAbstract = {
            abstract: true,
            name: 'inventories',
            url: '/inventories',
            templateUrl: './templates/inventories/inventories-abstract.html',
        };

        $stateProvider.state(new appConfig.UiRouterConfig(inventoriesAbstract));
    }
})();
