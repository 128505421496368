(function() {
    const app = angular.module('felfel.homedelivery.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const orderList = {
            name: 'homedelivery.orders_list',
            url:
                '/orders/?searchAliasId&searchCreatedAt&searchDeliveryDate&searchDeliveryType&searchEmail&searchHomeDeliveryOrderId&searchLocation&searchPage&searchPageSize&searchOrderBy&searchStatus',
            templateUrl: './templates/users/home-delivery/orders.html',
            controller: 'HomeDeliveryOrders',
            controllerAs: 'vm',
            //reloadOnSearch: false,
            resolve: {
                orders,
                locations,
            },
        };

        const deliveryDefaults = {
            name: 'homedelivery.delivery_defaults',
            url: '/delivery-defaults',
            templateUrl: './templates/users/home-delivery/delivery-defaults.html',
            controller: 'DeliveryDefaults',
            controllerAs: 'vm',
            //reloadOnSearch: false,
            resolve: {},
        };

        const homeDeliveryOrderEdit = {
            name: 'homedelivery.orders_edit',
            url: '/orders/edit/:id',
            params: { id: '' },
            templateUrl: './templates/users/home-delivery/order-edit.html',
            controller: 'HomeDeliveryOrderEdit',
            controllerAs: 'vm',
            resolve: {
                deliveryTours,
            },
        };

        const homeDeliveryOrderProcessing = {
            name: 'homedelivery.order_processing',
            url: '/orders/processing?aliasId&deliveryDate&overrideDeliveryDateCheck&preview',
            templateUrl: './templates/users/home-delivery/order-processing.html',
            controller: 'HomeDeliveryOrderProcessing',
            controllerAs: 'vm',
            resolve: {},
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(orderList))
            .state(new appConfig.UiRouterConfig(deliveryDefaults))
            .state(new appConfig.UiRouterConfig(homeDeliveryOrderEdit))
            .state(new appConfig.UiRouterConfig(homeDeliveryOrderProcessing));
    }

    deliveryTours.$inject = ['Common', 'SwalHelper', 'DeliveryTours'];
    function deliveryTours(Common, SwalHelper, DeliveryTours) {
        return DeliveryTours.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    locations.$inject = ['Restangular', 'SwalHelper'];
    function locations(Restangular, SwalHelper) {
        return Restangular.all('api/v2/locations/list')
            .getList()
            .then(
                function(data) {
                    return data.plain();
                },
                function() {
                    SwalHelper.showServerError();
                }
            );
    }

    orders.$inject = ['HomeDelivery', '$transition$'];
    function orders(HomeDelivery, $transition$) {
        return HomeDelivery.one('orders/search')
            .get({
                aliasId: $transition$.params().searchAliasId,
                createdAt: $transition$.params().searchCreatedAt,
                deliveryDate: $transition$.params().searchDeliveryDate,
                deliveryType: $transition$.params().searchDeliveryType,
                userEmail: $transition$.params().searchEmail,
                homeDeliveryOrderId: $transition$.params().searchHomeDeliveryOrderId,
                locationId: $transition$.params().searchLocation,
                page: $transition$.params().searchPage,
                pageSize: $transition$.params().searchPageSize ? $transition$.params().searchPageSize : '20',
                sortOrder: $transition$.params().searchOrderBy
                    ? $transition$.params().searchOrderBy
                    : 'createdAtDesc',
                homeDeliveryOrderStateAliasId: $transition$.params().searchStatus,
            })
            .then(
                function(data) {
                    swal.close();
                    return data.plain();
                },
                function(errorResponse) {
                    if (errorResponse.status == 400 && errorResponse.data && errorResponse.data.Message) {
                        SwalHelper.showValidationError(errorResponse.data.Message);
                    } else {
                        SwalHelper.showServerError();
                    }
                }
            );
    }
})();
