(function() {
    angular.module('felfel').factory('Companies', Companies);

    Companies.$inject = ['Restangular'];
    function Companies(Restangular) {
        const Companies = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'CompanyId',
            });
        });
        return Companies.service('api/Companies/');
    }
})();
