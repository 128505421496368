(function() {
    angular
        .module('felfel.core.auth', ['felfel.config'])
        .factory('AuthenticationService', AuthenticationService)
        .factory('LoginRestangular', LoginRestangular);

    AuthenticationService.$inject = ['Users', 'LoginRestangular', '$rootScope', 'KeycloakAuth'];
    function AuthenticationService(Users, LoginRestangular, $rootScope, KeycloakAuth) {
        return {
            isLoggedIn,
            logout,
            refreshToken,
        };

        function isLoggedIn() {
            // console.log("AuthenticationService - authentication check");
            // KeycloakAuth.tokenParsed.sub let us get the user id from the auth token
            return Users.one(KeycloakAuth.tokenParsed.sub).get(); // returns a promise
        }

        function logout() {
            store.remove('ta');
            store.remove('tr');
            store.remove('felfelix');
            store.remove('user');
        }

        function refreshToken() {
            const data = `grant_type=refresh_token&refresh_token=${store.get('tr')}&client_id=admin-felfel`;

            return LoginRestangular.one('token').customPOST(
                data,
                '',
                {},
                {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            );
        }
    }

    LoginRestangular.$inject = ['Restangular', 'appConfig'];
    function LoginRestangular(Restangular, appConfig) {
        if (!store.get('id')) {
            const id = Math.random()
                .toString(36)
                .substring(2);
            store.set('id', id);
        }

        return Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setBaseUrl(appConfig.baseUrl).addFullRequestInterceptor(function(
                elem,
                operation,
                path,
                url,
                headers,
                params,
                httpConfig
            ) {
                return { headers: { 'X-Device': store.get('id') } };
            });
        });
    }
})();
