(function() {
    angular.module('felfel').controller('TempCharts', TempCharts);

    TempCharts.$inject = ['$scope', '$rootScope', 'Common', 'Locations', 'Restangular'];
    function TempCharts($scope, $rootScope, Common, Locations, Restangular) {
        $rootScope.title = 'TempCharts';

        const vm = this;
        vm.loadTempChart = loadTempChart;

        function loadTempChart() {
            const toDate = moment();
            const fromDate = moment().add(-1 * vm.prevHours, 'hour');

            vm.chartObject = {};
            vm.chartObject.type = 'LineChart';

            vm.chartObject.data = {
                cols: [
                    { id: 'time', label: 'Time', type: 'datetime' },
                    { id: 'location', label: 'Location 1', type: 'number' },
                    { type: 'string', role: 'tooltip', p: { html: true } },
                ],
                rows: [],
            };

            const j = _.findIndex(vm.allLocations, {
                LocationId: vm.query_location,
            });
            const locationName = vm.allLocations[j].LocationName;

            Restangular.all('api/LocationStatistics')
                .one(vm.query_location)
                .get({
                    page: 1,
                    pageSize: 5000,
                    fromdate: fromDate.format(),
                    todate: toDate.format(),
                })
                .then(
                    function(succ) {
                        console.log('data returned');
                        vm.data = succ.Result;
                        for (let i = 0; i < vm.data.length; i++) {
                            const date = new Date(vm.data[i].CreateDate);
                            const temp = vm.data[i].Temperature / 100;
                            const tooltip = `<p>${locationName}</p><p>${date
                                .toString()
                                .slice(0, 21)}</p><p>Temperature: ${temp}C </p>`;

                            vm.chartObject.data.rows.push({
                                c: [{ v: date }, { v: temp }, { v: tooltip }],
                            });
                        }

                        setChartOptions();
                    },
                    function(err) {
                        console.log('Error: ', err);
                    }
                );

            function setChartOptions() {
                vm.chartObject.options = {
                    hAxis: {
                        viewWindow: {
                            min: new Date(vm.data[vm.data.length - 1].CreateDate),
                            max: new Date(vm.data[0].CreateDate),
                        },
                        gridlines: {
                            count: -1,
                            units: {
                                days: { format: ['MMM dd'] },
                                hours: { format: ['HH:mm', 'HH:00'] },
                            },
                        },
                        minorGridlines: {
                            units: {
                                hours: { format: ['HH:mm:ss', 'HH:00'] },
                                minutes: { format: ['HH:mm', ':mm'] },
                            },
                        },
                        title: 'Time',
                        textStyle: { fontName: 'Ubuntu' },
                        titleTextStyle: {
                            fontName: 'Ubuntu',
                            italic: false,
                            bold: true,
                        },
                    },

                    vAxis: {
                        title: 'Temperature (C)',
                        textStyle: { fontName: 'Ubuntu' },
                        titleTextStyle: {
                            fontName: 'Ubuntu',
                            italic: false,
                            bold: true,
                        },
                    },

                    legend: {
                        position: 'none',
                    },

                    tooltip: {
                        isHtml: true,
                    },

                    chartArea: {
                        width: '80%',
                        height: '80%',
                    },
                };
            }
        }

        // location temperature
        // define date
        Locations.getList({ selectList: true }).then(
            function(data) {
                vm.allLocations = data;

                Common.sortByName(vm.allLocations, 'LocationName');
                vm.query_location = 'D5D19DA0-6838-11E5-9225-00AC14EF2300'.toLowerCase();
                loadTempChart();
            },
            function(err) {
                console.log(err);
            }
        );
    }
})();
