(function() {
    angular.module('felfel').factory('Ingredients', Ingredients);

    Ingredients.$inject = ['Restangular'];
    function Ingredients(Restangular) {
        const Ingredients = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'IngredientId',
            });
        });
        return Ingredients.service('api/Ingredients');
    }
})();

const NUTRITIONAL_VALUE_KEYS = ['KCal', 'Carbs', 'Fat', 'Protein'];

(function() {
    angular.module('felfel').factory('Ingredients100g', Ingredients);

    Ingredients.$inject = ['Ingredients', 'Restangular'];
    function Ingredients(Ingredients, Restangular) {
        function transformFrom1gTo100g(ingredient) {
            NUTRITIONAL_VALUE_KEYS.forEach(v => {
                if (ingredient[v]) {
                    ingredient[v] = ingredient[v] * 100;
                    ingredient[v] = Number(ingredient[v].toPrecision(3));
                }
            });

            return ingredient;
        }

        function transformFrom100to1g(ingredient) {
            NUTRITIONAL_VALUE_KEYS.forEach(
                v => (ingredient[v] = Number((ingredient[v] / 100).toPrecision(3)))
            );
            return ingredient;
        }

        return {
            get(params = { page: 1 }, id = '') {
                return id.length > 0
                    ? Restangular.one('api/Ingredients')
                          .one(id)
                          .get(params)
                          .then(data => {
                              Array.isArray(data.Result)
                                  ? data.Result.map(ingredient => transformFrom1gTo100g(ingredient))
                                  : transformFrom1gTo100g(data);

                              return data;
                          })
                    : Restangular.one('api/v2/Ingredients')
                          .one(id)
                          .get(params)
                          .then(data => {
                              Array.isArray(data.Result)
                                  ? data.Result.map(ingredient => transformFrom1gTo100g(ingredient))
                                  : transformFrom1gTo100g(data);

                              return data;
                          });
            },
            update(ingredient) {
                return Ingredients.one(ingredient.IngredientId).customPUT(transformFrom100to1g(ingredient));
            },
            create(ingredient) {
                return Ingredients.post(transformFrom100to1g(ingredient));
            },
            delete(ingredientId) {
                return Ingredients.one(ingredientId).remove();
            },
        };
    }
})();
