(function() {
    const app = angular.module('felfel.account.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const adjustments = {
            name: 'users.adjustments',
            url: '/adjustments?email&userid',
            templateUrl: './templates/users/accounts/account-adjustment.html',
            controller: 'Adjustment',
            controllerAs: 'vm',
            resolve: {},
        };

        const createCodes = {
            name: 'users.create_codes',
            url: '/create_codes',
            templateUrl: './templates/users/accounts/account-create-activation-codes.html',
            controller: 'CreateCodes',
            controllerAs: 'vm',
            resolve: {
                allLocations,
            },
        };

        const registerByBatch = {
            name: 'users.register_batch',
            url: '/register_batch',
            templateUrl: './templates/users/accounts/account-register-batch.html',
            controller: 'RegisterByBatch',
            controllerAs: 'vm',
            resolve: {
                allLocations,
            },
        };

        const registerSingle = {
            name: 'users.register_single',
            url: '/register_single',
            templateUrl: './templates/users/accounts/account-register-single.html',
            controller: 'RegisterSingle',
            controllerAs: 'vm',
            resolve: {
                allLocations,
                allPaymentMethods,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(adjustments))
            .state(new appConfig.UiRouterConfig(createCodes))
            .state(new appConfig.UiRouterConfig(registerByBatch))
            .state(new appConfig.UiRouterConfig(registerSingle));
    }

    //
    // resolve functions
    //
    allLocations.$inject = ['Locations', 'SwalHelper'];
    function allLocations(Locations, SwalHelper) {
        return Locations.getList({ selectList: true }).then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    allPaymentMethods.$inject = ['PaymentMethods'];
    function allPaymentMethods(PaymentMethods) {
        return PaymentMethods.getList().then(
            function(data) {
                return data.plain();
            },
            function(err) {
                swal(errMsg);
            }
        );
    }
})();
