(function() {
    angular.module('felfel').factory('PickListProducts', PickListProducts);

    PickListProducts.$inject = ['Restangular'];
    function PickListProducts(Restangular) {
        const PickListProducts = Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setRestangularFields({
                id: 'PickListProductId',
            });
        });

        // argument is the RESTapi URL
        return PickListProducts.service('api/PickListProducts');
    }
})();
