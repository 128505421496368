(function() {
    const app = angular.module('felfel.operations.routes', [
        'felfel.config',
        'felfel.logschedules.routes',
        'felfel.logisticsbadge.routes',
    ]);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const operationsAbstract = {
            abstract: true,
            name: 'operations',
            url: '/operations',
            template: '<ui-view/>',
            // templateUrl: 'templates/users/users-abstract.html'
        };

        const opsTempCharts = {
            name: 'operations.temp_charts',
            url: '/temp-charts',
            templateUrl: './templates/operations/temperature.html',
            // template: '<temperature-charts vm="vm.data"/>',
            controller: 'TempCharts',
            controllerAs: 'vm',
            resolve: {},
        };

        const opsOfflineHistory = {
            name: 'operations.offline_history',
            url: '/offline-history',
            templateUrl: './templates/operations/offline-history.html',
            controller: 'OfflineHistory',
            controllerAs: 'vm',
            resolve: {},
        };

        const opsMapNew = {
            url: '/map-new',
            name: 'operations.ops_map_new',
            templateUrl: './templates/operations/ops-map-new.html',
        };

        const opsMapFull = {
            url: '/map-full',
            name: 'operations.ops_map_full',
            templateUrl: './templates/operations/ops-map-full.html',
            controller: 'OpsMapNew',
            controllerAs: 'vm',
            resolve: {
                allLocations,
                lastLocationStats,
                lastLogEntries,
            },
        };

        const opsChecklists = {
            name: 'operations.ops_checklists',
            url: '/operations/checklists',
            templateUrl: './templates/operations/checklists.html',
            controller: 'OpsChecklists',
            controllerAs: 'vm',
            resolve: {
                allServiceTiers,
            },
        };

        const opsDriversTraining = {
            name: 'operations.ops_drivers_training',
            url: '/operations/drivers-training',
            templateUrl: './templates/operations/ops-drivers-training.html',
            controller: 'OpsDriversTraining',
            controllerAs: 'vm',
            resolve: {},
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(operationsAbstract))
            .state(new appConfig.UiRouterConfig(opsTempCharts))
            .state(new appConfig.UiRouterConfig(opsOfflineHistory))
            .state(new appConfig.UiRouterConfig(opsMapNew))
            .state(new appConfig.UiRouterConfig(opsMapFull))
            .state(new appConfig.UiRouterConfig(opsChecklists))
            .state(new appConfig.UiRouterConfig(opsDriversTraining));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['Common', 'Locations', 'SwalHelper'];
    function allObjs(Common, Locations, SwalHelper) {
        // DO NOT CHANGE THIS: it overwrites Locations
        return Locations.getList().then(
            function(data) {
                return Common.sortByName(data, 'LocationName');
            },
            function(err) {
                // console.log((Locations get error");
                SwalHelper.showServerError();
            }
        );
    }

    allLocations.$inject = ['Locations', 'SwalHelper'];
    function allLocations(Locations, SwalHelper) {
        return Locations.getList({ selectList: true }).then(
            function(data) {
                // console.log("allCats", data);
                return data.plain();
            },
            function(err) {
                // console.log((Locations get error");
                SwalHelper.showServerError();
            }
        );
    }

    allServiceTiers.$inject = ['ServiceTiers', 'SwalHelper'];
    function allServiceTiers(ServiceTiers, SwalHelper) {
        return ServiceTiers.getList().then(
            function(data) {
                return data;
            },
            function(err) {
                SwalHelper.showServerError();
            }
        );
    }

    lastLocationStats.$inject = ['Restangular', 'SwalHelper'];
    function lastLocationStats(Restangular, SwalHelper) {
        return Restangular.one('api/LastLocationStatistic')
            .get({ period: 3 })
            .then(
                function(data) {
                    // console.log("allCats", data);
                    return data.plain();
                },
                function(err) {
                    // console.log((Locations get error");
                    SwalHelper.showServerError();
                }
            );
    }

    lastLogEntries.$inject = ['Restangular', 'SwalHelper'];
    function lastLogEntries(Restangular, SwalHelper) {
        return Restangular.one('api/InventoryManagement/LastLogEntries')
            .get()
            .then(
                function(data) {
                    // console.log("allCats", data);
                    return data.plain();
                },
                function(err) {
                    // console.log((Locations get error");
                    SwalHelper.showServerError();
                }
            );
    }
})();
