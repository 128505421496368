(function() {
    angular
        .module('felfel')
        .controller('SupplierOrdersList', SupplierOrdersList)
        .controller('SnackOrders', SnackOrders);

    SupplierOrdersList.$inject = [
        'allObjs',
        'appConfig',
        'SwalHelper',
        'SupplierOrders',
        'SupplierOrderItems',
        'Restangular',
        '$state',
        '$transition$',
    ];
    function SupplierOrdersList(
        allObjs,
        appConfig,
        SwalHelper,
        SupplierOrders,
        SupplierOrderItems,
        Restangular,
        $state,
        $transition$
    ) {
        const vm = this;
        vm.orders = {};
        vm.groups = {};
        vm.dayToggle = [];
        vm.sendMail = sendMail;
        vm.updateOrders = updateOrders;
        vm.deleteOrderItem = deleteOrderItem;
        vm.ixMediaUrl = appConfig.ixMediaUrl;
        vm.deleteOrder = deleteOrder;
        loadOrders(allObjs.results);
        groupOrders();

        function deleteOrder(order) {
            SwalHelper.confirm('Are you sure you want to delete the full order?', function() {
                SupplierOrders.one(order.supplierorderid)
                    .remove()
                    .then(
                        function(succ) {
                            let date = new Date(order.orderdate);
                            date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

                            vm.groups[date].orders = vm.groups[date].orders.filter(
                                or => or.supplierorderid != order.supplierorderid
                            );

                            if (vm.groups[date].orders.lenght === 0) delete vm.groups[date];

                            SwalHelper.showSuccess('Order Removed!');
                            swal.close();
                        },
                        function(err) {
                            SwalHelper.showError(err.data.error);
                            swal.close();
                        }
                    );
            });
        }

        function deleteOrderItem(order, delivery, orderItem) {
            SwalHelper.confirm('Are you sure you want to delete this item ?', function() {
                SupplierOrderItems.one(orderItem.supplierorderitemid)
                    .remove()
                    .then(
                        function(succ) {
                            let date = new Date(order.orderdate);
                            date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

                            vm.groups[date].orders = vm.groups[date].orders.map(or => {
                                if (or.createdate === order.createdate) {
                                    or.deliveries = or.deliveries.map(del => {
                                        if (del.$$hashKey === delivery.$$hashKey) {
                                            del.orderitems = del.orderitems.filter(
                                                oi => oi.supplierorderitemid != orderItem.supplierorderitemid
                                            );
                                        }
                                    });
                                }
                            });

                            swal.close();
                        },
                        function(err) {
                            SwalHelper.showError(err.data.error);
                        }
                    );
            });
        }

        function loadOrders(results) {
            for (let i = 0; i < results.length; i++) {
                const order = results[i];
                vm.orders[order.supplierorderid] = order;
            }
        }

        function groupOrders() {
            vm.groups = {};
            for (var key in vm.orders) {
                let date = new Date(vm.orders[key].orderdate);
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                const groupKey = date + (vm.orders[key].forecastweekid ? vm.orders[key].forecastweekid : '');
                if (!(groupKey in vm.groups)) {
                    const group = {};
                    group.orderdate = date;
                    group.forecastweek = vm.orders[key].forecastweek;
                    group.orders = [];
                    vm.groups[groupKey] = group;
                }

                vm.groups[groupKey].orders.push(vm.orders[key]);
            }

            for (var key in vm.groups) {
                vm.groups[key].orders = vm.groups[key].orders.sort(function(a, b) {
                    if (a.supplier.suppliername < b.supplier.suppliername) return -1;
                    if (a.supplier.suppliername > b.supplier.suppliername) return 1;
                    return 0;
                });
            }
        }

        function updateOrders(order, index) {
            SwalHelper.showSaving();

            const items = [];
            for (let i = 0; i < order.deliveries.length; i++) {
                for (let j = 0; j < order.deliveries[i].orderitems.length; j++) {
                    items.push(order.deliveries[i].orderitems[j]);
                }
            }

            // Update order items
            SupplierOrderItems.one()
                .customPUT(items, undefined, undefined, undefined)
                .then(
                    function(succ) {
                        reloadOrder(order);
                    },
                    function(err) {
                        SwalHelper.showError(err.data.error);
                    }
                );
        }

        function reloadOrder(order) {
            // Save order object and generate new pdf
            SupplierOrders.one(order.supplierorderid)
                .customPUT(order, undefined, undefined, undefined)
                .then(
                    function(succ) {
                        vm.orders[order.supplierorderid] = succ.plain();
                        groupOrders();
                        swal.close();
                    },
                    function(err) {
                        SwalHelper.showError(err.data.error);
                    }
                );
        }

        function sendMail(order) {
            const data = {
                supplierorderid: order.supplierorderid,
                remarks: order.remarks,
            };
            console.log(data);
            SwalHelper.confirmSend('order', function() {
                SwalHelper.showLoading();
                SupplierOrders.one('sendmail')
                    .customPOST(data, undefined, undefined, undefined)
                    .then(
                        function(succ) {
                            // update forecasts after successful PUT
                            SwalHelper.showSuccess('Sent!');
                            order.sent = true;
                        },
                        function(err) {
                            SwalHelper.showServerError();
                        }
                    );
            });
        }
    }

    SnackOrders.$inject = [
        'allSuppliers',
        'SwalHelper',
        'SupplierOrders',
        'Products',
        'SupplierOrderItems',
        'Restangular',
        '$state',
        '$transition$',
        '$stateParams',
    ];
    function SnackOrders(
        allSuppliers,
        SwalHelper,
        SupplierOrders,
        Products,
        SupplierOrderItems,
        Restangular,
        $state,
        $transition$,
        $stateParams
    ) {
        const vm = this;
        vm.suppliers = allSuppliers;
        vm.supplierId = $stateParams.supplierId ? $stateParams.supplierId : null;
        vm.orderId = $stateParams.orderId ? $stateParams.orderId : null;
        vm.addOrderItem = vm.supplierId && vm.orderId;
        vm.minDate = new Date();
        vm.orderDate = new Date();
        vm.deliveryDate = moment()
            .add(7, 'days')
            .toDate();
        vm.productionDate = moment()
            .add(7, 'days')
            .toDate();
        vm.amount = 0;
        vm.data = null;
        vm.getProductsBySupplier = getProductsBySupplier;
        vm.productList = null;
        vm.selectedProductId = null;
        vm.showProductDropdown = vm.addOrderItem;
        vm.createOrder = createOrder;

        vm.processOrder = processOrder;
        vm.storeOrderItem = storeOrderItem;

        if (vm.supplierId) vm.getProductsBySupplier();

        function createOrder() {
            const createOrderData = {
                orderdate: vm.orderDate.toISOString().slice(0, 10),
                supplierid_id: vm.supplierId,
            };

            SupplierOrders.one()
                .customPOST(createOrderData, undefined, undefined, undefined)
                .then(
                    function(succ) {
                        return succ;
                    },

                    function(err) {
                        SwalHelper.showServerError();
                    }
                )
                .then(orderData => {
                    storeOrderItem(orderData.supplierorderid);
                });
        }

        function storeOrderItem(supplierorderid) {
            vm.productionDate.setHours(0, 0, 0, 0);
            vm.deliveryDate.setHours(0, 0, 0, 0);

            const createOrderItemData = {
                quantity: vm.amount,
                adjustedquantity: vm.amount,
                productiondate: moment(vm.productionDate).format('YYYY-MM-DD'),
                deliverydate: moment(vm.deliveryDate).format('YYYY-MM-DD'),
                supplierorderid,
                productid: vm.selectedProductId,
            };

            SupplierOrderItems.one()
                .customPOST(createOrderItemData, undefined, undefined, undefined)
                .then(
                    function(succ) {
                        SwalHelper.showSuccess('Order created!', function() {
                            $state.go('inventories.supplierorders_list');
                        });
                    },
                    function(err) {
                        SwalHelper.showServerError();
                    }
                );
        }

        function processOrder() {
            SwalHelper.showLoading();
            vm.addOrderItem ? vm.storeOrderItem(vm.orderId) : vm.createOrder();
        }

        function getProductsBySupplier() {
            SwalHelper.showLoading();
            vm.showProductDropdown = false;

            Products.one()
                .get({ supplierid: vm.supplierId })
                .then(
                    function(succ) {
                        swal.close();
                        vm.productList = succ.Result.map(product => {
                            product.ProductName = product.ProductLanguages.find(
                                lang => lang.LanguageCode === 'en-US'
                            ).ProductName;
                            return product;
                        });
                        vm.showProductDropdown = true;
                    },

                    function(err) {
                        try {
                            swal.close();
                            SwalHelper.showError(err.data.error);
                        } catch (ex) {
                            SwalHelper.showError(err);
                        }
                    }
                );
        }
    }
})();
