(function() {
    const app = angular.module('felfel.company.routes', ['felfel.config']);

    app.config(Configuration);

    Configuration.$inject = ['$stateProvider', 'appConfig'];
    function Configuration($stateProvider, appConfig) {
        const companyList = {
            name: 'locations.companies_list',
            url: '/companies',
            templateUrl: './templates/locations/companies/company.html',
            controller: 'Company',
            controllerAs: 'vm',
            resolve: {
                allObjs,
            },
        };

        const companyEdit = {
            name: 'locations.companies_edit',
            url: '/companies/edit/:id',
            params: { id: '' },
            templateUrl: './templates/locations/companies/company-edit.html',
            controller: 'CompanyEdit',
            controllerAs: 'vm',
            resolve: {
                currentObj,
            },
        };

        $stateProvider
            .state(new appConfig.UiRouterConfig(companyList))
            .state(new appConfig.UiRouterConfig(companyEdit));
    }

    //
    // resolve functions
    //
    allObjs.$inject = ['Companies', 'SwalHelper'];
    function allObjs(Companies, SwalHelper) {
        return Companies.getList().then(
            function(data) {
                // console.log("allObjs", data);
                return data;
            },
            function(err) {
                // console.log("Companies get error");
                SwalHelper.showServerError();
            }
        );
    }

    currentObj.$inject = ['Companies', '$transition$', 'SwalHelper'];
    function currentObj(Companies, $transition$, SwalHelper) {
        // if $transition$.params().id is empty, it returns the whole list
        if ($transition$.params().id) {
            return Companies.one($transition$.params().id)
                .get()
                .then(
                    function(data) {
                        return data;
                    },
                    function(err) {
                        SwalHelper.showError('Company does not exist!');
                    }
                );
        }
    }
})();
