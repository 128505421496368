(function() {
    angular
        .module('felfel')
        .controller('FoodTag', FoodTag)
        .controller('FoodTagEdit', FoodTagEdit);

    // first controller
    FoodTag.$inject = ['FoodTags', 'allObjs', 'SwalHelper', '$state', '$rootScope'];
    function FoodTag(FoodTags, allObjs, SwalHelper, $state, $rootScope) {
        $rootScope.title = 'Food Tags';

        const vm = this;
        vm.tags = allObjs;
        vm.tags_en = filterEN(vm.tags);
        vm.deleteObj = deleteObj;

        // for sorting table, default options
        vm.sort_pred = 'name';
        vm.sort_rev = false;
        vm.sort = sort;

        function deleteObj(tag) {
            SwalHelper.confirmDelete(tag.name, function() {
                FoodTags.one(tag.id)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted(tag.name, function() {
                            $state.go('products.foodtags_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        // filter GET for only en-US results
        function filterEN(array) {
            const results = [];

            for (let i = 0; i < array.length; i++) {
                const id = array[i].FoodTagId;

                for (let j = 0; j < array[i].FoodTagLanguages.length; j++) {
                    const lang = _.get(array[i].FoodTagLanguages[j], 'LanguageCode');

                    if (lang == 'en-US') {
                        var name = array[i].FoodTagLanguages[j].FoodTagName;
                        break;
                    } else {
                        var name = 'No En Input';
                    }
                }

                results.push({ id, name });
            }

            return results;
        }

        function sort(predicate) {
            vm.sort_rev = vm.sort_pred === predicate ? !vm.sort_rev : false;
            vm.sort_pred = predicate;
        }
    }

    // second controller
    FoodTagEdit.$inject = [
        'FoodTags',
        'Upload',
        'currentObj',
        'productsWithFoodTag',
        'SwalHelper',
        '$state',
        '$rootScope',
        'appConfig',
    ];
    function FoodTagEdit(
        FoodTags,
        Upload,
        currentObj,
        productsWithFoodTag,
        SwalHelper,
        $state,
        $rootScope,
        appConfig
    ) {
        const vm = this;

        vm.currentTag = currentObj || {};
        vm.currentTag.FoodTagLanguages = vm.currentTag.FoodTagLanguages || [];
        vm.deleteObj = deleteObj;
        vm.productsWithFoodTag = productsWithFoodTag;

        vm.isEdit = false;
        if (_.has(vm.currentTag, 'FoodTagId')) {
            vm.isEdit = true;
        }

        vm.saveObj = saveObj;

        // check if all languages exist
        // loop through languageOptions defined in rootscope
        vm.lang = $rootScope.languages; // an object with properties language code: language name
        vm.languages = $rootScope.languageOptions; // an array with language codes
        for (let j = 0; j < vm.languages.length; j++) {
            const code = vm.languages[j];
            // if the language code does not exist, append to array
            if (!_.some(vm.currentTag.FoodTagLanguages, { LanguageCode: code })) {
                vm.currentTag.FoodTagLanguages.push({
                    FoodTagName: '',
                    LanguageCode: code,
                });
            }
        }

        function deleteObj() {
            console.log('delete');
            SwalHelper.confirmDelete('this Food Tag', function() {
                FoodTags.one(vm.currentTag.FoodTagId)
                    .remove()
                    .then(function() {
                        SwalHelper.showDeleted('Food Tag', function() {
                            $state.go('products.foodtags_list', {}, { reload: true });
                            swal.close();
                        });
                    });
            });
        }

        function removeImage() {
            delete vm.currentTag.MediaImageId;
            delete vm.currentTag.MediaImage;
            // console.log(vm.currentType);
        }

        function saveObj(exit, $flow) {
            vm.exit = exit;

            SwalHelper.showSavingMessage();

            if (_.has(vm.currentTag, 'FoodTagId')) {
                vm.currentTag.put().then(function(data) {
                    if (vm.image1) {
                        uploadImageSingle(vm.currentTag, vm.image1).then(function(succ) {
                            uploadSuccess();
                        });
                    } else {
                        uploadSuccess();
                    }
                });
            } else {
                FoodTags.post(vm.currentTag).then(function(data) {
                    vm.currentTag = data;
                    if (vm.image1) {
                        uploadImageSingle(data, vm.image1).then(function(succ) {
                            uploadSuccess();
                        });
                    } else {
                        uploadSuccess();
                    }
                });
            }
        }

        // uploads image
        // img is image to upload
        // obj is PromoRule
        function uploadImageSingle(obj, img) {
            console.log('save image');
            const url = `${appConfig.baseUrl}api/FileManager?MediaImageForeignGuid=${
                obj.FoodTagId
            }&MediaImageType=FoodTag`;

            img.upload = Upload.upload({
                url,
                data: { file: img },
            });

            // returns a promise
            return img.upload;
        }

        function uploadSuccess() {
            SwalHelper.showSaved('', function() {
                if (vm.exit) {
                    $state.go('products.foodtags_list');
                } else {
                    $state.go('products.foodtags_edit', {
                        id: vm.currentTag.FoodTagId,
                    });
                }
            });
        }
    }
})();
